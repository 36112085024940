import { IEventCategory } from '../services/EventCategoryService';
import { ABTestsSettings } from './ABTests';
import { AdSettings } from './AdSettings';
import { AnalyticSettings } from './Analytics';
import { AnnouncementMessage } from './AnnouncementMessage';
import { NestEnvironmentType } from './Enums';
import { ThemeSettings } from './ThemeSettings';
import { ThirdPartyScripts } from './ThirdPartyScripts';

export interface IArenaConfig {
    abTestsSettings: ABTestsSettings;
    announcementMessages: AnnouncementMessage[];
    feed: { promoGame: any; sorting: any; gamesRewrite: { [key: string]: string } };
    facebookAppId?: string;
    tags: Array<{ visible: boolean; name: string }>;
    theme: ThemeSettings;
    ad: AdSettings;
    analytics: AnalyticSettings;
    categories: { homeCategories: [{ name: string }]; allCategories: string[] };
    scripts: ThirdPartyScripts;
    sso?: { name: string; loginUrl: string };
    eventCategoryConfig?: IEventCategory;
    isEagle: boolean;
    isGdprSupport: boolean;
    isGemsSupport: boolean;
    openWebChatId?: string;
    userFeedbackUrl?: string;
    nestEnvironment: NestEnvironmentType;
}

export class ArenaConfig implements IArenaConfig {
    abTestsSettings: ABTestsSettings;
    announcementMessages: AnnouncementMessage[];
    feed: {
        promoGame: string | null;
        sorting: string;
        // TODO Remove if obsolete
        gamesRewrite: { [key: string]: string };
        feedId: string;
    };
    facebookAppId?: string;
    tags: Array<{ visible: boolean; name: string }>;
    theme: ThemeSettings;
    ad: AdSettings;
    analytics: AnalyticSettings;
    categories: {
        homeCategories: [{ name: string; eventCat: any }];
        allCategories: string[];
    };
    scripts: ThirdPartyScripts;
    sso?: {
        name: string;
        loginUrl: string;
    };
    eventCategoryConfig: IEventCategory;
    isEagle: boolean;
    isGemsSupport: boolean;
    isGdprSupport: boolean;
    openWebChatId?: string;
    userFeedbackUrl?: string;
    nestEnvironment: NestEnvironmentType;

    constructor(data, lang) {
        this.abTestsSettings = data.abTestsSettings;
        this.announcementMessages = data.announcementMessages || [];
        this.feed = data.feed || {};
        this.facebookAppId = data.facebookAppId;
        this.tags = data.tags || [];
        this.theme = new ThemeSettings(data.theme, lang);
        this.ad = new AdSettings(data.ad || {}, lang);
        this.analytics = new AnalyticSettings(data.analytics || {}, lang);
        this.categories = data.categories;
        this.scripts = new ThirdPartyScripts(data.scripts || {});
        this.sso = data.sso;
        this.eventCategoryConfig = data.eventCategoryConfig;
        this.isEagle = data.isEagle;
        this.isGdprSupport = data.isGdprSupport;
        this.isGemsSupport = data.isGemsSupport;
        this.openWebChatId = data.openWebChatId;
        this.isGemsSupport = data.isGemsSupport;
        this.userFeedbackUrl = data.userFeedbackUrl;
        this.nestEnvironment = data.nestEnvironment;
    }
}
