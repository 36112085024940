import { apiFetch } from '../../fetch';
import { EagleLoginService } from './EagleLoginService';
import { UrlService } from './UrlService';

interface IResult {
  u: string;
  e: string;
}

class HsnAuthService {
  async getUserInformation() {
    const arenaDomainUrl = new URL(UrlService.getCurrentOriginForEagle());
    const {
      u,
      e,
    }: IResult = await apiFetch(`${arenaDomainUrl.origin}/get-hsn-cookie`, { credentials: 'include' });

    await EagleLoginService.loginViaHsn({
      userId: u,
      email: e,
      registrationPlaceUrl: arenaDomainUrl.origin,
      registrationAppId: arenaDomainUrl.hostname,
    });
  }

  logout() {
    const arenaDomainUrl = new URL(UrlService.getCurrentOriginForEagle());

    return apiFetch(
      `${arenaDomainUrl.origin}/remove-hsn-cookie`, { credentials: 'include' },
    )
      .then(() => {
        return true;
      }).catch((err: Error) => {
        throw new Error(err.message);
      });
  }
}

export const hsnAuthService = new HsnAuthService();
