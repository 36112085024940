import { createAction, createReducer } from 'redux-act';
import { GameState } from '../../models/Enums';
import RemoteScoreService from '../../services/RemoteScoreService';
import UUPScoreService from '../../services/UUPScoreService';
import { logoutUser } from './user';
import { AppInsightsAnalytics } from '../../services/Analytics/AppInsights';

const setScoresState = createAction<number>('save game scores');
const setScoreSaving = createAction<boolean>('set score loading');

export const gameScoreReducer = createReducer<number>({}, null);
export const scoreSavingReducer = createReducer<boolean>({}, null);

gameScoreReducer.on(setScoresState, (_state, payload: number) => payload);
scoreSavingReducer.on(setScoreSaving, (_state, payload: boolean) => payload);

const tryToSaveScore = async (service, slug, score, dateTime, timeOffset) => {
    let count = 0;
    const requestToSave = () =>
        service
            .saveScoreToApi(slug, score, dateTime, timeOffset)
            .then(() => true)
            .catch(() => false);

    for (; count < 3; count++) {
        if (count > 0) {
            AppInsightsAnalytics.trackAppError('retry', {data: 'tryToSaveScore(), iteration=' + count});
        }

        const isSaveSuccessful = await requestToSave();

        if (isSaveSuccessful) {
            return true;
        }
    }

    return false;
};

/* Thunks */
export function saveScore(slug: string, score: number, dateTime: string, timeOffset: number) {
    return function (dispatch, getState) {
        const user = getState().user;
        const gameState = getState().gameState;
        const isEagle = getState().config.isEagle;

        dispatch(setScoresState(score));

        if (user && score && gameState !== GameState.GAME_SCORE_SAVED) {
            const ScoreService = isEagle ? UUPScoreService : RemoteScoreService;

            dispatch(setScoreSaving(true));
            tryToSaveScore(ScoreService, slug, score, dateTime, timeOffset)
                .then((resp) => dispatch(setScoreSaving(false)))
                .catch((error) => {
                    console.error('saveScoreToApi error: ', error.message);

                    if (error.name === 'HttpError' && error.response.status === 401) {
                        dispatch(logoutUser());
                    }
                });
        }
    };
}
