import { createAction, createReducer } from 'redux-act';

import { PaymentMethod } from '../../../models/Payment';

export const enum ReviewPaymentInfoStepContent {
  AuthForm,
  PurchaseDetails,
}

export const setRecurlyToken = createAction<null | string>('set recurly token');
export const recurlyTokenReducer = createReducer<null | string>({}, null);
recurlyTokenReducer.on(setRecurlyToken, (_state, payload) => payload);

export const setStepIndex = createAction<number>('set subscription page step');
export const stepIndexReducer = createReducer<number>({}, 0);
stepIndexReducer.on(setStepIndex, (_state, payload) => payload);

export const setPaymentMethod = createAction<PaymentMethod>('set payment method');
export const paymentMethodReducer = createReducer<PaymentMethod>({}, PaymentMethod.CARD);
paymentMethodReducer.on(setPaymentMethod, (_state, payload) => payload);

export type reviewPaymentInfoStepContent = {
  type: ReviewPaymentInfoStepContent;
  step: number;
};

export const setReviewPaymentInfoStepContent = createAction<reviewPaymentInfoStepContent>(
  'set subscription step 2 content',
);
export const reviewPaymentInfoStepContentReducer = createReducer<reviewPaymentInfoStepContent>(
  {},
  {
    type: ReviewPaymentInfoStepContent.PurchaseDetails,
    step: 1,
  },
);
reviewPaymentInfoStepContentReducer.on(setReviewPaymentInfoStepContent, (_state, payload) => payload);

export const setIsValidForm = createAction<boolean>('set subscription form valid');
export const isValidFormReducer = createReducer<boolean>({}, false);
isValidFormReducer.on(setIsValidForm, (_state, payload) => payload);
