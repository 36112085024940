import classnames from 'classnames';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { TEmailField, TErrorValidation, TGdprUserDataField, TNicknameField, TPasswordField } from '../../../utils';
import { EmailIcon } from '../Icons/EmailIcon';
import { NicknameIcon } from '../Icons/NicknameIcon';
import { PasswordIcon } from '../Icons/PasswordIcon';
import styles from './TextInput.css';

export enum ETextInputFields {
    NICKNAME = 'nickname',
    EMAIL = 'email',
    PASSWORD = 'password',
    CONFIRM_PASSWORD = 'confirm-password',
}

export enum ETextInputOrdering {
    FIRST = 'first',
    LAST = 'last',
    ONLY_ONE = 'only one',
}
export enum ETextInputTypes {
    TEXT = 'text',
    PASSWORD = 'password',
}

type TProps = {
    wrapperClassname?: string;
    className?: string;
    type: 'text' | 'password';
    field: ETextInputFields;
    placeholder: string;
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement>;
    errors: TErrorValidation<TEmailField | TPasswordField | TNicknameField | TGdprUserDataField | ''>[];
    ordering?: ETextInputOrdering;
    disabled?: boolean;
};
const TextInput = React.memo(
    ({
        wrapperClassname,
        className,
        errors,
        type,
        field,
        placeholder,
        value,
        onChange,
        ordering,
        disabled,
    }: TProps) => {
        const hasError = (field) => errors.map((e) => e.field).includes(field);
        const [Icon, setIcon] = useState(null);

        useEffect(() => {
            switch (field) {
                case ETextInputFields.EMAIL:
                    setIcon(EmailIcon);
                    break;
                case ETextInputFields.PASSWORD:
                case ETextInputFields.CONFIRM_PASSWORD:
                    setIcon(PasswordIcon);
                    break;
                case ETextInputFields.NICKNAME:
                    setIcon(NicknameIcon);
            }
        }, [field]);

        return (
            <div
                className={classnames(styles.inputWrapper, wrapperClassname, {
                    [styles.error]: hasError(field),
                })}
            >
                {Icon && <Icon className={styles.inputIcon} />}
                <input
                    className={classnames(styles.popupInput, className, {
                        [styles.first]:
                            ordering === ETextInputOrdering.FIRST || ordering === ETextInputOrdering.ONLY_ONE,
                        [styles.last]: ordering === ETextInputOrdering.LAST || ordering === ETextInputOrdering.ONLY_ONE,
                    })}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                />
            </div>
        );
    }
);

TextInput.displayName = 'TextInput';
export { TextInput };
