import React from 'react';

type iconProps = {
    className?: string;
};

export const ArrowIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            role="img"
            aria-label="arrow-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <g>
                    <path d="M0 0H34V34H0z" transform="translate(-266 -20) translate(20 20) translate(246)" />
                </g>
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 25L21 16.917 13 9"
                    transform="translate(-266 -20) translate(20 20) translate(246) rotate(-90 17 17)"
                />
            </g>
        </svg>
    );
});
