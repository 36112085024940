import { DeviceType } from '../services/DeviceDetector';

export class AnalyticsSortings {
    pc: any[];
    tablet: any[];
    mobile: any[];

    static fromResponse(data) {
        return {
            [DeviceType.DESKTOP]: data.desktop || [],
            [DeviceType.TABLET]: data.tablet || [],
            [DeviceType.MOBILE]: data.mobile || []
        };
    }
}
