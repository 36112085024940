import { AnalyticsInit } from '../services/Analytics/AnalyticsInit';
import { UrlService } from '../services/UrlService';

const FOUR_ZERO_FOUR_STATUS_EVENT = 'set 404 status';

export const pageTypeMiddleware = ({ dispatch, getState }) => (next) => (action: { type: string; payload: any }) => {
    if (!action.type) {
        return next(action);
    }

    if (action.type.includes(FOUR_ZERO_FOUR_STATUS_EVENT)) {
        AnalyticsInit.setGlobalCustomDimensions(undefined, {
            pageType: UrlService.getPageType(action.payload),
        });
    }

    return next(action);
};
