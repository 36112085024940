import React, { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { LocalStorageSKeys } from '../client/services/AuthService';
import { LocalStorageService } from '../client/services/LocalStorage';
import { logoutUser } from '../client/store/ducks/user';
import { ArkcomAvatar, AvatarTypes } from '../client/atoms/ArkcomAvatar/ArkcomAvatar';
import styles from './AppBodySubscription.css';
import { ArkadiumFullLogo } from '../client/atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { PadLockIcon } from '../client/FigmaStyleguide/Icons/PadLockIcon';
import { NavLink } from '../client/atoms/Link/Link';
import { EagleUser } from '../client/models/User';
import PurchasePageGemsShop from '../client/pages/PurchasePageGemsShop';
import { setBillingDataForTax, setChosenGemsPack } from '../client/store/ducks/gems';
import { useHistory } from 'react-router-dom';
import { setShopOpen } from '../client/store/ducks/modal';
import { setStepIndex } from '../client/store/ducks/subscription/common';

const HeaderLogo = (props: any) => {
  return (
    <ArkadiumFullLogo
      className={props.className}
      arrowInheritColor={props.isSideMenuOpened}
      textInheritColor={props.isSideMenuOpened}
    />
  );
};

export const AppBodySubscription: FC = () => {
  AppBodySubscription.displayName = 'AppBodySubscription';

  const user = useSelector((state) => state.user);
  const userAvatar = useSelector(
    (state) => state.user?.avatar || (state.user as EagleUser)?.subscriberAvatar?.avatar || ''
  );
  const gemPackInStore = useSelector((state) => state.chosenGemsPackItemName);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflow = 'visible';

    if (!gemPackInStore) {
      const chosenGemPack = LocalStorageService.getItem(LocalStorageSKeys.gemPackId);

      dispatch(setChosenGemsPack(chosenGemPack));
    }

    const isNewDesign = document.documentElement.dataset.new_design === '__new';
    const mainContentWrapper = document.querySelector('.main-content') as HTMLDivElement;

    if (isNewDesign) {
      mainContentWrapper.style.paddingLeft = '0';
    }

    return () => {
      if (isNewDesign && window.innerWidth > 1023) {
        dispatch(setShopOpen(false));
        dispatch(setStepIndex(0));
        dispatch(setBillingDataForTax(null));
        dispatch(setChosenGemsPack(null));
        mainContentWrapper.style.paddingLeft = 'var(--new-header-navmenu-width)';
      }
    };
  }, []);

  const handleLogOut = () => {
    dispatch(logoutUser());
    history.push('/');
  };

  return (
    <div className={styles.sub_body}>
      <div className={styles.sub_header}>
        <HeaderLogo className={styles.logo_block}/>
        <div className={styles.secure_elem}>
          <PadLockIcon/>
          <h1 className={styles.secure_label}>Secure checkout</h1>
        </div>
        {user && (
          <div className={styles.avatar_block}>
            <ArkcomAvatar
              image={userAvatar}
              selectable={false}
              selected={false}
              size={AvatarTypes.SMALL}
              onSelect={() => {}}
              disabled
            />
          </div>
        )}
      </div>
      <div className={styles.sub_wrapper}>
        <PurchasePageGemsShop/>
      </div>
      <div className={styles.sub_footer}>
        {user && (
          <div className={styles.sub_footer__text}>
            Not {user.name}?{' '}
            <NavLink onClick={handleLogOut} className={styles.logoutLink} to="/">
              Log out
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};
