import classNames from 'classnames';
import React from 'react';
import Logo from '../../../public/svg/ArkadiumLogoMini.svg';
import { ICON_SIZES } from '../../consts';
import styles from './Logos.css';

export const ArkadiumLogoMini = ({ size }) => (
    <Logo
        className={classNames(styles.arkadiumLogoMini, {
            [styles.small]: size === ICON_SIZES.SMALL,
            [styles.smaller]: size === ICON_SIZES.SMALLER,
        })}
    />
);
