import { createAction, createReducer } from 'redux-act';
import { TGRMGamesList } from '../../services/GRMGamesService';

export const initGRMGamesList: TGRMGamesList = [];

export const setGRMGamesList = createAction<TGRMGamesList>('set GRM games list');

const reducer = createReducer<TGRMGamesList>({}, initGRMGamesList);

reducer.on(setGRMGamesList, (_state, payload: any = null) => payload);

export default reducer;
