import Cookies from 'js-cookie';
import { GDPRMediatorService } from './Analytics/GDPR';

class CookieStorageProvider {
    public set(key: string, value: any, options?: any) {
        GDPRMediatorService.isBlockedByGdprConsent().then((isBlocked) => {
            if (isBlocked) {
                console.log('CookieStorage.set blocked = ' + key);
                return;
            } else {
                Cookies.set(key, value, options);
            }
        });
    }

    public get(key?: string) {
        return Cookies.get(key);
    }

    public remove(key: string) {
        Cookies.remove(key);
    }
}

export const CookieStorageService = new CookieStorageProvider();
