import classnames from 'classnames';
import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  TEmailField,
  TErrorValidation,
  TGdprUserDataField,
  TNicknameField,
  TPasswordField,
  validateEmail,
} from '../../../../utils';
import { ESignInUpButtonSize, ESignInUpButtonTheme, SignInUpButton } from '../../../atoms/Buttons/SignInUpButton';
import { TextButton } from '../../../atoms/Buttons/TextButton';
import { ETextInputFields, ETextInputOrdering, ETextInputTypes, TextInput } from '../../../atoms/Inputs/TextInput';
import { AuthProvider } from '../../../models/Login';
import { AuthType, EagleLoginService } from '../../../services/EagleLoginService';
import { ELoginSteps } from '../../../store/ducks/modal';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import { RECAPTCHA_ACTIONS, RECAPTCHA_MODES } from '../../ChallengeCaptcha/hooks/useCaptcha';
import { ErrorsList } from '../ErrorsList';
import resetPasswordStyles from '../ForgotPasswordForm/ForgotPasswordForm.css';
import commonStyles from '../LoginPopup.css';
import { CallToActionText, PopupDescription, PopupTitle } from '../PopupDumbComponents/PopupDumbComponents';
import styles from './RequestEmailForm.css';

type TProps = {
  emailToConfirm: string;
  tWithPrefix: WithTranslation['t'];
  saveEmail: (email: string) => void;
  arenaDomainURL: URL;
  openAfterSignForm: (isThankYouForm?: boolean) => void;
  step: ELoginSteps;
  onSupportClick: () => void;
  isResendEmailForm: boolean;
  goToPreviousStep: () => void;
  setupPreviousStep: (step: ELoginSteps) => void;
  isResendEmailFormInputDisabled: boolean;
  arenaLocale: string;
  getCaptchaToken: (RECAPTCHA_ACTIONS_TYPE: string) => void;
  captchaToken: string;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
  eventType: AuthProvider
};

const RequestEmailForm = React.memo(
  ({
    emailToConfirm,
    tWithPrefix,
    saveEmail,
    arenaDomainURL,
    openAfterSignForm,
    step,
    onSupportClick,
    isResendEmailForm,
    goToPreviousStep,
    setupPreviousStep,
    isResendEmailFormInputDisabled,
    arenaLocale,
    captchaToken,
    getCaptchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
    eventType,
  }: TProps) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>(emailToConfirm ?? '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorsList, setErrorsList] =
      useState<Array<TErrorValidation<TEmailField | TPasswordField | TNicknameField | TGdprUserDataField | ''>>>(
        []);

    useEffect(() => {
      if (isResendEmailForm && step === ELoginSteps.REQUEST_EMAIL) {
        setupPreviousStep(ELoginSteps.LOGIN);
      }
    }, [step]);

    useEffect(() => {
      if (captchaToken && isLoading) {
        void resendEmail();
      }
    }, [captchaToken, isLoading]);

    const resendEmail = async () => {
      try {
        await EagleLoginService.resendConfirmation({
          email,
          authType: AuthType[eventType] ?? AuthType.EmailPassword,
          resendConfirmationFormUrl: arenaDomainURL.origin,
          languageId: arenaLocale.toUpperCase(),
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
        });
        handleResendEmailSuccess();
      } catch (err) {
        handleResendEmailError(err);
      }
    };
    const handleResendEmailSuccess = () => {
      setIsLoading(false);
      openAfterSignForm();
      setupPreviousStep(ELoginSteps.LOGIN);
      clearCaptchaData();
    };
    const handleResendEmailError = (err: number) => {
      if (err === 1023) {
        dispatch(setShowRecaptcha(true));
      } else {
        setIsLoading(false);
        clearCaptchaData();
        setErrorsList([
          {
            field: '',
            description: err.toString(),
          },
        ]);
      }
    };
    const handleEmail = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim());
    const handleSubmit = () => {
      const {
        isValid,
        errors,
      } = validateEmail(email);

      setErrorsList(errors);

      if (isValid) {
        saveEmail(email);
        setIsLoading(true);
        getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
      }
    };
    const title = isResendEmailForm ? 'RESEND_EMAIL_TITLE' : 'REQUEST_EMAIL_TITLE';
    const description = isResendEmailForm ? (
      tWithPrefix('RESEND_EMAIL_DESCRIPTION')
    ) : (
      <>
        <p>{tWithPrefix('REQUEST_EMAIL_DESCRIPTION_START')}</p>
        <p>{tWithPrefix('REQUEST_EMAIL_DESCRIPTION_END')}</p>
      </>
    );
    const SubmitButton = (
      <SignInUpButton
        isEagle
        theme={ESignInUpButtonTheme.EAGLE_GREEN}
        withoutIcon
        onClick={handleSubmit}
        text={tWithPrefix('SUBMIT')}
        isLoading={isLoading}
        size={ESignInUpButtonSize.SMALL}
      />
    );

    return (
      <div
        className={styles.requestEmailContentWrapper}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
        role="presentation"
      >
        {!showChallengeRecaptcha && (
          <>
            <div className={classnames(commonStyles.popupHeader)}>
              <PopupTitle isEagle text={tWithPrefix(title)}/>
              <PopupDescription isEagle text={description} step={step}/>
            </div>
            <ErrorsList tWithPrefix={tWithPrefix} errors={errorsList}/>
            <TextInput
              field={ETextInputFields.EMAIL}
              errors={errorsList}
              placeholder={tWithPrefix('REQUEST_EMAIL_PLACEHOLDER')}
              type={ETextInputTypes.TEXT}
              value={email}
              onChange={handleEmail}
              ordering={ETextInputOrdering.ONLY_ONE}
              disabled={isResendEmailFormInputDisabled}
            />
            <CallToActionText
              actionText={tWithPrefix('REQUEST_EMAIL_STILL_NEED_HELP')}
              buttonText={tWithPrefix('REQUEST_EMAIL_SUPPORT')}
              onClick={onSupportClick}
              isEagle
              defaultOrder
              step={step}
            />
            {isResendEmailForm ? (
              <div className={classNames(resetPasswordStyles.resetPasswordRow, resetPasswordStyles.eagle)}>
                <TextButton isEagle onClick={goToPreviousStep} text={tWithPrefix('CANCEL')}/>
                {SubmitButton}
              </div>
            ) : (
              SubmitButton
            )}
          </>
        )}
      </div>
    );
  },
);

RequestEmailForm.displayName = 'RequestEmailForm';

export { RequestEmailForm };
