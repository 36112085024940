import React, { ChangeEvent, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { TErrorValidation, TPasswordField, validateEaglePassword } from '../../../../utils';
import { ESignInUpButtonSize, ESignInUpButtonTheme, SignInUpButton } from '../../../atoms/Buttons/SignInUpButton';
import { ETextInputFields, ETextInputOrdering, ETextInputTypes, TextInput } from '../../../atoms/Inputs/TextInput';
import { EagleLoginService } from '../../../services/EagleLoginService';

import { ErrorsList } from '../ErrorsList';
import { PasswordRequirements, PopupTitle } from '../PopupDumbComponents/PopupDumbComponents';
import styles from './ResetPasswordForm.css';
import commonStyles from '../LoginPopup.css';

type TProps = {
  recoveryCode: string;
  tWithPrefix: WithTranslation['t'];
  onClose: () => void;
  openLoginForm: () => void;
};
const ResetPasswordForm = React.memo(({
  recoveryCode,
  tWithPrefix,
  openLoginForm,
}: TProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordTouched, setPasswordTouched] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState<Array<TErrorValidation<TPasswordField | ''>>>([]);
  const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordTouched(true);
  };
  const handleConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value);
  const onResetPasswordFormSubmit = () => {
    const {
      isValid: isPasswordValid,
      errors: passwordErrors,
    } = validateEaglePassword(password, confirmPassword);

    if (isPasswordValid) {
      setIsLoading(true);
      void fetchChangePasswordByRecoveryCode(password, recoveryCode);
    }

    setErrors(passwordErrors);
  };
  const fetchChangePasswordByRecoveryCode = async (newPassword: string, resetCode: string) => {
    try {
      await EagleLoginService.changePasswordByRecoveryCode({
        newPassword,
        resetCode,
      });
      openLoginForm();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setErrors([
        {
          field: '',
          description: err,
        },
      ]);
    }
  };

  return (
    <>
      <div className={commonStyles.popupHeader}>
        <PopupTitle text={tWithPrefix('RESET_PASSWORD_TITLE')} isEagle/>
      </div>

      <div
        className={styles.resetPasswordContentWrapper}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onResetPasswordFormSubmit();
          }
        }}
        role="presentation"
      >
        <ErrorsList tWithPrefix={tWithPrefix} errors={errors}/>

        <TextInput
          errors={errors}
          field={ETextInputFields.PASSWORD}
          value={password}
          onChange={handlePassword}
          placeholder={tWithPrefix('RESET_PASSWORD_ENTER_PASSWORD')}
          type={ETextInputTypes.PASSWORD}
          ordering={ETextInputOrdering.FIRST}
        />
        <TextInput
          errors={errors}
          field={ETextInputFields.CONFIRM_PASSWORD}
          value={confirmPassword}
          onChange={handleConfirmPassword}
          placeholder={tWithPrefix('RESET_PASSWORD_REENTER_PASSWORD')}
          type={ETextInputTypes.PASSWORD}
          ordering={ETextInputOrdering.LAST}
        />
        <PasswordRequirements password={password} touched={passwordTouched} tWithPrefix={tWithPrefix}/>
        <SignInUpButton
          className={styles.resetPasswordButton}
          isEagle
          theme={ESignInUpButtonTheme.EAGLE_GREEN}
          withoutIcon
          onClick={onResetPasswordFormSubmit}
          text={tWithPrefix('SUBMIT')}
          isLoading={isLoading}
          size={ESignInUpButtonSize.SMALL}
        />
      </div>
    </>
  );
});

ResetPasswordForm.displayName = 'ResetPasswordForm';
export { ResetPasswordForm };
