import i18n from 'i18next';
import { RouteComponentProps } from 'react-router';
import urlJoin from 'url-join';
import { SupportedLangs } from '../../i18n';
import { DefaultArenaDev } from '../models/Enums';
import { sanitizeString } from '../../utils';
import { environment } from '../config/environment';

export interface UTMInfo {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
}

export class UrlService {
    public static getHost() {
        if (window.location.hostname === 'localhost') {
            return new URL(DefaultArenaDev).host;
        }

        return window.location.host;
    }

    public static getBaseHref(pathname: string = '/') {
        const firstSegment = (pathname.split('/')[1] || '').toLowerCase();
        const isLanguage = SupportedLangs.indexOf(firstSegment) !== -1;

        if (isLanguage) {
            return `/${firstSegment}`;
        }

        return '';
    }

    public static getWindowBaseHref() {
        return UrlService.getBaseHref(window.location.pathname);
    }

    public static getCustomVersionParam(routerProps: RouteComponentProps) {
        const search = new URLSearchParams(routerProps.location.search);

        return search.get('version') || null;
    }

    public static getGameCustomApi(routerProps: RouteComponentProps) {
        const search = new URLSearchParams(routerProps.location.search);

        return search.get('gameApi') || null;
    }

    public static getReferrerIfOuter(): string | undefined {
        const refDirect = window.document.referrer === '';

        if (!refDirect && window.document.referrer.indexOf(window.location.host) === -1) {
            return window.document.referrer;
        }
    }

    public static getUtmInfo(): UTMInfo {
        const search = new URLSearchParams(window.location.search);

        return {
            utmSource: search.get('utm_source') || undefined,
            utmMedium: search.get('utm_medium') || undefined,
            utmCampaign: search.get('utm_campaign') || undefined,
        } as UTMInfo;
    }

    public static getArkPromo(): string {
        const search = new URLSearchParams(window.location.search);

        return search.get('arkpromo') || undefined;
    }

    public static getPageType(is404?: boolean, isError?: boolean): string {
        if (isError) {
            return 'Error page';
        }

        if (is404) {
            return '404 page';
        }

        const url = window.location.pathname || '';
        const ROUTES = {
            [`${i18n.t('ROUTES.ALL_GAMES')}`]: 'All games page',
            [`${i18n.t('ROUTES.CATEGORY')}`]: 'Category page',
            [`${i18n.t('ROUTES.PROFILE')}`]: 'Profile page',
            [`${i18n.t('ROUTES.GAMES')}`]: 'Game page',
            [`${i18n.t('ROUTES.HELP')}`]: 'Help page',
            [`${i18n.t('ROUTES.SHOP')}`]: 'Subscription page',
            [`${i18n.t('ROUTES.ADVANTAGE')}`]: 'Subscription page',
            ['search']: 'Search page',
            ['404']: '404 page',
        };

        for (const route in ROUTES) {
            if (url.indexOf(encodeURI(route)) !== -1) {
                return ROUTES[route];
            }
        }

        return 'Home page';
    }

    public static getCurrentOrigin(): string {
        return window.location.origin;
    }

    public static getCurrentPathname(): string {
        return window.location.pathname;
    }

    static getCurrentPage(): string {
        const location = window.location.pathname || '';

        if (location.indexOf(`/${i18n.t('ROUTES.GAMES')}/`) !== -1) {
            return 'game start';
        } else if (location.indexOf(`${i18n.t('ROUTES.ALL_GAMES')}`) !== -1) {
            return 'category/allgames';
        } else if (location.indexOf(`${i18n.t('ROUTES.PROFILE')}`) !== -1) {
            return 'profile';
        } else if (location.indexOf('search') !== -1) {
            return 'search';
        } else if (location.indexOf(`${i18n.t('ROUTES.CATEGORY')}`) !== -1) {
            return location.substr(1);
        } else if (location.indexOf(`${i18n.t('ROUTES.HELP')}`) !== -1) {
            return 'help';
        }

        return 'arena home';
    }

    static get domain() {
        if (process.env.NODE_ENV !== 'production') {
            return new URL(DefaultArenaDev).host;
        }

        return window.location.host;
    }

    static getRedirectMsg(): string {
        const search = new URLSearchParams(window.location.search);

        return sanitizeString(search.get('redirmsg')) || undefined;
    }

    static serializeCategoryUrl(category: string) {
        return category.trim().replace(/\s+/g, '-').toLowerCase();
    }

    static deserializeCategoryFromUrl(categoryUrl: string) {
        return categoryUrl.replace(/-/g, ' ');
    }

    static getPrivacyPolicyUrl(lang: string, client?: string): string {
        if (client === 'abc-es') {
            return 'https://www.vocento.com/politica-privacidad'
        }

        if (lang && lang !== 'en' && lang !== 'it' ) {
            // ark.com doesn't support Italian yet
            return `https://www.arkadium.com/${lang}/privacy-policy/`;
        }

        if (client === 'washingtonpost2' || client === 'washingtonpost') {
            // client-request for custom link
            return `https://www.washingtonpost.com/privacy-policy/`;
        }

        return 'https://www.arkadium.com/privacy-policy/';
    }

    static makeExternalThumbUrl(
        isFtux: boolean,
        assetOriginUrl: string,
        secondaryThumbPath: string,
        thumbOriginUrl: string,
        type: string
    ) {
        // (isFtux ? assetOriginUrl + secondaryThumbPathWebP : thumbOriginUrl + 'Game_Tiles/webp/280x280.webp')
        const folder = type === 'jpg' ? 'jpeg' : type;

        return isFtux ? assetOriginUrl + secondaryThumbPath : thumbOriginUrl + `Game_Tiles/${folder}/280x280.${type}`;
    }

    public static getQSParam(queryString: string, name: string): string {
        const search = new URLSearchParams(queryString.toLowerCase());

        return search.get(name.toLowerCase()) || null;
    }

    public static generateCurrentUrl(): string {
        return `${this.getCurrentOrigin()}${this.getCurrentPathname()}`;
    }

    public static getCurrentOriginForEagle(): string {
        const localhostOrSandbox =
            window.location.origin.includes('localhost') || window.location.origin.includes('arena-sandbox');

        return localhostOrSandbox ? DefaultArenaDev : window.location.origin;
    }

    public static getCurrentHostnameForEagle(): string {
        return new URL(this.getCurrentOriginForEagle()).hostname;
    }

    public static get isLocalhost() {
        return location.origin.includes('localhost');
    }

    public static getFullUrlWithRoute = (route: string) => {
        const isLocalhost = this.isLocalhost;
        const domain = isLocalhost ? 'http://localhost:4200' : `https://${UrlService.domain}`;

        return `${domain}${UrlService.getWindowBaseHref()}/${route}`;
    };

    static toAvatarLink(avatarFileName: string, extension: string): string {
        const DEFAULT_AVATAR = `avatar_blank`;
        const avatarName = avatarFileName ? avatarFileName?.split('.').slice(0, -1).join('.') : DEFAULT_AVATAR;
        // TODO: Calculate every time! Not SAFE at all, check string for occurrences of strings
        const isPremiumFilename = avatarName.includes('premium');
        const PATH_TO_STORAGE = `https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkadium-avatars/`;
        let AVATAR_PATH = `${PATH_TO_STORAGE}subscription/${extension}/1x/${avatarName}.${extension}`;

        if (isPremiumFilename) {
            AVATAR_PATH = `${PATH_TO_STORAGE}subscription/${extension}/1x/premium/${avatarName}.${extension}`;
        }

        return AVATAR_PATH;
    }

    public static createURL(arenaUrl: string, path: string): string {
        if (arenaUrl === '') {
            arenaUrl = 'https://' + window.location.host + '/';
        }

        return arenaUrl + path;
    }

    static convertEntities(url: string) {
        return url.replace('&amp;', '&');
    }

    public static toCDNUrl(path: string): string {
        return urlJoin(environment.BASE_CDN, path);
    }

    static toFrameLink(frameFileName: string, extension: string): string {
        const DEFAULT_FRAME = `frame2`;
        const frameName = frameFileName?.split('.').slice(0, -1).join('.');

        return `https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkadium-frames/${extension}/1x/${
            frameName || DEFAULT_FRAME
        }.${extension}`;
    }
}
