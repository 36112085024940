import { isServer } from '../../../utils';
import { EagleUser, User } from '../../models/User';
import { EagleLevelupService } from '../../services/EagleLevelupService';
import { userService } from '../../services/UserService';
import { AppState } from '../../store/types';
import { BADGES_STORE_PROPS, THIS_BADGE } from './Badges';
import { BadgesCheckProgress, badgeVariation } from './BadgesLogic';
import { BadgesStoragesClear } from './BadgesStorageHelpers';

export const updateUserWithBadge = (badgeName: string = THIS_BADGE.badgeName) => {
    if (isServer) {
        return;
    }

    const state = (window as any)?.STORE?.getState() as AppState;
    const user: User | EagleUser = state.user;
    const isEagle = state.config.isEagle;
    const isThisBadgeDeserved = BadgesCheckProgress().isDone;
    const userHasBadge = user?.earnedBadges && user.earnedBadges.find((b) => b.badgeName === badgeName);

    if (userHasBadge) {
        BadgesStoragesClear();
        return;
    }

    const doesUserNeedUpdate =
        user && // is logged in
        !userHasBadge && // there is no this badge in user's badges
        badgeVariation() === 'Badges'; // experimental group
    const shouldUpdateBadge = isThisBadgeDeserved && doesUserNeedUpdate;
    const earnedBadges = [].concat(user.earnedBadges || []);

    if (shouldUpdateBadge) {
        earnedBadges.push(badgeName);

        if (isEagle) {
            EagleLevelupService.saveBadges(earnedBadges);
        } else {
            userService.update({ ...(user as User), earnedBadges });
        }
    }

    (window as any).sessionStorage.setItem(BADGES_STORE_PROPS.BADGE_DONE, JSON.stringify(earnedBadges));
};
