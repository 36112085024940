import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionTypes } from '../../models/Analytics';
import { ArenaConfig } from '../../models/ArenaConfig';
import { Game } from '../../models/Game';
import { GameRelatedGames } from '../../models/RelatedGames';
import { MoreGamesLink } from '../../molecules/MoreGamesLink/MoreGamesLink';
import { AnalyticsGamePage } from '../../services/Analytics/AnalyticsGamePage';
import { AnalyticsInit } from '../../services/Analytics/AnalyticsInit';
import { AppState } from '../../store/types';
import { GamesList } from '../GamesList/GamesList';
import classNames from 'classnames';
import styles from './RelatedGames.css';

type RelatedGamesProps = {
    game: Game;
    games: Game[];
    relatedGames: GameRelatedGames[];
    config: ArenaConfig;
};

type RelatedGameState = {
    relatedGames: Game[];
    themeName: string;
};

class RelatedGamesBase extends React.PureComponent<RelatedGamesProps & WithTranslation, RelatedGameState> {
    constructor(props) {
        super(props);

        this.state = {
            relatedGames: this.getRelatedGames(),
            themeName: this.props.config.theme.theming.name,
        };
    }

    componentDidMount() {
        AnalyticsGamePage.relatedGames(ActionTypes.IMPRESSION, this.state.relatedGames);
    }

    render() {
        const { t, game } = this.props;
        const { themeName } = this.state;

        return (
            <Container data-element-description="related-games">
                <TopRow>
                    <Title>{`${t('PLAYERS_ALSO_ENJOY', { gameName: game.name })}`}</Title>

                    <MoreGamesLink
                        onClick={this.onMoreGamesClick}
                        data-element-description="related games more games button"
                        url={`/${t('ROUTES.ALL_GAMES')}`}
                        themeName={themeName}
                    />
                </TopRow>

                <GamesList
                    hScroll="mobile"
                    games={this.state.relatedGames}
                    onTileClick={(slug) => this.onRelatedTileClick(slug)}
                    columnClass="col-sm-3 col-4"
                    themeName={themeName}
                />
            </Container>
        );
    }

    private onMoreGamesClick() {
        AnalyticsGamePage.relatedGames(ActionTypes.CLICK, [], 'More');
    }

    private onRelatedTileClick(slug: string) {
        AnalyticsInit.setPlayAgainOrFromRecGames(this.props.game.slug);
        AnalyticsGamePage.relatedGames(ActionTypes.CLICK, [], slug);
    }

    private getRelatedGames() {
        const chosenGame = this.props.game;
        const allGames = this.props.games;
        const relatedGamesFromAnalytics = this.props.relatedGames.find((game) => game.slug === chosenGame.slug);
        const relatedGamesKeys = relatedGamesFromAnalytics ? relatedGamesFromAnalytics.games.slice(0, 4) : [];
        const relatedGames = relatedGamesKeys
            .map((key) => allGames.find((game) => game.slug === key))
            .filter((game) => game);

        if (relatedGames.length !== 4) {
            allGames.forEach((gameItem) => {
                if (relatedGames.length === 4) {
                    return;
                }

                if (
                    gameItem.tags.filter((tag) => chosenGame.tags.includes(tag)).length > 0 &&
                    gameItem.slug !== chosenGame.slug &&
                    relatedGamesKeys.indexOf(gameItem.slug) === -1
                ) {
                    relatedGames.push(gameItem);
                }
            });
        }

        return relatedGames;
    }
}

const TopRow = (props: any) => <div className={styles.topRow} {...props} />;
const Title = (props: any) => (
    <p className={classNames(styles.title, styles.__override)} {...props}>
        {props.children}
    </p>
);
const Container = (props: any) => <div className={styles.relatedGames} {...props} />;
const RelatedGamesTranslated = withTranslation()(RelatedGamesBase);

export const RelatedGames = connect((state: AppState) => ({
    relatedGames: state.relatedGames,
    config: state.config,
}))(RelatedGamesTranslated);
