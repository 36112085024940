import React from 'react';

type iconProps = {
    className?: string;
    circleColor?: string;
    checkColor?: string;
    goldCheck?: boolean;
};

export const ListCheckCircleIcon = React.memo((props: iconProps) => {
    const { className, circleColor, checkColor, goldCheck } = props;
    const COLOR_BASE_CIRCLE = '#1E2A79';
    const COLOR_BASE_CHECK = '#F9F9F9';

    return (
        <svg
            className={className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="17.1741"
                    y1="17.0467"
                    x2="3.30615"
                    y2="3.3059"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C28C2B" />
                    <stop offset="0.498397" stopColor="#E6C14E" />
                    <stop offset="1" stopColor="#C28C2B" />
                </linearGradient>
            </defs>
            <circle
                cx="17.0001"
                cy="16.9998"
                r="9.56698"
                fill={goldCheck ? 'url(#paint0_linear)' : circleColor || COLOR_BASE_CIRCLE}
            />
            <path
                d="M13.2085 16.2877L16.5735 19.5278L20.7917 14.4723"
                stroke={checkColor || COLOR_BASE_CHECK}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
