import { createAction, createReducer } from 'redux-act';

export type NotificationBar = {
    message?: string;
    type?: string;
    isOpened: boolean;
};

export const setNotificationBar = createAction<NotificationBar>('show snackbar');
export const notificationBarReducer = createReducer<NotificationBar>(
    {},
    {
        type: 'success',
        message: '',
        isOpened: false,
    }
);
notificationBarReducer.on(setNotificationBar, (_state, payload: NotificationBar) => ({
    ..._state,
    isOpened: payload.isOpened,
    message: payload.message,
    type: payload.type,
}));
