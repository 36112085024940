import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { setBodyOverflowAuto, setBodyOverflowHidden } from '../../../utils';
import { PoweredByLink } from './PoweredByLink';
import { PoweredByModal } from './PoweredByModal';
import styles from './PoweredBy.css';

interface PoweredByState {
    showModal: boolean;
}

class PoweredByBase extends React.PureComponent<WithTranslation> {
    state: PoweredByState = {
        showModal: false,
    };

    openPoweredByModal() {
        setBodyOverflowHidden();
        this.setState({ showModal: true });
    }

    closePoweredByModal() {
        setBodyOverflowAuto();
        this.setState({ showModal: false });
    }

    render() {
        const { showModal } = this.state;

        return (
            <div className={styles.poweredBy}>
                <PoweredByLink openModal={() => this.openPoweredByModal()} />
                <PoweredByModal show={showModal} closeModal={() => this.closePoweredByModal()} />
            </div>
        );
    }
}

export const PoweredBy = withTranslation()(PoweredByBase);
