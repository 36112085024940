import fetch, { Headers } from 'node-fetch';
import { ErrorResponseObj } from './client/models/ErrorApiResponse';

const CACHE = new Map();

export function apiFetch(url: string, options?: any) {
    options = options || {};
    options.headers = options.headers || new Headers();
    options.headers.set?.('Content-type', 'application/json');

    return fetch(url, options).then((res) => {
        return res.text().then((text) => {
            let json;

            try {
                json = JSON.parse(text);
            } catch (e) {
                // Swallow
            }

            if (res.status >= 200 && res.status < 300) {
                return json || text;
            } else {
                const err = new HttpError(res);

                if (json) {
                    json = Array.isArray(json) ? json[0] : json;
                }

                err.body = json || text;

                throw err;
            }
        });
    });
}

export function fetchApiCall(url, options?, jsonResponse = true): Promise<any | HttpError> {
    options = options || {};
    options.headers = options.headers || new Headers();
    let { etag, data } = CACHE.get(url) || {};

    if (etag) {
        options.headers.set('if-none-match', etag);
    }

    return fetch(url, options)
        .then(async (res) => {
            if (res.status >= 304) {
                // Not modified, or api is unavailable
                return { etag, data };
            }

            // Content changed
            etag = res.headers?.get('etag');
            data = jsonResponse ? await res.json() : await res.text();

            if (etag) {
                CACHE.set(url, { etag, data });
            }

            return { etag, data };
        });
}

export function checkFetchStatus(res: Response) {
    if (res.status >= 200 && res.status <= 304) {
        return res;
    } else {
        throw new HttpError(res);
    }
}

export class HttpError extends Error {
    response: Response;
    body?: any | ErrorResponseObj;

    constructor(response: Response) {
        super(`${response.status} - ${response.statusText} for ${response.url}`);
        this.name = 'HttpError';
        this.response = response;
    }
}
