import React from 'react';
import { connect } from 'react-redux';
import { set403Status } from '../store/ducks/is403';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { Page403Template } from '../templates/Page403/Page403Template';

type Page403Props = {
    absoluteBaseHref: string;
    baseHref?: string;
    dispatch?: any;
};

class Page extends React.PureComponent<Page403Props> {
    constructor(props) {
        super(props);
        this.props.dispatch(set403Status(true));
    }

    componentWillUnmount() {
        this.props.dispatch(set403Status(false));
    }

    render() {
        return <Page403Template absoluteBaseHref={this.props.absoluteBaseHref} />;
    }
}

export const Page403 = connect((state: AppState) => ({
    absoluteBaseHref: absoluteBaseHrefSelector(state),
}))(Page);
