import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatNumbers } from '../../../utils';
import { StarIcon } from '../../atoms/Icons/StarIcon';
import styles from './GameEndScore.css';

type GameEndScoreProps = {
    highScore: number;
    isNewHighscore: boolean;
};

const GameEndHighScoreBase = React.memo(({ highScore = 0, isNewHighscore, t }: GameEndScoreProps & WithTranslation) => {
    return (
        <HighScores isNewHighscore={isNewHighscore} data-element-description="game-end highscores">
            {isNewHighscore && (
                <React.Fragment>
                    <Star /> {t('YOUR_NEW_HIGHSCORE')}
                </React.Fragment>
            )}

            {!isNewHighscore && (
                <React.Fragment>
                    {t('YOUR_HIGH_SCORE')}
                    <HighScoreValue>{formatNumbers(highScore)}</HighScoreValue>
                </React.Fragment>
            )}
        </HighScores>
    );
});
const HighScores = ({ isNewHighscore, ...props }) => (
    <div
        className={classNames(styles.highScores, {
            [styles.isNew]: isNewHighscore,
        })}
        {...props}
    />
);
const HighScoreValue = (props: any) => <span className={styles.highScoreValue} {...props} />;
const Star = (props: any) => <StarIcon className={styles.starIcon} {...props} />;

export const GameEndHighScore = withTranslation()(GameEndHighScoreBase);
