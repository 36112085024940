import { MapActionTypesToThunks } from './ducks/header';

const ActionTypePrefix = '@HEADERS/';

export const HeadersMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action: { type: string; payload: any }) => {
        if (typeof action !== 'function' && action.type?.indexOf(ActionTypePrefix) === 0) {
            const name = action.type.replace(ActionTypePrefix, '');
            const thunk = MapActionTypesToThunks[name];

            if (thunk) {
                return next(thunk(action.payload));
            } else {
                console.error('Headers Middleware caught an action but did not mapped to any thunk');
            }
        }

        return next(action);
    };
