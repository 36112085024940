import React from 'react';
import { connect } from 'react-redux';
import { set404Status } from '../store/ducks/is404';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { Page404Template } from '../templates/Page404/Page404Template';

type Page404Props = {
    absoluteBaseHref: string;
    baseHref?: string;
    dispatch?: any;
};

class Page extends React.PureComponent<Page404Props> {
    constructor(props) {
        super(props);
        this.props.dispatch(set404Status(true));
    }

    componentWillUnmount() {
        this.props.dispatch(set404Status(false));
    }

    render() {
        return <Page404Template absoluteBaseHref={this.props.absoluteBaseHref} />;
    }
}

export const Page404 = connect((state: AppState) => ({
    absoluteBaseHref: absoluteBaseHrefSelector(state),
}))(Page);
