import { createAction, createReducer } from 'redux-act';
import { LoginCaller } from '../../models/Login';

export enum ELoginSteps {
    LOGIN = 'LOGIN FORM',
    GDPR = 'GDPR FORM',
    REGISTER = 'REGISTER FORM',
    FORGOT_PASSWORD = 'FORGOT PASSWORD FORM',
    RESET_PASSWORD = 'RESET PASSWORD FORM',
    REQUEST_EMAIL = 'CONFIRM EMAIL FORM',
    AFTER_SIGN = 'AFTER SIGN FORM',
}

export enum EShopSteps {
  'LOADING' = 'LOADING',
  'SHOP' = 'SHOP',
  'LOGIN' = 'LOGIN',
  'ERROR' = 'ERROR',
}

type TLoginAdditionalProps = {
  caller?: LoginCaller;
  fromShopModal?: boolean;
  fromSkipAdButton?: boolean;
  prevStep?: ELoginSteps;
  // only for HSN login, don't use it for other login types
  email?: string;
};
export type TLoginReducer =
  TModalState<ELoginSteps>
  & TLoginAdditionalProps;
type TShopReducer = TModalState<EShopSteps>;
type TPromotionReducer = Omit<TModalState, 'step'>;
export type TModalsReducer = {
  login: TLoginReducer;
  shop: TShopReducer;
  promotion: TPromotionReducer;
};
export type TModalState<ESteps = void> = {
  isOpen: boolean;
  step: ESteps;
};

export const setLightboxPromotionOpen = createAction<TPromotionReducer['isOpen']>('set lightbox promotion status');

export const setShopOpen = createAction<TModalState<EShopSteps>['isOpen']>('set shop status');

export const setShopStep = createAction<TModalState<EShopSteps>['step']>('set shop step');

export const setLoginOpen = createAction<TModalState<ELoginSteps>['isOpen']>('set login status');

export const setLoginStep = createAction<TModalState<ELoginSteps>['step']>('set login step');

export const setLoginPrevStep = createAction<TModalState<ELoginSteps>['step']>('set prev login step');

export const setLoginModal = createAction<TLoginReducer>('set login modal');

const INITIAL_LOGIN_STATE = {
  isOpen: false,
  step: ELoginSteps.LOGIN,
};
const INITIAL_SHOP_STATE = {
  isOpen: false,
  step: EShopSteps.LOADING,
};
const INITIAL_PROMOTION_STATE = { isOpen: false };
const reducer = createReducer<TModalsReducer>(
  {},
  {
    login: INITIAL_LOGIN_STATE,
    shop: INITIAL_SHOP_STATE,
    promotion: INITIAL_PROMOTION_STATE,
  },
);

reducer.on(setLightboxPromotionOpen, (_state, payload: TPromotionReducer['isOpen']) => ({
  ..._state,
  promotion: { isOpen: payload },
}));

reducer.on(setShopOpen, (_state, payload: TShopReducer['isOpen']) => ({
  ..._state,
  shop: {
    ..._state.shop,
    isOpen: payload,
  },
  ...(payload === false ? { login: INITIAL_LOGIN_STATE } : {}),
}));

reducer.on(setShopStep, (_state, payload: TShopReducer['step']) => ({
  ..._state,
  shop: {
    ..._state.shop,
    step: payload,
  },
}));

reducer.on(setLoginOpen, (_state, payload: TLoginReducer['isOpen']) => ({
  ..._state,
  login: payload ?
    {
      ..._state.login,
      isOpen: payload,
    } :
    INITIAL_LOGIN_STATE,
}));

reducer.on(setLoginStep, (_state, payload: TLoginReducer['step']) => ({
  ..._state,
  login: {
    ..._state.login,
    step: payload,
  },
}));

reducer.on(setLoginPrevStep, (_state, payload: TLoginReducer['prevStep']) => ({
  ..._state,
  login: {
    ..._state.login,
    prevStep: payload,
  },
}));
reducer.on(setLoginModal, (_state, payload: TLoginReducer) => ({
  ..._state,
  login: { ...payload },
}));
export default reducer;
