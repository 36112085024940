export class AdSettings {
    product: string;
    adUnit?: string;
    adUnitAlias?: object;
    theySell: {
        display?: string;
        video?: string;
    };

    constructor(data, lang) {
        data = data || { product: 'phoenix' };

        this.product = data.product || 'phoenix';
        this.adUnit = data.adUnitAlias && data.adUnitAlias[lang] ? data.adUnitAlias[lang] : data.adUnit;
        this.adUnitAlias = data.adUnitAlias;
        this.theySell = data.theySell || {};
    }
}
