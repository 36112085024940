import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory, History } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { isServer } from '../../utils';
import { HeadersMiddleware } from './headersMiddleware';
import { pageTypeMiddleware } from './pageTypeMiddleware';
import createRootReducer from './reducers';
import { urlSanitizerMiddleware } from './urlSanitizerMiddleware';

const composeEnhancers =
  (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function (url = '/'): { store: Store<any>; history: History } {
  /** Create a history depending on the environment */
  const history = isServer ? createMemoryHistory({ initialEntries: [url] }) : createBrowserHistory();
  const initialState = !isServer ? (window as any).__INITIAL_STATE__ : {};

  if (!isServer) {
    // clearing the global var so it can be garbage collected
    (window as any).__INITIAL_STATE__ = null;
  }

  const middlewares = [
    HeadersMiddleware,
    urlSanitizerMiddleware,
    pageTypeMiddleware,
    thunkMiddleware,
    routerMiddleware(history),
  ];
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  return {
    store,
    history,
  };
}
