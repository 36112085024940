import { QuantcastService } from '../QuantcastService';
import { OneTrustService } from '../OneTrustService';
import { ThirdPartyScriptsKeys } from '../../models/ThirdPartyScripts';
import { GeoService } from '../GeoService';
import { ArenaConfig } from '../../models/ArenaConfig';

const GDPRCountryCodes = new Set([
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'GB'
]);

enum ProviderNames {
    Quantcast = 'Quantcast',
    OneTrust = 'OneTrust',
}

class GDPRMediator {
    activeProvider: ProviderNames | null = null;
    isGDPRUser: boolean = false;
    isGdprSupport: boolean = false;
    public isAnalyticsEnabled: boolean = false;

    async init(config: ArenaConfig) {
        this.isGdprSupport = config.isGdprSupport || false;
        await this.determineUserLocation();

        await this.initializeProviders(config);
    }

    private async determineUserLocation() {
        try {
            const country = await GeoService.getUserCountry();

            this.isGDPRUser = GDPRCountryCodes.has(country);
            this.isAnalyticsEnabled = !this.isGDPRUser;
        } catch (error) {
            console.error('Failed to determine user country:', error);
            this.isGDPRUser = true; // Assume GDPR compliance if the location is unknown
            this.isAnalyticsEnabled = false;
        }
    }

    private async initializeProviders(config: ArenaConfig) {
        const providers = {
            Quantcast: !!config.scripts.public.find((item) => item.name === ThirdPartyScriptsKeys.QUANTCAST),
            OneTrust: !!config.scripts.public.find((item) => item.name === ThirdPartyScriptsKeys.ONE_TRUST),
        };

        if (!providers.Quantcast && !providers.OneTrust && this.isGDPRUser) {
            console.error('No CMP provider configured for user detected as EU');
            this.isAnalyticsEnabled = false;
            return;
        }

        if (providers.Quantcast && providers.OneTrust) {
            console.error('Multiple CMP providers are active');
            this.isAnalyticsEnabled = false;
            return;
        }

        if (providers.Quantcast) {
            this.activeProvider = ProviderNames.Quantcast;
            QuantcastService.init(config);
        }

        if (providers.OneTrust) {
            this.activeProvider = ProviderNames.OneTrust;
            OneTrustService.init(config);
        }
    }

    async isBlockedByGdprConsent() {
        if (!this.isGDPRUser) {
            return false;
        }

        return !this.isUserWithConsent();
    }

    isUserWithConsent() {
        if (this.activeProvider === ProviderNames.Quantcast) {
            return QuantcastService.getGdprCookieConsent();
        }

        if (this.activeProvider === ProviderNames.OneTrust) {
            return OneTrustService.consents.targeting;
        }

        return false;
    }

    consentBy3rdPartyProvider() {
        return {
            CONSOLE: true,
            GOOGLE_ANALYTICS: this.isAnalyticsEnabled,
            APP_INSIGHTS: this.isUserWithConsent() || this.isAnalyticsEnabled,
            ALL: false,
        };
    }
}

export const GDPRMediatorService = new GDPRMediator();
