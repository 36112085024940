import React from 'react';

type iconProps = {
    className?: string;
};

export const WarningIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18C11.4615 18 12.8141 17.6346 14.0577 16.9038C15.2628 16.2115 16.2115 15.2628 16.9038 14.0577C17.6346 12.8141 18 11.4615 18 10C18 8.53846 17.6346 7.1859 16.9038 5.94231C16.2115 4.73718 15.2628 3.78846 14.0577 3.09615C12.8141 2.36538 11.4615 2 10 2C8.55128 2 7.20513 2.36538 5.96154 3.09615C4.75641 3.80128 3.80128 4.75641 3.09615 5.96154C2.36538 7.20513 2 8.55128 2 10C2 11.4487 2.36538 12.7949 3.09615 14.0385C3.78846 15.2436 4.73718 16.1987 5.94231 16.9038C7.1859 17.6346 8.53846 18 10 18Z"
                fill="#D50032"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M11 11H9V6H11V11Z" fill="#F9F9F9" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11 14H9V12H11V14Z" fill="#F9F9F9" />
        </svg>
    );
});
