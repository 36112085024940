import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { batch, connect } from 'react-redux';
import { Dispatch } from 'redux';
import { sanitizeString } from '../../../utils';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { GameState } from '../../models/Enums';
import { Game } from '../../models/Game';
import { LeaderboardRecord } from '../../models/LeaderboardRecord';
import { EagleUser, User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { UserTopScores } from '../../models/UserTopScores';
import { LeaderboardTab, LeaderboardTabs as Tabs } from '../../molecules/LeaderboardTab/LeaderboardTab';
import RemoteScoreService from '../../services/RemoteScoreService';
import UUPScoreService from '../../services/UUPScoreService';
import { saveScore } from '../../store/ducks/gameScores';
import { setLeaderboardList, setLeaderboardLoading, setLeaderboardTab } from '../../store/ducks/leaderboard';
import { AppState } from '../../store/types';
import { LeaderboardList } from '../LeaderboardScoreList/LeaderboardList';
import styles from './Leaderboard.css';

interface LeaderboardProps {
    user?: User | EagleUser;
    userAuthStatus?: UserAuthStatus;
    game?: Game;
    gameState?: GameState;
    topScores?: UserTopScores;
    gameScore?: number;
    t?: any;
    loadTab?: (tab: Tabs, slug: string, fetchFreshData: boolean) => void;
    themeName?: string;
    isEagle?: boolean;
    list?: LeaderboardRecord[];
    loading?: boolean;
    activeTab?: Tabs;
    dispatch?: any;
    gameStartTime?: Date;
}

class LeaderboardBase extends React.PureComponent<LeaderboardProps & WithTranslation & Dispatch> {
    tabs = [Tabs.TODAY, Tabs.THIS_WEEK, Tabs.THIS_MONTH];

    haveUserGotPlace = false;

    componentDidMount() {
        if (!this.props.list.length) {
            this.loadTab(Tabs.TODAY, true);
        } else {
            this.props.dispatch(setLeaderboardLoading(false));
        }
    }

    // componentDidUpdate(prevProps: LeaderboardProps) {
    //     const { game, gameState, user, gameScore } = this.props;
    //     const gameSavedScore =
    //         gameState !== prevProps.gameState &&
    //         (gameState === GameState.GAME_SCORE_SAVED || gameState === GameState.GAME_END);
    //     const userChanged = user?.email !== prevProps.user?.email;

    //     if (
    //         (userChanged && user && gameScore && !this.haveUserGotPlace) ||
    //         (user && prevProps.gameScore < this.props.gameScore)
    //     ) {
    //         // patching user once per game play
    //         this.haveUserGotPlace = true;

    //         if (this.props.isEagle) {
    //             UUPScoreService.patchCachedLeaderboardWithUserScore(game.slug, user as EagleUser, gameScore, true);
    //         } else {
    //             RemoteScoreService.patchCachedLeaderboardWithUserScore(game.slug, user as User, gameScore, true);
    //         }

    //         const dateTime = this.props.gameStartTime.toISOString();
    //         const timeOffset = new Date().getTimezoneOffset();

    //         this.props.dispatch(saveScore(game.slug, gameScore, dateTime, timeOffset));
    //     }
    // }

    getHighestUserScore() {
        // const {} = this.state;
        const { user, topScores, activeTab, list } = this.props;
        let score = 0;

        if (topScores && !!topScores[activeTab]) {
            score = topScores[activeTab] || 0;
        }

        if (user) {
            const userRecord = list.find((record) => record.name === user.name);
            const lbScore = (userRecord && userRecord.score) || 0;

            score = Math.max(score, lbScore);
        }

        return score;
    }

    loadTab(tab: Tabs, fetchFreshData: boolean = false) {
        this.props.dispatch(setLeaderboardLoading(true));
        // this.setState({ isLoading: true });
        const { isEagle, user, game } = this.props;
        const ScoreService = isEagle ? UUPScoreService : RemoteScoreService;
        const authorizedUserId = user && ('uid' in user ? user.uid : user.id);

        ScoreService.getLeaderboard(game.slug, tab, fetchFreshData, authorizedUserId).then(
            (list) =>
                batch(() => {
                    const lbList = LeaderboardRecord.checkAndUpdateCurrentUserData(this.props.user, list);

                    this.props.dispatch(setLeaderboardLoading(false));
                    this.props.dispatch(setLeaderboardList(lbList));
                })
            // this.setState({
            //     activeTab: tab,
            //     list: LeaderboardRecord.checkAndUpdateCurrentUserData(this.props.user, list),
            //     isLoading: false,
            // })
        );
    }

    selectTab(tab: Tabs) {
        // this.setState({ activeTab: tab });
        this.props.dispatch(setLeaderboardTab(tab));
        this.loadTab(tab);
    }

    render() {
        const { t, user, userAuthStatus, themeName, loading, list } = this.props;
        // const { isLoading, list } = this.state;
        const userScore = this.getHighestUserScore();
        const hasUserPlayed = !!userScore;

        return (
            <Container data-element-description="leaderboard">
                <Title data-element-description="leaderboard title">{t('TOP_SCORES')}</Title>

                <TabList>
                    <Row>
                        {this.tabs.map((tab) => (
                            <TabWrapper key={tab}>
                                <LeaderboardTab
                                    tabType={tab}
                                    isActive={this.props.activeTab === tab}
                                    onClick={() => this.selectTab(tab)}
                                    themeName={themeName}
                                />
                            </TabWrapper>
                        ))}
                    </Row>
                </TabList>

                <Content>
                    {loading && (
                        <LoaderWrapper>
                            <AppLoader />
                        </LoaderWrapper>
                    )}
                    {!loading && (
                        <LeaderboardList
                            list={list}
                            user={user}
                            userAuthStatus={userAuthStatus}
                            userScore={userScore}
                            hasUserPlayed={hasUserPlayed}
                        />
                    )}
                </Content>
            </Container>
        );
    }
}

const Container = (props: any) => <div className={styles.container} {...props} />;
const LoaderWrapper = (props: any) => <div className={styles.loaderWrapper} {...props} />;
const TabList = (props: any) => <div className={styles.tabList} {...props} />;
const Row = (props: any) => <div className="row" {...props} />;
const TabWrapper = (props: any) => <div className={classNames('col-4', styles.tabWrapper)} {...props} />;
const Title = (props: any) => (
    <p className={classNames(styles.title, styles.__override)} {...props}>
        {props.children}
    </p>
);
const Content = (props: any) => <div className={styles.content} {...props} />;
const LeaderboardTranslated = withTranslation()(LeaderboardBase);

export const Leaderboard = connect<any, {}, LeaderboardProps>((state: AppState) => ({
    gameState: state.gameState,
    user: state.user ? { ...state.user, ...{ name: sanitizeString(state.user.name, true) } } : state.user,
    userAuthStatus: state.userAuthStatus,
    topScores: state.userTopScores,
    gameScore: state.gameScore,
    themeName: state.config.theme.theming.name,
    isEagle: state.config.isEagle,
    list: state.leaderboard.list,
    loading: state.leaderboard.loading,
    activeTab: state.leaderboard.activeTab,
}))(LeaderboardTranslated);
