import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CoinsIcon } from '../../atoms/Icons/CoinsIcon';
import { LockIcon } from '../../atoms/Icons/LockIcon';
import { AvatarMatrix } from '../../models/Avatars';
import { EagleUser, User } from '../../models/User';
import { Avatar, EAvatarPlacement } from '../Avatar/Avatar';
import styles from './AvatarsList.css';

type AvatarsListProps = {
    avatars: AvatarMatrix[];
    user: User | EagleUser;
    chooseAvatar: (avatar: string) => void;
    buyAvatar: any;
};

const AvatarsListComponent = React.memo((props: AvatarsListProps & WithTranslation) => {
    const { avatars, t, chooseAvatar, buyAvatar } = props;
    const user = props.user as User;
    const onAvatarClick = (avatar: AvatarMatrix) => {
        if (avatar.Base || user.premiumAvatars?.includes(avatar.Name)) {
            chooseAvatar(avatar.Name);
        }
    };
    const userLevel = user.level || 0;
    const userCoins = user.coins || 0;

    return (
        <AvatarsListContainer>
            {avatars.map((avatar) => (
                <AvatarItem key={avatar.Name}>
                    <Avatar
                        avatar={avatar.Name}
                        place={EAvatarPlacement.AVATARS_LIST}
                        onAvatarClick={() => onAvatarClick(avatar)}
                    />
                    {!(avatar.Base || user.premiumAvatars?.includes(avatar.Name)) && (
                        <React.Fragment>
                            {userLevel < avatar.Level && (
                                <AvatarLock>
                                    <AvatarLockIcon />
                                    <AvatarLockDesc>{t('AVATAR_LOCK_DESC', { number: avatar.Level })}</AvatarLockDesc>
                                </AvatarLock>
                            )}
                            {userLevel >= avatar.Level && userCoins >= avatar.Coins && (
                                <AvatarInfo>
                                    <IconCoins />
                                    {`${avatar.Coins} | `}
                                    <BuyBtn
                                        onClick={() => {
                                            buyAvatar(avatar);
                                        }}
                                    >
                                        {t('REDEEM')}
                                    </BuyBtn>
                                </AvatarInfo>
                            )}
                            {(userLevel < avatar.Level || userCoins < avatar.Coins) && (
                                <AvatarInfo>
                                    <IconCoins />
                                    {avatar.Coins} | {t('LVL')} {avatar.Level}
                                </AvatarInfo>
                            )}
                        </React.Fragment>
                    )}
                </AvatarItem>
            ))}
        </AvatarsListContainer>
    );
});
const AvatarsListContainer = (props: any) => <ul className={styles.AvatarsList} {...props} />;
const AvatarItem = (props: any) => <li className={styles.AvatarsItem} {...props} />;
const AvatarImg = (props: any) => <img className={styles.AvatarsImg} alt={props.alt} {...props} />;
const AvatarInfo = (props: any) => <p className={styles.AvatarsInfo} {...props} />;
const IconCoins = (props: any) => <CoinsIcon className={styles.CoinsIcon} {...props} />;
const BuyBtn = (props: any) => <button className={styles.BuyBtn} {...props} />;
const AvatarLock = (props: any) => <div className={styles.AvatarLock} {...props} />;
const AvatarLockIcon = (props: any) => <LockIcon className={styles.LockIcon} {...props} />;
const AvatarLockDesc = (props: any) => <p className={styles.LockDesc} {...props} />;

export const AvatarsList = withTranslation()(AvatarsListComponent);
