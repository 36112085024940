import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isMobileMode, isServer } from '../../../utils';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { BackIcon } from '../../atoms/Icons/BackIcon';
import { Link } from '../../atoms/Link/Link';
import { HelpSidebar } from '../../organisms/HelpSidebar/HelpSidebar';
import { helpContentService, HelpSections } from '../../services/HelpContentService';
import styles from './Help.css';
import classnames from 'classnames';
import { DeviceDetector } from '../../services/DeviceDetector';

type HelpProps = {
    currentLang: string;
    adBlockByGame?: string;
};

type HelpState = {
    activeSection: HelpSections;
    returnGameAlias?: string;
    content?: string;
};

class HelpTemplateBase extends React.PureComponent<HelpProps & WithTranslation> {
    state: HelpState = {
        activeSection: HelpSections.ABOUT,
    };

    backButtonRef = React.createRef();
    sidebarRef = React.createRef();
    containerRef = React.createRef();

    componentDidMount() {
        if (this.props.adBlockByGame) {
            this.loadSection(HelpSections.AD_BLOCK);
        } else {
            this.loadSection(this.state.activeSection);
        }

        this.addOnScrollEvent();

        window.addEventListener('resize', () => {
            this.removeOnScrollEvent();
            this.addOnScrollEvent();
        });
        window.addEventListener('touchstart', handleIframedTouch, true);
        window.addEventListener('resize', () => this.loadSection(this.state.activeSection));
    }

    componentDidUpdate(prevProps: HelpProps) {
        const { adBlockByGame } = this.props;

        if (prevProps.adBlockByGame !== adBlockByGame && adBlockByGame) {
            this.loadSection(HelpSections.AD_BLOCK);
        }
    }

    componentWillUnmount() {
        this.removeOnScrollEvent();
        window.removeEventListener('touchstart', handleIframedTouch, true);
    }

    loadSection = (section: HelpSections) => {
        this.setState({ activeSection: section });

        const iframedSectionUrl = iframedSupportPages(section, this.props.currentLang);

        if (iframedSectionUrl) {
            return this.setState({
                activeSection: section,
                content: `
                    <iframe
                        src="${iframedSectionUrl}"
                        class="${classnames(
                            styles.iframedSection,
                            (DeviceDetector.isNotPc() ? styles.__device : ''),
                            (DeviceDetector.isMobile() ? styles.__mobile : ''),
                        )}"
                    ></iframe>
                `,
            });
        }

        helpContentService.getPage(section, this.props.currentLang).then((res) => {
            const topPos = (this.containerRef.current as HTMLElement).offsetTop - 20;
            const scrollTop = document.body.scrollTop || document.getElementsByTagName('html')[0].scrollTop;

            if (scrollTop > topPos) {
                window.scrollTo(0, topPos);
            }

            this.setState({ activeSection: section, content: res });
        });
    };

    render() {
        const { t, adBlockByGame } = this.props;
        const { content, activeSection } = this.state;

        return (
            <Wrapper data-element-description="help page">
                <Heading>
                    <HeadingText>{t(`HELP_TITLES.${activeSection.toUpperCase()}`)}</HeadingText>
                </Heading>

                <Container>
                    <HelpContainer ref={this.containerRef}>
                        {activeSection === HelpSections.AD_BLOCK && adBlockByGame && (
                            <BackToGame ref={this.backButtonRef}>
                                <BackToGameLink href={`/${t('ROUTES.GAMES')}/${adBlockByGame}`}>
                                    <BackIcon />
                                    <BackToGameText>{t('HELP_AD_BLOCK_BACK_TO_GAME')}</BackToGameText>
                                </BackToGameLink>
                            </BackToGame>
                        )}

                        <Row>
                            <SidebarColumn>
                                <SidebarSticky ref={this.sidebarRef}>
                                    <HelpSidebar onSelect={this.loadSection} activeItem={activeSection} />
                                </SidebarSticky>
                            </SidebarColumn>

                            <ContentColumn>
                                {activeSection === HelpSections.AD_BLOCK && <ShowOnlyFirstQuestionStyles />}
                                {!content && <AppLoader />}
                                {content && (
                                    <div
                                        className={classnames(
                                            styles.divWrapper,
                                            (iframedSupportPages(this.state.activeSection) ? styles.__iframe : ''),
                                            (DeviceDetector.isNotPc() ? styles.__device : ''),
                                            (DeviceDetector.isMobile() ? styles.__mobile : ''),
                                        )}
                                        dangerouslySetInnerHTML={{ __html: content }}
                                    />
                                )}
                            </ContentColumn>
                        </Row>
                        <br />
                        <br />
                        <br />
                    </HelpContainer>
                </Container>
            </Wrapper>
        );
    }

    private addOnScrollEvent() {
        if (!isMobileMode() && !isServer) {
            window.addEventListener('scroll', this.setSidebarSticky);
        }
    }

    private removeOnScrollEvent() {
        if (isMobileMode() && !isServer) {
            this.resetSticky();
        }

        window.removeEventListener('scroll', this.setSidebarSticky);
    }

    private setSidebarSticky = () => {
        const buttonEl: HTMLElement = this.backButtonRef.current as HTMLElement;
        const sideBarEl: HTMLElement = this.sidebarRef.current as HTMLElement;
        const containerEl: HTMLElement = this.containerRef.current as HTMLElement;
        const offsetTop = containerEl.offsetTop;
        const scrollTop = document.body.scrollTop || document.getElementsByTagName('html')[0].scrollTop;
        const stickyOffsetTop = 20;

        if (scrollTop > offsetTop - stickyOffsetTop) {
            let buttonHeight = 0;

            if (buttonEl) {
                buttonHeight = buttonEl.offsetHeight;
                containerEl.style.paddingTop = `${buttonHeight}px`;
                buttonEl.style.top = `${stickyOffsetTop}px`;
                buttonEl.style.position = 'fixed';
            }

            sideBarEl.style.position = 'fixed';
            sideBarEl.style.top = `${stickyOffsetTop + buttonHeight}px`;
        } else {
            this.resetSticky();
        }
    };

    private resetSticky = () => {
        const buttonEl: HTMLElement = this.backButtonRef.current as HTMLElement;
        const sideBarEl: HTMLElement = this.sidebarRef.current as HTMLElement;
        const containerEl: HTMLElement = this.containerRef.current as HTMLElement;

        if (buttonEl) {
            buttonEl.style.position = 'static';
            containerEl.style.paddingTop = '0';
        }

        sideBarEl.style.position = 'static';
    };
}

const Wrapper = (props: any) => <div className={styles.wrapper} {...props} />;
const Heading = (props: any) => <div className={styles.heading} {...props} />;
const HeadingText = (props: any) => (
    <h1 className={styles.headingText} {...props}>
        {props.children}
    </h1>
);
const Container = (props: any) => <div className={`container ${styles.container}`} {...props} />;
const HelpContainer = React.forwardRef((props: any, ref) => <div {...props} ref={ref} />);
const Row = (props: any) => <div className="row" {...props} />;
const SidebarColumn = (props: any) => <div className="col-12 col-md-4 col-lg-3" {...props} />;
const ContentColumn = (props: any) => <div className="col-12 col-md-8 col-lg-9" {...props} />;
const SidebarSticky = React.forwardRef<any, any>((props: any, ref) => <div {...props} ref={ref} />);
const BackToGame = React.forwardRef<any, any>((props: any, ref) => (
    <div className={styles.backToGame} {...props} ref={ref} />
));
const BackToGameLink = (props: any) => <Link className={styles.backToGameLink} {...props} />;
const BackToGameText = (props: any) => <span className={styles.backToGameText} {...props} />;
const ShowOnlyFirstQuestionStyles = () => (
    <style>
        {`
            .question,
            .question .question__btn {
                display: none;
            }

            .question:first-child {
                display: block;
                border: none;
            }

            .question:first-child .question__answer {
                display: block;
                margin: 0;
            }
        `}
    </style>
);

export const HelpTemplate = withTranslation()(HelpTemplateBase);

function iframedSupportPages(pageName: string, currLang: string = 'en') {
    const iframedSections = {
        [HelpSections.AD_BLOCK]: `https://support.arkadium.com/${currLang}/support/solutions/articles/44001739800-i-received-a-message-about-my-ad-blocker-what-should-i-do-`,
        [HelpSections.FAQ]: `https://support.arkadium.com/${currLang}/support/home`,
        [HelpSections.SYS_REQUIREMENTS]: `https://support.arkadium.com/${currLang}/support/solutions/articles/44001803144-what-are-the-minimum-system-requirements-needed-to-play-arkadium-games-`,
    };

    if (iframedSections.hasOwnProperty(pageName)) {
        return iframedSections[pageName];
    }

    return false;
}

function handleIframedTouch(ev) {
    const target: any = ev.target;

    if (!target) {
        return;
    }

    if (
        Array.from(target.classList).includes(styles.iframedSection)
        && Array.from(target.classList).includes('__device')
    ) {
        ev.stopPropagation();
        target.scrollIntoView();
    }
}
