import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const SupportedLangs = ['en', 'de', 'es', 'fr', 'it'];

// Sublocales
export const LangsMapping = {
    en: 'en'
};

export const defaultNameSpace: string = 'translation';

export const i18nOptions = {
    debug: false,
    whitelist: SupportedLangs,
    fallbackLng: '',
    defaultNS: defaultNameSpace,
    lowerCaseLng: true,
    // Force to load sync
    initImmediate: false,
    interpolation: {
        escapeValue: false,
    },
};

// client init
export const i18nClientInit = () => {
    return i18n.use(initReactI18next).init(i18nOptions);
};
