import { isServer } from '../../../utils';

export const initComeScore = (provider: string) => {
    if (isServer) return;
    const _comscore = (window as any)._comscore || [];

    _comscore.push({ c1: "2", c2: provider, c4: document.location.href });
    (window as any)._comscore = _comscore;
    const s = document.createElement("script"), el =
        document.getElementsByTagName("script")[0];

 s.async = true;
    s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") +
        ".scorecardresearch.com/beacon.js";
    el.parentNode.insertBefore(s, el);
}
