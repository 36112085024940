import React, { FC } from 'react';

import classNames from 'classnames';

import styles from './ProgressBar.css';

type ProgressBarPops = {
    steps: any[];
    activeStepIndex: number;
    stepClassName?: string;
};

export const ProgressBar: FC<ProgressBarPops> = React.memo(({ steps, activeStepIndex, stepClassName = '' }) => {
    const isActiveStep = (index) => {
        return activeStepIndex >= index;
    };

    return (
        <div className={styles.wrapper}>
            {steps.map(({ label }, index) => (
                <div
                    className={classNames(
                        styles.step,
                        {
                            [styles.activeStep]: isActiveStep(index),
                        },
                        stepClassName
                    )}
                    key={index.toString()}
                >
                    <div className={styles.step__circle}>{index + 1}</div>
                    <div className={styles.step__label}>{label}</div>
                </div>
            ))}
        </div>
    );
});
