import nodeFetch from 'node-fetch';
import { checkFetchStatus } from '../../fetch';
import { environment } from '../config/environment';
import { TLightboxPromotionImage, TLightboxPromotions } from '../models/LightboxPromotions';

export class LightboxPromotionService {
    public static fetchLightboxPromotions = (): Promise<TLightboxPromotions> => {
        return nodeFetch(`${environment.LIGHTBOX_PROMOTIONS_PATH}index.json`, { timeout: 20000 })
            .then((res) => checkFetchStatus(res as any))
            .then((response) => {
                try {
                    return response.json();
                } catch (e) {
                    return null;
                }
            });
    };

    public static isArenaExcluded = (lightboxPromotions: TLightboxPromotions) =>
        lightboxPromotions?.excluded?.includes(location.hostname);

    public static getImageUrl = (image: TLightboxPromotionImage, isMobile: boolean) =>
        `${environment.LIGHTBOX_PROMOTIONS_PATH}${image[isMobile ? 'mobile' : 'desktop']}`;
}
