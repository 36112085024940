import classnames from 'classnames';
import React, { useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { PlayCircleIcon } from '../../../atoms/Icons/PlayCircleIcon';
import { SignOutIcon } from '../../../atoms/Icons/SignOutIcon';
import styles from './GDPRForm.css';

type TLoginContinue = ({
  gdprUserData,
  gdprEmailMarketing,
}: {
  gdprUserData: boolean;
  gdprEmailMarketing: boolean;
}) => void;

type TProps = {
  onLogout: () => void;
  onLoginContinue: TLoginContinue;
  tWithPrefix: WithTranslation['t'];
};

const GDPRForm = React.memo(({
  tWithPrefix,
  onLoginContinue,
  onLogout,
}: TProps) => {
  const [gdprUserData, setGdprUserData] = useState<boolean>(false);
  const [gdprEmailMarketing, setGdprEmailMarketing] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>();
  const onContinuePress = () => {
    if (gdprUserData) {
      setIsValid(true);
      onLoginContinue({
        gdprUserData,
        gdprEmailMarketing,
      });
    } else {
      setIsValid(false);
    }
  };

  return (
    <>
      <div className={styles.agreementText}>{tWithPrefix('GDPR_TEXT')}</div>
      <div className={styles.header}>{tWithPrefix('PLEASE_ACCEPT')}</div>
      <div className={classnames(styles.checkboxes, { [styles.error]: isValid === false })}>
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            id="first-checkbox"
            checked={gdprUserData}
            onChange={() => setGdprUserData(!gdprUserData)}
          />
          <label htmlFor="first-checkbox">{tWithPrefix('CHECKBOX_USER_DATA')}</label>
        </div>
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            id="second-checkbox"
            checked={gdprEmailMarketing}
            onChange={() => setGdprEmailMarketing(!gdprEmailMarketing)}
          />
          <label htmlFor="second-checkbox">{tWithPrefix('CHECKBOX_MARKETING')}</label>
        </div>
      </div>
      <div className={styles.buttons}>
        <button className={styles.continueButton} onClick={onContinuePress}>
          {tWithPrefix('PLAY_BUTTON')}
          <PlayCircleIcon className={styles.gdprIcon}/>
        </button>
        <button className={styles.logoutButton} onClick={onLogout}>
          {tWithPrefix('LOGOUT_BUTTON')}
          <SignOutIcon className={styles.gdprIcon}/>
        </button>
      </div>
      <div className={styles.userNote}>{tWithPrefix('NOTE')}</div>
    </>
  );
});

GDPRForm.displayName = 'GDPRForm';
export { GDPRForm };
