// if we use it outside of subscription, then move it to a separate directory

import { AuthType } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/resend-confirmation.dto';
import { createAction, createReducer } from 'redux-act';

export type authErrorsProps = {
    email: string;
    password: string;
    checkbox: string;
};

export const setAuthErrors = createAction<authErrorsProps>('set auth errors');
export const authErrorsReducer = createReducer<authErrorsProps>({}, { email: '', password: '', checkbox: '' });
authErrorsReducer.on(setAuthErrors, (_state, payload) => payload);

export type authValuesProps = {
    email: string;
    password: string;
    checkbox: boolean;
};

export const setAuthValues = createAction<authValuesProps>('set auth values');
export const authValuesReducer = createReducer<authValuesProps>({}, { email: '', password: '', checkbox: true });
authValuesReducer.on(setAuthValues, (_state, payload) => payload);

export const setIsUserExists = createAction<boolean>('set auth values');
export const isUserExistsReducer = createReducer<boolean>({}, true);
isUserExistsReducer.on(setIsUserExists, (_state, payload) => payload);

// refactor and delete authValues (payment form) || authData (sign up form)

type authDataProps = {
    email: string;
    password?: string;
    checkBox?: boolean;
    user_id?: number;
    name?: string;
    avatar?: string;
    avatarBackground?: string;
    auth_provider_id?: number[];
    frame?: string;
    authProvider?: AuthType; // EmailPassword || Facebook || Google
    authProviderToken?: string; // token from google/fb/apple* sdk
};

export const setAuthData = createAction<authDataProps>('set auth values');
export const authDataReducer = createReducer<authDataProps>({}, { email: '' });
authDataReducer.on(setAuthData, (_state, payload) => ({
    ..._state,
    ...payload,
}));
