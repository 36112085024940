import classnames from 'classnames';
import React from 'react';
import styles from './AppLoader.css';

type AppLoaderProps = {
    text?: string;
    customClassName?: string;
    isFullPage?: boolean;
};

export const AppLoader = React.memo(({ text, isFullPage, customClassName }: AppLoaderProps) => {
    return (
        <Container customClassName={customClassName} isFullPage={isFullPage}>
            <Svg>
                <Circle />
            </Svg>

            {text && <Text>{text}</Text>}
        </Container>
    );
});

const Container = ({ customClassName, isFullPage, ...props }: any) => (
    <div className={classnames(styles.container, customClassName, { [styles.isFullPage]: isFullPage })} {...props} />
);
const Svg = (props: any) => (
    <svg className={styles.spinner} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66" {...props} />
);
const Circle = (props: any) => (
    <circle className={styles.path} stroke="#005aff" fill="none" cx="33" cy="33" r="30" {...props} />
);
const Text = (props: any) => <div className={styles.text} {...props} />;
