export enum AvatarsGroup {
    EAGLE = 'NEW',
    HUMANS = 'HUMANS',
    ANIMALS = 'ANIMALS',
    OBJECTS = 'OBJECTS',
    GAMES = 'GAMES',
}

export type AvatarMatrix = {
    Group: string;
    Name: string;
    Coins: number;
    Level: number;
    Base: boolean;
};

export const EagleAvatarImagesDefault: AvatarMatrix[] = [
    {
        Name: 'avatar_14.png',
        Base: true,
        Coins: 0,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_24.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_43.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_4.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_38.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_32.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_19.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_31.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_12.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_34.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_16.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_3.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_41.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_11.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_40.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_37.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_39.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_23.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_33.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_5.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_9.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_2.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_28.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_26.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_6.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_35.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_22.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_20.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_30.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_7.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_13.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_1.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_17.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_10.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_8.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_15.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_21.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_36.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_18.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_27.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_29.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_25.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_42.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_44.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_45.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_46.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_47.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_48.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_49.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_50.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
    {
        Name: 'avatar_51.png',
        Coins: 0,
        Base: true,
        Level: 0,
        Group: AvatarsGroup.EAGLE,
    },
];
