import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { LocalStorageSKeys } from '../../services/AuthService';
import { LocalStorageService } from '../../services/LocalStorage';
import { UrlService } from '../../services/UrlService';
import { setChosenGemsPack } from '../../store/ducks/gems';
import { ELoginSteps, setLoginModal, setShopOpen } from '../../store/ducks/modal';
import { AppState } from '../../store/types';
import { BuyButton } from '../Buttons/BuyButton';
import styles from './ShopItem.css';
import { Analytics } from '../../services/Analytics/Analytics';
import { AppInsightsAnalytics } from '../../services/Analytics/AppInsights';
import { gemsShopLocationSelector } from '../../store/ducks/gemsSelectors';
import { getAssetUrl } from '../../../utils';

export type TShopItem = {
  gemPackId: string;
  gemsAmount: number;
  imageSrc: string;
  price: string;
  currency: string;
  oldPrice?: number;
  ribbon?: string;
};

const ShopItemBase = ({ gemPackId, imageSrc, gemsAmount, price, currency, ribbon, t }: WithTranslation & TShopItem) => {
  const isLoggedIn = useSelector((state: AppState) => state.userAuthStatus === UserAuthStatus.USER_AUTHORIZED);
  const history = useHistory();
  const dispatch = useDispatch();
  const shopLocation = useSelector(gemsShopLocationSelector);
  const chooseGemPack = () => {
    dispatch(setChosenGemsPack(gemPackId));
    LocalStorageService.setItem(LocalStorageSKeys.gemPackId, gemPackId);
  };
  const openPaymentForm = async () => {
    const isSubscription = UrlService.getPageType() === 'Subscription page';

    chooseGemPack();

    if (!isSubscription) {
      history.push(`/${t('ROUTES.SHOP')}`);
    }

    dispatch(setShopOpen(false));

    AppInsightsAnalytics.trackAnalyticsEvent(
      await Analytics.gems.gemPurchaseClick(shopLocation, price, gemPackId, gemsAmount),
    );
  };
  const openLoginModal = () => {
    dispatch(setShopOpen(false));
    dispatch(setLoginModal({ isOpen: true, step: ELoginSteps.LOGIN, fromShopModal: true }));
    chooseGemPack();
  };

  return (
    <article key={price} className={classNames(styles.shopItemWrapper, { [styles.withRibbon]: ribbon })}>
      <h4 className={styles.gemsAmount}>
        <img className={styles.gemIcon} src={`${getAssetUrl()}/images/GemShopItem.png`} alt="Gem Shop Item"/>
        {Number(gemsAmount).toLocaleString('en-US')}
      </h4>
      <div className={classNames(styles.imageAndRibbonWrapper, { [styles.withRibbon]: ribbon })}>
        <img className={styles.gemImage} src={imageSrc} alt="Shop Item"/>
        {ribbon && (
          <div className={styles.ribbon}>
            <span>{ribbon}</span>
          </div>
        )}
      </div>

      <BuyButton
        onClick={isLoggedIn ? openPaymentForm : openLoginModal}
        price={`${Number(price).toLocaleString('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 2,
        })}`}
      />
    </article>
  );
};

export const ShopItem = withTranslation()(ShopItemBase);
