function getStorage(): Storage {
    try {
        window.localStorage.getItem("");
        return window.localStorage;
    } catch (_) {
        return window.sessionStorage;
    }
}

export function manageQADimension(): string {
    const keyName = '__qa_auto_test__';
    const storage = getStorage();
    const us = new URLSearchParams(window.location.search);
    const isQAAutoTest = storage.getItem(keyName) || us.get(keyName) || '';

    storage.setItem(keyName, isQAAutoTest);

    return isQAAutoTest;
}
