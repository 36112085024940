import { isServer } from '../../../utils';
import { BadgesStoredLogin, BadgesStoredPlays, BadgesStoredShown, BadgesStoredVisits } from './BadgesStorageHelpers';
import { CookieStorageService } from '../../services/CookieStorage';

export const badgeVariation = () => {
    if (isServer) {
        return 'Control';
    }

    // cookie encoded in useABTest() and already decoded inside Service
    const arkabtests = CookieStorageService.get('arkabtests');
    const variation = arkabtests && arkabtests
        .match(/abtest_Badges\:Badges(\,|$)/gi)
            ? 'Badges'
            : 'Control';

    return variation;
};

export const BadgesCheckProgress = () => {
    const state = {
        login: Boolean(BadgesStoredLogin()),
        plays: BadgesStoredPlays(),
        visits: BadgesStoredVisits(),
        wasShown: BadgesStoredShown(),
    };
    const status = {
        login: Boolean(state.login),
        plays: state.plays && state.plays >= 10,
        visits: state.visits,
    };
    const isDone = [...Object.values(status)].reduce((acc, curr) => (acc && Boolean(curr)), true);
    const out = {state, status, isDone};

    return out;
};
