import classNames from 'classnames';
import React from 'react';
import Daily from '../../../public/svg/Daily.svg';
import DailyRounded from '../../../public/svg/DailyRounded.svg';
import styles from './Icons.css';

export const DailyIcon = React.memo((props: any) => {
    const Icon = props.isRounded ? DailyRounded : Daily;

    return <Icon className={classNames(styles.dailyIcon, props.className)} aria-hidden="true" />;
});
