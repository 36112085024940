import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import {
  checkIfPassIsNotEmpty,
  TEagleErrorRegistrationResponse,
  TEmailField,
  TErrorValidation,
  TPasswordField,
  validateEmail,
} from '../../../../utils';
import { ESignInUpButtonTheme, SignInUpButton } from '../../../atoms/Buttons/SignInUpButton';
import { TextButton } from '../../../atoms/Buttons/TextButton';
import { ETextInputFields, ETextInputOrdering, ETextInputTypes, TextInput } from '../../../atoms/Inputs/TextInput';
import { environment } from '../../../config/environment';
import { AuthProvider } from '../../../models/Login';
import { EagleUser } from '../../../models/User';
import { TAuthorizedData } from '../../../organisms/Login/Login';
import { EagleLoginProvider, EagleLoginService } from '../../../services/EagleLoginService';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import { RECAPTCHA_ACTIONS, RECAPTCHA_ACTIONS_TYPE, RECAPTCHA_MODES } from '../../ChallengeCaptcha/hooks/useCaptcha';
import { ErrorsList, INITIAL_EAGLE_ERRORS_EMAIL } from '../ErrorsList';
import commonStyles from '../LoginPopup.css';
import { CallToActionText, PopupDescription, PopupTitle } from '../PopupDumbComponents/PopupDumbComponents';
import styles from './LoginForm.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/types';
import { NotificationBar } from '../../../atoms/NotificationBar/NotificationBar';

type TProps = {
  onAuthorized: (data: TAuthorizedData) => void;
  fbAppId: string;
  arenaDomainURL: URL;
  openRegisterForm: () => void;
  openForgotPasswordForm: () => void;
  openRequestEmailForm: (isResendConfirmationEmail?: boolean, isInputDisabled?: boolean) => void;
  tWithPrefix: WithTranslation['t'];
  t: WithTranslation['t'];
  saveEmail: (email: string) => void;
  fromShopModal: boolean;
  getCaptchaToken: (type: RECAPTCHA_ACTIONS_TYPE) => void;
  eventType: AuthProvider;
  captchaToken: string;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
};

enum EBUTTON_TYPES {
  EAGLE_BUTTON = 'EAGLE_BUTTON',
}

const GOOGLE_APP_ID_MESSAGE_TYPE = 'GOOGLE_APP_ID';
const LoginForm = React.memo(
  ({
    tWithPrefix,
    t,
    onAuthorized,
    fbAppId,
    arenaDomainURL,
    openRegisterForm,
    openForgotPasswordForm,
    openRequestEmailForm,
    saveEmail,
    fromShopModal,
    getCaptchaToken,
    eventType,
    captchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
  }: TProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errors, setErrors] = useState<Array<TErrorValidation<TEmailField | TPasswordField | ''>>>([]);
    const [eagleErrors, setEagleErrors] = useState<TEagleErrorRegistrationResponse>(INITIAL_EAGLE_ERRORS_EMAIL);
    const fbIframeRef = useRef();
    const googleIframeRef = useRef();
    const [isFbButtonLoad, setIsFbButtonLoad] = useState<boolean>(false);
    const [isGoogleButtonLoad, setIsGoogleButtonLoad] = useState<boolean>(false);
    const { isOpened: isNotificationBarOpened } = useSelector(({ notificationBar }: AppState) => notificationBar);

    useEffect(() => {
      if (fbIframeRef?.current && isFbButtonLoad) {
        postMessageToButton({
          type: EBUTTON_TYPES.EAGLE_BUTTON,
          target: fbIframeRef.current,
          origin: EagleLoginProvider.getFacebookIframeUrl(fbAppId),
        });
      }
    }, [isFbButtonLoad]);

    useEffect(() => {
      // Post message to db and google
      if (googleIframeRef?.current && isGoogleButtonLoad) {
        postMessageToButton({
          type: EBUTTON_TYPES.EAGLE_BUTTON,
          target: googleIframeRef.current,
          origin: EagleLoginProvider.getGoogleIframeUrl(),
          payload: {
            innerWidth: window.innerWidth,
          },
        });
        postMessageToButton({
          type: GOOGLE_APP_ID_MESSAGE_TYPE,
          target: googleIframeRef.current,
          origin: EagleLoginProvider.getGoogleIframeUrl(),
          payload: {
            googleAppId: environment.DEFAULT_GOOGLE_APP_ID,
          },
        });
      }
    }, [isGoogleButtonLoad]);

    useEffect(() => {
      void fetchLogin(captchaToken);
    }, [captchaToken]);

    const fetchLogin = async (token: string) => {
      if (token && !eventType.includes(AuthProvider.facebook) && !eventType.includes(AuthProvider.google)) {
        try {
          const user = await EagleLoginService.loginViaEmail({
            email,
            password,
            registrationPlaceUrl: arenaDomainURL,
            captchaToken: token,
            captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
          });

          handleLoginSuccess(user as EagleUser);
        } catch (err) {
          handleLoginError(err);
        }
      }
    };
    const handleLoginSuccess = (data: EagleUser) => {
      setIsLoading(false);
      clearCaptchaData();
      onAuthorized({
        user: data,
        eventType: AuthProvider.arkadium,
      });
    };
    const handleLoginError = (err: number) => {
      if (err === 1018) {
        clearCaptchaData();
        saveEmail(email);
        openRequestEmailForm(true, true);
      } else if (err === 1023) {
        dispatch(setShowRecaptcha(true));
      } else {
        clearCaptchaData();
        setEagleErrors(EagleLoginService.manageErrorsEmailVsPass(err));
        setIsLoading(false);
      }
    };
    const onFbButtonLoad = () => {
      setIsFbButtonLoad(true);
    };
    const onGoogleButtonLoad = () => {
      setIsGoogleButtonLoad(true);
    };
    const postMessageToButton = ({
      type,
      target,
      origin,
      payload = {},
    }: {
      type: string,
      target: HTMLIFrameElement,
      origin: string,
      payload?: { googleAppId?: string, innerWidth?: number }
    }) => {
      target.contentWindow.postMessage({
        type,
        payload,
      }, origin);
    };
    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim());
    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
    const onLoginFormSubmit = () => {
      const {
        isValid: isEmailValid,
        errors: emailErrors,
      } = validateEmail(email);
      const {
        isValid: isPasswordValid,
        errors: passwordErrors,
      } = checkIfPassIsNotEmpty(password);

      if (isEmailValid && isPasswordValid) {
        setIsLoading(true);
        getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
      }

      setErrors([...emailErrors, ...passwordErrors]);
    };
    const renderDescription = () => {
      if (isNotificationBarOpened) {
        return <NotificationBar/>;
      }

      return (
        <>
          {!fromShopModal && <PopupDescription text={tWithPrefix('LOG_IN_HELP')} isEagle/>}
          <PopupDescription
            text={tWithPrefix('LOG_IN_ACCOUNT')}
            isEagle
            className={classnames({ [commonStyles.popupDescriptionFromShopModal]: fromShopModal })}
          />
        </>
      );
    };

    return (
      <>
        {!showChallengeRecaptcha && (
          <>
            <div
              className={classnames(commonStyles.popupHeader, {
                [commonStyles.fromShopModal]: fromShopModal,
              })}
            >
              <PopupTitle
                text={fromShopModal ? t('GEMS.LOG_IN_TITLE') : tWithPrefix('LOG_IN_TITLE')}
                isEagle
                className={classnames({ [commonStyles.popupTitleFromShopModal]: fromShopModal })}
              />
              {renderDescription()}
            </div>
            <div
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onLoginFormSubmit();
                }
              }}
              role="presentation"
              className={classnames(commonStyles.defaultContentWrapper, styles.eagle, {
                [commonStyles.fromShopModal]: fromShopModal,
              })}
            >
              <ErrorsList tWithPrefix={tWithPrefix} errors={errors} eagleErrors={eagleErrors}/>
              <div className={styles.flexColumn}>
                <TextInput
                  field={ETextInputFields.EMAIL}
                  errors={errors}
                  placeholder={tWithPrefix('EMAIL')}
                  type={ETextInputTypes.TEXT}
                  onChange={handleEmail}
                  value={email}
                  ordering={ETextInputOrdering.FIRST}
                />

                <TextInput
                  field={ETextInputFields.PASSWORD}
                  errors={errors}
                  placeholder={tWithPrefix('PASSWORD')}
                  type={ETextInputTypes.PASSWORD}
                  onChange={handlePassword}
                  value={password}
                  ordering={ETextInputOrdering.LAST}
                />
                <div className={classnames(commonStyles.loginBtnRow, commonStyles.eagle)}>
                  <SignInUpButton
                    onClick={onLoginFormSubmit}
                    theme={ESignInUpButtonTheme.EAGLE_GREEN}
                    text={tWithPrefix('LOGIN')}
                    isLoading={isLoading}
                    isEagle
                  />
                </div>
                <div className={classnames(styles.forgotPasswordRow, styles.eagle)}>
                  <TextButton
                    onClick={openForgotPasswordForm}
                    text={tWithPrefix('FORGOT_YOUR_PASSWORD')}
                    isEagle
                  />
                </div>
                <CallToActionText
                  actionText={tWithPrefix('DO_NOT_HAVE_AN_ACCOUNT')}
                  buttonText={tWithPrefix('SIGN_UP')}
                  isEagle
                  onClick={openRegisterForm}
                />
              </div>
            </div>
            <div
              className={classnames(styles.buttons, styles.eagle, {
                [styles.fromShopModal]: fromShopModal,
              })}
            >
              <div className={styles.oAuthLoginButton}>
                <iframe
                  className={classnames(styles.oAuthLoginButtonIframe, styles.eagle)}
                  id="fb-login"
                  title="fb-login"
                  src={EagleLoginProvider.getFacebookIframeUrl(fbAppId)}
                  ref={fbIframeRef}
                  onLoad={onFbButtonLoad}
                />
              </div>
              <div className={styles.oAuthLoginButton}>
                <iframe
                  className={classnames(styles.oAuthLoginButtonIframe, styles.eagle)}
                  id="google-login"
                  title="google-login"
                  src={EagleLoginProvider.getGoogleIframeUrl()}
                  ref={googleIframeRef}
                  onLoad={onGoogleButtonLoad}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

export { LoginForm };
