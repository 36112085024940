import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ArkadiumLogoMini from '../../../public/svg/ArkadiumLogoMini.svg';
import styles from './PoweredBy.css';

interface PoweredByLinkProps {
    openModal: () => void;
}

class PoweredByLinkBase extends React.PureComponent<PoweredByLinkProps & WithTranslation> {
    render() {
        return (
            <LinkWrapper onClick={() => this.props.openModal()}>
                <div className={styles.poweredByLink}> 
                    <SmallText>{this.props.t('POWERED_BY.LINK_TEXT')}</SmallText>
                </div>
                <ArkadiumLogoMini />
            </LinkWrapper>
        );
    }
}

const LinkWrapper = (props) => <div className={styles.linkWrapper} {...props} />;
const SmallText = (props) => <div className={styles.smallText} {...props} />;

export const PoweredByLink = withTranslation()(PoweredByLinkBase);
