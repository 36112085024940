import classNames from 'classnames';
import React, { RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './Tooltip.css';

const BOUND_PX = 15;

type TooltipProps = {
    text: string;
    children: string | any;
    className?: string;
    tooltipClass?: string;
};

type TooltipState = {
    leftPosBounded: number;
    rightPosBounded: number;
    isOpacityZero: boolean;
    isTooltipVisible: boolean;
    isMissClickActive: boolean;
    isBottom: boolean;
};

class TooltipBase extends React.PureComponent<TooltipProps & WithTranslation> {
    tooltipRef = React.createRef<Element>();
    containerRef = React.createRef<HTMLDivElement>();
    observer: IntersectionObserver;

    state: TooltipState = {
        leftPosBounded: 0,
        rightPosBounded: 0,
        isOpacityZero: false,
        isTooltipVisible: false,
        isMissClickActive: false,
        isBottom: false,
    };

    componentDidMount() {
        const options = {
            root: document.querySelector('.main-content'),
            threshold: 0,
            rootMargin: '0px',
        };
        let callback = (entries, observer) => {
            entries.forEach((entry) => {
                console.debug(entry);

                if (entry.intersectionRatio > 0 && entry.intersectionRatio < 1) {
                    this.setState({ isBottom: true });
                }
            });
        };

        this.observer = new IntersectionObserver(callback, options);
    }

    componentDidUpdate(prevProps: TooltipProps, prevState: TooltipState) {
        const { isMissClickActive, isTooltipVisible } = this.state;
        const isTooltipChanged = prevState.isTooltipVisible !== isTooltipVisible;

        if (isTooltipChanged) {
            if (isTooltipVisible && !isMissClickActive) {
                document.addEventListener('click', this.misClick);
                this.setState({ isMissClickActive: true });
            } else if (!isTooltipVisible && isMissClickActive) {
                document.removeEventListener('click', this.misClick);
                this.setState({ isMissClickActive: false });
            }
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.misClick);
    }

    render() {
        const { children, className, text, tooltipClass } = this.props;
        const { leftPosBounded, rightPosBounded, isOpacityZero, isTooltipVisible } = this.state;

        return (
            <Container
                className={className}
                isTooltipVisible={isTooltipVisible}
                isBottom={this.state.isBottom}
                ref={this.containerRef}
                onMouseEnter={(_) => DeviceDetector.isDesktop() && this.toggleTooltip(true)}
                onMouseLeave={(_) => DeviceDetector.isDesktop() && this.toggleTooltip(false)}
                onClick={(_) => this.toggleTooltip(null)}
            >
                {children}

                <TooltipBlock
                    leftPosBounded={leftPosBounded}
                    rightPosBounded={rightPosBounded}
                    isOpacityZero={isOpacityZero}
                    tooltipClass={tooltipClass}
                    ref={this.tooltipRef}
                    isBottom={this.state.isBottom}
                >
                    <Text dangerouslySetInnerHTML={{ __html: text }} />
                </TooltipBlock>
            </Container>
        );
    }

    private misClick = (event: MouseEvent) => {
        if (event.target !== this.tooltipRef.current) {
            this.setState({ isTooltipVisible: false });
        }
    };

    private toggleTooltip = (toShow?: boolean) => {
        const { isTooltipVisible } = this.state;
        const windowWidth = window.innerWidth;
        const doShowTooltip = toShow !== null ? !isTooltipVisible && toShow : !isTooltipVisible;

        this.setState({
            isTooltipVisible: doShowTooltip,
            isOpacityZero: doShowTooltip,
        });

        const state = {} as TooltipState;

        // waiting tooltip render to get his positions
        setTimeout(() => {
            // we need updated value of isTooltipVisible from state
            if (this.state.isTooltipVisible) {
                const tooltipBounds = this.tooltipRef.current.getBoundingClientRect();
                const containerBounds = this.containerRef.current.getBoundingClientRect();
                const tooltipLeftPos = tooltipBounds.left;
                const tooltipRightPos = tooltipBounds.right;
                const iconLeftPos = containerBounds.left;
                const iconRightPos = windowWidth - containerBounds.right;

                if (tooltipLeftPos <= BOUND_PX) {
                    state.leftPosBounded = -1 * (iconLeftPos - BOUND_PX);
                } else if (tooltipRightPos >= windowWidth - BOUND_PX) {
                    state.rightPosBounded = -1 * (iconRightPos - BOUND_PX);
                }

                state.isOpacityZero = false;

                this.observer.observe(this.tooltipRef.current);
            } else {
                state.leftPosBounded = 0;
                state.rightPosBounded = 0;
            }

            this.setState(state);
        }, 0);
    };
}

const Container = React.forwardRef<any, any>(({ isTooltipVisible, isBottom, className, ...props }: any, ref) => (
    <div
        className={classNames(styles.container, className, {
            [styles.tooltipIsVisible]: isTooltipVisible,
            [styles.isBottom]: isBottom,
        })}
        {...props}
        ref={ref}
    />
));
const TooltipBlock = React.forwardRef<any, any>(
    (
        { leftPosBounded, rightPosBounded, isOpacityZero, isTooltipVisible, tooltipClass, isBottom, ...props }: any,
        ref
    ) => (
        <div
            className={classNames(styles.tooltip, tooltipClass, {
                [styles.boundedLeft]: leftPosBounded,
                [styles.boundedRight]: rightPosBounded,
                [styles.isBottom]: isBottom,
            })}
            style={{
                opacity: isOpacityZero ? 0 : 1,
                left: leftPosBounded ? leftPosBounded + 'px' : null,
                right: rightPosBounded ? rightPosBounded + 'px' : null,
            }}
            {...props}
            ref={ref}
        />
    )
);
const Text = (props: any) => <span {...props} />;

export const Tooltip = withTranslation()(TooltipBase);
