import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DailyIcon } from '../../atoms/Icons/DailyIcon';
import { MonthlyIcon } from '../../atoms/Icons/MonthlyIcon';
import { WeeklyIcon } from '../../atoms/Icons/WeeklyIcon';
import { THEMES } from '../../consts';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './LeaderboardTab.css';

export enum LeaderboardTabs {
    TODAY = 'day',
    THIS_WEEK = 'week',
    THIS_MONTH = 'month',
}

type LeaderboardTabProps = {
    onClick: any;
    isActive: boolean;
    tabType: LeaderboardTabs;
    t: any;
    themeName?: string;
};

const LeaderboardTabBase = ({ t, isActive, onClick, tabType, themeName }: LeaderboardTabProps & WithTranslation) => {
    const isDigitalTurbine = themeName === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();
    const Tabs = {
        [LeaderboardTabs.TODAY]: {
            title: 'Today',
            iconComponent: <DailyIcon isRounded={isDigitalTurbine} />,
        },
        [LeaderboardTabs.THIS_WEEK]: {
            title: 'This Week',
            iconComponent: <WeeklyIcon />,
        },
        [LeaderboardTabs.THIS_MONTH]: {
            title: 'This Month',
            iconComponent: <MonthlyIcon isRounded={isDigitalTurbine} />,
        },
    };
    const tab = Tabs[tabType];

    return (
        <Button isActive={isActive} onClick={onClick} data-element-description={`leaderboard ${tabType} tab`}>
            <IconWrapper isActive={isActive}>{tab.iconComponent}</IconWrapper>

            <Title>{t(tab.title.toUpperCase())}</Title>
        </Button>
    );
};
const Button = ({ isActive, ...props }: any) => (
    <button className={classNames(styles.button, { [styles.isActive]: isActive })} {...props} />
);
const IconWrapper = ({ isActive, ...props }: any) => (
    <span className={classNames(styles.icon, { [styles.isActive]: isActive })} {...props} />
);
const Title = (props: any) => <span {...props} />;

export const LeaderboardTab = withTranslation()(LeaderboardTabBase);
