import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary';
import { Game } from '../models/Game';
import { LDJsonTemplateService } from '../services/LDJsonTemplateService';
import { PwaService } from '../services/PwaService';
import { SEOService } from '../services/SEOService';
import { gamesByLangSelector } from '../store/ducks/games';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { CategoryTemplate } from '../templates/Category/Category';
import { generateSeoHreflangLinksList } from '../utils/generateSeoHreflangLinksList';

type AllGamesPageProps = {
    absoluteBaseHref: string;
    clientName: string;
    games: Game[];
    lang: string;
    isAdsEnabled: boolean;
    themeName: string;
    client?: string;
    disablePoweredBy?: boolean;
    arenaLang: string;
    sharingImage: string;
};

class AllGamesPageBase extends React.PureComponent<AllGamesPageProps & WithTranslation> {
    constructor(props) {
        super(props);
        const { lang, clientName, t } = props;
        const description = t('HOME_BOTTOM_DESCRIPTION_TEXT', { name: clientName });

        PwaService.createManifest(lang, {
            name: t('GAMES'),
            description,
            iconName: 'arena',
        });
    }

    getMeta(canonical: string) {
        const { t, clientName, absoluteBaseHref, sharingImage } = this.props;
        const metaDescription = t('ALL_GAMES_META_DESCRIPTION', { clientName });

        return SEOService.buildMeta(metaDescription, canonical, clientName, absoluteBaseHref, sharingImage);
    }

    render() {
        const { absoluteBaseHref, games, clientName, lang, t, isAdsEnabled, themeName, disablePoweredBy, client } = this.props;
        const caption = t('ALL_GAMES');
        const metaTitle = t('ALL_GAMES_META_TITLE', { clientName });
        const subpath = ['ROUTES.ALL_GAMES'];
        const canonical = `${absoluteBaseHref}/${t(subpath[0])}`;
        const LDJsonData = LDJsonTemplateService.buildGameListTemplate(games, absoluteBaseHref, lang);
        const seoHreflangLinks = generateSeoHreflangLinksList(absoluteBaseHref, this.props.arenaLang, subpath);

        return (
            <ErrorBoundary>
                {
                    <ConnectedHelmet
                        title={metaTitle}
                        meta={this.getMeta(canonical)}
                        link={[{ rel: 'canonical', href: canonical }, ...seoHreflangLinks]}
                    >
                        <script async type="application/ld+json">
                            {JSON.stringify(LDJsonData)}
                        </script>
                    </ConnectedHelmet>
                }

                <CategoryTemplate
                    isAdsEnabled={isAdsEnabled}
                    categoryKey="all-games"
                    caption={caption}
                    games={games}
                    themeName={themeName}
                    client={client}
                    disablePoweredBy={disablePoweredBy}
                />
            </ErrorBoundary>
        );
    }
}

const AllGamesPageState = connect((state: AppState) => ({
    absoluteBaseHref: absoluteBaseHrefSelector(state),
    clientName: state.config.theme.name,
    client: state.config.theme.client,
    disablePoweredBy: state.config.theme.disablePoweredBy,
    games: gamesByLangSelector(state),
    lang: state.currentLang,
    isAdsEnabled: state.config.theme.shouldShowDisplayAd(),
    themeName: state.config.theme.theming.name,
    arenaLang: state.config.theme.locale,
    sharingImage: state.config.theme.sharingImage,
}))(AllGamesPageBase);

export const AllGamesPage = withTranslation()(AllGamesPageState);
