export type GameRenderingGamesList = Array<string>;
export type GamesWithEagleList = Record<string, Array<string>>;
export enum ArenaEvents {
    CLOSE_SHOP_POPUP = 'CLOSE_SHOP_POPUP',
}
export const ARENA_CUSTOM_EVENT = 'MESSAGE_TO_MODULE';
export type ArenaEventTypes = keyof typeof ArenaEvents;
export type PostMessageDataType = {
    type: string;
    payload?: any;
    callback?: Function;
};
