export enum ThirdPartyScriptsKeys {
  ONE_TRUST = 'one-trust-cookie-consent',
  QUANTCAST = 'quantcast-cmp'
}

export class ThirdPartyScripts {
    public: Array<{
        name: string;
        vars: any[];
    }>;
    private: any[];
    isActive: boolean;

    constructor(data) {
        this.public = data.public || [];
        this.private = data.private || [];
        this.isActive = data.isActive;
    }
}
