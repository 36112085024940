import { EnumIframeMessageTypes } from '@arkadium/modules';
import { IframeMessageTypes } from './Enums';

export enum GameEvents {
  TEST_READY = 'TEST_READY', // game is loaded
  GAME_START = 'GAME_START', // game started
  EVENT_CHANGE = 'EVENT_CHANGE', // is triggered every time user interact with a game
  AD_START = 'AD_START', // is triggered when user is about to see reward video
  CHANGE_SCORE = 'CHANGE_SCORE', // is triggered right before GAME_END // score: event.payload
  GAME_END = 'GAME_END', // game is finished
  REWARD_START = 'REWARD_START',
  INTERSTITIAL_START = 'INTERSTITIAL_START',
  AD_REFRESH = 'AD_REFRESH',
  PURCHASE_UPDATE = 'PURCHASE_UPDATE',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  PURCHASE_RESPONSE = 'PURCHASE_RESPONSE',
  GAME_PAUSE = 'GAME_PAUSE',
  GAME_RESUME = 'GAME_RESUME',
}

export type PurchaseRequestEvent = {
  type: EnumIframeMessageTypes.PURCHASE_REQUEST;
  payload: {
    payload: unknown;
    currencySku: string;
    type: string;
  };
};

export type PurchaseResponseEvent = {
  type: EnumIframeMessageTypes.PURCHASE_RESPONSE;
  payload: {
    payload: unknown;
    result: boolean;
    code?: number;
    reason?: string;
  };
};

export type GameEventTypes = keyof typeof GameEvents;
export type IGameEventAction = {
  type: GameEventTypes;
  payload?: any;
};

export class GameObservable {
  fn = null;
  functions = [];

  // Being called by game
  next(event) {
    if (this.fn) {
      this.fn(event);
    }
  }

  xSubscribe(fn) {
    this.fn = fn;
  }

  xUnsubscribe() {
    this.fn = null;
  }

  // Reserved - used by game
  subscribe(f) {
    this.functions.push(f);
  }

  dispatch(action: IGameEventAction, postMessageToIframe = null) {
    this.functions.forEach((f) => f(action));

    if (postMessageToIframe) {
      // postMessageToIframe({
      //     type: IframeMessageTypes.UPDATE_LOCAL_STORAGE,
      //     payload: {
      //         ls: { ...JSON.parse(JSON.stringify(window.localStorage)) },
      //         ss: { ...JSON.parse(JSON.stringify(window.sessionStorage)) },
      //         ck: `${document.cookie}`,
      //     },
      // });
      postMessageToIframe({
        type: IframeMessageTypes.ARENA_TO_GAME_ACTION,
        payload: {
          ...action
        }
      });
    }
  }
}
