// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Store {
    // Common interfaces
    export interface Action {
        type: string;
        payload?: any;
    }

    // Grid interfaces
    export interface GridElement {
        x: string;
        y: string;
        solution?: string;
        userGuess?: string;
        checked?: boolean;
        revealed?: boolean;
    }
    export interface GridCell extends GridElement {
        number?: string;
        hint?: string;
        type?: string;
    }

    export interface GridWord extends GridElement {
        id: string;
        predefinedSolution?: string;
        preset?: string;
    }

    export type Grid = GridCell[];

    export interface GridMeta {
        size: {
            width: number;
            height: number;
        };
        title?: string;
        alphabet?: string;
        description?: string;
        creator?: string;
        date?: Date;
        copyright?: string;
        instructions?: string;
    }

    export interface GridClue {
        word: string;
        number: number;
        format: number;
        text: string;
        direction: Directions;
    }
    export enum ScreensEnum {
        GAME_START,
        GAME_SCREEN,
        GAME_END,
    }

    export enum Directions {
        ACROSS,
        DOWN,
    }

    export enum LayoutType {
        desktop = 'desktop',
        mobile = 'mobile',
        tablet = 'tablet',
    }

    export enum Orientation {
        portrait = 'portrait',
        landscape = 'landscape',
    }

    export enum CluesPosition {
        LEFT,
        RIGHT,
    }

    export interface Hash<T> {
        [key: string]: T;
    }

    export interface GridActive {
        direction: Directions;
        hints: string[];
        selectedCell: GridCell;
        selectedWord: GridWord;
        isDone: boolean;
        isGameStarted: boolean;
        isDailyPuzzle: boolean;
        isOldPuzzle: boolean;
        time: number;
        puzzleUrl: string;
        puzzleYear: number;
        selectedLetters: Hash<string>;
        checkedLetters: Hash<boolean>;
        revealedLetters: Hash<boolean>;

        selectedWords: Hash<string>;
        checkedWords: Hash<boolean>;
        revealedWords: Hash<boolean>;
        gridRevealed: boolean;
    }

    export interface GridState {
        meta: GridMeta;
        cells: Grid;
        words: GridWord[];
        clues: GridClue[];
        active: GridActive;
    }

    export interface SettingsState {
        timer: boolean;
        sounds: boolean;
        highlightErrors: boolean;
        arrowKeysToChangeDirection: boolean;
        skipOverFilledCells: boolean;
        jumpBackToBlanksAtTheEndOfWord: boolean;
        jumpToNextWordIfItIsFilled: boolean;
        highlightConflictingLetters: boolean;
        cluesPosition: CluesPosition;
        darkMode: boolean;
    }

    export enum MaxificationPopups {
        signUp = 'signUp',
        profile = 'profile',
        createProfile = 'createProfile',
        signInWelcome = 'signInWelcome',
        subscriptionCongrats = 'subscriptionCongrats',
        subscriptionThanks = 'subscriptionThanks',
        subscriptionAdvantagesWithIcons = 'subscriptionAdvantagesWithIcons',
        subscriptionAdvantagesWithText = 'subscriptionAdvantagesWithText',
        conditions = 'conditions',
        confirmDeleteAccount = 'confirmDeleteAccount',
    }

    export enum Popups {
        void = 'void',
        debug = 'debug',
        puzzleInfo = 'puzzleInfo',
        resetPuzzle = 'resetPuzzle',
        exitPuzzle = 'exitPuzzle',
        printPuzzle = 'printPuzzle',
        credits = 'credits',
        tutorial = 'tutorial',
        reveal = 'reveal',
        check = 'check',
        noInternet = 'noInternet',
        creatorNotes = 'creatorNotes',
    }

    export interface UIState {
        currentScreen: ScreensEnum;
        rootNodeId: string;
        layoutType: LayoutType;
        requestedDesktopMode: boolean;
        orientation: Orientation;
        isTutorialPuzzle: boolean;
        settings: boolean;
        currentPopup: Popups | MaxificationPopups;
        size: {
            width: number;
            height: number;
            font: number;
        };
    }
}
