import { RecurlyItemDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/recurly-data.dto';
import { PurchasableItemDto } from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GRM } from '../../../modules/GameRenderingModule';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { Modal } from '../../atoms/Modal/Modal';
import { ShopBanner } from '../../atoms/ShopBanner/ShopBanner';
import { ShopDescription } from '../../atoms/ShopDescription/ShopDescription';
import { ShopErrorScreen } from '../../atoms/ShopErrorScreen/ShopErrorScreen';
import { ShopItem, TShopItem } from '../../atoms/ShopItem/ShopItem';
import { environment } from '../../config/environment';
import { GEMS_TEST_SKU } from '../../constants/GemsConstants';
import { Analytics } from '../../services/Analytics/Analytics';
import { AppInsightsAnalytics } from '../../services/Analytics/AppInsights';
import EaglePaymentService from '../../services/EaglePaymentService';
import GemsService from '../../services/GemsService';
import { gemsShopLocationSelector } from '../../store/ducks/gemsSelectors';
import { EShopSteps, setShopOpen, setShopStep } from '../../store/ducks/modal';
import { AppState } from '../../store/types';
import styles from './ShopModal.css';

export const prefixGems = environment?.RECURLY_PLAN_PREFIX;

export const RecurlyGemsItemNames = ['5000_gem', '2400_gem', '1120_gem', '600_gem', '350_gem', '90_gem'];
export const gemsSkuList = RecurlyGemsItemNames.map((itemName) => `${prefixGems}${itemName}`);

export const ShopModal = () => {
  const {
    isOpen,
    step,
  } = useSelector((state: AppState) => state.modal.shop);
  const [shopList, setShopList] = useState<TShopItem[]>([]);
  const shopLocation = useSelector(gemsShopLocationSelector);
  const calculateGemsCardData = (
    gemsServiceData: PurchasableItemDto[],
    paymentServiceData: RecurlyItemDto[],
    isSubscriber: boolean = false,
  ): TShopItem[] => {
    return gemsServiceData
      .map((gemsServiceDataItem) => {
        const RECURLY_ERR_MESSAGE = 'Recurly data are incorrect';
        const EAGLE_ERR_MESSAGE = 'Eagle data are incorrect';
        const {
          sku,
          image,
          items,
        } = gemsServiceDataItem;
        const gemsAmountInPack = items.find((value) => value.sku === GEMS_TEST_SKU)?.amount;

        if (gemsAmountInPack === undefined) {
          throw new Error(EAGLE_ERR_MESSAGE);
        }

        const paymentServiceDataItem = paymentServiceData.find(({ code }) => code === sku);

        if (paymentServiceDataItem === undefined) {
          throw new Error(RECURLY_ERR_MESSAGE);
        }

        const {
          currencies,
          discountCurrencies,
        } = paymentServiceDataItem;
        let oldPrice: number;
        let finalPrice: number;
        const usdCurrency = 'USD';
        const priceWithoutDiscount = currencies.find(({ currency }) => currency === usdCurrency)?.unitAmount;
        const withDiscount = Boolean(discountCurrencies.length);

        if (priceWithoutDiscount === undefined) {
          throw new Error(RECURLY_ERR_MESSAGE);
        }

        finalPrice = priceWithoutDiscount;

        if (isSubscriber && withDiscount) {
          finalPrice = discountCurrencies.find(({ currency }) => currency === usdCurrency)?.unitAmount;

          if (finalPrice === undefined) {
            throw new Error(RECURLY_ERR_MESSAGE);
          }

          oldPrice = priceWithoutDiscount;
        }

        const cardData: TShopItem = {
          gemPackId: sku,
          gemsAmount: gemsAmountInPack,
          imageSrc: image,
          price: finalPrice.toString(),
          currency: usdCurrency,
          oldPrice,
        };

        return cardData;
      })
      .sort((cardDataA, cardDataB) => cardDataB.gemsAmount - cardDataA.gemsAmount);
  };

  useEffect(() => {
    (async () => {
      if (isOpen && step === EShopSteps.LOADING) {
        try {
          const [gemsServiceData, paymentServiceData] = await Promise.all([
            GemsService.getPurchasableItemsBySkuList(gemsSkuList),
            EaglePaymentService.getPurchasableItems(gemsSkuList),
          ]);
          const gemCardsData = calculateGemsCardData(gemsServiceData, paymentServiceData);

          setShopList(gemCardsData);
          dispatch(setShopStep(EShopSteps.SHOP));

          AppInsightsAnalytics.trackAnalyticsEvent(await Analytics.gems.gemShopImpression(shopLocation));
        } catch (e) {
          dispatch(setShopStep(EShopSteps.ERROR));
        }
      }
    })();
  }, [isOpen, step]);

  const dispatch = useDispatch();
  const onClose = () => {
    GRM.makeGameResume();
    dispatch(setShopOpen(false));
  };
  const onTryAgainClick = () => {
    dispatch(setShopStep(EShopSteps.LOADING));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalWrapperClassName={classNames({
        [styles.shopModalWrapper]: step !== EShopSteps.LOGIN,
      })}
      modalClassName={classNames({
        [styles.shopModal]: step !== EShopSteps.LOGIN,
      })}
    >
      <ShopInner onTryAgainClick={onTryAgainClick} shopList={shopList} step={step}/>
    </Modal>
  );
};

const ShopInner = ({
  shopList,
  step,
  onTryAgainClick,
}) => (
  <section className={styles.shopModalInnerWrapper}>
    <ShopBanner/>
    <section className={classNames(styles.contentWrapper, { [styles.forLoader]: step === EShopSteps.LOADING })}>
      {step === EShopSteps.LOADING && <AppLoader/>}
      {step === EShopSteps.SHOP && (
        <>
          <ShopDescription/>
          <section className={styles.shopListWrapper}>
            <div className={styles.shopList}>
              {shopList.map(({
                price,
                oldPrice,
                currency,
                gemPackId,
                gemsAmount,
                imageSrc,
                ribbon,
              }) => (
                <ShopItem
                  key={price}
                  price={price}
                  oldPrice={oldPrice}
                  currency={currency}
                  gemPackId={gemPackId}
                  gemsAmount={gemsAmount}
                  imageSrc={imageSrc}
                  ribbon={ribbon}
                />
              ))}
            </div>
          </section>
        </>
      )}
      {step === EShopSteps.ERROR && <ShopErrorScreen onTryAgainClick={onTryAgainClick}/>}
    </section>
  </section>
);
