export enum Browsers {
    SAFARI = 'safari',
    FIREFOX = 'firefox',
    CHROME = 'chrome',
    UNKNOWN = 'unknown',
}

class IncognitoDetector {
    public async incognitoMode(): Promise<string> {
        const userAgent = this.detectUserAgent();
        let incognitoMode = 'unknown';

        return new Promise(async (resolve) => {
            switch (userAgent) {
                case Browsers.CHROME:
                    if ('storage' in navigator && 'estimate' in navigator.storage) {
                        const { usage, quota } = await navigator.storage.estimate();

                        if (quota <= 1200000000) {
                            incognitoMode = 'true';
                            resolve(incognitoMode);
                        } else {
                            incognitoMode = 'false';
                            resolve(incognitoMode);
                        }
                    } else {
                        resolve(incognitoMode);
                    }

                    break;

                case Browsers.FIREFOX:
                    const db = indexedDB.open('test');

                    db.onerror = function () {
                        incognitoMode = 'true';
                        resolve(incognitoMode);
                    };

                    db.onsuccess = function () {
                        incognitoMode = 'false';
                        resolve(incognitoMode);
                    };

                    break;

                case Browsers.SAFARI:
                    incognitoMode = 'false';
                    const storage = window.sessionStorage;

                    try {
                        storage.setItem('someKeyHere', 'test');
                        storage.removeItem('someKeyHere');
                        resolve(incognitoMode);
                    } catch (e) {
                        if (e.code === DOMException.QUOTA_EXCEEDED_ERR && storage.length === 0) {
                            incognitoMode = 'true';
                            resolve(incognitoMode);
                        }
                    }

                    break;

                case Browsers.UNKNOWN || '':
                    resolve(Browsers.UNKNOWN);
                    break;
            }
        });
    }

    private detectUserAgent(): string {
        if ((window as any).chrome) {
            return Browsers.CHROME;
        }

        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            return Browsers.FIREFOX;
        }

        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return Browsers.SAFARI;
        }

        return Browsers.UNKNOWN;
    }
}

export const incognitoDetector = new IncognitoDetector();
