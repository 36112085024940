import classNames from 'classnames';
import React, { LegacyRef } from 'react';
import useCaptcha from './hooks/useCaptcha';
import styles from './CaptchaIframe.css';

type TCaptchaIframeProps = {
  iframeRef: LegacyRef<HTMLIFrameElement>;
  iframeURL: string;
  setIsIframeCaptchaLoaded: (value: boolean) => void
};

const CaptchaIframe = ({
  iframeRef,
  iframeURL,
  setIsIframeCaptchaLoaded,
}: TCaptchaIframeProps) => {
  const {
    showChallengeRecaptcha,
  } = useCaptcha();

  return (
    <div
      className={classNames({
        [styles.contentWrapper]: showChallengeRecaptcha,
        [styles.invisibleCaptcha]: !showChallengeRecaptcha,
      })}
    >
      <div className="modal-content">
        <h2>Verify you're human</h2>
        <iframe
          ref={iframeRef}
          onLoad={() => setIsIframeCaptchaLoaded(true)}
          src={iframeURL}
          title="Recaptcha for Eagle"
          className={styles.recaptchaIframe}
        />
      </div>
    </div>
  );
};

export default CaptchaIframe;
