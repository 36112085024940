import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store/types';
import { gamesByLangSelector } from '../../../store/ducks/games';
import { EventCat } from '../../../atoms/EventCategory/Eventcat';
import { PromoBanner } from '../PromoBanner';

const PromoBannerSplit = (props) => (
    <EventCat>
        {(data) => (
            <PromoBanner
                promoFromConfig={data.isArenaExcludedPromo || !data.eventData ? props.config.feed.promoGame : false}
                games={data.isArenaExcludedPromo || !data.eventData ? props.games : [data.eventData]}
                themeName={props.config.theme.theming.name}
            />
        )}
    </EventCat>
);

export const PromoBannerSplitWrapper = connect((state: AppState) => ({
    config: state.config,
    games: gamesByLangSelector(state),
}))(PromoBannerSplit);
