import React from 'react';

export const SOCLogo = React.memo(() => {
    return (
        <svg
            role="img"
            aria-label="soc-icon"
            width="97"
            height="50"
            viewBox="0 0 97 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M48.8677 10.9559L49.7667 9.65327C50.5659 10.4549 51.6648 10.9559 52.8636 10.9559C54.2621 10.9559 54.7616 10.2545 54.7616 9.65327C54.7616 8.75147 53.7626 8.55107 52.5639 8.25047C51.0654 7.74946 49.1674 7.34866 49.1674 5.24445C49.1674 3.64125 50.5659 2.43884 52.6638 2.43884C54.0623 2.43884 55.361 2.93984 56.2601 3.84165L55.2611 5.14425C54.5618 4.34265 53.5628 3.94185 52.5639 3.94185C51.5649 3.94185 50.8656 4.44285 50.8656 5.14425C50.8656 5.94586 51.8646 6.14626 52.9635 6.44686C54.5618 6.84766 56.4598 7.34866 56.4598 9.45287C56.4598 11.0561 55.361 12.4589 52.7637 12.4589C51.3651 12.5591 49.8666 11.9579 48.8677 10.9559Z"
                    fill="#231F20"
                />
                <path
                    d="M59.2571 7.44888C59.1572 4.84367 61.1551 2.53906 63.8523 2.43886C63.9522 2.43886 64.0521 2.43886 64.2519 2.43886C66.8492 2.33866 69.1469 4.44287 69.1469 7.04808C69.1469 7.14828 69.1469 7.24848 69.1469 7.44888C69.2467 10.0541 67.2488 12.3587 64.5516 12.4589C64.4517 12.4589 64.3518 12.4589 64.152 12.4589C61.5547 12.5591 59.2571 10.4549 59.2571 7.84968C59.1572 7.64928 59.1572 7.54908 59.2571 7.44888ZM67.3487 7.44888C67.5485 5.64527 66.2498 4.14227 64.4517 3.94187C64.3518 3.94187 64.2519 3.94187 64.152 3.94187C62.3539 3.94187 60.9553 5.34467 60.9553 7.14828C60.9553 7.24848 60.9553 7.34868 60.9553 7.44888C60.7555 9.25249 62.0542 10.7555 63.8523 10.9559C63.9522 10.9559 64.0521 10.9559 64.152 10.9559C65.9502 10.9559 67.3487 9.55309 67.3487 7.74948C67.3487 7.64928 67.3487 7.54908 67.3487 7.44888Z"
                    fill="#231F20"
                />
                <path
                    d="M71.9436 7.44886C71.8438 4.74345 74.0415 2.53904 76.6388 2.43884C76.7387 2.43884 76.8386 2.43884 76.9385 2.43884C78.5369 2.43884 80.0353 3.24045 80.8345 4.64325L79.4359 5.34465C78.9364 4.44285 78.0374 3.94185 77.0384 3.94185C75.2403 3.94185 73.7418 5.44485 73.7418 7.24846C73.7418 7.34866 73.7418 7.34866 73.7418 7.44886C73.6419 9.25247 75.0405 10.8557 76.8386 10.9559C76.9385 10.9559 76.9385 10.9559 77.0384 10.9559C78.0374 10.9559 78.9364 10.4549 79.4359 9.55307L80.8345 10.2545C80.0353 11.6573 78.5369 12.4589 76.9385 12.4589C74.2413 12.5591 71.9436 10.4549 71.8438 7.74946C71.9436 7.64926 71.9436 7.54906 71.9436 7.44886Z"
                    fill="#231F20"
                />
                <path
                    d="M88.9266 12.2585V10.9559C92.6227 8.15027 94.1212 6.84766 94.1212 5.44486C94.1212 4.54305 93.322 3.94185 92.5229 3.94185H92.423C91.424 3.94185 90.425 4.34265 89.8256 5.14426L88.8267 4.04205C89.7257 3.04005 91.0244 2.43885 92.423 2.53905C94.2211 2.43885 95.8195 3.54105 95.8195 5.44486C95.8195 7.24846 94.2211 8.85167 91.6238 10.7555H95.9193V12.2585H88.9266Z"
                    fill="#231F20"
                />
                <path
                    d="M52.1792 28.453V20.2365H49.2822V18.7335H56.7745V20.2365H53.8775V28.453H52.1792Z"
                    fill="#231F20"
                />
                <path
                    d="M61.5697 28.453V24.4449L57.8735 18.7335H59.7716L62.4688 22.8417L65.0661 18.7335H66.9642L63.268 24.3447V28.3528H61.5697V28.453Z"
                    fill="#231F20"
                />
                <path
                    d="M68.3628 28.4529V18.7335H72.5585C74.1568 18.6333 75.5554 19.8357 75.6553 21.4389C75.6553 21.5391 75.6553 21.6393 75.6553 21.7395C75.6553 23.3427 74.4565 24.7455 72.7583 24.7455C72.6584 24.7455 72.5585 24.7455 72.4586 24.7455H69.9611V28.3527L68.3628 28.4529ZM74.0569 21.7395C74.0569 20.9379 73.3576 20.2365 72.5585 20.2365H72.4586H70.1609V23.3427H72.4586C73.2577 23.3427 73.957 22.7415 74.0569 21.9399C74.0569 21.8397 74.0569 21.8397 74.0569 21.7395Z"
                    fill="#231F20"
                />
                <path
                    d="M77.3535 28.453V18.7335H83.9467V20.2365H79.0518V22.7415H83.8468V24.2445H79.0518V26.95H83.9467V28.453H77.3535Z"
                    fill="#231F20"
                />
                <path d="M89.9404 28.453V18.7335H91.6387V28.453H89.9404Z" fill="#231F20" />
                <path d="M94.0361 28.453V18.7335H95.7344V28.453H94.0361Z" fill="#231F20" />
                <path
                    d="M50.2461 42.1983C50.2461 40.7955 51.345 39.4929 52.8434 39.4929C52.9433 39.4929 52.9433 39.4929 53.0432 39.4929C53.9423 39.4929 54.7415 39.8937 55.141 40.6953L54.3419 41.0961C54.0422 40.5951 53.5427 40.2945 53.0432 40.2945C52.0442 40.2945 51.2451 41.0961 51.2451 42.0981V42.1983C51.1452 43.2003 51.9443 44.1021 52.9433 44.1021H53.0432C53.6426 44.1021 54.1421 43.8015 54.3419 43.3005L55.141 43.7013C54.7415 44.6031 53.9423 45.0039 53.0432 45.0039C51.5448 45.1041 50.346 43.9017 50.2461 42.3987C50.2461 42.3987 50.2461 42.2985 50.2461 42.1983Z"
                    fill="#231F20"
                />
                <path
                    d="M56.8394 44.9038V39.5931H60.4356V40.3947H57.8383V41.7975H60.5355V42.5992H57.8383V44.1022H60.5355V44.9038H56.8394Z"
                    fill="#231F20"
                />
                <path
                    d="M65.5301 44.9038L64.3313 42.8998H63.4323V44.9038H62.5332V39.5932H64.8308C65.7299 39.493 66.5291 40.1944 66.5291 41.0962C66.5291 41.1964 66.5291 41.1964 66.5291 41.2966C66.5291 42.0982 66.0296 42.6994 65.2304 42.8998L66.5291 45.004H65.5301V44.9038ZM65.63 41.2966C65.63 40.7956 65.2304 40.495 64.8308 40.495H64.7309H63.4323V42.1984H64.7309C65.2304 42.0982 65.63 41.7976 65.63 41.2966Z"
                    fill="#231F20"
                />
                <path
                    d="M69.7258 44.9038V40.3947H68.1274V39.5931H72.2232V40.3947H70.6249V44.9038H69.7258Z"
                    fill="#231F20"
                />
                <path d="M73.9219 44.9038V39.5931H74.8209V44.9038H73.9219Z" fill="#231F20" />
                <path
                    d="M76.9185 44.9038V39.5931H80.5147V40.3947H77.8175V41.7975H80.5147V42.5992H77.8175V44.9038H76.9185Z"
                    fill="#231F20"
                />
                <path d="M82.4131 44.9038V39.5931H83.3122V44.9038H82.4131Z" fill="#231F20" />
                <path
                    d="M85.4097 44.9038V39.5931H89.006V40.3947H86.3087V41.7975H89.006V42.5992H86.3087V44.1022H89.006V44.9038H85.4097Z"
                    fill="#231F20"
                />
                <path
                    d="M91.104 44.9037V39.5931H93.1019C94.5005 39.4929 95.7992 40.5951 95.8991 41.9979C95.8991 42.0981 95.8991 42.1983 95.8991 42.1983C95.8991 43.6011 94.8002 44.8035 93.4016 44.9037C93.3017 44.9037 93.2018 44.9037 93.1019 44.9037H91.104ZM94.9001 42.1983C94.9001 41.2965 94.2008 40.4949 93.3017 40.3947C93.2018 40.3947 93.1019 40.3947 93.1019 40.3947H92.103V44.1021H93.1019C94.1009 44.1021 94.9001 43.4007 94.9001 42.3987C94.9001 42.3987 94.9001 42.2985 94.9001 42.1983Z"
                    fill="#231F20"
                />
                <path d="M97.0179 34.8036H49.2671V35.505H97.0179V34.8036Z" fill="#231F20" />
                <path d="M97.0179 49.3326H49.2671V50.034H97.0179V49.3326Z" fill="#231F20" />
                <path
                    d="M37.3615 22.8457H36.0628V15.8317C36.0628 7.11423 29.07 0 20.2791 0C11.5881 0 4.59526 7.11423 4.49537 15.8317V22.8457H3.2966C1.49846 22.8457 0 24.2485 0 26.0521V46.3928C0 48.1964 1.49846 49.6994 3.2966 49.6994H37.4614C39.2595 49.6994 40.758 48.1964 40.758 46.3928V26.0521C40.6581 24.2485 39.1596 22.8457 37.3615 22.8457ZM10.7889 15.8317C10.7889 10.521 15.0844 6.31262 20.2791 6.31262C25.5736 6.31262 29.7693 10.6212 29.7693 15.8317V22.8457H10.7889V15.8317ZM31.3677 32.0641L18.9804 44.5892C18.6807 44.8898 18.1813 45.0902 17.6818 45.0902C17.1823 45.0902 16.6828 44.8898 16.3831 44.5892L9.29042 37.4749C8.99073 37.1743 8.79094 36.6733 8.79094 36.1723C8.79094 35.6713 8.99073 35.1703 9.29042 34.8697L11.0886 33.0661C11.3883 32.7655 11.8877 32.5651 12.3872 32.5651C12.8867 32.5651 13.3862 32.7655 13.6859 33.0661L17.6818 37.0741L26.9722 27.7555C27.2719 27.4549 27.7714 27.2545 28.2709 27.2545C28.7703 27.2545 29.2698 27.4549 29.5695 27.7555L31.3677 29.5591C31.6674 29.8597 31.8671 30.3607 31.8671 30.8617C31.967 31.2625 31.7673 31.7635 31.3677 32.0641Z"
                    fill="#2D6EB4"
                />
            </g>
        </svg>
    );
});
