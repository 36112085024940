import { apiFetch } from '../../fetch';
import { removeDuplicatesFromLeaderboard } from '../../utils';
import { environment } from '../config/environment';
import { LeaderboardRecord } from '../models/LeaderboardRecord';
import { EagleUser, User } from '../models/User';
import { UserTopScores } from '../models/UserTopScores';
import { LeaderboardTabs } from '../molecules/LeaderboardTab/LeaderboardTab';
import { AppInsightsAnalytics } from './Analytics/AppInsights';
import { authService } from './AuthService';
import { EagleLoginService } from './EagleLoginService';
import { UrlService } from './UrlService';

const API_PATH = environment.UUP_SCORE_API;

type TimeFrames = LeaderboardTabs.TODAY | LeaderboardTabs.THIS_WEEK | LeaderboardTabs.THIS_MONTH;

class UUPScoreService {
    private currentGameSlug: string = '';
    private lbPromise: Promise<LeaderboardRecord[]>;

    private lbStorage: {
        [timeFrame: string]: LeaderboardRecord[];
    } = {};
    // private eagleToken: string = EagleLoginService.getToken();
    public getLeaderboard(
        slug: string,
        timeFrame: TimeFrames,
        fetchFreshData?: boolean,
        authorizedUserId?: string
    ): Promise<LeaderboardRecord[]> {
        if (this.lbStorage[timeFrame] && !fetchFreshData) {
            return Promise.resolve(this.lbStorage[timeFrame]);
        }

        const LB_LIMIT_FOR_DUPLICATES = 200; // we need this to filter data from possible duplicates due to azure storage may be out of sync from time to time
        const LB_LIMIT = 101;

        // Forces lbStorage wipe on game change and if user's logged in on game end
        if (slug !== this.currentGameSlug) {
            this.currentGameSlug = slug;
            this.lbStorage = {};
        }

        if (!this.lbPromise || fetchFreshData) {
            // preventing multiple requests
            this.lbPromise = new Promise<LeaderboardRecord[]>((resolve, reject) => {
                const apiUrl = `${API_PATH}/score/arena/leaderboard/${slug}/${timeFrame}/?limit=${LB_LIMIT_FOR_DUPLICATES}&arenaDomain=${
                    UrlService.domain
                }&timeOffset=${new Date().getTimezoneOffset()}`;

                authService
                    .authFetch(apiUrl)
                    .then((data) => {
                        const filteredData = removeDuplicatesFromLeaderboard(data);

                        this.lbStorage[timeFrame] = filteredData
                            .slice(0, LB_LIMIT)
                            .map((item) => new LeaderboardRecord(item, authorizedUserId));
                        resolve(this.lbStorage[timeFrame]);
                        this.lbPromise = null;
                    })
                    .catch((err) => {
                        reject(err);
                        this.lbPromise = null;
                    });
            });
        }

        return this.lbPromise;
    }

    public patchCachedLeaderboardWithUserScore(
        slug: string,
        user: User | EagleUser,
        score: number = 0,
        userChanged: boolean
    ): Promise<void> {
        return this.getLeaderboard(slug, LeaderboardTabs.TODAY, userChanged, 'uid' in user ? user.uid : user.id).then(
            () => {
                if (score === 0) {
                    return;
                }

                const timeFrames = Object.keys(this.lbStorage);

                timeFrames.forEach((timeFrame: TimeFrames) => {
                    const rows = this.lbStorage[timeFrame];
                    // IsCurrent user
                    const userExistingIndex = rows.findIndex((row) => row.isCurrentUser);

                    // User already presented in LeaderBoard
                    if (userExistingIndex !== -1) {
                        // User didn't beat his score
                        if (rows[userExistingIndex].score >= score) {
                            return;
                        }

                        // Otherwise update
                        rows[userExistingIndex].score = score;
                    } else {
                        rows.push(LeaderboardRecord.FromUserAndScore(user, score));
                    }

                    rows.sort((a, b) => {
                        return b.score - a.score;
                    });
                    // Remove extra row if new row was inserted
                    rows.splice(100, 1);
                });
            }
        );
    }

    public async getTopScores(slug: string): Promise<UserTopScores> {
        try {
            const url = `${API_PATH}/score/arena/user/game/${slug}?arenaDomain=${
                UrlService.domain
            }&timeOffset=${new Date().getTimezoneOffset()}`;
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await EagleLoginService.getToken()}`,
                },
            };

            return apiFetch(url, options);
        } catch (error) {
            AppInsightsAnalytics.trackAppError(error, { data: 'getTopScores()' });
            throw error;
        }
        // return authService.authFetch(`${API_PATH}/score/user/top/${slug}?arenaDomain=${UrlService.domain}`);
    }

    public async saveScoreToApi(slug: string, value: number, dateTime: string, timeOffset: number) {
        const body = JSON.stringify([{ slug, score: +value, dateTime, timeOffset }]);

        try {
            const url = `${API_PATH}/score?arenaDomain=${UrlService.domain}`;
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await EagleLoginService.getToken()}`,
                },
                body, // body data type must match "Content-Type" header
            };

            return apiFetch(url, options);
        } catch (error) {
            AppInsightsAnalytics.trackAppError(error, { data: 'saveScoreToApi()' });
            throw error;
        }
    }
}

export default new UUPScoreService();
