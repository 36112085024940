import { environment } from '../config/environment';
const prefix = environment?.RECURLY_PLAN_PREFIX;

export const SubscriptionPlans = {
    ANNUAL: `${prefix}aasub_arkcom_annual`,
    MONTHLY: `${prefix}aasub_arkcom_monthly`,
};

export const getPlanByCode = (code, plans) => {
    let plan = null;

    if (plans) {
        plan = plans.find((item) => item?.code.match(code));
    }

    return plan;
};

export const getPlanName = (planId: string): string => {
    const type = planId === SubscriptionPlans.MONTHLY ? 'monthly' : 'annual';

    return type;
};

export enum SUBSCRIPTION_PLAN_NAME {
    MONTHLY = 'monthly',
    ANNUAL = 'annual',
}
