import React, { FC } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { MiscUtils } from '../../../../utils/MiscUtils';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { ListCheckCircleIcon } from '../../../FigmaStyleguide/Icons/ListCheckCircleIcon';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import subsStyles from '../SubscriptionStyles.css';
import styles from './ChosenPurchaseBlock.css';
import { setShopOpen } from '../../../store/ducks/modal';
import { AppInsightsAnalytics } from '../../../services/Analytics/AppInsights';
import { Analytics } from '../../../services/Analytics/Analytics';

export type SubscriptionPlanProps = {
  type: SubscriptionType;
  planCode: string;
  paymentType?: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
};

export type SubscriptionType = {
  label: string;
  price: number;
};

const DetailsList = {
  [PaymentType.gems]: [
    {
      infoText: (
        <>
          <ListCheckCircleIcon/>{' '}
          <p>Use gems to skip ads or buy boosts, themes, and more to use in your favorite games</p>
        </>
      ),
    },
  ],
};

export const ChosenPurchaseBlock: FC<SubscriptionPlanProps> = ({
  type,
  paymentType = PaymentType.gems,
  gemsAnalyticsProps,
}) => {
  const dispatch = useDispatch();
  const {
    label: typeLabel,
    price: typePrice,
  } = type;
  const isGemsPurchase = paymentType === PaymentType.gems;
  const switchText = 'Update my gem purchase';
  const switchCallback =
    isGemsPurchase
      ? async () => {
        AppInsightsAnalytics.trackAnalyticsEvent(
          await Analytics.gems.gemPurchaseChangeGems(
            gemsAnalyticsProps.shopLocation,
            gemsAnalyticsProps.priceInGem,
            gemsAnalyticsProps.gemsPackId,
            gemsAnalyticsProps.gemsInPack,
          ),
        );
        dispatch(setShopOpen(true));
      }
      : () => null;

  return (
    <>
      <div>
                <span className={subsStyles.heading}>
                    Gems
                </span>
        <span
          aria-hidden="true"
          className={classNames(subsStyles.link, styles.switchLink, { [styles.__gems]: isGemsPurchase })}
          onClick={switchCallback}
        >
                    {switchText}
        </span>
      </div>
      <div className={styles.subPlanWrapper}>
        <div
          className={classNames(styles.typeBlock)}
        >
          <div className={styles.typeName}>
            {updateLabel(typeLabel, 'Gem', gemsAnalyticsProps, paymentType)}
          </div>
          <div className={styles.typePrice}>
            ${typePrice}
          </div>
        </div>
        <div className={styles.typeDescription}>
          <ul className={styles.planDetailsList}>
            {DetailsList[paymentType].map((item, index) => (
              <li key={index.toString()}>{item.infoText}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export const updateLabel = (
  label: string,
  itemName: string,
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions,
  paymentType: string,
): string => {
  const gemsAmount = gemsAnalyticsProps && gemsAnalyticsProps?.gemsInPack;

  if (gemsAmount && paymentType === PaymentType.gems) {
    return `${MiscUtils.numberWithCommas(gemsAmount)} ${itemName}`;
  } else {
    return label;
  }
};
