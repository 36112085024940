import classnames from 'classnames';
import React from 'react';
import { EyeCrossedIcon } from '../../atoms/Icons/EyeCrossedIcon';
import { EyeIcon } from '../../atoms/Icons/EyeIcon';
import styles from './ProfileChangePass.css';

type ProfileChangePassInputProps = {
    title: string;
    value: string;
    onChange: any;
    errorMsg?: string;
};

export class ProfileChangePassInput extends React.PureComponent<ProfileChangePassInputProps> {
    state = {
        isPassVisible: false,
    };

    render() {
        const { title, value, onChange, errorMsg } = this.props;
        const { isPassVisible } = this.state;

        return (
            <InputContainer>
                <InputLabel data-element-description="change pass label">{title}</InputLabel>

                <InputRow data-element-description="change pass input row">
                    <Input
                        data-element-description="change pass input"
                        isPassVisible={isPassVisible}
                        value={value}
                        onChange={onChange}
                        isError={!!errorMsg}
                    />
                    <VisibleBtn data-element-description="visibility pass btn" onClick={this.togglePassVisibility}>
                        {isPassVisible && <Eye />}
                        {!isPassVisible && <EyeCrossed />}
                    </VisibleBtn>
                </InputRow>
                {errorMsg && <Error>{errorMsg}</Error>}
            </InputContainer>
        );
    }

    private togglePassVisibility = (e) => {
        e.preventDefault();
        this.setState({ isPassVisible: !this.state.isPassVisible });
    };
}

const InputContainer = (props: any) => <div className={styles.InputContainer} {...props} />;
const InputRow = (props: any) => <div className={styles.InputRow} {...props} />;
const InputLabel = (props: any) => <label htmlFor="changePsw" className={styles.InputLabel} {...props} />;
const Input = ({ isPassVisible, isError, ...props }) => (
    <input
        className={classnames(styles.Input, {
            [styles.ErrorInput]: isError,
        })}
        type={isPassVisible ? 'text' : 'password'}
        {...props}
    />
);
const VisibleBtn = (props: any) => <button className={styles.VisibleBtn} {...props} />;
const Eye = (props: any) => <EyeIcon className={styles.Eye} {...props} />;
const EyeCrossed = (props: any) => <EyeCrossedIcon className={styles.EyeCrossed} {...props} />;
const Error = (props: any) => <div className={styles.Error} {...props} />;
