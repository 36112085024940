import React, { ReactNode, useEffect } from 'react';
import styles from './Modal.css';
import classnames from 'classnames';
import { CloseButton } from './CloseButton';
import { setBodyOverflowHidden, setBodyOverflowAuto } from '../../../utils';
import { DeviceDetector } from '../../services/DeviceDetector';
import classNames from 'classnames';

type TModalProps = {
  closeByOverlayClick?: boolean;
  isOpen: boolean;
  modalClassName?: string;
  closeIconClassName?: string;
  onClose?: () => void;
  hasCloseIcon?: boolean;
  modalWrapperClassName?: string;
  closeIconColor?: string;
  children: ReactNode;
};

export const Modal = ({
  closeByOverlayClick,
  onClose,
  modalWrapperClassName,
  modalClassName,
  closeIconClassName,
  isOpen,
  hasCloseIcon = true,
  children,
  closeIconColor,
}: TModalProps) => {
  const isDesktop = DeviceDetector.isDesktop();
  const isNewDesign = document.documentElement.dataset.new_design === '__new';
  const closeDialog = (event: React.MouseEvent<Element, MouseEvent>) => {
    const eventTarget = event.target as Element;
    const currentTarget = event.currentTarget;
    const isClickedOnOverlay = currentTarget === event.target;
    const isClickedOnModalWrapper = Array.from(eventTarget.classList).join('').includes('Modal__modalWrapper');

    if (closeByOverlayClick && (isClickedOnOverlay || isClickedOnModalWrapper)) {
      onClose?.();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setBodyOverflowHidden();
    } else {
      setBodyOverflowAuto();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classNames(styles.overlay, { [styles.mobile]: !isDesktop })}
      onClick={closeDialog}
      role="presentation"
    >
      <div
        className={classnames(styles.modalWrapper, modalWrapperClassName, {
          [styles.mobile]: !isDesktop,
          [styles.oldDesign]: !isNewDesign,
        })}
      >
        <div
          className={classnames(styles.modal, modalClassName, { [styles.mobile]: !isDesktop })}
        >
          {hasCloseIcon && (
            <CloseButton
              className={closeIconClassName}
              onClose={onClose}
              mobile={!isDesktop}
              closeIconColor={closeIconColor}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
