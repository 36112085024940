import { createAction, createReducer } from 'redux-act';
import { GameRenderingGamesList } from '../../models/GameRendering';

export const setGameRenderingGamesList = createAction<GameRenderingGamesList>('set game rendering games list');

const reducer = createReducer<GameRenderingGamesList>({}, []);

reducer.on(setGameRenderingGamesList, (_state, payload: any = null) => payload);

export default reducer;
