import { isServer } from '../../utils';
import { LocalStorageService } from '../services/LocalStorage';
import { KeysEnum } from './Enums';

export const ThemeSettingsAdType = {
    GAME_VIDEO_PREROLL: 'GAME_VIDEO_PREROLL',
};

const GAME_PLAY_CLEAR_TIME = 12; // hours

export interface CustomThemeModel {
    name: string;
    layout: {
        promoBannerBg: boolean;
        navbar: boolean;
        title: boolean;
    };
}
export class ThemeSettings {
    name: string;
    nameAlias: {
        [locale: string]: string;
    };
    client: string;
    clientHomePageUrl?: string;
    domain?: string;
    subfolder?: string;
    logoPath: string;
    locale: string;
    headerBgColorHex?: string;
    headerTextColorHex?: string;
    adRefreshTimeMs?: string;
    supportRewardVideo?: boolean;
    supportInterstitialVideo?: boolean;
    ads?: typeof ThemeSettingsAdType & {
        DisableVideo: { preroll: []; prerollShowRate: '' };
        DisableDisplay: boolean;
        isAdBlockerEnabled?: boolean;
    };
    loginExclude?: string;
    ftuxEnabled?: boolean;
    disableLogin?: boolean;
    disableLeaderboard?: boolean;
    disablePoweredBy?: boolean;
    theming: CustomThemeModel;
    freshDeskId: string;
    useExternalImages: boolean;
    sharingImage: string;

    constructor(data, lang) {
        data = data || {};
        this.name = data.nameAlias && data.nameAlias[lang] ? data.nameAlias[lang] : data.name;
        this.client = data.client;
        this.clientHomePageUrl = data.clientHomePageUrl;
        this.domain = data.domain;
        this.subfolder = data.subfolder;
        this.logoPath = data.logoPath;
        this.locale = data.locale;
        this.headerBgColorHex = data.headerBgColorHex;
        this.headerTextColorHex = data.headerTextColorHex;
        this.adRefreshTimeMs = data.adRefreshTimeMs;
        this.supportRewardVideo = data.supportRewardVideo === undefined ? true : data.supportRewardVideo;
        this.supportInterstitialVideo =
            data.supportInterstitialVideo === undefined ? true : data.supportInterstitialVideo;
        this.ads = data.ads || {};
        this.ads.isAdBlockerEnabled =
            data.ads.isAdBlockerEnabled === undefined || data.ads.isAdBlockerEnabled === null
                ? true
                : data.ads.isAdBlockerEnabled;
        this.loginExclude = data.loginExclude;
        this.ftuxEnabled = data.ftuxEnabled === undefined || data.ftuxEnabled === null ? true : data.ftuxEnabled;
        this.disableLogin = !!data.disableLogin;
        this.disableLeaderboard = !!data.disableLeaderboard;
        this.disablePoweredBy = !!data.disablePoweredBy;
        this.theming = data.theming || {};
        this.freshDeskId = data.freshDeskId?.toString() || '';
        this.useExternalImages = !!data.useExternalImages;
        this.sharingImage = data.sharingImage?.toString() || '';
    }

    shouldShowPreroll(game): boolean {
        if (isServer) {
            return;
        }

        if (game.isAdsFree) {
            return false;
        }

        return this.isPrerollEnabled() && this.isPrerollEnabledForGame(game.slug) && this.isPrerollEnabledOnCount();
    }
    private;

    public isPrerollEnabled(): boolean {
        return !!this.ads[ThemeSettingsAdType.GAME_VIDEO_PREROLL];
    }

    public isPrerollEnabledForGame(slug: string): boolean {
        const preroll = this.ads.DisableVideo && this.ads.DisableVideo.preroll;

        if (Array.isArray(preroll)) {
            return !preroll.find((item) => item === slug);
        }

        return true;
    }

    public isPrerollEnabledOnCount(): boolean {
        const showRate = this.ads.DisableVideo && +this.ads.DisableVideo.prerollShowRate;

        if (!showRate || showRate < 2) {
            return true;
        }

        const lastGamePlay = LocalStorageService.getItem(KeysEnum.lastGamePlay);
        const needClearGamePlays =
            lastGamePlay && (Date.now() - Date.parse(lastGamePlay)) / 1000 / 60 / 60 >= GAME_PLAY_CLEAR_TIME;

        if (needClearGamePlays) {
            LocalStorageService.removeItem(KeysEnum.gamePlayCount);
        }

        LocalStorageService.setItem(KeysEnum.lastGamePlay, new Date().toString());

        const gamePlayCount = +LocalStorageService.getItem(KeysEnum.gamePlayCount);

        return gamePlayCount % showRate === 0;
    }

    public shouldShowDisplayAd(): boolean {
        return !this.ads.DisableDisplay;
    }
}
