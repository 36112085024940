import React, { memo, RefObject, useEffect, useState } from 'react';
import { useRecurly } from '@recurly/react-recurly';
import { WithTranslation, withTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { chooseGemsPacksPlan, PaymentType } from '../../../constants/RecurlyPurchase';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import { PaymentMethod } from '../../../models/Payment/PaymentForm';
import {
  RECAPTCHA_ACTIONS,
  RECAPTCHA_ACTIONS_TYPE,
  RECAPTCHA_MODES,
} from '../../../molecules/ChallengeCaptcha/hooks/useCaptcha';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { AppInsightService } from '../../../services/AppInsight';
import { LocalStorageSKeys } from '../../../services/AuthService';
import { LocalStorageService } from '../../../services/LocalStorage';
import { UrlService } from '../../../services/UrlService';
import { setFullScreenLoading, setSnackbarData } from '../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import {
  setStepIndex,
  ReviewPaymentInfoStepContent,
  setRecurlyToken,
} from '../../../store/ducks/subscription/common';
import EaglePaymentService from '../../../services/EaglePaymentService';
import { UserAuthStatus } from '../../../models/UserAuthStatus';
import { gemsShopLocationSelector } from '../../../store/ducks/gemsSelectors';
import { AppInsightsAnalytics } from '../../../services/Analytics/AppInsights';
import { GRM } from '../../../../modules/GameRenderingModule';
import styles from '../PurchasePageTemplate.css';
import { AnalyticsGeneral } from '../../../services/Analytics/AnalyticsGeneral';

type SummaryInfoProps = {
  formRef: RefObject<HTMLFormElement>;
  paymentType: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
};

const SubmitButtonBase = memo(
  ({
    t,
    formRef,
    paymentType,
    gemsAnalyticsProps,
    getCaptchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
  }: WithTranslation & SummaryInfoProps) => {
    const dispatch = useDispatch();
    const recurly = useRecurly();
    const stepIndex = useSelector((state) => state.stepIndex);
    const paymentMethod = useSelector((state) => state.paymentMethod);
    const user = useSelector((state) => state.user);
    const userProcessed = useSelector((state) => state.userAuthStatus === UserAuthStatus.USER_AUTHORIZED);
    const paymentStepContent = useSelector(({ reviewPaymentInfoStepContent }) => reviewPaymentInfoStepContent);
    const recurlyToken = useSelector((state) => state.recurlyToken);
    const isValidForm = useSelector((state) => state.isValidForm);
    const fullScreenLoading = useSelector((state) => state.fullScreenLoading);
    const chosenGemsPackItemName = LocalStorageService.getItem(LocalStorageSKeys.gemPackId);
    const gemsShopLocation = useSelector(gemsShopLocationSelector);
    const [mainBtnLabel, setMainBtnLabel] = useState('Next step');
    const captchaToken = useSelector(({ recaptcha }) => recaptcha.recaptchaToken);
    const paypal = recurly.PayPal({
      display: {
        displayName: paymentType === PaymentType.gems
          ? `Arkadium ${gemsAnalyticsProps.gemsInPack} Gem purchase. ${t('PURCHASE_PAGE.PAYPAL_TAXES_TEXT')}`
          : 'Arkadium TEST purchase',
      },
    });

    useEffect(() => {
      if (captchaToken) {
        const itemCode = chooseGemsPacksPlan()[chosenGemsPackItemName].plan;

        void fetchPurchaseItem(itemCode, gemsAnalyticsProps);
      }
    }, [captchaToken]);

    const fetchPurchaseItem = async (itemCode: string, gemsAnalytics: GemsAnalyticsCustomDimensions) => {
      try {
        await EaglePaymentService.purchaseItem({
          arenaName: UrlService.getCurrentHostnameForEagle(),
          itemCode,
          quantity: 1,
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
          tokenId: recurlyToken,
        });
        await handlePurchaseItemSuccess(gemsAnalytics);
      } catch (err) {
        const { body: { details } } = err;

        AppInsightService.trackAppError(err, { data: 'handleSubmitForm()' });
        handlePurchaseItemError(details);
      }
    };
    const handlePurchaseItemSuccess = async (gemsAnalytics: GemsAnalyticsCustomDimensions) => {
      const {
        gemsPackId,
        gemsInPack,
        priceInGem,
      } = gemsAnalytics;

      GRM.setGamePurchaseRequestResult(true);
      AppInsightsAnalytics.trackAnalyticsEvent(
        await Analytics.gems.gemPurchaseSuccess(
          priceInGem,
          gemsPackId,
          gemsInPack,
          gemsShopLocation,
        ),
      );
      AnalyticsGeneral.gemPurchaseSuccessGA();
      clearCaptchaData();
      dispatch(setStepIndex(2));
      dispatch(setFullScreenLoading(false));
    };
    const handlePurchaseItemError = (details: Array<{ Message: string, ErrorCode: number }>) => {
      GRM.setGamePurchaseRequestResult(false);
      batch(() => {
        dispatch(
          setSnackbarData({
            isOpened: true,
            message: details[0].Message,
            type: 'error',
          }),
        );

        if (details[0].ErrorCode === 1010 || details[0].ErrorCode === 1023) {
          dispatch(setShowRecaptcha(true));
        } else {
          dispatch(setStepIndex(0));
          clearCaptchaData();
        }
      });
    };

    paypal.on('token', function (token) {
      batch(() => {
        dispatch(setRecurlyToken(token.id));
        dispatch(setStepIndex(1));
      });
    });

    paypal.on('error', function (err) {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: err.message,
          type: 'error',
        }),
      );
    });

    const getButtonLabel = () => {
      if (stepIndex === 0) {
        return paymentMethod === PaymentMethod.PAYPAL ? 'Confirm' : 'Next step';
      }

      return 'Confirm Purchase';
    };

    useEffect(() => {
      setMainBtnLabel(getButtonLabel());
    }, [stepIndex, paymentStepContent, paymentMethod]);

    const handleSubmit = async () => {
      if (stepIndex === 0) {
        if (paymentMethod === PaymentMethod.CARD) {
          formRef.current.dispatchEvent(
            new Event('submit', {
              cancelable: true,
              bubbles: true,
            }),
          );
        }

        // Gems Analytics
        if (paymentType === PaymentType.gems) {
          const {
            gemsPackId,
            gemsInPack,
            priceInGem,
          } = gemsAnalyticsProps;

          AppInsightsAnalytics.trackAnalyticsEvent(
            await Analytics.gems.gemPurchaseNextStepClick(
              priceInGem,
              gemsPackId,
              gemsInPack,
              gemsShopLocation,
            ),
          );
        }

        if (paymentMethod === PaymentMethod.PAYPAL) {
          paypal.start();
        }
      }

      if (stepIndex === 1) {
        dispatch(setFullScreenLoading(true));

        if (
          (userProcessed || paymentType === PaymentType.gems) &&
          user &&
          paymentStepContent.type === ReviewPaymentInfoStepContent.PurchaseDetails
        ) {
          dispatch(setFullScreenLoading(true));

          if (paymentType === PaymentType.gems) {
            getCaptchaToken(RECAPTCHA_ACTIONS.PurchaseItem);
          }
        }
      }
    };

    return (
      <div>
        <Button
          pseudoDisabled={stepIndex === 0 && paymentMethod === PaymentMethod.CARD && !isValidForm}
          disabled={(!isValidForm || fullScreenLoading) && paymentMethod !== PaymentMethod.PAYPAL}
          onClick={handleSubmit}
          className={styles.nextStepBtn}
        >
          {mainBtnLabel}
        </Button>
      </div>
    );
  },
);

export const SubmitButton = withTranslation()(SubmitButtonBase);
