import { ActionTypes, CustomCategories } from '../../models/Analytics';
import { LoginCaller } from '../../models/Login';
import { UrlService } from '../UrlService';
import { AnalyticsInit } from './AnalyticsInit';
import { AppInsightsAnalytics } from './AppInsights';
import { GoogleAnalytics } from './Google';
import { GemsAnalyticsEvents } from './AI/GemsAnalyticsAi';
import { AnalyticsEventAction } from './AnalyticsEventBuilder';

class AnalyticsGeneralClass {
    private clickCounts: any = {};

    public topNavCategories(actionType: string, tags: string) {
        const nonInteraction = actionType === ActionTypes.IMPRESSION;
        const dimensionsGoogle: any = {};
        const dimensionsAppInsights: any = {};

        if (actionType === ActionTypes.CLICK) {
            if (this.clickCounts.prevCat === tags) {
                return;
            }

            this.clickCounts.prevCat = tags;

            let tag = tags;

            if (tags === 'HOME') {
                tag = 'Games home';
            }

            if (tags === 'ALL_GAMES') {
                tag = 'All games';
            }

            dimensionsGoogle.dimension3 = tag;
            dimensionsAppInsights.topnavClick = tag;
        } else {
            dimensionsGoogle.dimension4 = tags;
            dimensionsAppInsights.topnavImpression = tags;
        }

        let googleEvent;

        if (actionType === ActionTypes.CLICK) {
            googleEvent = {
                name: 'TopNav_Category_Click',
                category: dimensionsGoogle.dimension3,
                label: `${AnalyticsInit.domain} | ${UrlService.getCurrentPage()}`,
                nonInteraction: false,
            };
        } else if (actionType === ActionTypes.IMPRESSION) {
            googleEvent = {
                action: actionType,
                category: 'Top_Nav_Slots',
                label: `${AnalyticsInit.domain} | ${UrlService.getCurrentPage()}`,
                nonInteraction,
                ...dimensionsGoogle,
            };
        }

        GoogleAnalytics.trackEvent(googleEvent);

        // don't send the event on impression
        if (actionType !== ActionTypes.IMPRESSION) {
            const eventAppInsight = {
                action: actionType,
                nonInteraction,
                ...dimensionsAppInsights,
            };

            AppInsightsAnalytics.trackEvent('topnavSlot', eventAppInsight);
        }
    }

    public searchingAnalytics(actionType: string, data: string) {
        const page = UrlService.getCurrentPage();
        const gamePageSlug = AnalyticsInit.gameSlug ? `/${AnalyticsInit.gameSlug}` : '';
        const eventAppInsight = {
            action: actionType,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            searchQuery: data,
        };

        AppInsightsAnalytics.trackEvent('SearchBox', eventAppInsight);

        let name;

        if (actionType === ActionTypes.CLICK) {
            name = 'Search_Game_Click';
        } else if (actionType === ActionTypes.QUERY) {
            name = 'Search_Query';
        }

        const event = {
            action: actionType,
            name,
            label: `${AnalyticsInit.domain} | ${page}${gamePageSlug}`,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        GoogleAnalytics.trackEvent(event);
    }

    public loginGA(actionType: string, loginCaller: string, loginSystem?: string) {
        const nonInteraction = actionType === ActionTypes.IMPRESSION;
        let loginPlaceGA = loginCaller;
            const label =
                actionType === ActionTypes.LOGIN ? loginSystem : `${AnalyticsInit.domain} | ${UrlService.getPageType()}`;
            const event = {
                action: actionType,
                category: loginPlaceGA,
                nonInteraction,
                label,
            };

            GoogleAnalytics.trackEvent(event);
    }

    public loginAI(actionType: string, loginCaller: string, loginSystem?: string) {
        const nonInteraction = actionType === ActionTypes.IMPRESSION;
        const eventAppInsight = {
            action: actionType,
            loginButtonLocation: loginCaller,
            nonInteraction,
            loginSystem,
        };

        AppInsightsAnalytics.trackEvent('loginButton', eventAppInsight);
    }

    public registration(loginCaller: string, loginSystem: string) {
        const event = {
            name: 'registration',
            action: loginCaller,
            label: loginSystem,
            nonInteraction: false,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            nonInteraction: false,
            loginButtonLocation: loginCaller,
            loginSystem,
        };

        AppInsightsAnalytics.trackEvent('registration', eventAppInsight);
    }

    public displayAd(isRefresh: boolean, isViewable: boolean, dimensions: number[], adId: string, eventDetail?) {
        const adType = isRefresh ? '_refresh' : '_notrefresh';
        const nonInteraction = adType === '_notrefresh';
        const page = UrlService.getCurrentPage();
        const sizeString = this.adSizeToString(dimensions);
        const gamePageSlug = AnalyticsInit.gameSlug ? `/${AnalyticsInit.gameSlug}` : '';
        let adIsEmpty = '';
        let adCpm = '';

        if (eventDetail) {
            adIsEmpty = eventDetail.isEmpty;
            adCpm = eventDetail.cpm;
        }

        const eventAppInsight = {
            action: ActionTypes.IMPRESSION,
            nonInteraction,
            displayAdType: adType,
            displayAdSize: sizeString,
            displayAdLocation: adId,
            displayAdIsEmpty: adIsEmpty,
            displayAdCpm: adCpm,
            displayAdIsViewable: isViewable ? '1' : '0',
            ...AnalyticsInit.checkPlayAgainOrFromRecGames(),
        };

        AppInsightsAnalytics.trackEvent('displayAd', eventAppInsight);
    }

    public pwa(actionType: string, category: string) {
        let label;

        if (category === CustomCategories.DESKTOP_PWA) {
            label = UrlService.getCurrentPage();
        } else {
            label = AnalyticsInit.gameSlug;
        }

        const nonInteraction =
            actionType === ActionTypes.IMPRESSION ||
            actionType === ActionTypes.DESKTOP_BOOKMARK_IMPRESSION ||
            actionType === ActionTypes.ANDROID_INSTALL_IMPRESSION;
        const event = {
            action: actionType,
            category,
            label,
            nonInteraction,
        };
        const eventAppInsight = {
            action: actionType,
            nonInteraction,
            label,
            category,
        };

        AppInsightsAnalytics.trackEvent(category, eventAppInsight);
        return event;
    }

    avatarChange(
        actionType: string,
        level: number,
        coinsUserHas: number,
        profileAvatarSelected?: string,
        profileAvatarPrice?: number
    ) {
        const gaAvatarLabel = profileAvatarSelected ? `| ${profileAvatarSelected}` : '';
        const nonInteraction = actionType === ActionTypes.AVATAR_AVAILABLE_IMPRESSION;
        const eventAppInsight = {
            action: actionType,
            levelUpId: level,
            nonInteraction,
            profileAvatarSelected,
            coinsUserHas,
            profileAvatarPrice,
        };

        AppInsightsAnalytics.trackEvent('changeProfileImage', eventAppInsight);
    }

    gemPurchaseSuccessGA(): void {
        const event = {
            name: GemsAnalyticsEvents.gemPurchaseSuccess,
            action: AnalyticsEventAction.CLICK,
            label: UrlService.getCurrentPage(),
            nonInteraction: false,
        };

        GoogleAnalytics.trackEvent(event);
    }

    createAccountGA(): void {
        const event = {
            name: 'create_Account_Click',
            action: AnalyticsEventAction.CLICK,
            label: UrlService.getCurrentPage(),
            nonInteraction: false,
        };

        GoogleAnalytics.trackEvent(event);
    }
    oneTrust(actionType: string, nonInteraction: boolean) {
        const aiEvent = {
            action: actionType,
            nonInteraction,
        };

        AppInsightsAnalytics.trackEvent('OneTrust', aiEvent);
    }

    oneTrustPopup(otPopupWasShowedFirstTime: boolean) {
        AppInsightsAnalytics.trackEvent('OneTrustPopupWasShowed', { otPopupWasShowedFirstTime });
    }

    private adSizeToString(dimensions: number[]) {
        return this.padStart(dimensions[0]) + 'x' + this.padStart(dimensions[1]);
    }

    private padStart(value = 0, length = 3, insert = '0') {
        const lengthDiff = length - value.toString().length;

        if (lengthDiff <= 0) {
            return value;
        }

        return new Array(lengthDiff + 1).join(insert) + value;
    }
}

export const AnalyticsGeneral = new AnalyticsGeneralClass();
