import React, { useState, memo } from 'react';
import { CardCvvElement, CardNumberElement } from '@recurly/react-recurly';
import classNames from 'classnames';
import { AmericanExpress } from '../../FigmaStyleguide/Icons/AmericanExpress';
import { BlankCard } from '../../FigmaStyleguide/Icons/BlankCard';
import { MasterCard } from '../../FigmaStyleguide/Icons/MasterCard';
import { Visa } from '../../FigmaStyleguide/Icons/Visa';
import styles from './CreditInput.css';
import { ExpirationDateInput } from './ExpirationDateInput';

type InputProps = {
  dataRecurly?: string;
  className?: string;
  placeholder?: string;
  setValue: (name: string, value: string | object) => void;
  setValidation: (isValid: boolean, name: string, message: string) => void;
  errors: unknown;
};

export const CreditInput = memo(
  ({
    dataRecurly,
    className,
    placeholder,
    setValue,
    setValidation,
    errors,
  }: InputProps) => {
    const [valueLength, setValueLength] = useState<number>(0);
    const [touched, setTouched] = useState({
      cardNumber: false,
      cvv: false,
      month: false,
      year: false,
    });
    const [creditCardCompany, setCreditCardCompany] = useState<string>('unknown');
    const handleCreditChange = (change: any, type: string) => {
      setValueLength(change.length);

      if (type === 'cardNumber') {
        const creditBrand = change.brand as string;
        const creditLastFour = change.lastFour as string;

        setValue(
          'cardNumber',
          {
            creditBrand,
            creditLastFour,
          },
        );
        setCreditCardCompany(creditBrand);
      } else {
        setValue(type, change.valid);
      }
    };
    const getErrorMessage = (recurlyData: string) => {
      switch (recurlyData) {
        case 'cardNumber':
          return 'Please enter a valid credit card number';
        case 'cvv':
          return 'Please enter valid code';
        case 'month':
          return 'Please enter a valid date';
        case 'year':
          return 'Please enter a valid date';
        default:
          break;
      }
    };
    const handleBlur = (change?: any, recurlyData?: string) => {
      setTouched({
        ...touched,
        [recurlyData]: true,
      });
      setValidation(change.state.valid, recurlyData, getErrorMessage(recurlyData));
    };
    const renderCreditCard = (cardProvider: string) => {
      switch (cardProvider) {
        case 'unknown':
          return <BlankCard className={styles.creditCardIcon}/>;
        case 'master':
          return <MasterCard className={styles.creditCardIcon}/>;
        case 'visa':
          return <Visa className={styles.creditCardIcon}/>;
        case 'american_express':
          return <AmericanExpress className={styles.creditCardIcon}/>;
        default:
          return <BlankCard className={styles.creditCardIcon}/>;
      }
    };
    const generateStyles = (recurlyData: string, additionalStyle?: string) => {
      return classNames(
        className,
        styles.input,
        styles.cardInput,
        additionalStyle,
        (touched[recurlyData] || valueLength > 0) && !errors[recurlyData] && styles.successful,
        errors[recurlyData] && styles.error,
      );
    };
    const renderInput = (recurlyData: string) => {
      switch (recurlyData) {
        case 'cardNumber':
          return (
            <CardNumberElement
              onChange={(e) => handleCreditChange(e, recurlyData)}
              // @ts-ignore
              onBlur={(e: HTMLInputElement) => handleBlur(e, 'cardNumber')}
              className={generateStyles('cardNumber')}
              style={{
                fontSize: '18px',
                placeholder: { content: placeholder },
              }}
            />
          );
        case 'cvv':
          return (
            <CardCvvElement
              onChange={(e) => handleCreditChange(e, recurlyData)}
              // @ts-ignore
              onBlur={(e: HTMLInputElement) => handleBlur(e, 'cvv')}
              className={generateStyles('cvv', styles.cvvElement)}
              style={{
                fontSize: '18px',
                placeholder: { content: placeholder },
              }}
            />
          );
        default:
          return null;
      }
    };

    return (
      <>
        {dataRecurly === 'expirationDate' ? (
          <ExpirationDateInput
            errors={errors}
            touched={touched}
            handleCreditChange={handleCreditChange}
            onBlur={handleBlur}
          />
        ) : (
          renderInput(dataRecurly)
        )}
        {dataRecurly === 'cardNumber' && renderCreditCard(creditCardCompany)}
      </>
    );
  },
);
