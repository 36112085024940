import { createAction, createReducer } from 'redux-act';

export interface ThemeState {
    cssVariables: Record<string, any>;
}

export const setTheme = createAction<ThemeState>('set theme');

const reducer = createReducer<ThemeState>({}, { cssVariables: {} });

reducer.on(setTheme, (_state, payload: any = null) => payload);

export default reducer;
