import { createAction, createReducer } from 'redux-act';

export const setShowRecaptcha = createAction<boolean>('SET_SHOW_RECAPTCHA');
export const setRecaptchaToken = createAction<string | null>('SET_RECAPTCHA_TOKEN');
export const setRecaptchaAction = createAction<string | null>('SET_RECAPTCHA_ACTION');

export const showRecaptchaReducer = createReducer<boolean>({}, false);
export const captchaTokenReducer = createReducer<string | null>({}, null);
export const captchaActionReducer = createReducer<string | null>({}, null);

showRecaptchaReducer.on(setShowRecaptcha, (_state, payload: boolean) => payload);
captchaTokenReducer.on(setRecaptchaToken, (_state, payload: string = null) => payload);
captchaActionReducer.on(setRecaptchaAction, (_state, payload: string = null) => payload);
