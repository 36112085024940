import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { HttpError } from '../../../fetch';
import { sanitizeString } from '../../../utils';
import { EditIcon } from '../../atoms/Icons/EditIcon';
import { InfoIcon } from '../../atoms/Icons/InfoIcon';
import { EagleUser, User } from '../../models/User';
import { userService } from '../../services/UserService';
import { updateUser } from '../../store/ducks/user';
import { AppState } from '../../store/types';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './ProfileName.css';
import { updateEagleUser } from '../../services/EagleLoginService';

type ProfileNameProps = {
    user: User | EagleUser;
    isEagle: boolean;
    dispatch?: any;
};

class ProfileNameClass extends React.PureComponent<ProfileNameProps & WithTranslation> {
    state = {
        isEdit: false,
        value: this.props.user.name,
        error: null,
    };

    render() {
        const { isEdit, value, error } = this.state;

        return (
            <Container>
                <NameBlock>
                    {isEdit && (
                        <NameInput
                            data-element-description="profile name"
                            isEdit={isEdit}
                            value={value}
                            onChange={this.onChangeName}
                            onBlur={this.saveName}
                            onKeyUp={this.onNameKeyUp}
                        />
                    )}

                    {!isEdit && <NameText>{value}</NameText>}

                    <EditBtn data-element-description="profile name btn" onClick={this.setNameEditable}>
                        <Icon />
                    </EditBtn>

                    <Tooltip text={this.props.t('INFO_TOOLTIP.USER_NAME')}>
                        <InfoIcon />
                    </Tooltip>
                </NameBlock>

                {error && <ErrorMessage>{error}</ErrorMessage>}
            </Container>
        );
    }

    onChangeName = (e) => {
        const value = sanitizeString(e.target.value, true);

        this.setState({ value });
    };

    setNameEditable = () => {
        this.setState({ isEdit: true });
    };

    onNameKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.saveName();
        }
    };

    saveName = () => {
        if (!this.state.value) {
            return this.handleEmptyName();
        }

        this.setState({
            isEdit: false,
            error: null,
        });

        const successHandler = (user) => {
            this.props.dispatch(updateUser(user));
        };
        const errorHandler = (err) => {
            if (err instanceof HttpError && err.body && err.body.errorCode === 300) {
                this.handleEmptyName();
            } else {
                console.log(err);
            }
        };
        const eagleErrorHandler = (error) => {
            const isGeneralError = Number(error.message) === 1000;

            this.setState({
                error: this.props.t(`LOGIN.${error.message}${isGeneralError ? '_PROFILE_NAME' : ''}`),
                isEdit: false,
                value: this.props.user.name,
            });
        };
        const user = { ...this.props.user, name: this.state.value };

        if (this.props.isEagle) {
            updateEagleUser(user).then(successHandler).catch(eagleErrorHandler);
        } else {
            userService
                .update(user as User)
                .then(successHandler)
                .catch(errorHandler);
        }
    };

    handleEmptyName() {
        this.setState({
            isEdit: false,
            value: this.props.user.name,
            error: this.props.t('ERROR_CODE_300'),
        });
    }
}

const Container = (props: any) => <div {...props} />;
const NameBlock = (props: any) => <div className={styles.NameBlock} {...props} />;
const NameInput = ({ isEdit, value, ...props }) => (
    <input
        type="text"
        className={classNames(styles.NameInput, {
            [styles.edit]: isEdit,
        })}
        value={value}
        {...props}
    />
);
const EditBtn = (props: any) => <button className={styles.EditBtn} {...props} />;
const NameText = (props: any) => <span className={styles.NameText} {...props} />;
const Icon = (props: any) => <EditIcon className={styles.Icon} {...props} />;
const ErrorMessage = (props: any) => <div className={styles.ErrorMessage} {...props} />;
const ProfileState = connect((state: AppState) => ({
    user: state.user,
    isEagle: state.config.isEagle,
}))(ProfileNameClass);

export const ProfileName = withTranslation()(ProfileState);
