import { apiFetch } from '../../fetch';
import { hostnameToArenaDomain, isServer } from '../../utils';
import { TEarnedBadges } from '../atoms/Badges/Badges';
import { environment } from '../config/environment';
import { AchievementEvent, AchievementEventJSON } from '../models/AchievementEvent';
import { DefaultArenaDev } from '../models/Enums';
import { ExperienceResponse } from '../models/ExperienceDate';
import { UserLevelupInfo } from '../models/UserLevelupInfo';
import { EagleLoginService } from './EagleLoginService';

type AchievementEventResponse = {
    eventName: string;
    userId: string;
    value: string;
};
const API_PATH = environment.EAGLE_LEVELUP_API;

export enum AchievementType {
    RANK_ACHEIEVED = 'rank_acheieved',
    SCORE_ACHEIEVED = 'score_acheieved',
}

class EagleLevelupServiceBase {
    arenaDomain: any;
    constructor() {
        if (isServer) {
            return;
        }

        const hostname = hostnameToArenaDomain(window.location.hostname);

        this.arenaDomain = hostname === 'localhost' ? DefaultArenaDev.replace('https://', '') : hostname;
    }
    getOptionsWithAuth = async (options = {}) => {
        const headers = new Headers();
        const uToken = await EagleLoginService.getToken();

        headers.set('Authorization', `Bearer ${uToken}`);
        return { ...options, headers };
    };
    getAchievements = async (): Promise<AchievementEvent[]> => {
        const url = `${API_PATH}/user-event?arenaDomain=${this.arenaDomain}&takeCount=10`;

        return apiFetch(url, await this.getOptionsWithAuth()).then(
            (res: AchievementEventResponse[]): AchievementEvent[] => {
                return res.map((event) => {
                    const eventValue: AchievementEventJSON = JSON.parse(event.value);

                    return {
                        eventName: event.eventName,
                        score: Number(eventValue.score),
                        gameName: eventValue.gameName,
                        slug: eventValue.gameSlug,
                        userTodayRank: Number(eventValue.userTodayRank) || null,
                    };
                });
            }
        );
    };

    saveAchievement = async (event: AchievementEvent) => {
        const url = `${API_PATH}/user-event?arenaDomain=${this.arenaDomain}`;
        const body = JSON.stringify({
            eventName: event.eventName,
            value: JSON.stringify({
                score: event.score,
                gameName: event.gameName,
                gameSlug: event.slug,
                userTodayRank: event.userTodayRank,
            } as AchievementEventJSON),
        });

        return apiFetch(url, await this.getOptionsWithAuth({ method: 'POST', body }));
    };

    getRecentlyPlayed = async (count = 6) => {
        const url = `${API_PATH}/recently-played?arenaDomain=${this.arenaDomain}&takeCount=${count}`;

        return await apiFetch(url, await this.getOptionsWithAuth());
    };

    public saveRecentlyPlayed = async (slug: string) => {
        const url = `${API_PATH}/recently-played?arenaDomain=${this.arenaDomain}`;
        const body = JSON.stringify({ slug });

        return apiFetch(url, await this.getOptionsWithAuth({ method: 'POST', body }));
    };

    buyAvatar = async (avatar: string) => {
        const body = JSON.stringify({ avatarName: avatar });

        return apiFetch(
            `${API_PATH}/profile/buy-avatar?arenaDomain=${this.arenaDomain}`,
            await this.getOptionsWithAuth({
                method: 'POST',
                body,
            })
        );
    };

    getExperience = async (slug: string, value: number): Promise<ExperienceResponse> => {
        const body = JSON.stringify({ slug, value });
        const uToken = await this.getOptionsWithAuth({
            method: 'POST',
            body,
        });

        return apiFetch(`${API_PATH}/profile/reward?arenaDomain=${this.arenaDomain}`, uToken);
    };

    getLevelupInfo = async (): Promise<UserLevelupInfo> => {
        const uToken = await this.getOptionsWithAuth({
            method: 'GET',
        });

        return apiFetch(`${API_PATH}/profile/reward?arenaDomain=${this.arenaDomain}`, uToken);
    };

    saveBadges = async (body: TEarnedBadges[]): Promise<void> => {
        return apiFetch(
            `${API_PATH}/badges?arenaDomain=${this.arenaDomain}`,
            await this.getOptionsWithAuth({ method: 'POST', body })
        );
    };
}

export const EagleLevelupService = new EagleLevelupServiceBase();
