import { createAction, createReducer } from 'redux-act';
import { GamesWithEagleList } from '../../models/GameRendering';

export const setGamesWithEagleList = createAction<GamesWithEagleList>('set games with eagle list');

const reducer = createReducer<GamesWithEagleList>({}, {});

reducer.on(setGamesWithEagleList, (_state, payload: any = null) => payload);

export default reducer;
