import React, { ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import { setBodyOverflowHidden, setBodyOverflowAuto } from '../../../../utils';
import { CloseButton } from '../../../atoms/Modal/CloseButton';
import { DeviceDetector } from '../../../services/DeviceDetector';
import styles from './ChallengeCaptchaModal.css';

type TModalProps = {
  modalClassName?: string;
  onClose?: () => void;
  children: ReactNode;
  isVisible: boolean;
};

export const ChallengeCaptchaModal = ({
  onClose,
  modalClassName,
  children,
  isVisible,
}: TModalProps) => {
  const isDesktop = DeviceDetector.isDesktop();
  const closeModal = (event: React.MouseEvent<Element, MouseEvent>) => {
    const eventTarget = event.target as Element;
    const currentTarget = event.currentTarget;
    const isClickedOnOverlay = currentTarget === event.target;
    const isClickedOnModalWrapper = Array.from(eventTarget.classList).join('').includes('Modal__modalWrapper');

    if ((isClickedOnOverlay || isClickedOnModalWrapper)) {
      onClose?.();
    }
  };

  useEffect(() => {
    if (isVisible) {
      setBodyOverflowHidden();
    } else {
      setBodyOverflowAuto();
    }
  }, [isVisible]);

  return (
    <div
      className={classNames({
        [styles.overlay]: isVisible,
        [styles.mobile]: !isDesktop,
      })}
      onClick={closeModal}
      role="presentation"
    >
      <div
        className={classNames({
          [styles.mobile]: !isDesktop,
        })}
      >
        <div
          className={classNames(
            modalClassName,
            {
              [styles.modal]: isVisible,
              [styles.mobile]: !isDesktop,
            },
          )}
        >
          {isVisible && (
            <CloseButton
              className={styles.closeIcon}
              onClose={onClose}
              mobile={!isDesktop}
              closeIconColor="black"
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
