import classNames from 'classnames';
import React from 'react';
import { CloseIcon } from '../Icons/CloseIcon';
import styles from './Modal.css';
export const CloseButton = ({ onClose, mobile, className, closeIconColor }) => (
    <div
        className={classNames(styles.closeIcon, className, { [styles.mobile]: mobile })}
        onClick={onClose}
        role="button"
        tabIndex={-1}
        onKeyDown={(e) => {
            if (e.key === 'Enter') {
                onClose();
            }
        }}
        style={{ ...(closeIconColor && { color: closeIconColor }) }}
    >
        <CloseIcon />
    </div>
);
