import { CaptchaActionsEnum } from '@arkadium/eagle-payments-api-client';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOriginUrlForCaptchaIframe } from '../../../../utils';
import { environment } from '../../../config/environment';
import { setFullScreenLoading } from '../../../store/ducks/layout';
import {
  setRecaptchaToken,
  setRecaptchaAction,
  setShowRecaptcha,
} from '../../../store/ducks/recaptcha';
import { AppState } from '../../../store/types';

export enum RECAPTCHA_ACTIONS_CLIENT {
  // User flows, provided by Eagle directly
  SIGN_IN = 'SignIn',
  SIGN_UP = 'SignUp',
  PASSWORD_RESET = 'PasswordReset',
  CONFIRMATION_RESEND = 'ResendConfirmation',
}

export enum RECAPTCHA_MODES {
  CHALLENGE = 'challenge',
}

const RECAPTCHA_ACTIONS_PAYMENTS = CaptchaActionsEnum; // Payment flows, from "@arkadium/eagle-payments-api-client"

export const RECAPTCHA_ACTIONS = { ...RECAPTCHA_ACTIONS_CLIENT, ...RECAPTCHA_ACTIONS_PAYMENTS };
export type RECAPTCHA_ACTIONS_TYPE = (typeof RECAPTCHA_ACTIONS)[keyof typeof RECAPTCHA_ACTIONS];

const useCaptcha = () => {
  const dispatch = useDispatch();
  const iframeRef = useRef<HTMLIFrameElement>();
  const iframeURL = getOriginUrlForCaptchaIframe();
  const [isIframeCaptchaLoaded, setIsIframeCaptchaLoaded] = useState<boolean>(false);
  const showChallengeRecaptcha = useSelector(({ recaptcha }: AppState) => recaptcha.showChallengeRecaptcha);
  const captchaToken = useSelector(({ recaptcha }: AppState) => recaptcha.recaptchaToken);
  const captchaAction = useSelector((({ recaptcha }: AppState) => recaptcha.recaptchaAction));
  const postMessageToIframe = useCallback((data) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage({ ...data }, getOriginUrlForCaptchaIframe());
    }
  }, []);
  const getCaptchaToken = useCallback((action: RECAPTCHA_ACTIONS_TYPE) => {
    postMessageToIframe({
      type: 'GET_CAPTCHA_TOKEN',
      payload: {
        key: environment.RECAPTCHAAPPKEY,
        action,
      },
    });
  }, [postMessageToIframe]);
  const clearCaptchaData = useCallback(() => {
    dispatch(setRecaptchaToken(null));
    dispatch(setRecaptchaAction(null));
    dispatch(setShowRecaptcha(false));
    dispatch(setFullScreenLoading(false));
  }, [dispatch]);
  const onMessage = (message: MessageEvent) => {
    const event = message.data;

    switch (event.type) {
      case 'SEND_CAPTCHA_TOKEN':
        dispatch(setRecaptchaAction(event.payload.action));
        dispatch(setRecaptchaToken(event.payload.token));
        break;
      case 'CHALLENGE_CAPTCHA_TOKEN':
        dispatch(setRecaptchaToken(event.payload));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, [dispatch]);

  useEffect(() => {
    if (isIframeCaptchaLoaded) {
      if (!showChallengeRecaptcha) {
        postMessageToIframe({
          type: 'LOAD_CAPTCHA',
          payload: environment.RECAPTCHAAPPKEY,
        });
      } else {
        postMessageToIframe({
          type: 'LOAD_CHALLENGE_CAPTCHA',
          payload: {
            key: environment.CHALLENGE_CAPTCHA_KEY,
            action: captchaAction,
          },
        });
      }
    }
  }, [isIframeCaptchaLoaded, showChallengeRecaptcha]);

  return {
    iframeURL,
    iframeRef,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    captchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
  };
};

export default useCaptcha;
