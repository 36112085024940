import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isMobileMode } from '../../../utils';
import { ArrowIcon } from '../../atoms/Icons/ArrowIcon';
import { ContactUsIcon } from '../../atoms/Icons/ContactUsIcon';
import { FeatureIcon } from '../../atoms/Icons/FeatureIcon';
import { HelpIcon } from '../../atoms/Icons/HelpIcon';
import { InfoIcon } from '../../atoms/Icons/InfoIcon';
import { SettingsIcon } from '../../atoms/Icons/SettingsIcon';
import { WarningIcon } from '../../atoms/Icons/WarningIcon';
import { FreshDesk } from '../../molecules/Freshdesk/FreshDesk';
import { HelpSections } from '../../services/HelpContentService';
import { AppState } from '../../store/types';
import styles from './HelpSidebar.css';

const EL_PAIS = 'elpais';
const AS = 'as';

interface HelpSidebarProps {
    t;
    onSelect: (args: any) => void;
    activeItem: HelpSections;
    client: string;
}

const SidebarIcons = {
    [HelpSections.AD_BLOCK]: <WarningIcon />,
    [HelpSections.ABOUT]: <InfoIcon />,
    [HelpSections.FEATURES]: <FeatureIcon />,
    [HelpSections.FAQ]: <HelpIcon />,
    [HelpSections.SYS_REQUIREMENTS]: <SettingsIcon />,
    [HelpSections.CONTACT_US]: <ContactUsIcon />,
};
const Items = Object.keys(HelpSections).map((key) => HelpSections[key]);

class HelpSidebarBase extends React.PureComponent<HelpSidebarProps & WithTranslation> {
    state: {
        isMobile: boolean;
        isDropdownOpened: boolean;
    } = {
        isMobile: false,
        isDropdownOpened: false,
    };

    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        this.setState({ isMobile: isMobileMode() });
    };

    onClick(item) {
        if (this.state.isMobile) {
            if (this.props.activeItem !== item) {
                this.props.onSelect(item);
            }

            this.setState({ isDropdownOpened: !this.state.isDropdownOpened });
        } else {
            this.props.onSelect(item);
        }
    }

    render() {
        const { t, activeItem, client } = this.props;
        const { isMobile, isDropdownOpened } = this.state;
        const supportUrl = this.getSupportUrl(client);
        const SupportWrapper = client === EL_PAIS || client === AS ? ElPaisSupportLink : FreshDesk;
        let items = Items.slice().filter((item) => item !== HelpSections.CONTACT_US);

        if (isMobile) {
            // Show active item on mobile first
            items = items.sort((a, b) => (a === activeItem ? -1 : 1));
        }

        return (
            <Sidebar>
                <List>
                    {items.map((item) => (
                        <Item
                            key={item}
                            onClick={this.onClick.bind(this, item)}
                            isActive={item === activeItem}
                            isHidden={isMobile && !isDropdownOpened && item !== activeItem}
                        >
                            <ItemIcon>{SidebarIcons[item]}</ItemIcon>

                            <ItemText>{t(`HELP_MENU.${item.toUpperCase()}`)}</ItemText>

                            <ItemIcon>
                                <ArrowIcon className={styles.arrowRotated} />
                            </ItemIcon>
                        </Item>
                    ))}
                    {
                        <SupportWrapper className={styles.item} supportUrl={supportUrl}>
                            <ItemIcon>{SidebarIcons[HelpSections.CONTACT_US]}</ItemIcon>

                            <ItemText>{t('CONTACT_US_BTN')}</ItemText>
                        </SupportWrapper>
                    }
                </List>
            </Sidebar>
        );
    }

    private getSupportUrl(client: string) {
        if (client === EL_PAIS || client === AS) {
            return client === AS
                ? 'https://as.com/formularios/contacto.pl'
                : 'https://elpais.com/estaticos/contacte/envio/contactoC.pl';
        }

        return '';
    }
}

const Sidebar = (props) => <aside className={styles.sidebar} {...props} />;
const List = (props) => <ul className={styles.list} {...props} />;
const Item = ({ isActive, isHidden, ...props }: any) => (
    <li
        className={classNames(styles.item, {
            [styles.isActive]: isActive,
            [styles.isHidden]: isHidden,
        })}
        {...props}
    />
);
const ItemIcon = (props) => <div className={styles.itemIcon} {...props} />;
const ItemText = (props) => <div className={styles.itemText} {...props} />;
// TODO Refactor Custom clients support
const ElPaisSupportLink = ({ ...props }) => {
    return (
        <a rel="noreferrer nofollow" target="_blank" href={props.supportUrl} {...props}>
            {props.children}
        </a>
    );
};
const HelpSidebarTranslated = withTranslation()(HelpSidebarBase);

export const HelpSidebar = connect((state: AppState) => ({
    client: state.config.theme.client,
}))(HelpSidebarTranslated);
