import classNames from 'classnames';
import React from 'react';
import Refresh from '../../../public/svg/PlayagainIcon.svg';
import styles from './Icons.css';

export const RefreshIcon = React.memo((props: any) => (
    <Refresh
        className={classNames(styles.refreshIcon, props.className, { [styles.isMirrored]: props.isMirrored })}
        aria-hidden="true"
    />
));
