import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary';
import { ArenaConfig } from '../models/ArenaConfig';
import { Game } from '../models/Game';
import { HOME_CATEGORY_MAX_GAMES } from '../organisms/HomeCategory/HomeCategory';
import { LDJsonTemplateService } from '../services/LDJsonTemplateService';
import { PwaService } from '../services/PwaService';
import { SEOService } from '../services/SEOService';
import { UrlService } from '../services/UrlService';
import { gamesByLangSelector } from '../store/ducks/games';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { HomeTemplate } from '../templates/Home/HomeTemplate';
import { generateSeoHreflangLinksList } from '../utils/generateSeoHreflangLinksList';
import {
    EventCategoryService,
    generateEventCustomClass,
    generateEventCustomStyles,
    IEvent,
} from '../services/EventCategoryService';
import { EventCat } from '../atoms/EventCategory/Eventcat';

type HomePageProps = {
    absoluteBaseHref: string;
    games: Game[];
    clientName: string;
    config: ArenaConfig;
    lang: string;
    url: string;
    t: any;
    arenaLang: string;
    eventCustomStyles?: string;
    eventsCustomClass?: string;
    sharingImage?: string;
};

export type GamesByCategory = {
    category: string;
    url: string;
    games: Game[];
    eventCat?: IEvent;
};

type HomePageState = {
    gamesByCategories: GamesByCategory[];
    usedGameList: Game[];
};

export const HomePageOtherCategory = 'other';

class HomePageBase extends React.PureComponent<HomePageProps & WithTranslation, HomePageState> {
    constructor(props: (HomePageProps & WithTranslation)) {
        super(props);
        const {
            config,
            games,
            lang,
            t,
        } = props;
        let allCategories: string[] = [];

        // TODO: made values unique - need to find real reason
        allCategories = [...new Set(config.categories.allCategories as string[])];
        const homeCategories = [...new Set(config.categories.homeCategories.map((c) => c.name))];
        const homeCategoriesFull = [...new Set(config.categories.homeCategories)];
        const gamesByCategories = this.buildCategoryGameList(
            allCategories,
            homeCategories,
            games,
            homeCategoriesFull,
        );
        const usedGameList = this.buildUsedGameList(gamesByCategories);

        this.state =
            {
                gamesByCategories,
                usedGameList,
            };

        const description = t('HOME_BOTTOM_DESCRIPTION_TEXT', { name: config.theme.name });

        PwaService.createManifest(lang, {
            name: t('GAMES'),
            description,
            iconName: 'arena',
        });
    }

    getMeta(canonical: string) {
        const {
            clientName,
            t,
            absoluteBaseHref,
            sharingImage,
        } = this.props;
        const metaDescription = t('HOME_META_DESCRIPTION', { clientName });

        return SEOService.buildMeta(metaDescription, canonical, clientName, absoluteBaseHref, sharingImage);
    }

    render() {
        const {
            absoluteBaseHref,
            config,
            clientName,
            games,
            lang,
            t,
        } = this.props;
        const metaTitle = t('HOME_META_TITLE', { clientName });
        const canonical = absoluteBaseHref;
        const LDJsonData = LDJsonTemplateService.buildGameListTemplate(this.state.usedGameList, absoluteBaseHref, lang);
        const seoHreflangLinks = generateSeoHreflangLinksList(
            absoluteBaseHref,
            this.props.arenaLang,
            [],
        );

        return (
            <ErrorBoundary>
                {
                    <ConnectedHelmet
                        title={metaTitle}
                        meta={this.getMeta(canonical)}
                        link={[
                            {
                                rel: 'canonical',
                                href: canonical,
                            },
                            ...seoHreflangLinks,
                        ]}
                    >
                        <script async type="application/ld+json">
                            {JSON.stringify(LDJsonData)}
                        </script>
                    </ConnectedHelmet>
                }

                <EventCat>
                    {(eventData) => (
                        <HomeTemplate
                            config={config}
                            games={games}
                            gamesByCategories={this.state.gamesByCategories}
                            isEventCategoryOn={eventData}
                            eventCustomStyles={this.props.eventCustomStyles}
                            eventsCustomClass={this.props.eventsCustomClass}
                        />
                    )}
                </EventCat>
            </ErrorBoundary>
        );
    }

    private buildUsedGameList(gamesByCategories: GamesByCategory[]) {
        const usedGameSlugs = [];

        return gamesByCategories.reduce((acc, el) => {
            const games = el.games.slice(0, HOME_CATEGORY_MAX_GAMES);
            const unusedGames = games.filter((game) => usedGameSlugs.indexOf(game.slug) === -1);

            usedGameSlugs.push(...unusedGames.map((g) => g.slug));

            return acc.concat(unusedGames);
        }, []);
    }

    private buildCategoryGameList(
        categories: string[],
        homeCategories: any,
        games: Game[],
        homeCategoriesFull: any,
    ): GamesByCategory[] {
        const {
            t,
            lang,
        } = this.props;
        const restCategories = categories.filter(
            (category) => homeCategories.map((c) => c.name).indexOf(category) === -1,
        );
        const usedGameSlugs = [];
        // Build Categories
        const list = homeCategoriesFull.map((categoryFull) => {
            const categoryTranslated = EventCategoryService.categoryTranslated(
                categoryFull,
                t(categoryFull.name.toUpperCase()),
                lang,
            );
            const matchingGames = EventCategoryService.mixinHomeCategories(
                [...games].filter((game) => game.hasCategory(categoryTranslated)).slice(0, HOME_CATEGORY_MAX_GAMES),
                categoryFull,
            );

            matchingGames.forEach((game) => usedGameSlugs.push(game.slug));

            return {
                category: categoryTranslated,
                url: `/${t('ROUTES.CATEGORY')}/${UrlService.serializeCategoryUrl(categoryTranslated)}`,
                games: matchingGames,
                eventCat: categoryFull?.eventCat,
                eventCatNamesList: EventCategoryService.getEventCatNamesList(homeCategoriesFull),
            };
        });
        // Build Other section
        const otherGames = games
            .filter((game) => usedGameSlugs.indexOf(game.slug) === -1)
            .filter((game) => restCategories.find((category) => game.hasCategory(category)))
            .slice(0, HOME_CATEGORY_MAX_GAMES);

        list.push({
            category: HomePageOtherCategory,
            url: `/${t('ROUTES.ALL_GAMES')}`,
            games: otherGames,
        });

        return list.filter((item) => !!item.games.length);
    }
}

const TranslatedHomePage = withTranslation()(HomePageBase);

export const HomePage = connect((state: AppState, props: RouteComponentProps<any>) => ({
    absoluteBaseHref: absoluteBaseHrefSelector(state),
    games: gamesByLangSelector(state),
    clientName: state.config.theme.name,
    config: state.config,
    lang: state.currentLang,
    url: props.location.pathname,
    arenaLang: state.config.theme.locale,
    eventCustomStyles: generateEventCustomStyles(state),
    eventsCustomClass: generateEventCustomClass(state),
    sharingImage: state.config.theme.sharingImage,
}))(TranslatedHomePage);
