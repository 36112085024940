import React from 'react';
import { formatNumbers, getPercentFromNumbers } from '../../../utils';
import styles from './GameEndProgressBar.css';

type GameEndProgressBarProps = {
    levelXPThresholdCurrent: number;
    xpCurrent: number;
    xpPrev: number;
    xpGained: number;
    achievedNewLevel: boolean;
};

export class GameEndProgressBar extends React.PureComponent<GameEndProgressBarProps> {
    state = {
        widthCurrent: 0,
        widthGained: 0,
    };

    componentDidMount() {
        const { levelXPThresholdCurrent, xpCurrent, xpPrev, xpGained, achievedNewLevel } = this.props;

        setTimeout(() => {
            if (achievedNewLevel) {
                this.setState({
                    widthCurrent: getPercentFromNumbers(xpCurrent, levelXPThresholdCurrent),
                });
            } else {
                if (xpPrev) {
                    this.setState({
                        widthCurrent: getPercentFromNumbers(xpPrev, levelXPThresholdCurrent),
                    });
                }

                if (xpGained) {
                    setTimeout(() => {
                        this.setState({
                            widthGained: getPercentFromNumbers(xpGained, levelXPThresholdCurrent),
                        });
                    }, 1000);
                }
            }
        }, 500);
    }

    render() {
        const { xpGained } = this.props;

        return (
            <ProgressBar data-element-description="xp progressbar">
                <ProgressCurrent
                    data-element-description="xp progressbar current"
                    style={{ width: this.state.widthCurrent + '%' }}
                />
                <ProgressGained
                    data-element-description="xp progressbar gained"
                    style={{ width: this.state.widthGained + '%' }}
                >
                    {this.state.widthGained !== 0 && (
                        <ProgressTooltip data-element-description="xp progressbar tooltip">
                            {`+${formatNumbers(xpGained)}`}
                        </ProgressTooltip>
                    )}
                </ProgressGained>
            </ProgressBar>
        );
    }
}

const ProgressBar = (props: any) => <div className={styles.progressBar} {...props} />;
const ProgressCurrent = (props: any) => <div className={styles.progressCurrent} {...props} />;
const ProgressGained = (props: any) => <div className={styles.progressGained} {...props} />;
const ProgressTooltip = (props: any) => <div className={styles.progressTooltip} {...props} />;
