export interface IZoomCheckResult {
    isZoomUsed: boolean;
    presetZoomPercentValue: number;
    pinchZoomPercentValue: number;
    totalZoomPercentValue: number;
}

export type CalculateZoom = () => IZoomCheckResult;
type CheckZoomCallback = (calculateZoom: CalculateZoom) => void;
type CheckZoomCallbacks = CheckZoomCallback[];

type firefoxTouches = {
    twoTouchPoints: boolean;
    touchPointsDistanceStart: number;
    touchPointsDistanceEnd: number;
    debounceTimeoutStore: any;
    pinchScaleValue: number;
};

export const ZoomCheckHelper = (): CalculateZoom => {
    // pass callbacks here if needed (to init and mount ZoomCheckHelper once in AppsInsights.ts analytics)
    return ZoomCheckHelperInit();
};

function ZoomCheckHelperInit(...callbacks: CheckZoomCallbacks): CalculateZoom {
    // declaring the variables
    const defaultResult: IZoomCheckResult = {
        isZoomUsed: false,
        presetZoomPercentValue: 100,
        pinchZoomPercentValue: 100,
        totalZoomPercentValue: 100,
    };

    try {
        const isTesting: boolean =
            !!window.location.hostname.match(/^localhost/gi) ||
            !!window.location.hostname.match(/^arena51-dev/gi) ||
            !!window.location.hostname.match(/^arena51-staging/gi);
        const platform: string = window.navigator.platform;
        const isMac: boolean = platform && !!(platform.toString().match(/^Mac/gi) || platform.toString().match(/^i/gi));
        const isRetinaDisplay = (): boolean => {
            const maybeDefaultPixelRatio = window.devicePixelRatio || 1;

            return isMac && maybeDefaultPixelRatio >= 2;
        };
        const defaultPixelRatio: number = isRetinaDisplay() ? 2 : 1;
        // prepare data for firefox case of pinch zoom
        const isFirefox: boolean = !window.VisualViewport && !!window.AudioContext; // feature detection + IE excluded
        // for desktop safari case of preset zoom
        const isSafariDesktop: boolean = !window.Touch && !!window.AudioContext; // feature detection + IE excluded
        // presetZoom (by ctrl+'+', browser settings zoom, WinOS settings zoom) check function
        const checkPresetZoom = (): number => {
            const currentPixelRatio: number = window.devicePixelRatio;
            let presetZoomValue: number = 1;

            try {
                switch (true) {
                    case isSafariDesktop:
                        presetZoomValue = window.outerWidth / window.innerWidth;
                        break;
                    case currentPixelRatio === defaultPixelRatio:
                        break;
                    default:
                        presetZoomValue = currentPixelRatio / defaultPixelRatio;
                        break;
                }
            } catch (e) {
                console.error(`ZoomCheckHelper error @ checkPresetZoom: `, e);
            }

            return presetZoomValue;
        };
        const firefoxTouchData: firefoxTouches = {
            twoTouchPoints: false,
            touchPointsDistanceStart: 0, // not real value to start with (for checks)
            touchPointsDistanceEnd: 0, // not real value to start with (for checks)
            debounceTimeoutStore: null,
            pinchScaleValue: 1,
        };

        if (isFirefox) {
            window.addEventListener('touchstart', handleFirefoxTouchDown);
            window.addEventListener('touchmove', handleFirefoxTouchMoveDebounced);
            window.addEventListener('touchend', handleFirefoxTouchUp);

            function getTouchesDistance(e): number {
                return Math.hypot(e.touches[0].pageX - e.touches[1].pageX, e.touches[0].pageY - e.touches[1].pageY);
            }

            function handleFirefoxTouchDown(ev): void {
                if (ev.touches.length === 2) {
                    firefoxTouchData.twoTouchPoints = true;
                }

                firefoxTouchData.touchPointsDistanceStart = Math.hypot(
                    ev.touches[0].pageX - ev.touches[1].pageX,
                    ev.touches[0].pageY - ev.touches[1].pageY
                );
            }

            function handleFirefoxTouchMoveDebounced(): void {
                if (firefoxTouchData.debounceTimeoutStore) {
                    clearTimeout(firefoxTouchData.debounceTimeoutStore);
                }

                setTimeout((ev) => handleFirefoxTouchMove(ev), 500);
            }

            function handleFirefoxTouchMove(ev): void {
                if (firefoxTouchData.twoTouchPoints) {
                    firefoxTouchData.touchPointsDistanceEnd = getTouchesDistance(ev);
                }
            }

            function handleFirefoxTouchUp(ev): void {
                const minScale = 1;
                const maxScale = 5;
                const distanceDelta: number =
                    firefoxTouchData.touchPointsDistanceEnd - firefoxTouchData.touchPointsDistanceStart;
                const isScaleGesture: boolean = distanceDelta !== 0;

                firefoxTouchData.twoTouchPoints = false;

                if (!isScaleGesture) {
                    return;
                }

                const scaleValue: number =
                    firefoxTouchData.pinchScaleValue *
                    (firefoxTouchData.touchPointsDistanceEnd / firefoxTouchData.touchPointsDistanceStart);

                firefoxTouchData.pinchScaleValue =
                    scaleValue > maxScale ? maxScale : scaleValue < minScale ? minScale : scaleValue;
            }
        }

        // pinchZoom (mobile / MacOS touch scaling) check function
        const checkPinchZoom = (): number => {
            let pinchZoomValue: number = 1;

            try {
                // zoom by pinch gesture, used on MacOS or mobiles
                const scale: number =
                    isFirefox || !window.visualViewport?.scale
                        ? firefoxTouchData.pinchScaleValue
                        : window.visualViewport.scale;
                const noScale: boolean = scale === 1;

                switch (true) {
                    case noScale:
                        break;
                    default:
                        pinchZoomValue = scale;
                        break;
                }
            } catch (e) {
                console.error(`ZoomCheckHelper error @ checkPinchZoom: `, e);
            }

            return pinchZoomValue;
        };
        // counting the zooms finally
        const calculateZoom: CalculateZoom = () => {
            const presetZoomVal: number = checkPresetZoom();
            const pinchZoomVal: number = checkPinchZoom();
            const presetZoomPercentValue: number = Math.floor(presetZoomVal * 100);
            const pinchZoomPercentValue: number = Math.floor(pinchZoomVal * 100);
            const totalZoomPercentValue: number = !isSafariDesktop
                ? Math.floor(presetZoomVal * pinchZoomVal * 100)
                : Math.floor([presetZoomVal, pinchZoomVal].sort().reverse()[0] * 100);
            const isZoomUsed: boolean = totalZoomPercentValue !== 100;
            const zoomData: IZoomCheckResult = Object.assign({}, defaultResult, {
                isZoomUsed,
                presetZoomPercentValue,
                pinchZoomPercentValue,
                totalZoomPercentValue,
            });

            return zoomData;
        };
        // passing result zoomData to callbacks
        const callbacksList = Array.from(callbacks);

        try {
            callbacksList.forEach((callback: CheckZoomCallback) => {
                callback(calculateZoom);
            });
        } catch (e) {
            if (isTesting) {
                console.error(
                    `
                ZoomCheckHelper, passing to callbacks error!,
                \nCallbacks list: ${callbacksList};
                \nError:
            `,
                    e
                );
            }
        }

        return calculateZoom;
    } catch (e) {
        console.error(`ZoomCheckHelper error (default zoom-rate passed): `, e);
        return () => defaultResult;
    }
}

function zoomTestingInit(calculateZoom): void {
    const handleClick = (e): void => {
        e.preventDefault();
        e.stopPropagation();
        const zoomData = calculateZoom();

        alert(`
            ZOOM-CHECK INFO:
            \n•zoomUsed: ${zoomData.isZoomUsed};
            \n•presetZoomPercentValue: ${zoomData.presetZoomPercentValue}%;
            \n•pinchZoomPercentValue: ${zoomData.pinchZoomPercentValue}%;
            \n•totalZoomPercentValue: ${zoomData.totalZoomPercentValue}%;
        `);
    };
    const handleZDown = (e): void => {
        if (e.code !== 'KeyZ' || e.repeat) {
            return;
        }

        window.addEventListener('click', handleClick, true);
    };
    const handleZUp = (e): void => {
        if (e.code !== 'KeyZ' || e.repeat) {
            return;
        }

        window.removeEventListener('click', handleClick, true);
    };

    window.addEventListener('keydown', handleZDown);
    window.addEventListener('keyup', handleZUp);
    console.info('ZoomCheckHelper testing inited, please press [Z+Click] to check zoom.');
}
