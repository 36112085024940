import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary';
import { HelpSections } from '../services/HelpContentService';
import { SEOService } from '../services/SEOService';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { HelpTemplate } from '../templates/Help/Help';
import { generateSeoHreflangLinksList } from '../utils/generateSeoHreflangLinksList';

type CategoryPageProps = {
    absoluteBaseHref: string;
    adBlockByGame: string;
    clientName: string;
    currentLang: string;
    dispatch?: any;
    t: any;
    arenaLang: string;
    sharingImage?: string,
};

class HelpPageBase extends React.PureComponent<CategoryPageProps & WithTranslation> {
    getMeta(canonical) {
        const { t, clientName, absoluteBaseHref, sharingImage } = this.props;
        const metaDescription = t('HELP_META_DESCRIPTION', { clientName });

        return SEOService.buildMeta(metaDescription, canonical, clientName, absoluteBaseHref, sharingImage);
    }

    render() {
        const { absoluteBaseHref, adBlockByGame, currentLang, clientName, t } = this.props;
        const metaTitle = t('HELP_META_TITLE', { clientName });
        const subpath = ['ROUTES.HELP'];
        const canonical = `${absoluteBaseHref}/${t(subpath[0])}`;
        // tslint:disable-next-line:max-line-length
        const seoHreflangLinks = generateSeoHreflangLinksList(absoluteBaseHref, this.props.arenaLang, subpath);

        return (
            <ErrorBoundary>
                {(
                    <ConnectedHelmet
                        title={metaTitle}
                        meta={this.getMeta(canonical)}
                        link={[
                            { rel: 'canonical', href: canonical },
                            ...seoHreflangLinks]}
                    />
                )}

                <HelpTemplate currentLang={currentLang} adBlockByGame={adBlockByGame} />
            </ErrorBoundary>
        );
    }
}

const HelpPageTranslated = withTranslation()(HelpPageBase);

export const HelpPage = connect((state: AppState, props: RouteComponentProps<any>) => ({
    absoluteBaseHref: absoluteBaseHrefSelector(state),
    adBlockByGame: adBlockByGameSelector(props.location.search),
    clientName: state.config.theme.name,
    currentLang: state.currentLang,
    arenaLang: state.config.theme.locale,
    sharingImage: state.config.theme.sharingImage,
}))(HelpPageTranslated);

function adBlockByGameSelector(search: string): string {
    const params = new URLSearchParams(search);

    if (params.get('section') === HelpSections.AD_BLOCK && params.get('game')) {
        return params.get('game');
    }
}
