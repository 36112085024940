// STORAGE
import { BadgesDecodeValue, BadgesEncodeValue, BadgesStorageInterface } from '../../../utils/UtilsStorage';
import { BADGE_STORAGES_PROPS, BADGES_STORE_PROPS } from './Badges';

export const BadgesStoredShown = (value?: number): any => ( // SESSION STORAGE!!!
    value !== undefined
        ? window.sessionStorage.setItem(BADGES_STORE_PROPS.BADGE_SHOWN, BadgesEncodeValue(value))
        : BadgesDecodeValue(window.sessionStorage.getItem(BADGES_STORE_PROPS.BADGE_SHOWN))
);

export const BadgesStoredLogin = (value?: number): any => BadgesStorageInterface(
    BADGES_STORE_PROPS.BADGE_LOGIN,
    value
);

export const BadgesStoredPlays = (value?: number): any => BadgesStorageInterface(
    BADGES_STORE_PROPS.BADGE_PLAYS,
    value
);

export const formatDate = (date) => [date.getDate(), date.getMonth(), date.getFullYear()];

export const todayDate = () => formatDate(new Date());

export const BadgesLastVisit = (formatedDate?: number[]): any => {
    const propName = BADGES_STORE_PROPS.BADGE_LASTVISIT;
    const propValue = window.localStorage.getItem(propName);

    return (formatedDate
            ? window.localStorage.setItem(propName, JSON.stringify(formatedDate))
            : window.localStorage.getItem(propName)
                ? JSON.parse(propValue)
                : null
    );
};

export const isNextDay = () => {
    const today = todayDate();
    const saved = BadgesLastVisit();
    const result = (
        !saved
            ? null
            : (
                (
                    today[2] === saved[2] // same year
                    && today[1] === saved[1] // same month
                    && today[0] === saved[0] + 1 // next day
                ) || ( // new month
                    today[2] === saved[2] // same year
                    && today[1] === saved[1] + 1 // next month
                    && today[0] === 1
                    && (
                        ((saved[0] === 29 || saved[0] === 28) && saved[1] === 1) // february
                        || (saved[0] === 30 && [3, 5, 8, 10].includes(saved[1])) // 30-day months
                        || (saved[0] === 31 && [0, 2, 4, 6, 7, 9, 11].includes(saved[1])) // 31-day months
                    )
                ) || ( // new year
                    today[2] === saved[2] + 1 // next year
                    && saved[0] === 31 && saved[1] === 11 // saved is 31st of december
                    && today[0] === 1 && today[1] === 0 // today is the 1st of january
                )
            )
    );

    // consoleLog('AB_BADGES: isNextDay => ', saved, today, result);

    return result;
};

export const BadgesStoredVisits = (): any => {
    if (Boolean(BadgesStorageInterface(BADGES_STORE_PROPS.BADGE_VISITS))) {
        return true;
    }

    if (!isNextDay()) { // not saved or not next day
        BadgesLastVisit(todayDate()); // new last visit record
        return false;
    } else {
        BadgesStorageInterface(BADGES_STORE_PROPS.BADGE_VISITS, 1); // save visiting 2 days in a row is done
        return true; // already use saved
    }
};

export function BadgesStoragesClear() {
    BADGE_STORAGES_PROPS.localStorage.forEach(key => (window as any).localStorage.removeItem(key));
    // BADGE_STORAGES_PROPS.sessionStorage.forEach(key => (window as any).sessionStorage.removeItem(key));
}
