import {
  PurchasableItemPurchaseItemRequestDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { GEMS_TEST_SKU, skipPrerollItem, skipPrerollVirtualItem } from '../../constants/GemsConstants';
import { GameState } from '../../models/Enums';
import { Game } from '../../models/Game';
import { AppInsightService } from '../../services/AppInsight';
import { EagleLoginService } from '../../services/EagleLoginService';
import GemsService from '../../services/GemsService';
import { setGameState } from '../../store/ducks/gameState';
import { setGemsAmount } from '../../store/ducks/gems';
import { ELoginSteps, setLoginModal, setShopOpen } from '../../store/ducks/modal';
import { AppState } from '../../store/types';
import { Analytics } from '../../services/Analytics/Analytics';
import { AppInsightsAnalytics } from '../../services/Analytics/AppInsights';

export async function skipAdsButtonHandler(
    dispatch: ThunkDispatch<AppState, void, Action>,
    game: Game,
    getActualProps,
    loadingPurchaseSetter
) {
    const { gems, prerollSkipPrice, isPrerollSkipPriceLoading, isGemsAmountLoading } = getActualProps;

    AppInsightsAnalytics.trackAnalyticsEvent(await Analytics.gems.gemSkipAdButton(prerollSkipPrice));

    if (!EagleLoginService.isUserLoggedIn()) {
        dispatch(setLoginModal({ isOpen: true, step: ELoginSteps.LOGIN, fromSkipAdButton: true }));
    } else if (!isGemsAmountLoading && !isPrerollSkipPriceLoading && prerollSkipPrice && gems >= prerollSkipPrice) {
        const item: [PurchasableItemPurchaseItemRequestDto[], string, number, string] = [
            [skipPrerollItem],
            GEMS_TEST_SKU,
            prerollSkipPrice,
            game.slug,
        ];

        // set game state assuming user have enough gems
        loadingPurchaseSetter(true);
        GemsService.purchaseItem(...item)
            .then(() => {
                loadingPurchaseSetter(false);
                dispatch(setGameState(GameState.GAME));
                GemsService.getUserGemsAmount().then((gems) => {
                    dispatch(setGemsAmount(gems));
                });
                GemsService.consumeUserInventory(skipPrerollVirtualItem)
                    .then(async () => {
                        AppInsightsAnalytics.trackAnalyticsEvent(
                            await Analytics.gems.gemSpendingSkipAdSuccess(prerollSkipPrice)
                        );
                    })
                    .catch((err) => {
                        // allow user to play the game even if purchase was not successfull
                        console.error(err);
                        AppInsightService.trackAppError(err, { data: 'consumeUserInventory()' });
                    });
            })
            .catch((err) => {
                // unsuccessfull purchase request - allow user to play the game even if purchase was not successfull
                loadingPurchaseSetter(false);
                console.error(err);
                AppInsightService.trackAppError(err, { data: 'purchaseItem()' });
            });
    } else {
        if (!isPrerollSkipPriceLoading && !isGemsAmountLoading) {
            dispatch(setShopOpen(true));
        }
    }
}
