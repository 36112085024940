import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import { RECAPTCHA_ACTIONS } from '../../../molecules/ChallengeCaptcha/hooks/useCaptcha';
import { Analytics } from '../../../services/Analytics/Analytics';
import { setFullScreenLoading } from '../../../store/ducks/layout';
import { setShopOpen } from '../../../store/ducks/modal';
import GemsService from '../../../services/GemsService';
import { setGemsAmount } from '../../../store/ducks/gems';
import { AppInsightsAnalytics } from '../../../services/Analytics/AppInsights';
import styles from '../PurchasePageTemplate.css';

export const ConfirmationStep = ({
  paymentType,
  gemsAnalyticsProps,
  getCaptchaToken,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(({ user }) => user);
  const isUserExists = useSelector((state) => state.isUserExists);
  const authValues = useSelector((state) => state.authValues);
  const isGemsPurchase = paymentType === PaymentType.gems;
  const handleSubmit = () => {
    if (!isUserExists) {
      dispatch(setFullScreenLoading(true));
      getCaptchaToken(RECAPTCHA_ACTIONS.CONFIRMATION_RESEND);
    }

    if (isUserExists) {
      history.goBack();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setShopOpen(false));
    GemsService.getUserGemsAmount().then(async (gems) => {
      dispatch(setGemsAmount(gems));
      AppInsightsAnalytics.trackAnalyticsEvent(
        await Analytics.gems.gemPurchaseSuccessImpression(
          gemsAnalyticsProps.priceInGem,
          gemsAnalyticsProps.gemsPackId,
          gemsAnalyticsProps.gemsInPack,
        ),
      );
    });
  }, []);

  return (
    <div className={styles.step3wrapper}>
      <div className={classNames(styles.mainStepHeading, styles.textCenter)}>
        {isGemsPurchase && <I18nText keyName={'PURCHASE_PAGE.THANK_YOU_TEXT'}/>}
      </div>
      <div className={classNames(styles.animationBlock, { [styles.__gems]: isGemsPurchase })}/>
      {isUserExists && (
        <div className={classNames(styles.noAdsBlock, styles.textCenter)}>
          <p>
            <I18nText keyName={'PURCHASE_PAGE.LOGGED_IN_USER_TOP_CONTENT'}/>
            &nbsp;
            <strong>{userData ? userData?.email : authValues.email}</strong>.
          </p>
        </div>
      )}
      <div className={classNames(styles.profileBlock, styles.textCenter)}>
        <p>
          <I18nText keyName={'PURCHASE_PAGE.LOGGED_IN_USER_BOTTOM_CONTENT'}/>
        </p>
      </div>
      <div className={styles.mainBtnWrapper}>
        <Button type="button" onClick={handleSubmit} className={styles.continueBtn}>
          {'Continue playing'}
        </Button>
      </div>
    </div>
  );
};
