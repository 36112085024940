import classnames from 'classnames';
import React from 'react';
import styles from './RegisterButton.css';

type TProps = {
    onClick: () => void;
    className?: string;
    text: string;
    isEagle?: boolean;
};
const RegisterButton = React.memo(({ onClick, className, text }: TProps) => {
    return (
        <button onClick={onClick} className={classnames(styles.registerButton, className)}>
            {text}
        </button>
    );
});

RegisterButton.displayName = 'RegisterButton';

export { RegisterButton };
