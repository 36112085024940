import React from 'react';

type iconProps = {
    className?: string;
    color?: string;
};

export const ArrowRightIcon = React.memo((props: iconProps) => {
    const { className, color } = props;

    return (
        <svg
            role="img"
            aria-label="arrow-right-icon"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path
                                d="M0 0H34V34H0z"
                                transform="translate(-665 -31) translate(612 20) translate(53 11)"
                            />
                            <path
                                stroke={color || 'currentColor'}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 25L21 16.917 13 9"
                                transform="translate(-665 -31) translate(612 20) translate(53 11)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
});
