import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WebGameIcon } from '../../atoms/Icons/WebGameIcon';
import { Link as RouterLink } from '../../atoms/Link/Link';
import { ImageTypes, ProgressiveImage } from '../../atoms/ProgressiveImage/ProgressiveImage';
import { THEMES } from '../../consts';
import { Game } from '../../models/Game';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './GameTile.css';
import { UrlService } from '../../services/UrlService';

type GameTileProps = {
    ftuxCategory?: string;
    game: Game;
    onTileClick?: (slug: string) => void;
    isTextDisabled?: boolean;
    themeName?: string;
    useExternalImages?: boolean;
    passedClassName?: string;
};

const GameTileBase = React.memo((props: GameTileProps & WithTranslation) => {
    const { assetOriginUrl, thumbOriginUrl, thumbPath,
        thumbPathWebP, secondaryThumbPath, secondaryThumbPathWebP } = props.game;
    const { themeName, useExternalImages } = props;

    if (!props.game.tags) {
        return null;
    }

    const TileTextRef = useRef<HTMLDivElement>();

    // decrease font-size if title is more than 3 lines
    useEffect(() => {
        if (!isDigitalTurbine) {
            return;
        }

        (document as any).fonts.ready.then(() => {
            const lineHeight = parseInt(getComputedStyle(TileTextRef.current).lineHeight);
            const height = TileTextRef.current.clientHeight;
            const moreThanThreeLines = height / lineHeight > 3;

            if (moreThanThreeLines) {
                TileTextRef.current.style.fontSize = '12px';
            }
        });
    }, []);

    const isFtux = !!props.ftuxCategory;
    const onClick = () => {
        if (props.onTileClick) {
            props.onTileClick(props.game.slug);
        }
    };
    const imgThumb = props.game?.customEventGameThumb ||
        UrlService.makeExternalThumbUrl(isFtux, assetOriginUrl, secondaryThumbPath, thumbOriginUrl, 'jpg');
    const webpThumb = props.game?.customEventGameThumbWebp ||
        UrlService.makeExternalThumbUrl(isFtux, assetOriginUrl, secondaryThumbPathWebP, thumbOriginUrl, 'webp');
    const imgThumbBackFill = props.game?.customEventGameThumb ||
        assetOriginUrl + (isFtux ? secondaryThumbPath : thumbPath);
    const webpThumbBackFill = props.game?.customEventGameThumbWebp ||
        assetOriginUrl + (isFtux ? secondaryThumbPathWebP : thumbPathWebP);
    const title = props.game?.customEventGameName || (isFtux ? props.ftuxCategory : props.game.name);
    const isDigitalTurbine = themeName === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();
    const renderText = () => {
        if (isDigitalTurbine) {
            return (
                <TextBlock>
                    <WebGameIcon />
                    <TileText ref={TileTextRef} data-element-description="tile title">
                        {title}
                    </TileText>
                </TextBlock>
            );
        }

        if (props.isTextDisabled) {
            return null;
        }

        if (isFtux) {
            return <FtuxTileText>{props.ftuxCategory}</FtuxTileText>;
        }

        return <TileText data-element-description="tile title">{title}</TileText>;
    };

    return (
        <TileLink
            className={props.passedClassName}
            isFtux={isFtux}
            data-element-description="game tile"
            title={title}
            to={`/${props.t('ROUTES.GAMES')}/${props.game.alias}`}
            onClick={onClick}
            style={props.isTextDisabled ? {padding: '8px 0'} : {}}
        >
            <TilePictureWrapper data-element-description="tile picture" isFtux={isFtux}>
                <TilePicture
                    isFtux={isFtux}
                    imageType={isFtux ? ImageTypes.WIDE : ImageTypes.SQUARE}
                    title={`Enjoy playing ${title}`}
                    alt={title}
                    img={imgThumb}
                    webp={webpThumb}
                    imgBackFill={imgThumbBackFill}
                    webpBackFill={webpThumbBackFill}
                    isDigitalTurbine={isDigitalTurbine}
                />
            </TilePictureWrapper>

            {renderText()}
        </TileLink>
    );
});
const TileLink = ({ isFtux, ...props }: any) => (
    <RouterLink
        className={classNames(styles.tileLink, {
            [styles.isFtux]: isFtux,
        })}
        {...props}
    />
);
const TilePictureWrapper = ({ isFtux, ...props }: any) => (
    <div className={classNames(styles.tilePictureWrapper, { [styles.isFtux]: isFtux })} {...props} />
);

export const TilePicture = ({ isFtux, isDigitalTurbine, className, ...props }: any) => (
    <ProgressiveImage
        className={classNames(className, {
            [styles.ftuxPicture]: isFtux,
            [styles.digitalTurbine]: isDigitalTurbine,
        })}
        {...props}
    />
);
const TileText = React.forwardRef((props: any, ref) => <div ref={ref} className={styles.tileText} {...props} />);
const FtuxTileText = (props: any) => <div className={styles.ftuxTileText} {...props} />;
const TextBlock = (props: any) => <div className={styles.textBlock} {...props} />;

export const GameTile = withTranslation()(GameTileBase);

GameTile.displayName = 'GameTile';
