import React, { ReactElement } from 'react';
import { WithTranslation } from 'react-i18next';
import {
    TEagleErrorRegistrationResponse,
    TEmailField,
    TErrorValidation,
    TGdprUserDataField,
    TNicknameField,
    TPasswordField,
} from '../../../utils';
import styles from './LoginPopup.css';

export const INITIAL_EAGLE_ERRORS_EMAIL = { email: '', password: '' };
type TProps = {
    errors: Array<TErrorValidation<TEmailField | TPasswordField | TNicknameField | TGdprUserDataField | ''>>;
    tWithPrefix: WithTranslation['t'];
    children?: ReactElement | ReactElement[];
    eagleErrors?: TEagleErrorRegistrationResponse;
};
const ErrorsList = React.memo(({ errors, tWithPrefix, eagleErrors, children }: TProps) => {
    if (!errors.length && eagleErrors && !Object.values(eagleErrors).length) {
        return null;
    }

    const isGeneralError = Number(eagleErrors?.email) === 1000;

    return (
        <div className={styles.popupErrorsList}>
            {errors
                .filter((err) => err.field !== 'confirm-password')
                .map((err) => {
                    return (

                        <div
                            key={err.description}
                            dangerouslySetInnerHTML={{ __html: tWithPrefix(err.description) }}
                        />
                    );
                })}
            {eagleErrors?.email && (
                <div
                    dangerouslySetInnerHTML={{
                        __html: tWithPrefix(`${eagleErrors.email}${isGeneralError ? '_EMAIL' : ''}`),
                    }}
                />
            )}
            {eagleErrors?.password && <div>{tWithPrefix(eagleErrors.password)}</div>}
            {children}
        </div>
    );
});

ErrorsList.displayName = 'ErrorsList';
export { ErrorsList };
