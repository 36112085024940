import i18n from 'i18next';

import { SupportedLangs } from './i18n';
import { CategoryPage } from './client/pages/CategoryPage';
import { GamePage } from './client/pages/GamePage';
import { HomePage } from './client/pages/HomePage';
import { AllGamesPage } from './client/pages/AllGamesPage';
import { HelpPage } from './client/pages/HelpPage';
import { ProfilePage } from './client/pages/ProfilePage';
import { Page403 } from './client/pages/Page403';
import { Page404 } from './client/pages/Page404';
import { PaymentPage } from './client/pages/PaymentPage';
import { AppBodySubscription } from './subscription/AppBodySubscription';

const langsUrlBases = SupportedLangs.map((lang) => `/${lang}/`);
const bases = ['/', ...langsUrlBases];
const commonPages = [
  {
    path: '*',
    exact: true,
    is404: true,
    component: Page404,
  },
];

export const routes = (lang?: string) => {
  const t = i18n.getFixedT(lang);
  const page2component = {
    [`games/${t('ROUTES.HELP')}`]: HelpPage, // duplicated for arena in subfolder
    [`games/${t('ROUTES.ALL_GAMES')}`]: AllGamesPage, // duplicated for arena in subfolder
    [`games/${t('ROUTES.CATEGORY')}/:category`]: CategoryPage,
    [`games/${t('ROUTES.PROFILE')}`]: ProfilePage,
    'games': HomePage, // duplicated for arena in subfolder
    [`${t('ROUTES.CATEGORY')}/:category`]: CategoryPage,
    [`${t('ROUTES.GAMES')}/:game`]: GamePage,
    [`${t('ROUTES.HELP')}`]: HelpPage,
    [`${t('ROUTES.ALL_GAMES')}`]: AllGamesPage,
    [`${t('ROUTES.PROFILE')}`]: ProfilePage,
    [`${t('ROUTES.PAYMENT')}`]: PaymentPage,
    [`${t('ROUTES.SHOP')}`]: AppBodySubscription,
    '': HomePage,
    '404': Page404,
    '403': Page403,
  };

  return bases
    .reduce(
      (acc, base) => [
        ...acc,
        ...Object.keys(page2component).map((page) => ({
          exact: true,
          path: base + page,
          component: page2component[page],
        })),
      ],
      [],
    )
    .concat(commonPages);
};
