import classNames from 'classnames';
import React from 'react';
import styles from './Button.css';

type ButtonProps = {
    className?: string;
    onClick?: any;
    children?: any;
};

export class Button extends React.PureComponent<ButtonProps> {
    static displayName = 'Button';

    render() {
        const { className, onClick, children, ...restProps } = this.props;

        return (
            <button className={classNames(styles.Base, className)} onClick={onClick} {...restProps}>
                {children}
            </button>
        );
    }
}
