import { apiFetch, HttpError } from '../../fetch';
import { EagleLoginService } from './EagleLoginService';
import { UrlService } from './UrlService';

const USER_API_PATH = `https://user.usatoday.com/USAT-GUP-ARKADIUM/user/`;
const USAT_VERIFICATION_LINK = `https://login.usatoday.com/USAT-GUP-ARKADIUM/personal-information/?from-state=email-verify&from-state=email-verify`;

interface IMeta {
    status: number;
    message: string;
    isAnonymous: boolean;
    unmetRequirements: [];
    allRequirementsMet: boolean;
    isAuthenticatedInCurrentContext: boolean;
    market_relationship: string;
}

interface IResponse {
    client: {
        attributes: object;
        insights: object;
    };
    insights: {
        nstar: {
            fabrickId: string;
            lastFetched: number;
            adAdvisorSegment: string;
            qsp_hash: string;
        };
    };
    attributes: object;
    propensitySubscribe: {
        ex: boolean;
        na: boolean;
        sub: boolean;
        no: boolean;
    };
    anonymousId: string;
    always_anonymousId: string;
    userId: string;
    clientId: string;
    user: {
        profileAttributes: object;
        fireflyUserId: number;
        hasMarketAccess: boolean;
        isPaidSubscriber: boolean;
        firstLoginDate: string;
        firstLoginToSiteDate: string;
        userLicenseType: string;
        phoneNumber: null | string;
        welcomeName: string;
        fullName: string;
        firstName: string;
        lastName: string;
        licensePaymentHistory: any[];
        email: string;
        isEmailVerified: boolean;
        '3PID': string;
        zuoraAccountNumbers: any[];
        entitlements: {
            ad_free: boolean;
            digital: boolean;
            print_replica: boolean;
        };
        coralJWT: string;
    };
}

interface IUsatResponse {
    meta: IMeta;
    response: IResponse;
}

class UsaTodayAuthService {
    async getUserInformation() {
        // Receiving data from USAT
        return apiFetch(USER_API_PATH, { credentials: 'include' })
            .then(async ({
                             meta,
                             response,
                         }: IUsatResponse) => {
                if (meta.isAnonymous) {
                    return null;
                }

                // if email is not verified redirect to usat verification page
                if (response && !response.user.isEmailVerified) {
                    return window.location.assign(USAT_VERIFICATION_LINK);
                }

                const arenaDomainUrl = new URL(UrlService.getCurrentOriginForEagle());

                try {
                    // send data from usat to eagle and login user
                    await EagleLoginService.loginViaUsat({
                        userGuid: response.userId,
                        registrationPlaceUrl: arenaDomainUrl,
                        registrationAppId: arenaDomainUrl.hostname,
                    });
                } catch (err) {
                    if (err instanceof HttpError && err.response.status === 404) {
                        // User not found
                        return null;
                    }

                    throw new Error('Unknown token exchange error. ' + err.message);
                }
            })
            .catch((reason: string) => {
                console.log(reason);
                return null;
            });
    }

    logout() {
        apiFetch(USER_API_PATH + 'logout/', { credentials: 'include' })
            .then(() => {
                return true;
            })
            .catch((reason: string) => {
                console.log(reason);
                return false;
            });
    }
}

export const usaTodayAuthService = new UsaTodayAuthService();
