import { ActionTypes } from '../../models/Analytics';
import { UrlService } from '../UrlService';
import { AnalyticsInit } from './AnalyticsInit';
import { AppInsightsAnalytics } from './AppInsights';
import { GoogleAnalytics } from './Google';

class AnalyticsHomePageClass {
    private clickCounts: any = {};

    promoGame(actionType: string, slug: string) {
        if (actionType === ActionTypes.CLICK) {
            if (this.clickCounts.promoGame) {
                return false;
            }

            this.clickCounts.promoGame = true;

            const googleEvent = {
                name: 'Carousel_Play_Click',
                action: actionType,
                label: `${AnalyticsInit.domain} | arena home`,
                nonInteraction: false,
                dimension5: slug
            };

            GoogleAnalytics.trackEvent(googleEvent);
        }

        const eventAppInsight = {
            action: actionType,
            game: '',
            promotedGame: slug,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        AppInsightsAnalytics.trackEvent('bigPromo', eventAppInsight);

        return event;
    }

    redesignedPromobannerClicks(actionType: string, slug: string, isTitle = false) {
        const eventAppInsight = {
            action: actionType,
            promotedGame: slug,
            nonInteraction: false,
        };
        const eventName = isTitle ? 'promobox' : 'promoboxSlot';

        AppInsightsAnalytics.trackEvent(eventName, eventAppInsight);
    }

    tilesSections(actionType: string, eventCategory: string, tags: string) {

        if (actionType === ActionTypes.CLICK) {
            const event = {
                name: 'PromoboxSlot_Click',
                action: actionType,
                label: `${AnalyticsInit.domain} | ${UrlService.getCurrentPage()}`,
                nonInteraction: ActionTypes.CLICK,
                dimension7: eventCategory === 'Promo_Box' ? tags : undefined,
                dimension8: eventCategory === 'Arena_Promo_Box' ? tags : undefined,
                dimension9: eventCategory === 'Promo_Box_Slot' ? tags : undefined,
            };

            GoogleAnalytics.trackEvent(event);
        }

        const appInsightCategory = eventCategory === 'Promo_Box_Slot' ? 'promoboxSlot' : 'promobox';
        const eventAppInsight = {
            action: actionType,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            promoboxClick: eventCategory === 'Promo_Box' ? tags : undefined,
            promoboxImpression: eventCategory === 'Arena_Promo_Box' ? tags : undefined,
            promoboxSlotClick: eventCategory === 'Promo_Box_Slot' ? tags : undefined,
        };

        AppInsightsAnalytics.trackEvent(appInsightCategory, eventAppInsight);
    }

    fTuxAnalytics(actionType: string, category: string, catName?: string) {
        const event = {
            action: actionType,
            category,
            label: catName,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        GoogleAnalytics.trackEvent(event);

        const eventAppInsight = {
            action: actionType,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
            ftuxWelcomeClick: catName,
        };

        AppInsightsAnalytics.trackEvent(category, eventAppInsight);
    }
}

export const AnalyticsHomePage = new AnalyticsHomePageClass();
