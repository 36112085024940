// import { pushSupportedPages } from '../client/constants/PushSupportedPages';
// import { WIDTH_QUERY } from '../client/models/Enums';

export class MiscUtils {
    public static get isServer(): boolean {
        return typeof window === 'undefined';
    }

    // public static matchWidth(query: WIDTH_QUERY, value: string, ssrResult = false): boolean {
    //     if (MiscUtils.isServer) {
    //         return ssrResult;
    //     }
    //     const queryString = `(${query}: ${value}px)`;
    //     return window.matchMedia(queryString).matches;
    // }

    // public static clone(obj): any {
    //     return JSON.parse(JSON.stringify(obj));
    // }

    // public static isNumber(x: any): x is number {
    //     return typeof x === 'number';
    // }

    // public static isString(x: any): x is string {
    //     return typeof x === 'string';
    // }

    // public static isBoolean(x: any): x is boolean {
    //     return typeof x === 'boolean';
    // }

    // public static random(min, max): number {
    //     return Math.floor(Math.random() * (max - min + 1) + min);
    // }

    // public static isAdBlockEnabled(): boolean {
    //     return (window as any).__ark_ads__ === undefined || typeof (window as any).__ark_ads__.AdVideo !== 'function';
    // }

    // public static getNowTimestampUTC(): number {
    //     return new Date().getTime();
    // }

    // public static fixMissingLang(lang: string): string {
    //     if (lang === 'es' || lang === 'de') {
    //         return 'en';
    //     }
    //     return lang;
    // }

    // public static debounce(wrappedFunction: () => any, delayInMilliSeconds: number): () => any {
    //     let debounceTimerId: number;

    //     return (...args) => {
    //         clearTimeout(debounceTimerId);
    //         debounceTimerId = window.setTimeout(() => {
    //             wrappedFunction.apply(this, args);
    //         }, delayInMilliSeconds);
    //     };
    // }
}
