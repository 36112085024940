import { fetchApiCall } from '../../fetch';
import { environment } from '../config/environment';

const API_PATH = `${environment.HELP_CONTENT_BLOB}`;

export enum HelpSections {
    // Project-scoped value
    AD_BLOCK = 'ad_block',
    // Used in translations and as names of content in blob
    ABOUT = 'about',
    FEATURES = 'features',
    FAQ = 'faq',
    SYS_REQUIREMENTS = 'sys_require',
    // Used in translations
    CONTACT_US = 'contact_us_btn',
}

class HelpContentService {
    cache: { [key: string]: string } = {};

    public getPage(page: HelpSections, lang: string): Promise<string> {
        if (page === HelpSections.AD_BLOCK) {
            page = HelpSections.FAQ;
        }

        const blobName = `${page}_${lang}`;

        if (this.cache[blobName]) {
            return Promise.resolve(this.cache[blobName]);
        }

        return fetchApiCall(`${API_PATH}/${page}/${blobName}.html`, { timeout: 10000 }, false)
            .then((res) => {
                this.cache[blobName] = res.data;
                return res.data;
            })
            .catch((err) => {
                console.error(err);
                return null;
            });
    }
}

export const helpContentService = new HelpContentService();
