import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import CountriesList from '../../../constants/CountriesList';
import { BillingInfoValues } from '../../../models/Payment';
import { setStepIndex } from '../../../store/ducks/subscription/common';
import subsStyles from '../SubscriptionStyles.css';
import styles from './BillingBlock.css';

type BillingBlockProps = {
  userData: BillingInfoValues;
  setSmoothScrolling: (v: { isScrolling: boolean, id: string }) => void;
};

export const BillingBlock: FC<BillingBlockProps> = ({
  userData,
  setSmoothScrolling,
}) => {
  const dispatch = useDispatch();
  const onEditClick = () => {
    setSmoothScrolling({
      isScrolling: true,
      id: 'billingFieldsRef',
    });
    dispatch(setStepIndex(0));
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <span className={subsStyles.heading}>Billing Address </span>
        {(
          <button
            className={subsStyles.link}
            onClick={onEditClick}
          >
            Edit
          </button>
        )}
      </div>
      <div className={subsStyles.infoBlock}>
        <div className={subsStyles.paymentHeading}>
          {userData.country &&
            CountriesList.filter((i) => {
              return i.id === userData.country;
            })[0].name}
        </div>
        <div className={styles.description}>
          <p>{userData.address1}</p>
          <p>
            {userData.state}, {userData.city} {userData.postal_code}
          </p>
        </div>
      </div>
    </div>
  );
};
