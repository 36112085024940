import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ArrowIcon } from '../../atoms/Icons/ArrowIcon';
import { Link } from '../../atoms/Link/Link';
import { THEMES } from '../../consts';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './MoreGamesLink.css';

type MoreGamesProps = {
    url: string;
    t: any;
    onClick?: () => void;
    themeName?: string;
};

const MoreGamesLinkBase = React.memo(({ url, t, onClick, themeName }: MoreGamesProps & WithTranslation) => {
    const onMoreGamesClick = () => {
        if (onClick) {
            onClick();
        }
    };
    const isDigitalTurbine = themeName === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();
    const linkText = !isDigitalTurbine ? 'TEXT_VIEW_MORE_MOBILE' : 'TEXT_VIEW_MORE_MOBILE_DIGITAL_TURBINE';

    return (
        <ViewMoreLink onClick={onMoreGamesClick} data-element-description="more games link" to={url}>
            <DescContent>
                {t('TEXT_VIEW_MORE')}
                <ViewMoreIcon />
            </DescContent>

            <TabletMobileContent>{t(linkText)}</TabletMobileContent>
        </ViewMoreLink>
    );
});
const ViewMoreLink = (props: any) => <Link className={styles.viewMore} {...props} />;
const ViewMoreIcon = () => <ArrowIcon className={styles.viewMoreIcon} />;
const DescContent = (props: any) => <div className="d-none d-lg-block" {...props} />;
const TabletMobileContent = (props: any) => <div className="d-block d-lg-none" {...props} />;

export const MoreGamesLink = withTranslation()(MoreGamesLinkBase);
