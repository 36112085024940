export class Deferred {
    resolve: (arg?) => void;
    reject: (err?) => void;
    promise: Promise<any>;

    constructor() {
        this.promise = new Promise((res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
    }
}
