import React, { forwardRef, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { PayPalIcon } from '../../../FigmaStyleguide/Icons/PayPalIcon';
import { PaymentMethod } from '../../../models/Payment/PaymentForm';
import { TabsComponent } from '../../../molecules/TabsComponent/TabsComponent';
import { PaymentForm } from '../../../organisms/PaymentForm/PaymentForm';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { Analytics } from '../../../services/Analytics/Analytics';
import { setPaymentMethod } from '../../../store/ducks/subscription/common';
import { gemsShopLocationSelector } from '../../../store/ducks/gemsSelectors';
import { AppInsightsAnalytics } from '../../../services/Analytics/AppInsights';
import styles from '../PurchasePageTemplate.css';

interface IPaymentInfoStepProps {
  isStepVisible: boolean;
  paymentMethodsApplied: PaymentMethod[];
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
}

export const PaymentInfoStep = memo(
  forwardRef<HTMLFormElement, IPaymentInfoStepProps>(
    (
      {
        isStepVisible,
        paymentMethodsApplied,
        gemsAnalyticsProps,
      }: IPaymentInfoStepProps,
      ref,
    ) => {
      const dispatch = useDispatch();
      const paymentMethod = useSelector((state) => state.paymentMethod);
      const shopLocation = useSelector(gemsShopLocationSelector);
      const runAnalytics = async () => {
        AppInsightsAnalytics.trackAnalyticsEvent(
          await Analytics.gems.gemPurchaseClickImpression(
            gemsAnalyticsProps.priceInGem,
            gemsAnalyticsProps.gemsPackId,
            gemsAnalyticsProps.gemsInPack,
            shopLocation,
          ),
        );
      };

      useEffect(() => {
        void runAnalytics();
      }, [paymentMethod]);

      const paymentMethods = paymentMethodsApplied
        .map((payMethod) => {
          const card = {
            key: PaymentMethod.CARD,
            tabComponent: <span>Credit/Debit Card</span>,
            content: <PaymentForm ref={paymentMethod === PaymentMethod.CARD ? ref : null}/>,
            onClick: () => dispatch(setPaymentMethod(PaymentMethod.CARD)),
          };
          const paypal = {
            key: PaymentMethod.PAYPAL,
            tabComponent: <PayPalIcon/>,
            content: (
              <div className={styles.stepHeading}>
                <p>
                  <I18nText keyName="PURCHASE_PAGE.PAYPAL_PAYMENT_METHOD_TITLE"/>
                  &nbsp;
                  <I18nText keyName="PURCHASE_PAGE.PAYPAL_TAXES_TEXT"/>
                </p>
              </div>
            ),
            onClick: () => {
              dispatch(setPaymentMethod(PaymentMethod.PAYPAL));
            },
          };

          switch (payMethod) {
            case card.key:
              return card;
            case paypal.key:
              return paypal;
            default:
              return undefined;
          }
        })
        .filter((el) => Boolean(el));

      return (
        <div style={{ display: isStepVisible ? 'inherit' : 'none' }}>
          <div className={styles.mainStepHeading}>
            <h2>
              <strong>1.&nbsp;</strong>
              <I18nText keyName="PURCHASE_PAGE.SELECT_PAYMENT_METHOD"/>
            </h2>
          </div>
          <TabsComponent
            defaultTab={PaymentMethod.CARD}
            content={paymentMethods}
          />
        </div>
      );
    },
  ),
);
