class AbTestsConsoleClass {
    constructor() {
        this.abTestShareInfo = this.abTestShareInfo.bind(this);
    }

    abTestShareInfo(testName, variation) {
        if (testName && variation && !this[testName]) {
            console.debug(testName + ' control group=' + variation);
            this[testName] = variation;
        }
    }
}

export const AbTestsConsole = new AbTestsConsoleClass();
