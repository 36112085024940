import React from 'react';
import { useSelector } from 'react-redux';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { chooseGemsPacksPlan, PaymentType, RecurlyPlanObject } from '../../../constants/RecurlyPurchase';
import { SubscriptionPlans } from '../../../constants/SubscriptionPlan';
import { PaymentMethod } from '../../../models/Payment/PaymentForm';
import { BillingBlock } from '../../../molecules/Subscription/BillingBlock/BillingBlock';
import { ChosenPurchaseBlock } from '../../../molecules/Subscription/ChosenPurchaseBlock/ChosenPurchaseBlock';
import { PaymentBlock } from '../../../molecules/Subscription/PaymentBlock/PaymentBlock';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { AppInsightsAnalytics } from '../../../services/Analytics/AppInsights';
import { Analytics } from '../../../services/Analytics/Analytics';
import { gemsShopLocationSelector } from '../../../store/ducks/gemsSelectors';
import { ReviewPaymentInfoStepContent } from '../../../store/ducks/subscription/common';
import styles from '../PurchasePageTemplate.css';

interface IReviewPaymentInfoStepProps {
  setSmoothScrolling: (v: { isScrolling: boolean, id: null | string }) => void;
  price: any; // recurly obj
  paymentType: PaymentType;
  paymentGoodPlan?: RecurlyPlanObject;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
}

export const ReviewPaymentInfoStep = React.forwardRef<HTMLFormElement, IReviewPaymentInfoStepProps>(
  (
    {
      setSmoothScrolling,
      price,
      paymentType,
      paymentGoodPlan,
      gemsAnalyticsProps,
    }: IReviewPaymentInfoStepProps,
    _,
  ) => {
    const userPaymentData = useSelector(({ billingInfoValues }) => billingInfoValues);
    const paymentMethod = useSelector((state) => state.paymentMethod);
    const planCode = SubscriptionPlans.ANNUAL;
    const paymentInfoStepContent = useSelector(({ reviewPaymentInfoStepContent }) => reviewPaymentInfoStepContent);
    const shopLocation = useSelector(gemsShopLocationSelector);
    const subscriptionPlanType = [...Object.values(chooseGemsPacksPlan())]
      .filter((recurlyPlan: { key: string }) => recurlyPlan.key === (paymentGoodPlan ? paymentGoodPlan.key : planCode))
      .map((recurlyPlan: { key: string }) => ({
        label: recurlyPlan.key,
        price: price?.now?.subtotal,
      }))[0];
    const {
      gemsPackId,
      gemsInPack,
      priceInGem,
    } = gemsAnalyticsProps;
    const runAnalytics = async () => {
      AppInsightsAnalytics.trackAnalyticsEvent(
        await Analytics.gems.gemPurchaseNextStepImpression(
          priceInGem,
          gemsPackId,
          gemsInPack,
          shopLocation,
        ));
    };
    const renderContent = ({
      type,
    }: { type: number }) => {
      if (type === ReviewPaymentInfoStepContent.PurchaseDetails) {
        if (paymentType === PaymentType.gems) {
          void runAnalytics();
        }

        return (
          <>
            <div className={styles.mainStepHeading}>
              <I18nText keyName={'PURCHASE_PAGE.PURCHASE_DETAILS_TITLE'}/>
            </div>
            <ChosenPurchaseBlock
              type={subscriptionPlanType}
              planCode={planCode}
              paymentType={paymentType}
              gemsAnalyticsProps={gemsAnalyticsProps}
            />
            <div className={styles.paymentRow}>
              <PaymentBlock
                setSmoothScrolling={setSmoothScrolling}
                userData={userPaymentData}
              />
              {paymentMethod === PaymentMethod.CARD && (
                <BillingBlock setSmoothScrolling={setSmoothScrolling} userData={userPaymentData}/>
              )}
            </div>
          </>
        );
      }
    };

    return renderContent(paymentInfoStepContent);
  },
);
