import React, { FC } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AmericanExpress } from '../../../FigmaStyleguide/Icons/AmericanExpress';
import { BlankCard } from '../../../FigmaStyleguide/Icons/BlankCard';
import { MasterCard } from '../../../FigmaStyleguide/Icons/MasterCard';
import { PayPalIcon } from '../../../FigmaStyleguide/Icons/PayPalIcon';
import { VisaCardIcon } from '../../../FigmaStyleguide/Icons/VisaCardIcon';
import { setStepIndex } from '../../../store/ducks/subscription/common';
import subsStyles from '../SubscriptionStyles.css';
import styles from './PaymentBlock.css';
import { BillingInfoValues, PaymentMethod } from '../../../models/Payment';

type PaymentBlockProps = {
  payment?: string;
  userData: BillingInfoValues;
  setSmoothScrolling: (v: { isScrolling: boolean, id: null | string }) => void;
};

export const PaymentBlock: FC<PaymentBlockProps> = ({
  userData,
  setSmoothScrolling,
}) => {
  const dispatch = useDispatch();
  const paymentMethod = useSelector((state) => state.paymentMethod);
  const renderCreditCard = (creditCardCompany: string) => {
    switch (creditCardCompany) {
      case 'unknown':
        return <BlankCard/>;
      case 'master':
        return <MasterCard/>;
      case 'visa':
        return <VisaCardIcon/>;
      case 'american_express':
        return <AmericanExpress/>;
      default:
        return <BlankCard/>;
    }
  };
  const renderPaymentMethodIcon = (method: string, creditCardCompany?: string) => {
    switch (method) {
      case PaymentMethod.CARD:
        return renderCreditCard(creditCardCompany);
      case PaymentMethod.PAYPAL:
        return <PayPalIcon/>;
      default:
        break;
    }
  };
  const handleOnEditClick = () => {
    setSmoothScrolling({
      isScrolling: true,
      id: 'paymentFieldsRef',
    });
    dispatch(setStepIndex(0));
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <span className={subsStyles.heading}>Payment </span>
        {paymentMethod === PaymentMethod.CARD && (
          <button
            className={subsStyles.link}
            onClick={handleOnEditClick}
          >
            Edit
          </button>
        )}
      </div>
      <div
        className={classNames(
          subsStyles.infoBlock,
          paymentMethod !== PaymentMethod.GIFT_CARD && styles.infoBlockSingleIcon,
        )}
      >
        <div className={styles.paymentType}>
          {renderPaymentMethodIcon(
            paymentMethod,
            paymentMethod === PaymentMethod.CARD && userData.creditBrand,
          )}
        </div>
        {paymentMethod === PaymentMethod.CARD && (
          <div className={styles.paymentDesc}>
            <ul>
              <li className={subsStyles.paymentHeading}>Card</li>
              <li>
                <strong>
                  <span>&#8226;&#8226;&#8226;&#8226;</span>
                  {userData.creditLastFour}
                </strong>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
