import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './NotifyIconTooltip.css';
import { InfoIcon } from '../../FigmaStyleguide/Icons/InfoIcon';

type TooltipProps = {
    textTop: React.ReactNode | string;
    classNameMessage: string;
    focusable?: boolean;
    textBottom?: string;
    tooltipNotVisible?: boolean;
    classNameIcon?: string;
    mainColor?: string;
    backgroundColor?: string;
    backgroundHoverColor?: string;
    messageBackgroundColor?: string;
    customIcon?: boolean;
    hiddenFromScreenReader?: boolean;
    textForScreenReaderOnly?: string;
    infoIconHidden?: boolean;
};

export const NotifyIconTooltip = React.memo(
    ({
        textTop,
        textBottom,
        tooltipNotVisible,
        classNameIcon,
        classNameMessage,
        mainColor = 'rgba(255, 255, 255, 1)',
        backgroundColor = 'rgb(170, 170, 170)',
        backgroundHoverColor = 'rgba(0, 66, 187, 1)',
        messageBackgroundColor,
        customIcon,
        focusable = true,
        hiddenFromScreenReader = false,
        textForScreenReaderOnly,
        infoIconHidden = false,
    }: TooltipProps) => {
        const [isVisible, setIsVisible] = useState(false);
        const hideToolTip = () => setIsVisible(false);
        const showToolTip = () => setIsVisible(true);

        return (
            <div className={styles.tooltipContainer}>
                <div
                    role="presentation"
                    className={classNames(styles.infoButtonWrapper)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.stopPropagation();
                            isVisible ? hideToolTip() : showToolTip();
                        }
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        isVisible ? hideToolTip() : showToolTip();
                    }}
                    onMouseOver={showToolTip}
                    onMouseLeave={hideToolTip}
                    onFocus={showToolTip}
                    onBlur={hideToolTip}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={focusable ? 0 : -1}
                >
                    {!customIcon && (
                        <InfoIcon
                            className={classNames(styles.infoButton, classNameIcon && styles[classNameIcon])}
                            iColor={mainColor}
                            backColor={isVisible ? backgroundHoverColor : backgroundColor}
                            ariaHidden={infoIconHidden}
                        />
                    )}
                    {customIcon && classNameIcon && <div className={styles[classNameIcon]} aria-hidden={true} />}
                    {textForScreenReaderOnly && (
                        <span className={styles.screenReaderOnly}>{textForScreenReaderOnly}</span>
                    )}
                </div>
                <div
                    className={classNames(styles.tooltipMessageWrapper, styles[classNameMessage], {
                        [styles.infoMessageVisible]: isVisible && !tooltipNotVisible,
                        [styles.hiddenFromScreenReader]: hiddenFromScreenReader,
                    })}
                >
                    <div
                        style={{ backgroundColor: messageBackgroundColor }}
                        className={styles.infoMessage}
                        role="tooltip"
                    >
                        <p>
                            {textTop}
                            <br />
                            {textBottom}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
);
