import React, { FC } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { PaymentType, RecurlyPlanObject } from '../../../constants/RecurlyPurchase';
import { PaymentMethod } from '../../../models/Payment/PaymentForm';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { updateLabel } from '../ChosenPurchaseBlock/ChosenPurchaseBlock';
import styles from './SummaryBlock.css';

type SummaryBlockProps = {
  heading: string;
  totalSum: string;
  taxSum: string | null;
  hidden?: boolean;
  promoCodes: string[];
  price: any; // recurly obj
  paymentGoodPlan: RecurlyPlanObject;
  paymentType: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
};

export const SummaryBlock: FC<SummaryBlockProps> = ({
  heading,
  taxSum,
  totalSum,
  hidden,
  promoCodes,
  price,
  paymentGoodPlan,
  paymentType,
  gemsAnalyticsProps,
}) => {
  const paymentMethod = useSelector((state) => state.paymentMethod);
  const mainText = updateLabel(paymentGoodPlan?.title, 'Gem', gemsAnalyticsProps, paymentType);
  const subText = paymentGoodPlan?.description;

  return (
    <div className={classNames(styles.summaryInfo__mainInfo, hidden && styles.blockHidden)}>
      <div>
        <span className={styles.summaryInfoLabel}>{heading}</span>
      </div>
      <div className={styles.advantageRow}>
        <div className={styles.advantageBlock}>
          <div className={styles.advantageBlock__text}>
            <p className={styles.mainText}>{mainText}</p>
            <p className={styles.subText}>{subText}</p>
          </div>
        </div>
        <div className={styles.summaryInfoLabel}>${price?.now?.adjustments}</div>
      </div>
      <div className={classNames(styles.advantageRow, styles.taxRow)}>
        <div className={styles.advantageBlock}>
          <div className={styles.advantageBlock__text}>
            <p className={styles.subText}>Sales Tax</p>
          </div>
        </div>
        <div
          className={classNames(styles.summaryInfoLabel, {
            [styles.subText]: !taxSum && paymentMethod !== PaymentMethod.PAYPAL,
          })}
        >
          {paymentMethod === PaymentMethod.PAYPAL ? 'TBD' : taxSum || '-'}
        </div>
      </div>
      {promoCodes.length > 0 && (
        <div className={classNames(styles.advantageRow, styles.appliedBlock)}>
          <div className={styles.advantageBlock}>
            <div className={styles.advantageBlock__text}>
              <p className={styles.mainText}>Promo code applied</p>
              <p className={styles.subText}>
                {promoCodes.join('')}
                &nbsp;
                {price?.now &&
                  // calculating the percentage of the discount (recurly does not give it as a percentage by default)
                  `-${(price.now.discount / ((price.now.adjustments / 100))).toFixed(0)}%`
                }
              </p>
            </div>
          </div>
          <div className={styles.summaryInfoLabel}>- ${price?.now?.discount}</div>
        </div>
      )}
      <div className={styles.divider}/>
      <div className={styles.totalRow}>
        <div className={styles.summaryInfoLabel}>
          {`Total ${!taxSum && paymentMethod === PaymentMethod.PAYPAL ? 'Before Tax' : ''}`}
        </div>
        <div className={styles.summaryInfoLabel}>
          {promoCodes.length > 0 && (
            <s>
              ${price?.now.adjustments}
            </s>
          )}
          ${totalSum}
        </div>
      </div>
    </div>
  );
};
