import classNames from 'classnames';
import React from 'react';
import Person from '../../../public/svg/PersonIcon.svg';
import PersonRounded from '../../../public/svg/PersonIconRounded.svg';
import styles from './Icons.css';

export const PersonIcon = React.memo(({ isRounded, ...props }: any) => {
    const Icon = isRounded ? PersonRounded : Person;

    return <Icon className={classNames(styles.personIcon, props.className)} aria-hidden="true" />;
});
