import { AppInsights } from 'applicationinsights-js';
import { isServer, normalizePath, trimUndefinedProps } from '../../../utils';
import { adBlockDetector } from '../AdBlockerService';
import { incognitoDetector } from '../IncognitoDetector';
import { UrlService } from '../UrlService';
import { IZoomCheckResult, ZoomCheckHelper } from '../ZoomCheckHelper';
import { EagleLoginService } from '../EagleLoginService';
import { AnalyticsEvent } from './AnalyticsEventBuilder';
import { FingerprintService } from './FingerprintJS/FingerprintService';

interface ICustomEvent {
    screenSize: string;
    windowSize: string;
    gameSize?: string;
    zoomUsed?: string;
    zoomPresetValue?: string;
    zoomPinchValue?: string;
    zoomTotalValue?: string;
    incognitoMode: string;
    xsollaId: string;
}

class AppInsightService {
    private customDimensionsAppinsights = {};
    private pingInterval = 30000;
    private lastEventSent = false;
    private timer = null;
    private customDimensionsGetZoom = null;

    public async init() {
        if (isServer) {
            return;
        }

        const ikey =
            process.env.NODE_ENV === 'production'
                ? '43553537-7bc0-4a68-b0e5-3a8b28822543'
                : '58667f6a-1cdd-4ac4-a65e-9e66e1d45ed1';
        const cfg = {
            instrumentationKey: ikey,
            disableExceptionTracking: true,
            disableAjaxTracking: true,
            enableDebug: false,
            maxBatchInterval: 0,
            samplingPercentage: this.getAppInsightsSampleRate(),
            disableFetchTracking: true,
        };

        AppInsights.downloadAndSetup(cfg);
        AppInsights.queue.push(() => {
            AppInsights.context.addTelemetryInitializer((envelope) => {
                envelope.tags['ai.operation.name'] = normalizePath(UrlService.getCurrentPathname());
            });
        });

        await this.initEnvVariables();
    }

    public async initEnvVariables() {
        if (isServer) {
            return;
        }

        const arenaApi = (window as any).__ArenaApi__;

        if (arenaApi) {
            const sdkApi = await arenaApi.getInstance(
                BUILD_ENV === 'prod' || BUILD_ENV === 'canary' ? 'PROD' : 'DEV'
            );
            const info = await sdkApi.analytics.getEnvVersion();

            if (info) {
                this.setCustomDimensions(getCustomDimensions(info));
            }
        }
    }

    public trackPageView(data) {
        AppInsights.trackPageView(null, null, { ...data, pagePath: window.location.pathname });
        this.lastEventSent = true;
    }

    public trackAppError(error, info = {}) {
        adBlockDetector.isBlocked.then((isBlocked) => {
            const data: any = {};

            // was added just to compare difference in count between js-exception and js-error
            AppInsights.trackEvent('js-exception', {
                error,
                info: JSON.stringify(info),
                adBlockEnabled: isBlocked.toString(),
                ...this.customDimensionsAppinsights,
                pagePath: window.location.pathname
            });
            this.addMandatoryDimensions(data).then(() => {
                AppInsights.trackEvent('js-error', {
                    error,
                    info: JSON.stringify(info),
                    adBlockEnabled: isBlocked.toString(),
                    ...{ ...this.customDimensionsAppinsights, ...data },
                    pagePath: window.location.pathname
                });
            });
        });
    }

    public trackAnalyticsEvent(event: AnalyticsEvent) {
        this.trackEvent(event.name, event.data);
    }

    public trackEvent(event: string, data: any) {
        trimUndefinedProps(data);
        this.addMandatoryDimensions(data).then((r) => {
            AppInsights.trackEvent(event, {
                ...this.customDimensionsAppinsights,
                ...data,
                pagePath: window.location.pathname
            });
        });
        this.lastEventSent = true;
    }

    public async addMandatoryDimensions(props: ICustomEvent) {
        const { width, height } = window.screen;

        props.screenSize = width + 'x' + height;

        const { innerWidth, innerHeight } = window;

        props.windowSize = innerWidth + 'x' + innerHeight;

        if (!this.customDimensionsGetZoom && typeof window !== 'undefined') {
            this.customDimensionsGetZoom = ZoomCheckHelper();
        }

        const calculatedZoom: IZoomCheckResult = this.customDimensionsGetZoom ? this.customDimensionsGetZoom() : {};

        props.zoomUsed = calculatedZoom.isZoomUsed.toString();
        props.zoomPresetValue = `${calculatedZoom.presetZoomPercentValue}%`;
        props.zoomPinchValue = `${calculatedZoom.pinchZoomPercentValue}%`;
        props.zoomTotalValue = `${calculatedZoom.totalZoomPercentValue}%`;

        const gameContainer = document.querySelector('[data-element-description="game"] > *');

        if (gameContainer) {
            const { clientWidth, clientHeight } = gameContainer;

            props.gameSize = clientWidth + 'x' + clientHeight;
        }

        if (!(window as any).incognitoMode) {
            (window as any).incognitoMode = await incognitoDetector.incognitoMode();
        }

        props.incognitoMode = (window as any).incognitoMode;
        props.xsollaId = EagleLoginService.getUserFromStore()?.uid ?? '';

        await this.initEnvVariables();

        const fingerprintGlobalCDs = FingerprintService.detected || (await FingerprintService.detect());

        this.setCustomDimensions({
            ...fingerprintGlobalCDs,
        });

        return props;
    }

    public setCustomDimensions(customDimensions) {
        this.customDimensionsAppinsights = {
            ...this.customDimensionsAppinsights,
            ...customDimensions,
        };
    }

    public setupPingTracking() {
        this.timer = setInterval(() => {
            if (!this.lastEventSent) {
                this.trackPingEvent();
            }

            this.lastEventSent = false;
        }, this.pingInterval);
    }

    private getAppInsightsSampleRate() {
        const AppInsightsSampleRateDEFAULT = 50; // default
        const AppInsightsSampleRateCONFIG =
            (window as any)?.STORE?.getState()?.config?.analytics?.appInsightsSampleRate || null;
        const validateConfigValue = {
            exists: Boolean(AppInsightsSampleRateCONFIG),
            number: typeof AppInsightsSampleRateCONFIG === 'number',
        };
        const AppInsightsSampleRate =
            AppInsightsSampleRateCONFIG && validateConfigValue.exists && validateConfigValue.number
                ? {
                      val: AppInsightsSampleRateCONFIG,
                      src: ' from config, -',
                      cause: ` config value is OK: ${AppInsightsSampleRateCONFIG}`,
                  }
                : {
                      val: AppInsightsSampleRateDEFAULT,
                      src: ' from default value',
                      cause: ` because config value NOT
                    ${Object.keys(validateConfigValue)
                        .filter((val) => validateConfigValue[val])
                        .join(', NOT ')}
                `,
                  };

        console.log(
            `
            AI: AI sampling rate was set up to: ${AppInsightsSampleRate.val}
            ${AppInsightsSampleRate.src}
            ${AppInsightsSampleRate.cause}
        `
                .trim()
                .replace(/\s/gi, ' ')
        );

        return AppInsightsSampleRate.val;
    }

    private trackPingEvent() {
        this.trackEvent('alive', {
            message: 'User is alive',
            timestamp: new Date().toISOString(),
            ...this.customDimensionsAppinsights
        });
    }
}

function getCustomDimensions (info: any) {
    return {
        gameVersion: info.GameVersion && info.GameVersion !== 'undefined' ? info.GameVersion : '',
        nestVersion: info.NestVersion && info.NestVersion !== 'undefined' ? info.NestVersion : '',
        sdkVersion: info.SdkVersion && info.SdkVersion !== 'undefined' ? info.SdkVersion : '',
    };
}

export const AppInsightsAnalytics = new AppInsightService();
