import { Api, ApiGateway, ApiGatewayInitParams, ApiSet, UserApiErrorV1 } from '@arkadium/eagle-user-client';
import { ChangeEmailConfirmDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-email-confirm.dto';
import { ChangePasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/change-password.dto';
import { ConfirmResetPasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/confirm-reset-password.dto';
import { EmailConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-confirmation.dto';
import { EmailLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-login.dto';
import { EmailRegistrationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/email-registration.dto';
import { FacebookLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/facebook-login.dto';
import { GoogleLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/google-login.dto';
import { HsnLoginDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/hsn-login.dto';
import { RequstResetPasswordDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/request-reset-password.dto';
import { ResendConfirmationDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/resend-confirmation.dto';
import { UsatLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/usat-login.dto';
import { UserProfileForSocialDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile-for-social.dto';
import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import { SessionStorage } from '@arkadium/eagle-user-client/dist/types/session-storage';
import {
  checkIfSlugInList,
  getOriginUrlForIframe,
  hostnameToArenaDomain,
  isServer,
  sendPostMessage,
  TEagleErrorRegistrationResponse,
} from '../../utils';
import { environment } from '../config/environment';
import { DEFAULT_AVATAR, IframeMessageTypes } from '../models/Enums';
import { EagleUser } from '../models/User';
import { UserAuthStatus } from '../models/UserAuthStatus';
import { ELoginSteps, setLoginModal, setLoginOpen } from '../store/ducks/modal';
import { loginUser } from '../store/ducks/user';
import { setUserAuthStatus } from '../store/ducks/userAuthStatus';
import { AppInsightsAnalytics } from './Analytics/AppInsights';
import { EagleLevelupService } from './EagleLevelupService';
import { LocalStorageService } from './LocalStorage';
import { OpenWebService } from './OpenWebService';

export type ResponseError = {
  name: string;
  response: ReadableStream;
  body: {
    details: Record<any, any>;
    statusCode: number;
  };
};

enum Events {
  onAuthStateChanged = 2,
}

export enum EmailCodes {
  REGISTER_CONFIRMATION_CODE = 'account_confirmation_code',
  RESET_PASSWORD_CODE = 'request_reset_password_code',
  EMAIL_CHANGE_CONFIRMATION_CODE = 'email_change_confirmation_code',
}

export enum PaymentQueryParams {
  LOGOUT_CODE = 'subsLogout',
}

export enum AuthType {
  EmailPassword = 'EmailPassword',
  Facebook = 'Facebook',
  Google = 'Google',
  HSN = 'HSN'
}

export class EagleLoginProvider {

  public static getFacebookIframeUrl(fbAppId: string) {
    let fbLoginUrl = environment.FB_BUTTON_PATH;

    if (fbAppId) {
      fbLoginUrl = fbLoginUrl + '?fbid=' + fbAppId;
    }

    return fbLoginUrl;
  }

  public static getGoogleIframeUrl() {
    return environment.GOOGLE_BUTTON_PATH;
  }

  public authApi: ApiGateway;
  public paramsCheck: ApiGatewayInitParams;
  private api: ApiSet;

  // workaround for correct getting tokens from local storage
  private authStateChangedCounter = 0;

  private BYPASS_CAPTCHA_TOKEN = '9332339f-c2eb-4fb6-92e9-1f8a332dd57b';
  private BYPASS_CAPTCHA_DOMAINS = [
    'arena51-dev.arkadiumarena.com',
    'arena51-dev2.arkadiumarena.com',
    'arena51-staging.arkadiumarena.com',
    'games-dev.arkadiumarena.com',
    'games-staging.arkadiumarena.com',
  ];

  constructor(isParent) {
    if (isServer) {
      return;
    }

    const params: ApiGatewayInitParams = {
      // Base url of Eagle API
      server: new URL(environment.EAGLE_USER_API),
      sessionStorageInitParams: {
        broadcastToIframe: isParent,
        receiveInIframe: !isParent,
      },
    };

    this.paramsCheck = params;

    this.authApi = new ApiGateway(params);
    this.authStateChangedListenerAdd();
    this.authApi.checkAuthorization();
    this.authApi.getAuthApi(Api.v1).then((res) => (this.api = res));

    // Overwrite since game will use it
    this.authApi.openWidget = () => sendPostMessage({ type: IframeMessageTypes.OPEN_LOGIN_POPUP });
    this.authApi.closeWidget = () => sendPostMessage({ type: IframeMessageTypes.CLOSE_LOGIN_POPUP });
  }

  public errorCodeToEnum(errorCode: number): string {
    return Object.keys(UserApiErrorV1).find((key) => UserApiErrorV1[key] === errorCode);
  }

  public extractErrorCode(error: ResponseError): number {
    let result = 400;

    if (error.body?.details && error.body?.details[0] && error.body?.details[0]?.ErrorCode) {
      result = error.body?.details[0]?.ErrorCode;
    } else if (error.body?.statusCode) {
      result = error.body?.statusCode;
    }

    return result;
  }

  public extractErrorEmail(error: ResponseError): string {
    let result = '';

    if (error.body?.details && error.body?.details[0] && error.body?.details[0]?.Data?.email) {
      result = error.body.details[0].Data.email;
    }

    return result;
  }

  public manageErrorsEmailVsPass(res: number): TEagleErrorRegistrationResponse {
    const passwordEnums = ['PasswordTooEasy'];
    const codeEnum = this.errorCodeToEnum(res);
    const message = {
      email: '',
      password: '',
    };

    if (passwordEnums.indexOf(codeEnum) !== -1) {
      message.password = String(res);
    } else {
      message.email = String(res);
    }

    return message;
  }

  public authStateChangedListenerAdd = () => {
    this.authApi.addEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
  }

  public authStateChangedListenerRemove = () => {
    this.authApi.removeEventListener(Events.onAuthStateChanged, this.handleAuthStateChanged);
  }

  public handleAuthStateChanged = () => {
    this.authStateChangedCounter += 1; // the first time always shows false

    // we need to track the second value
    if (this.authStateChangedCounter === 2) {
      const hasAuthToken = localStorage.getItem('eagle-access-token');

      if (hasAuthToken) {
        this.loadUser().then(this.authStateChangedListenerRemove);
      }
    }
  }

  public logout = () => {
    this.authApi.logout();
  }

  public changePassword(data: ChangePasswordDTO, successCallback: () => void, errorCallback: (arg: number) => void) {
    if (this.isBypassCaptchaToken()) {
      data.captchaToken = this.BYPASS_CAPTCHA_TOKEN;
    }

    return this.api.auth
      .changePassword(data)
      .then(successCallback)
      .catch((err) => {
        console.debug(err);
        errorCallback(this.extractErrorCode(err));
      });
  }

  public async loginViaEmail(data: EmailLoginDTO): Promise<void | EagleUser> {
    const api = await this.authApi.getAuthApi(Api.v1);

    if (this.isBypassCaptchaToken()) {
      data.captchaToken = this.BYPASS_CAPTCHA_TOKEN;
    }

    try {
      await api.auth.loginViaEmail(data);
      return this.loadUser();
    } catch (err) {
      console.error(err);
      throw this.extractErrorCode(err);
    }
  }

  public loginViaHsn = async (data: HsnLoginDto) => {
    const store = (window as any).STORE;
    const api = await this.authApi.getAuthApi(Api.v1);

    try {
      await api.auth.loginViaHsn(data);
      await this.loadUser();
    } catch (err) {
      const errCode = this.extractErrorCode(err);
      const email = this.extractErrorEmail(err);

      if (errCode === 1018) {
        store.dispatch(setLoginModal({
          isOpen: true,
          step: ELoginSteps.AFTER_SIGN,
          email,
        }));
        store.dispatch(setLoginOpen(true));
      }

      AppInsightsAnalytics.trackAppError(err, {
        data: 'loginViaHsn()',
        errorCode: {
          errorCode: errCode,
          errorEmail: email,
          isRegistered: {},
        },
      });
      console.error(err);
      throw errCode;
    }
  }

  public loginViaFacebook = async (data: FacebookLoginDTO) => {
    const api = await this.authApi.getAuthApi(Api.v1);

    try {
      const result = await api.auth.loginViaFacebook(data);

      await this.loadUser();
      return result;
    } catch (err) {
      console.error(err);
      const errorForAnalytics = {
        errorCode: this.extractErrorCode(err),
        errorEmail: this.extractErrorEmail(err),
        isRegistered: {},
      };

      AppInsightsAnalytics.trackAppError(
        err,
        {
          data: 'loginViaFacebook()',
          errorCode: errorForAnalytics,
        },
      );
      throw this.extractErrorCode(err);

    }
  }

  public loginViaUsat = async (data: UsatLoginDTO) => {
    let res;
    const api = await this.authApi.getAuthApi(Api.v1);

    return api.auth
      .loginViaUsat(data)
      .then(this.loadUser)
      .catch(err => {
        console.error(err);
        res = {
          errorCode: this.extractErrorCode(err),
          errorEmail: this.extractErrorEmail(err),
          isRegistered: {},
        };

        AppInsightsAnalytics.trackAppError(err, {
          data: 'loginViaUsat()',
          errorCode: res,
        });
      });
  }

  public loginViaGoogle = async (data: GoogleLoginDTO) => {
    const api = await this.authApi.getAuthApi(Api.v1);

    if (this.isBypassCaptchaToken()) {
      data.captchaToken = this.BYPASS_CAPTCHA_TOKEN;
    }

    try {
      const result = await api.auth.loginViaGoogle(data);

      await this.loadUser();
      return {
        ...result,
        isRegistered: result.isRegistered ?? false,
      };
    } catch (err) {
      console.error(err);
      const errorForAnalytics = {
        errorCode: this.extractErrorCode(err),
        errorEmail: this.extractErrorEmail(err),
        isRegistered: {},
      };

      AppInsightsAnalytics.trackAppError(
        err,
        {
          data: 'loginViaGoogle()',
          errorCode: errorForAnalytics,
        },
      );
      throw this.extractErrorCode(err);

    }
  }

  public async registerViaEmail(data: EmailRegistrationDTO): Promise<void> {
    const api = await this.authApi.getAuthApi(Api.v1);

    if (this.isBypassCaptchaToken()) {
      data.captchaToken = this.BYPASS_CAPTCHA_TOKEN;
    }

    try {
      await api.auth.registerViaEmail(data);
    } catch (err) {
      const errorCode = this.extractErrorCode(err);

      AppInsightsAnalytics.trackAppError(
        err,
        {
          data: 'registerViaEmail()',
          errorCode,
        },
      );
      throw errorCode;
    }
  }

  public requestResetPassword = async (data: RequstResetPasswordDTO) => {
    const api = await this.authApi.getAuthApi(Api.v1);

    if (this.isBypassCaptchaToken()) {
      data.captchaToken = this.BYPASS_CAPTCHA_TOKEN;
    }

    try {
      await api.auth.requestResetPassword(data);
    } catch (err) {
      console.error(err);
      throw this.extractErrorCode(err);
    }
  }

  public resendConfirmation = async (data: ResendConfirmationDTO): Promise<void> => {
    const api = await this.authApi.getAuthApi(Api.v1);

    if (this.isBypassCaptchaToken()) {
      data.captchaToken = this.BYPASS_CAPTCHA_TOKEN;
    }

    try {
      await api.auth.resendConfirmation(data);
    } catch (err) {
      const errorCode = this.extractErrorCode(err);

      console.error(err);
      AppInsightsAnalytics.trackAppError(
        err,
        {
          data: 'resendConfirmation()',
          errorCode,
        },
      );
      throw errorCode;
    }
  }

  public getUser = async (): Promise<EagleUser> => {
    const api = await this.authApi.getAuthApi(Api.v1);
    const requests: Array<{ status: 'fulfilled' | 'rejected'; value?: any; reason?: any }> =
      await Promise.allSettled([api.managment.getUserProfile(), EagleLevelupService.getLevelupInfo()]);
    const [user, userLevelUp] = requests.map((res) => res.value);

    return user ?
      {
        ...user,
        avatar: user?.avatar || DEFAULT_AVATAR, ...userLevelUp,
      } :
      null;
  }
  public loadUser = (): Promise<EagleUser | void> => {
    const store = (window as any).STORE;
    // in case when we overwrite this variable from iframe for localhost/same domain
    const state = store?.getState?.() || store;

    if (!state) {
      return Promise.resolve();
    }

    if (!state.config.isEagle) {
      return Promise.resolve();
    }

    return this.getUser()
      .then((user) => {
        if (user) {
          sendPostMessage({
            type: IframeMessageTypes.LOGIN_USER,
            payload: user,
          });
          store.dispatch?.(loginUser(user));
          const iframe = document.getElementById('canvas-box') as HTMLIFrameElement;
          const isGameForGameRenderingModule = checkIfSlugInList(
            state.game?.slug,
            state.gameRenderingGamesList,
          );

          iframe?.contentWindow.postMessage(
            {
              type: IframeMessageTypes.UPDATE_LOCAL_STORAGE,
              payload: { ...window.localStorage },
            },
            getOriginUrlForIframe(isGameForGameRenderingModule),
          );
          return user;
        } else {
          sendPostMessage({
            type: IframeMessageTypes.SET_AUTHORIZED_STATUS,
            payload: false,
          });
          store.dispatch?.(setUserAuthStatus(UserAuthStatus.USER_NOT_AUTHORIZED));
        }

        return user;
      })
      .catch((err) => {
        console.error(err);
        sendPostMessage({ type: IframeMessageTypes.LOGOUT_USER });
      });
  }
  public confirmUser = async (data: EmailConfirmationDTO): Promise<void> => {
    const api = await this.authApi.getAuthApi(Api.v1);

    try {
      await api.auth.confirmUser(data);
    } catch (err) {
      console.error(err);
      const errorCode = this.extractErrorCode(err);

      AppInsightsAnalytics.trackAppError(
        err,
        {
          data: 'confirmUser()',
          errorCode,
        },
      );
      throw errorCode;
    }
  }
  public confirmEmailChangeUser = async (data: ChangeEmailConfirmDTO): Promise<void> => {
    const api = await this.authApi.getAuthApi(Api.v1);

    try {
      await api.managment.changeEmailConfirm(data);
    } catch (err) {
      console.error(err);
      const errorCode = this.extractErrorCode(err);

      AppInsightsAnalytics.trackAppError(
        err,
        {
          data: 'confirmEmailChangeUser()',
          errorCode,
        },
      );
      throw errorCode;
    }
  }

  public updateUser = async (data: Partial<UserProfileDTO>): Promise<UserProfileDTO> => {
    const api = await this.authApi.getAuthApi(Api.v1);

    // @ts-ignore because it's bug on Eagle side. updateUser actually
    // returns Promise<UserProfileDTO> not Promise<void>
    return api.managment.updateUser(data).catch((err) => {
      console.error(err);
      AppInsightsAnalytics.trackAppError(err, {
        data: 'updateUser()',
        errorCode: this.extractErrorCode(err),
      });
      const errorCode = this.extractErrorCode(err);

      throw new Error(String(errorCode));
    });
  }
  public changePasswordByRecoveryCode = async (data: ConfirmResetPasswordDTO): Promise<void> => {
    const api = await this.authApi.getAuthApi(Api.v1);

    try {
      await api.auth.confirmResetPassword(data);
    } catch (err) {
      const errorCode = this.extractErrorCode(err);

      AppInsightsAnalytics.trackAppError(
        err,
        {
          data: 'confirmResetPassword()',
          errorCode,
        },
      );
      throw errorCode;
    }
  }

  public getUserByEmail = async (email: string): Promise<UserProfileForSocialDto> => {
    const api = await this.authApi.getAuthApi(Api.v1);

    // @ts-ignore because it's bug on Eagle side. updateUser actually
    // returns Promise<UserProfileDTO> not Promise<void>
    return api.managment.checkUserEmail({ email });
  }

  public async getToken(): Promise<string> {
    return this.authApi.getToken();
  }

  public getSessionStorage(): SessionStorage {
    return this.authApi.getSessionStorage();
  }

  public getUserFromStore(): EagleUser {
    const store = (window as any).STORE;

    if (isServer) {
      return null;
    }

    return store.getState().user as EagleUser;
  }

  public isUserLoggedIn(): boolean {
    if (isServer) {
      return false;
    }

    return this.getSessionStorage().isAuthorised();
  }

  private isBypassCaptchaToken = () =>
    this.BYPASS_CAPTCHA_DOMAINS.includes(hostnameToArenaDomain(window.location.hostname))
}

export const EagleLoginService = new EagleLoginProvider(true);

export const updateEagleUser = async (data: Partial<UserProfileDTO>): Promise<UserProfileDTO> => {
  const token = await EagleLoginService.getToken();
  const config = (window as any).STORE.getState().config;
  const spotId = config?.openWebChatId ? config.openWebChatId : LocalStorageService.getItem('openWebChatId');

  if (token && spotId) {
    OpenWebService(data, token, false);
  }

  return EagleLoginService.updateUser(data);
};
