import { createSelector } from 'reselect';
import { UrlService } from '../../services/UrlService';
import { AppState } from '../types';

/** Selectors */
const getRouterPath = (state: AppState) => state.router.location.pathname;
const getArenaOrigin = (state: AppState) => state.arenaOrigin;

export const baseHrefSelector = createSelector(getRouterPath, (path) => UrlService.getBaseHref(path));

export const absoluteBaseHrefSelector = createSelector(getArenaOrigin, getRouterPath, (arenaOrigin, path) => {
    let base = UrlService.getBaseHref(path);

    // Do not insert base if it is not lang
    base = base === '/' ? '' : base;

    return arenaOrigin + base;
});
