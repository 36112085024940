import React from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { PadLockIcon } from '../../../FigmaStyleguide/Icons/PadLockIcon';
import { InputWrapper } from '../InputWrapper/InputWrapper';
import styles from './CardDetails.css';

type CardDetailsProps = {
  register?: any; // need to check ts support for react-hook-form
  errors: FieldErrors;
  setValue: (name: string, value: string) => void;
  setValidation: (isValid: boolean, name: string, message?: string) => void;
  values: FieldValues;
};

export const CardDetails = ({
  errors,
  register,
  setValue,
  setValidation,
  values,
}: CardDetailsProps) => {
  return (
    <>
      {/* id added for smooth scrolling */}
      <div className={styles.mainStepHeading} id="paymentFieldsRef">
        <h2>
          <strong>2.</strong>
          &nbsp;
          <I18nText keyName="PURCHASE_PAGE.CARD_DETAILS_TITLE"/>
        </h2>
        <PadLockIcon className={styles.padlockIcon}/>
      </div>
      <InputWrapper
        ariaInvalid={errors.first_name ? 'true' : 'false'}
        register={register}
        name="first_name"
        type="text"
        label="First Name"
        errors={errors}
        isValid={!errors?.first_name}
        errorMessage={errors?.first_name?.message}
        description={'Omit your middle name or initial'}
        descriptionId="firstNameDescription"
        placeholder="JANE"
        dataRecurly="first_name"
        value={values.first_name}
      />
      <InputWrapper
        ariaInvalid={errors.last_name ? 'true' : 'false'}
        register={register}
        name="last_name"
        type="text"
        label="Last Name"
        errors={errors}
        isValid={!errors?.last_name}
        errorMessage={errors?.last_name?.message}
        placeholder="SMITH"
        dataRecurly="last_name"
        value={values.last_name}
      />
      <InputWrapper
        name="cardNumber"
        label="Card Number"
        errors={errors}
        errorMessage="Please enter a valid credit card number"
        description={`Don't include any dashes`}
        placeholder="0000 0000 0000 0000"
        creditInput
        dataRecurly="cardNumber"
        setValue={setValue}
        setValidation={setValidation}
      />
      <InputWrapper
        name="expiration date"
        label="Expiration Date"
        errors={errors}
        errorMessage={'Please enter a valid date'}
        description={`Enter the expiration date as it appears on your card`}
        placeholder="YY"
        creditInput
        dataRecurly="expirationDate"
        setValue={setValue}
        setValidation={setValidation}
      />
      <InputWrapper
        name="cvv"
        label="Security Code"
        errors={errors}
        errorMessage={errors?.cvv?.message}
        placeholder="CVC/CVV"
        creditInput
        dataRecurly="cvv"
        setValue={setValue}
        setValidation={setValidation}
        description={`Check the back of your card
                for a 3-digit code.
                For American Express, look for
                a 4-digit code on the front
                of your card.`}
      />
    </>
  );
};
