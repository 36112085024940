import classnames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  TEagleErrorRegistrationResponse,
  TEmailField,
  TErrorValidation,
  TGdprUserDataField,
  TNicknameField,
  TPasswordField,
  validateEaglePassword,
  validateEmail,
  validateGdprUserData,
} from '../../../../utils';
import { ESignInUpButtonTheme, SignInUpButton } from '../../../atoms/Buttons/SignInUpButton';
import { CheckboxInput, ECheckboxInputFields } from '../../../atoms/Inputs/CheckboxInput';
import { ETextInputFields, ETextInputOrdering, ETextInputTypes, TextInput } from '../../../atoms/Inputs/TextInput';
import { AuthProvider } from '../../../models/Login';
import { EagleLoginService } from '../../../services/EagleLoginService';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import { RECAPTCHA_ACTIONS, RECAPTCHA_ACTIONS_TYPE, RECAPTCHA_MODES } from '../../ChallengeCaptcha/hooks/useCaptcha';
import { ErrorsList, INITIAL_EAGLE_ERRORS_EMAIL } from '../ErrorsList';
import commonStyles from '../LoginPopup.css';
import {
  CallToActionText,
  PasswordRequirements,
  PopupDescription,
  PopupTitle,
} from '../PopupDumbComponents/PopupDumbComponents';
import styles from './RegisterForm.css';
import { AnalyticsGeneral } from '../../../services/Analytics/AnalyticsGeneral';

type TProps = {
  openLoginForm: () => void;
  openAfterSignForm: (isThankYouForm?: boolean) => void;
  arenaDomainURL: URL;
  arenaLocale: string;
  onRegister: (eventType: AuthProvider, email: string) => void;
  tWithPrefix: WithTranslation['t'];
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  captchaToken: string;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
};
const RegisterForm = React.memo(
  ({
    tWithPrefix,
    openLoginForm,
    openAfterSignForm,
    arenaDomainURL,
    arenaLocale,
    onRegister,
    getCaptchaToken,
    captchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
  }: TProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordTouched, setPasswordTouched] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [gdprEmailMarketing, setGdprEmailMarketing] = useState<boolean>(false);
    const [gdprUserData, setGdprUserData] = useState<boolean>(false);
    const [errors, setErrors] = useState<
      Array<TErrorValidation<TEmailField | TPasswordField | TNicknameField | TGdprUserDataField>>
    >([]);
    const [eagleErrors, setEagleErrors] = useState<TEagleErrorRegistrationResponse>(INITIAL_EAGLE_ERRORS_EMAIL);

    useEffect(() => {
      void fetchRegister(captchaToken);
    }, [captchaToken]);

    const fetchRegister = async (token: string) => {
      if (token) {
        try {
          await EagleLoginService.registerViaEmail({
            email,
            password,
            emailRetrievalConsent: gdprEmailMarketing,
            registrationPlaceUrl: arenaDomainURL,
            user: {
              languageId: arenaLocale.toUpperCase(),
            },
            registrationAppId: arenaDomainURL.hostname,
            captchaToken: token,
            captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
          });
          handleRegistrationSuccess();
        } catch (err) {
          handleRegistrationError(err);
        }
      }
    };
    const handleEmail = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim());
    const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value.trim());
      setPasswordTouched(true);
    };
    const handleConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value);
    const handleGdprUserData = () => setGdprUserData(!gdprUserData);
    const handleGdprEmailMarketing = () => setGdprEmailMarketing(!gdprEmailMarketing);
    const handleClickRegisterButton = () => {
      setEagleErrors(INITIAL_EAGLE_ERRORS_EMAIL);
      const {
        isValid: isEmailValid,
        errors: emailErrors,
      } = validateEmail(email);
      const {
        isValid: isPasswordValid,
        errors: passwordErrors,
      } = validateEaglePassword(password, confirmPassword);
      const {
        isValid: isGdprUserDataValid,
        errors: gdprUserDataErrors,
      } = validateGdprUserData(gdprUserData);
      const isValid = isEmailValid && isPasswordValid && isGdprUserDataValid;

      if (isValid) {
        setIsLoading(true);
        getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_UP);
      }

      setErrors([...emailErrors, ...passwordErrors, ...gdprUserDataErrors]);
    };
    const handleRegistrationSuccess = () => {
      setIsLoading(false);
      clearCaptchaData();
      onRegister(AuthProvider.arkadium, email);
      openAfterSignForm();
      AnalyticsGeneral.createAccountGA();
    };
    const handleRegistrationError = (err: number) => {
      if (err === 1023) {
        dispatch(setShowRecaptcha(true));
      } else {
        clearCaptchaData();
        setIsLoading(false);
        setEagleErrors(EagleLoginService.manageErrorsEmailVsPass(err));
      }
    };

    return (
      <>
        {!showChallengeRecaptcha && (
          <>
            <div className={commonStyles.popupHeader}>
              <PopupTitle isEagle text={tWithPrefix('REGISTER_NOW')}/>
              <PopupDescription isEagle text={tWithPrefix('REGISTER_NOW_DESCRIPTION')}/>
            </div>
            <div
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClickRegisterButton();
                }
              }}
              role="presentation"
              className={classnames(commonStyles.defaultContentWrapper, commonStyles.eagle)}
            >
              <ErrorsList tWithPrefix={tWithPrefix} errors={errors} eagleErrors={eagleErrors}/>
              <div>
                <TextInput
                  field={ETextInputFields.EMAIL}
                  errors={errors}
                  placeholder={tWithPrefix('YOUR_EMAIL')}
                  type={ETextInputTypes.TEXT}
                  value={email}
                  onChange={handleEmail}
                  ordering={ETextInputOrdering.FIRST}
                />
                <TextInput
                  field={ETextInputFields.PASSWORD}
                  errors={errors}
                  placeholder={tWithPrefix('CREATE_PASSWORD')}
                  type={ETextInputTypes.PASSWORD}
                  value={password}
                  onChange={handlePassword}
                  ordering={ETextInputOrdering.LAST}
                />
                <PasswordRequirements
                  password={password}
                  touched={passwordTouched}
                  tWithPrefix={tWithPrefix}
                />
                <TextInput
                  field={ETextInputFields.PASSWORD}
                  errors={errors}
                  placeholder={tWithPrefix('REPEAT_PASSWORD')}
                  type={ETextInputTypes.PASSWORD}
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  ordering={ETextInputOrdering.ONLY_ONE}
                />

                <div>
                  <a
                    href="https://www.arkadium.com/arkadium-player-agreement.pdf"
                    target="_blank"
                    className={commonStyles.termConditions}
                  >
                    Terms and conditions
                  </a>
                </div>
                <div className={classnames(styles.popupCheckboxRow, styles.eagle)}>
                  <CheckboxInput
                    isEagle
                    errors={errors}
                    checked={gdprUserData}
                    onChange={handleGdprUserData}
                    field={ECheckboxInputFields.GDPR}
                    text={tWithPrefix('CHECKBOX_USER_DATA')}
                  />
                  <CheckboxInput
                    isEagle
                    errors={errors}
                    checked={gdprEmailMarketing}
                    onChange={handleGdprEmailMarketing}
                    field={ECheckboxInputFields.CHECKBOX_MARKETING}
                    text={tWithPrefix('CHECKBOX_MARKETING')}
                  />
                </div>
                <div className={commonStyles.loginBtnRow}>
                  <SignInUpButton
                    isEagle
                    isRegisterForm
                    isLoading={isLoading}
                    onClick={handleClickRegisterButton}
                    text={tWithPrefix('REGISTER')}
                    theme={ESignInUpButtonTheme.EAGLE_GREEN}
                  />
                </div>
                <CallToActionText
                  isEagle
                  actionText={tWithPrefix('ALREADY_HAVE_AN_ACCOUNT')}
                  buttonText={tWithPrefix('LOG_IN')}
                  onClick={openLoginForm}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

RegisterForm.displayName = 'RegisterForm';
export { RegisterForm };
