import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DesktopQuery } from '../../atoms/Layout/Responsive';
import { Link } from '../../atoms/Link/Link';
import { ArkadiumLogoMini } from '../../atoms/Logos/ArkadiumLogo';
import { ICON_SIZES, THEMES } from '../../consts';
import { ActionTypes, CustomCategories } from '../../models/Analytics';
import { Game } from '../../models/Game';
import { DisplayAd } from '../../molecules/DisplayAd/DisplayAd';
import { MoreGamesLink } from '../../molecules/MoreGamesLink/MoreGamesLink';
import { GameTile } from '../../molecules/Tiles/GameTile';
import { AnalyticsHomePage } from '../../services/Analytics/AnalyticsHomePage';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './HomeCategory.css';
import { frenchTextFix } from '../../services/EventCategoryService';
import { addArrowBlocks, checkAlreadyScrolledCategory, overflowCheck } from '../Header/Redesign/Utilites/overflowCheck';
import { isServer } from '../../../utils';

type HomeCategoryProps = {
    category: string;
    url: string;
    games: Game[];
    sectionIndex: number;
    isAdsEnabled: boolean;
    themeName: string;
    useExternalImages?: boolean;
};

export const HOME_CATEGORY_MAX_GAMES = 8;

class HomeCategoryBase extends React.PureComponent<HomeCategoryProps & WithTranslation, { gameRows: Game[][] }> {
    isDigitalTurbine: boolean = this.props.themeName === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();
    catElements: any[];
    categoryRef: any;

    constructor(props) {
        super(props);

        this.state = { gameRows: [this.props.games] };
        this.categoryRef = React.createRef();
        this.catElements = [];
        this.handleOverflow = this.handleOverflow.bind(this);
    }

    onResize() {
        if (this.props) {
            const {games} = this.props;
            const gameRows = !isServer && (window as any).innerWidth < 375 ? [games.slice(0, 4)] : [games];

            this.setState({gameRows});
        }
    }

    handleOverflow(turnOn = true) {
        const { current } = this.categoryRef;

        if (!current) {
            return;
        }

        if (turnOn) {
            const targetEls = current.querySelectorAll(`.${styles.content}`);

            if (targetEls && targetEls.length) {
                this.catElements = Array.from(targetEls);
                this.catElements.forEach((target) => {
                    const content = (target as any).parentNode;

                    overflowCheck(target, false, undefined, checkAlreadyScrolledCategory);
                    (target as any).addEventListener('scroll', scrollHandler);
                    // addArrowBlocks(content, `.${styles.content}`, checkAlreadyScrolledCategory);
                });
            } else if (!turnOn && this.catElements.length) {
                this.catElements.forEach((target) => (target as any).removeEventListener('scroll', scrollHandler));
            }

            function scrollHandler(e) {
                e.stopPropagation();
                const target = e.currentTarget;

                setTimeout(() => checkAlreadyScrolledCategory(target), 400);
            }
        }
    }

    componentDidMount() {
        this.onResize();
        this.handleOverflow();
        window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate() {
        this.handleOverflow();
    }

    componentWillUnmount() {
        this.handleOverflow(false);
        window.removeEventListener('resize', this.onResize);
    }

    renderCategoryLink = () => {
        const { t, category, url, sectionIndex, i18n } = this.props;
        const title = i18n.exists(`CATEGORY_TITLE_${category.toUpperCase()}`)
            ? t(`CATEGORY_TITLE_${category.toUpperCase()}`)
            : category;
        const CategoryLink = (
            <Link data-element-description="section title" to={url}>
                <CategoryTitle onClick={() => this.onCategoryClick(category, sectionIndex)}>{title}</CategoryTitle>
            </Link>
        );

        if (!this.isDigitalTurbine) {
            return CategoryLink;
        }

        return (
            <LinkAndPoweredByWrapper>
                {CategoryLink}
                <ByArkadium>
                    <By>by</By>
                    <ArkadiumLogoMini size={ICON_SIZES.SMALL} />
                </ByArkadium>
            </LinkAndPoweredByWrapper>
        );
    }

    render() {
        const { games, category, url, sectionIndex, isAdsEnabled, themeName, useExternalImages } =
            this.props;
        const { gameRows } = this.state;

        return (
            <div
                ref={this.categoryRef}
                data-element-description={`section ${category}`}
            >
                <Header isAdsDisabled={!isAdsEnabled}>
                    {this.renderCategoryLink()}
                    <MoreGamesLink
                        url={url}
                        onClick={() => this.onCategoryClick(category, sectionIndex)}
                        themeName={this.props.themeName}
                    />
                </Header>

                <Content>
                    {gameRows.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                            <Tiles isAdsDisabled={!isAdsEnabled}>
                            {row.map((game) => (
                                <TileWrapper key={game.slug} isDigitalTurbine={this.isDigitalTurbine}>
                                    <GameTile
                                        onTileClick={(slug) => this.onTileClick(slug, category)}
                                        game={game}
                                        themeName={themeName}
                                        useExternalImages={useExternalImages}
                                    />
                                </TileWrapper>
                            ))}
                            </Tiles>

                            {isAdsEnabled && (
                                <Ads>
                                    <AdWrapper>
                                        <DesktopQuery>
                                            <DisplayAd
                                                componentId={`ark_display_c_${sectionIndex}_ad_${rowIndex}`}
                                                dataElementDescription="ark-display-section"
                                                dimensions={[[300, 250]]}
                                            />
                                        </DesktopQuery>
                                    </AdWrapper>
                                </Ads>
                            )}
                            <Break />

                            {
                                // If last row
                                rowIndex === gameRows.length - 1 && <SpacingTile />
                            }
                        </React.Fragment>
                    ))}
                </Content>
            </div>
        );
    }

    private onTileClick(slug: string, category: string) {
        AnalyticsHomePage.tilesSections(ActionTypes.CLICK, CustomCategories.PROMO_BOX_SLOT, `${category}|${slug}|yes`);
    }

    private onCategoryClick(category: string, index: number) {
        AnalyticsHomePage.tilesSections(ActionTypes.CLICK, CustomCategories.PROMO_BOX, `${category}|${index}|yes`);
    }
}

const Tiles = ({ isAdsDisabled, ...props }: any) => (
    <div
        className={classNames({ [styles.adsDisabled]: isAdsDisabled }, styles.tiles)}
        {...props}
    />
);
const Ads = (props: any) => <div className={styles.ads} {...props} />;
const Header = ({ isAdsDisabled, ...props }: any) => (
    <div
        className={classNames({ [styles.adsDisabled]: isAdsDisabled }, styles.header)}
        {...props}
    />
);
const ByArkadium = (props: any) => <p className={styles.byArkadium} {...props} />;
const LinkAndPoweredByWrapper = (props: any) => <div className={styles.linkAndPoweredBy} {...props} />;
const By = (props: any) => <span className={styles.by} {...props} />;
const CategoryTitle = (props: any) => (
    <h2 className={styles.categoryTitle} {...props}>
        {frenchTextFix(props.children)}
    </h2>
);
const Content = (props: any) => <div className={classNames('row', styles.content)} {...props} />;
const Break = (props: any) => <div className="d-none d-lg-block w-100" {...props} />;
const TileWrapper = ({ isDigitalTurbine, ...props }: any) => (
    <div
        className={classNames('col-md', styles.tileWrapper, { [styles.digitalTurbine]: isDigitalTurbine })}
        {...props}
    />
);
const SpacingTile = (props: any) => <div className={styles.spacingTile} {...props} />;
const AdWrapper = (props: any) => <div className={`d-none d-lg-block col-auto ${styles.adWrapper}`} {...props} />;

export const HomeCategory = withTranslation()(HomeCategoryBase);
