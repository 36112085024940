import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { HistoryIcon } from '../../atoms/Icons/HistoryIcon';
import { Game } from '../../models/Game';
import { GamesList } from '../GamesList/GamesList';
import styles from './RecentlyPlayed.css';

type RecentlyPlayedProps = {
    games: Game[];
    themeName: string;
};

export const RecentlyPlayedComponent = React.memo(({ games, themeName, t }: RecentlyPlayedProps & WithTranslation) => {
    return (
        <Container>
            <Title>
                <TitleIcon /> {t('RECENTLY_PLAYED')}
            </Title>
            <Games>
                <GamesList games={games} themeName={themeName} columnClass={'col-sm-4 col-6'} isTextDisabled={true} />
            </Games>
        </Container>
    );
});

const Container = (props: any) => <div className={styles.Container} {...props} />;
const Title = (props: any) => (
    <h3 className={styles.Title} {...props}>
        {props.children}
    </h3>
);
const TitleIcon = (props: any) => <HistoryIcon className={styles.TitleIcon} {...props} />;
const Games = (props: any) => <div className={styles.Games} {...props} />;

export const RecentlyPlayed = withTranslation()(RecentlyPlayedComponent);
