import { environment } from '../config/environment';

export const prefixGems = environment?.RECURLY_PLAN_PREFIX;
const suffixGemsAdvantage = '_advantage';

export const RecurlyGemsItemNames = ['5000_gem', '2400_gem', '1120_gem', '600_gem', '350_gem', '90_gem'];
export const gemsSkuList = RecurlyGemsItemNames.map((itemName) => `${prefixGems}${itemName}`);

export enum RecurlyGemsRibbonLabels {
    BEST_VALUE = 'Best Value',
    MOST_POPULAR = 'Most Popular',
}

export const RecurlyGemPackItems = () => {
    const subscribersGemPackItems = {};
    const registeredGemPackItems = {};

    RecurlyGemsItemNames.forEach((gemPackName) => generateGemPackItem(subscribersGemPackItems, true, gemPackName));
    RecurlyGemsItemNames.forEach((gemPackName) => generateGemPackItem(registeredGemPackItems, false, gemPackName));
    const gemPackItems = {
        forSubscribers: subscribersGemPackItems,
        forRegistered: registeredGemPackItems,
    };

    return gemPackItems;
};

function generateGemPackItem(linkToGemPackObj, isForSubscribers, gemPackName): void {
    const packName = generatePackName(gemPackName); // used for texts on purchase page and as keys of chosen pack in store
    const gemsNumber = packName.split(' ')[0];

    linkToGemPackObj[generateCurrentPackName(gemPackName)] = {
        key: packName,
        plan: `${prefixGems}${gemPackName}${isForSubscribers ? suffixGemsAdvantage : ''}`,
        title: packName, // reversed title and description texts as for design idea
        description: 'Gems', // used in purchase summary block
        gemsAmount: gemsNumber,
    };
}

export function generateCurrentPackName(gemPackName: string): string {
    return `${prefixGems}${gemPackName}`;
}

export function generatePackName(gemPackName: string): string {
    return gemPackName
        .replace(new RegExp(`^${prefixGems}`, 'gi'), '')
        .replace('_', ' ')
        .replace('g', 'G');
}
