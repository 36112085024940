import { createAction, createReducer } from 'redux-act';

import { PrerollVariations } from '../../services/ABTestService';

export interface ABTestsState {
    prerollVariation?: PrerollVariations;
}

export const setABTests = createAction<ABTestsState>('set AB Tests');

const reducer = createReducer<ABTestsState>({}, {});

reducer.on(setABTests, (_state, payload: {}) => Object.assign({}, _state, payload));

export default reducer;
