import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from '../../atoms/Link/Link';
import { ActionTypes } from '../../models/Analytics';
import { KeysEnum } from '../../models/Enums';
import { LoginCaller } from '../../models/Login';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { SSOService } from '../../services/SSOService';
import { AppState } from '../../store/types';
import styles from './AdDivider.css';
import { User } from '../../models/User';
import { CookieStorageService } from '../../services/CookieStorage';
import { ELoginSteps, setLoginModal } from '../../store/ducks/modal';
import { getAssetUrl } from '../../../utils';

type AdDividerProps = {
    userAuthStatus: UserAuthStatus;
    dispatch: any;
    config: any;
    user: User;
};

class AdDividerBase extends React.PureComponent<AdDividerProps & WithTranslation, any> {
    constructor(props) {
        super(props);
        this.onResize = this.onResize.bind(this);
    }

    componentDidUpdate(prevProps: AdDividerProps) {
        const { userAuthStatus } = this.props;

        if (prevProps.userAuthStatus !== userAuthStatus && userAuthStatus === UserAuthStatus.USER_NOT_AUTHORIZED) {
            AnalyticsGeneral.loginAI(ActionTypes.IMPRESSION, LoginCaller.RightRail);
        }
    }

    // tslint:disable-next-line:no-empty
    onResize() {}

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onLoginClick = () => {
        const ssoUrl = SSOService.getLoginPageUrl(this.props.config, window.location.href);

        if (ssoUrl) {
            CookieStorageService.set(KeysEnum.arkStateReturnUrl, window.location.href);
            window.location.href = ssoUrl;
        } else {
            this.props.dispatch(
                setLoginModal({
                    isOpen: true,
                    step: ELoginSteps.LOGIN,
                    caller: LoginCaller.RightRail,
                }),
            );
        }
    }

    render() {
        const {
            t,
            userAuthStatus,
        } = this.props;
        const isLoggedIn = userAuthStatus === UserAuthStatus.USER_AUTHORIZED;
        const AdDividerToggler = () => (
            <Container>
                <Image src={`${getAssetUrl()}/images/ad-divider-${isLoggedIn ? 'logged' : 'anonymous'}.png`} alt="login button"/>
                {isLoggedIn ? (
                    <ProfileLink to={`/${t('ROUTES.PROFILE')}`}>{t('GO_TO_YOUR_PROFILE')}</ProfileLink>
                ) : (
                    <LoginButton onClick={this.onLoginClick}>{t('LOG_IN_TO_SAVE_YOUR_SCORES')}</LoginButton>
                )}
            </Container>
        );

        return <AdDividerToggler/>;
    }
}

const Container = (props: any) => <div className={styles.container} {...props} />;
const Image = (props: any) => <img alt={props.alt} className={styles.image} {...props} />;
const LoginButton = (props: any) => <button className={styles.loginOrProfileButton} {...props} />;
const ProfileLink = (props: any) => <Link className={styles.loginOrProfileButton} {...props} />;
const AdDividerTranslated = withTranslation()(AdDividerBase);

export const AdDivider = connect((state: AppState) => ({
    userAuthStatus: state.userAuthStatus,
    config: state.config,
    user: state.user,
}))(AdDividerTranslated);
