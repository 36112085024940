export enum LoginCaller {
    TopNav = 'TopNav',
    GameEnd = 'GameEnd',
    RightRail = 'RightRail',
    Leaderboard = 'Leaderboard',
    OpenWeb = 'OpenWeb',
}

export enum AuthProvider {
    facebook = 'Facebook',
    google = 'Google',
    arkadium = 'Arkadium'
}

export enum LoginState {
    Login = 'Login',
    GDPR = 'GDPR'
}
