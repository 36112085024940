class WidgetSizeService {
    getScreenSize(): string {
        const { width, height } = window.screen;

        return `${width}x${height}`;
    }

    getWindowSize(): string {
        const { innerWidth, innerHeight } = window;

        return `${innerWidth}x${innerHeight}`;
    }

    getGameSize(): string {
        const gameContainer = document.querySelector('[data-element-description="game"] > *');

        if (!gameContainer) {
            return `Not defined`;
        }

        const { clientWidth, clientHeight } = gameContainer;

        return `${clientWidth}x${clientHeight}`;
    }
}

export const widgetSizeService = new WidgetSizeService();
