import { createAction, createReducer } from 'redux-act';
import { TLightboxPromotions } from '../../models/LightboxPromotions';

export const setLightboxPromotions = createAction<TLightboxPromotions>('set lightbox promotions');

const reducer = createReducer<TLightboxPromotions>({}, null);

reducer.on(setLightboxPromotions, (_state, payload: TLightboxPromotions) => payload);

export default reducer;
