import * as React from 'react';

export function overflowCheck(
    el,
    isVert = false,
    callback = overflowDefaultCallback,
    addedCallback = checkAlreadyScrolled
) {
    const hasOverflow = isVert ? el.scrollHeight > el.clientHeight : el.scrollWidth > el.clientWidth;

    if (callback) {
        callback(hasOverflow, el, addedCallback);
    }
}

export function overflowDefaultCallback(hasOverflow, el, callback) {
    // some markup changes => el === el.parentNode
    el.parentNode.classList[hasOverflow ? 'add' : 'remove']('__overflown');
    checkAlreadyScrolled(el);
    el.addEventListener('scroll', callback);
}

export function checkAlreadyScrolled(el) {
    // some markup changes => el === el.parentNode
    const scrolledRight = '__scrolled_right';
    const scrolledFully = '__scrolled_fully';

    if (!el.parentNode) {
        return;
    }

    if (el.parentNode.querySelector('.search-results')) {
        const offset = 32; // + 235 + 25;

        el.parentNode.classList[el.scrollLeft > offset ? 'add' : 'remove'](scrolledRight);
        el.parentNode.classList[el.scrollLeft + el.clientWidth >= el.scrollWidth - offset ? 'add' : 'remove'](
            scrolledFully
        );
    } else {
        el.parentNode.classList.remove(scrolledRight);
        el.parentNode.classList.remove(scrolledFully);
    }
}

export function checkAlreadyScrolledCategory(el) {
    // some markup changes => el === el.parentNode
    const scrolledRight = '__scrolled_right';
    const scrolledFully = '__scrolled_fully';
    const offset = 32; // + 235 + 25;

    if (!el.parentNode) {
        return;
    }

    el.parentNode.classList[el.scrollLeft > offset ? 'add' : 'remove'](scrolledRight);
    el.parentNode.classList[el.scrollLeft + el.clientWidth >= el.scrollWidth - offset ? 'add' : 'remove'](
        scrolledFully
    );
}

export const addArrowBlocks = (el, targetSelector = '.search-results', checkCallback = checkAlreadyScrolled) => {
    ['__arrow-left', '__arrow-right'].forEach((cls, index) => {
        if (!el.querySelector(`.${cls}`)) {
            const arrow = document.createElement('div');

            arrow.classList.add('__arrow');
            arrow.classList.add(cls);
            arrow.innerHTML = `
                <div class='arrow-img'>
                    <svg class='arrow-svg' width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.2226 14.7779H23.3159L16.8573 22.529C16.2279 23.2828 16.331 24.4045 17.0848 25.0321C17.8404 25.6614 18.9604 25.5583 19.5897 24.8045L28.4786 14.1379C28.5479 14.0543 28.5817 13.9566 28.6333 13.8641C28.6759 13.7894 28.7275 13.7254 28.7595 13.6437C28.8395 13.4392 28.8875 13.2241 28.8875 13.0072C28.8875 13.0054 28.8893 13.0019 28.8893 13.0001C28.8893 12.9983 28.8875 12.9948 28.8875 12.993C28.8875 12.7761 28.8395 12.561 28.7595 12.3566C28.7275 12.2748 28.6759 12.2108 28.6333 12.1361C28.5817 12.0437 28.5479 11.9459 28.4786 11.8623L19.5897 1.19566C19.2359 0.774331 18.731 0.555664 18.2226 0.555664C17.8208 0.555664 17.4173 0.690775 17.0848 0.968108C16.331 1.59566 16.2279 2.71744 16.8573 3.47122L23.3159 11.2223H2.2226C1.24127 11.2223 0.444824 12.0188 0.444824 13.0001C0.444824 13.9814 1.24127 14.7779 2.2226 14.7779Z" fill="#F9F9F9"/>
                    </svg>
                </div>
            `;
            arrow.onclick = function (ev) {
                ev.stopPropagation();
                // some markup changes => el === querySelector used
                const target = this.parentNode.querySelector(targetSelector);
                const targetRect = target.getBoundingClientRect();
                const targetOffsetLeft = targetRect.x;
                const delta = window.innerWidth - targetOffsetLeft;

                if (target) {
                    target.scrollTo({
                        left: index === 0 ? target.scrollLeft - delta : target.scrollLeft + delta,
                        top: 0,
                        behavior: 'smooth',
                    });
                    setTimeout(() => checkCallback(target), 1000);
                }
            }.bind(arrow);
            el.appendChild(arrow);
        }
    });
};
