import { createAction, createReducer } from 'redux-act';
import { IframeGamesList } from '../../services/IframeGamesService';

export const initIframeGamesList: IframeGamesList = [];

export const setIframeGamesList = createAction<IframeGamesList>('set iframe games list');

const reducer = createReducer<IframeGamesList>({}, initIframeGamesList);

reducer.on(setIframeGamesList, (_state, payload: any = null) => payload);

export default reducer;
