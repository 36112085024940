import React, { forwardRef, memo, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecurly } from '@recurly/react-recurly';
import { FieldValue, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbarData } from '../../store/ducks/layout';
import { setIsValidForm, setRecurlyToken, setStepIndex } from '../../store/ducks/subscription/common';
import { setBillingInfoValues } from '../../store/ducks/subscription/paymentForm';
import { AddressDetails } from './AddressDetails/AddressDetails';
import { CardDetails } from './CardDetails/CardDetails';
import { setBillingDataForTax } from '../../store/ducks/gems';
import debounce from 'lodash/debounce';
import { defaultValues, TFormValue, TFormValues, validationSchema } from './schemas/validationSchema';

export const PaymentForm = memo(
  forwardRef<HTMLFormElement>((_, ref) => {
    const {
      register,
      handleSubmit,
      setValue,
      setError,
      clearErrors,
      watch,
      formState: { errors },
    } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(validationSchema),
      defaultValues,
    });
    const values = watch();
    const setValidation = (valid: boolean, fieldName: TFormValue, message?: string) => {
      valid ?
        clearErrors(fieldName) :
        setError(
          fieldName,
          {
            type: 'manual',
            message,
          },
        );
    };
    const dispatch = useDispatch();
    const billingDataForTax = useSelector((state) => state.billingDataForTax);
    const recurly = useRecurly();
    // Updating request for receiving taxes data for customer's ITEM purchase
    const updateTaxData = (formValues: TFormValues) => {
      const taxBillingData = {
        first_name: 'NamePlaceholder',
        last_name: 'NamePlaceholder',
        postal_code: formValues.postal_code,
        country: formValues.country,
      };
      const isBillingDataEnough = !Object.values(taxBillingData).some((val) => !val);

      if (isBillingDataEnough) {
        return taxBillingData;
      }
    };

    // here is a specific validation due to the fact that there are inputs in iframes and requirements from UX
    // good practice to use isValid from react-hook-form useForm hook
    useEffect(() => {
      const isValidForm = Object.values(values).every((i) => i !== false);

      dispatch(setIsValidForm(isValidForm));
    }, [values]);

    const dispatchUpdatedTaxData = useCallback(
      debounce((taxDataValues) => {
        const updatedTaxData = updateTaxData(taxDataValues);

        if (updatedTaxData && JSON.stringify(updatedTaxData) !== JSON.stringify(billingDataForTax)) {
          dispatch(setBillingDataForTax(updatedTaxData));
        }
      }, 500),
      [],
    );

    useEffect(() => {
      dispatchUpdatedTaxData(values);
    }, [values.postal_code, values.country]);

    const onSubmit = (data: TFormValues) => {
      recurly.token((ref as any).current, (err, token) => {
        if (err) {
          (err as any).fields.forEach((i: FieldValue<any>) => {
            console.log('i', i);
            const formattedName = i.replace(/[0-9]|_/g, ' '); // result: string without number and underscore

            // (e.g. postal code/address instead of postal_code/address1)
            setError(i, {
              type: 'manual',
              message: `Please enter a valid ${formattedName}`,
            });
          });
          dispatch(
            setSnackbarData({
              isOpened: true,
              message: err.code === 'invalid-parameter' ? 'Please update the information flagged below' : err.message,
              type: 'error',
            }),
          );
        } else {
          dispatch(setRecurlyToken(token.id));
          const billingInfo = {
            address1: data.address1,
            city: data.city,
            country: data.country,
            postal_code: data.postal_code,
            creditBrand: data.cardNumber.creditBrand,
            creditLastFour: data.cardNumber.creditLastFour,
            state: data.state,
            year: data.year,
            month: data.month,
          };

          // set these values to show on the 2nd step
          dispatch(setBillingInfoValues(billingInfo));
          dispatch(setStepIndex(1));
        }
      });
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)} id="paymentForm" ref={ref}>
        <input type="submit" hidden/>
        {/*
        recurly is bound to inputs in the form, so we need to create these inputs and pass them the appropriate
        values along with data-recurly
        */}
        <input hidden defaultValue={values.country} data-recurly="country"/>
        <input hidden defaultValue={values.country ? values.state : ''} data-recurly="state"/>
        <CardDetails
          setValidation={setValidation}
          errors={errors}
          register={register}
          setValue={setValue}
          values={values}
        />
        <AddressDetails
          setValidation={setValidation}
          errors={errors}
          register={register}
          setValue={setValue}
          values={values}
        />
      </form>
    );
  }),
);
