import React from 'react';

type iconProps = {
    className?: string;
    strokeWidth?: number;
};

export const AvatarIconCheckmark = React.memo((props: iconProps) => {
    const strokeWidth = props.strokeWidth || 2;

    return (
        <svg
            className={props.className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="11" fill="#017C55" stroke="#F9F9F9" strokeWidth={strokeWidth} />
            <path
                d="M7.76465 11.2043L11.5235 14.8235L16.2352 9.17647"
                stroke="#F9F9F9"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
