import { fetchApiCall } from '../../fetch';
import { environment } from '../config/environment';
import { hostnameToArenaDomain } from '../../utils';

export interface IEventGame {
    slug: string;
    customEventGameName?: string;
    customEventGameThumb?: string;
    customEventGameThumbWebp?: string;
}

export interface IEvent {
    isCategory: boolean;
    eventName: string;
    eventDescription: string;
    eventNameLocales?: {
        en?: string;
        fr?: string;
        it?: string;
        de?: string;
        es?: string;
    };
    eventDescriptionLocales?: {
        en?: string;
        fr?: string;
        it?: string;
        de?: string;
        es?: string;
    };
    eventPromoBanner: any;
    eventPromoBannerExcludes: string[];
    eventCategoryOrder?: number[];
    eventGames: IEventGame[];
    startDate?: number[];
    endDate?: number[];
    eventCustomStyles?: string;
    eventAssetsPrefix?: string;
}

export interface IEventCategory {
    common: IEvent[];
    excludes?: string[];
}

const EVENT_CATEGORY_DATA_PATH = environment.EVENT_CATEGORY_DATA_PATH;

export class EventCategoryService {
    public static getEventCategoryData(): Promise<IEventCategory> {
        return fetchApiCall(EVENT_CATEGORY_DATA_PATH, { timeout: 10 * 1000 }).then((res) => {
            return res.data;
        });
    }

    public static isAppliedToArena(arenaDataRef, eventCategoryData, url): boolean {
        const arenaDomain = hostnameToArenaDomain(url.hostname);
        const eventCatExcludes =
            eventCategoryData?.excludes && eventCategoryData.excludes.length ? eventCategoryData.excludes : [];

        return !(
            (arenaDataRef?.config?.theme?.domain && eventCatExcludes.includes(arenaDataRef.config.theme.domain)) ||
            (arenaDataRef?.config?.theme?.name && eventCatExcludes.includes(arenaDataRef.config.theme.name)) ||
            (arenaDomain && eventCatExcludes.includes(arenaDomain))
        );
    }

    public static categoryTranslated(category, defaultReturn, locale = 'en') {
        return category?.eventCat?.eventNameLocales?.[locale] || category?.eventCat?.eventName || defaultReturn;
    }

    public static isEventUpToDate(EVENT?: IEvent): boolean {
        if (
            !EVENT?.startDate ||
            !EVENT?.endDate ||
            !Array.isArray(EVENT.startDate) ||
            !Array.isArray(EVENT.endDate) ||
            EVENT.startDate.length !== 3 ||
            EVENT.endDate.length !== 3
        ) {
            return true;
        }

        const todayTime = new Date().getTime();
        const dateFromFrontier = (configDate, isStart = true) => {
            if (!configDate || configDate.length !== 3 || !Array.isArray(configDate)) {
                return todayTime;
            }

            const dateArr = configDate.map((num) => num.toString().padStart(2, '0'));
            const dateTime = isStart ? '00:00:00' : '24:00:00';

            return dateArr && dateArr.length === 3
                ? new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}T${dateTime}`).getTime()
                : todayTime;
        };
        const eventStart = EVENT?.startDate;
        const eventStartDate = dateFromFrontier(eventStart);
        const eventEnd = EVENT?.endDate;
        const eventEndDate = dateFromFrontier(eventEnd, false);
        const isEventUpToDate = todayTime > eventStartDate && todayTime < eventEndDate;

        return isEventUpToDate;
    }

    public static mixinConfigCategories(arenaDataRef, eventCategoryData): void {
        if (eventCategoryData && eventCategoryData?.common && eventCategoryData.common.length) {
            const updatedConfigCategories = {
                ...(arenaDataRef?.config?.categories || { homeCategories: [], allCategories: [] }),
            };
            const doFillArr = (arr, val, order) => {
                if (arr && arr.length && arr.length >= order) {
                    arr.splice(order, 0, val);
                } else if (arr) {
                    arr.push(val);
                }
            };

            eventCategoryData.common
                .filter((EVENT) => this.isEventUpToDate(EVENT))
                .forEach((EVENT: IEvent) => {
                    const orders = [
                        EVENT?.eventCategoryOrder[0] || 0, // HomeCategory, on first row by default
                        EVENT?.eventCategoryOrder[1] || 1, // Menu category, after "All games" by default
                    ];

                    EVENT.eventNameLocales = EVENT.eventNameLocales || {};
                    EVENT.eventNameLocales.en =
                        EVENT.eventNameLocales.en.replace(/\'/gi, '_') || EVENT?.eventName.replace(/\'/g, '_') || '';
                    EVENT.eventNameLocales.fr =
                        EVENT.eventNameLocales.fr.replace(/\'/gi, '_') || EVENT?.eventName.replace(/\'/g, '_') || '';
                    EVENT.eventNameLocales.it =
                        EVENT.eventNameLocales.it.replace(/\'/gi, '_') || EVENT?.eventName.replace(/\'/g, '_') || '';
                    EVENT.eventNameLocales.de =
                        EVENT.eventNameLocales.de.replace(/\'/gi, '_') || EVENT?.eventName.replace(/\'/g, '_') || '';
                    EVENT.eventNameLocales.es =
                        EVENT.eventNameLocales.es.replace(/\'/gi, '_') || EVENT?.eventName.replace(/\'/g, '_') || '';
                    EVENT.eventDescriptionLocales = EVENT.eventDescriptionLocales || {};
                    EVENT.eventDescriptionLocales.en =
                        EVENT.eventDescriptionLocales.en || EVENT?.eventDescription || '';
                    EVENT.eventDescriptionLocales.fr =
                        EVENT.eventDescriptionLocales.fr || EVENT?.eventDescription || '';
                    EVENT.eventDescriptionLocales.it =
                        EVENT.eventDescriptionLocales.it || EVENT?.eventDescription || '';
                    EVENT.eventDescriptionLocales.de =
                        EVENT.eventDescriptionLocales.de || EVENT?.eventDescription || '';
                    EVENT.eventDescriptionLocales.es =
                        EVENT.eventDescriptionLocales.es || EVENT?.eventDescription || '';

                    if (!updatedConfigCategories.homeCategories.some(c => c.name === EVENT.eventName)) {
                        doFillArr(
                            updatedConfigCategories.homeCategories,
                            { name: EVENT.eventName, eventCat: EVENT },
                            orders[0]
                        );
                    }

                    Object.keys(EVENT.eventNameLocales).forEach((localeKey) => {
                        if (EVENT.eventNameLocales[localeKey]) {
                            doFillArr(
                                updatedConfigCategories.allCategories,
                                EVENT.eventNameLocales[localeKey],
                                orders[1]
                            );
                        }
                    });
                });

            arenaDataRef.config.categories = updatedConfigCategories;
        }

        arenaDataRef.config.eventCategoryConfig = eventCategoryData;
    }

    public static mixinConfigGamesTags(arenaDataRef, eventCategoryData): void {
        if (eventCategoryData?.common?.length && arenaDataRef?.games) {
            eventCategoryData.common
                .filter((EVENT) => this.isEventUpToDate(EVENT))
                .forEach((eventObject) => {
                    const eventCategoryName = eventObject.eventName;

                    eventObject.eventGames.forEach((eventGame) => {
                        arenaDataRef.games.forEach((arenaGame) => {
                            if (arenaGame.slug === eventGame.slug) {
                                arenaGame.tags.push(eventCategoryName);

                                if (eventObject?.eventNameLocales) {
                                    const locales = [...Object.keys(eventObject.eventNameLocales)];

                                    locales.forEach((locale) => {
                                        if (Boolean(eventObject.eventNameLocales[locale])) {
                                            arenaGame.tags.push(eventObject.eventNameLocales[locale]);
                                        }
                                    });

                                    arenaGame.eventCat = arenaGame.eventCat || {};
                                    arenaGame.eventCat[eventObject.eventName] = { ...eventGame };
                                }
                            }
                        });
                    });
                });
        }
    }

    public static mixinConfigGamesAssets(arenaDataRef, eventCategoryData): void {
        if (eventCategoryData?.common?.length && arenaDataRef?.games) {
            eventCategoryData.common.forEach((eventObject) => {
                eventObject.eventGames.forEach((eventGame) => {
                    arenaDataRef.games.forEach((arenaGame) => {
                        if (arenaGame.slug === eventGame.slug) {
                            const propsToUpdate = Object.keys(eventGame).filter((propName) => propName !== 'slug');

                            propsToUpdate.forEach((prop) => {
                                // overwriting default
                                if (arenaGame.hasOwnProperty(prop) && Boolean(eventGame[prop])) {
                                    arenaGame[prop] = eventGame[prop];
                                }
                            });
                        }
                    });
                });
            });
        }
    }
    public static getEventCatNamesList(fullCategories) {
        const eventCatNamesList = [];

        fullCategories.forEach((fullCat) => {
            eventCatNamesList.push(fullCat?.eventCat?.eventName);
            Object.values(fullCat?.eventCat?.eventNameLocales || {}).forEach((val) =>
                eventCatNamesList.push(((val as any) || '').toLowerCase())
            );
        });

        return eventCatNamesList;
    }
    public static mixinHomeCategories(gamesList, categoryFull) {
        return gamesList.map((game) => {
            if (!categoryFull?.eventCat?.eventGames || !this.isEventUpToDate(categoryFull.eventCat)) {
                return game;
            }

            const gameMix = { ...game };
            const eventGames = categoryFull.eventCat.eventGames.filter((eventGame) => eventGame.slug === game.slug);
            const eventGame = eventGames.length ? eventGames[0] : {};
            const propsToUpdate = Object.keys(eventGame).filter((propName) => propName !== 'slug');

            propsToUpdate.forEach((prop) => {
                // overwriting default
                if (gameMix.hasOwnProperty(prop) && Boolean(eventGame[prop])) {
                    gameMix[prop] = eventGame[prop];
                }
            });
            gameMix.tags = [];

            return gameMix;
        });
    }
    public static mixinGames(gamesList, eventCategoryData, categoryName) {
        const eventDataArr = categoryName
            ? (eventCategoryData?.common || []).filter(
                  (eventObj) =>
                      (Object.values(eventObj?.eventNameLocales || {}).includes(categoryName) ||
                          eventObj?.eventName === categoryName) &&
                      this.isEventUpToDate(eventObj)
              )
            : eventCategoryData?.common || [];

        return gamesList.map((game) => {
            if (!eventDataArr.length) {
                return game;
            }

            const eventObj = eventDataArr[0];
            const gameMix = { ...game };
            const eventGames = eventObj.eventGames.filter((eventGame) => eventGame.slug === game.slug);
            const eventGame = eventGames.length ? eventGames[0] : {};
            const propsToUpdate = Object.keys(eventGame).filter((propName) => propName !== 'slug');

            propsToUpdate.forEach((prop) => {
                // overwriting default
                if (gameMix.hasOwnProperty(prop) && Boolean(eventGame[prop])) {
                    gameMix[prop] = eventGame[prop];
                }
            });
            gameMix.tags = [];

            return gameMix;
        });
    }
}

export const generateEventCustomStyles = (state) =>
    state?.config?.eventCategoryConfig?.eventCustomStyles &&
    state?.config?.eventCategoryConfig?.common &&
    state?.config?.eventCategoryConfig?.common.filter((EVENT) => EventCategoryService.isEventUpToDate(EVENT)).length
        ? state.config.eventCategoryConfig?.eventCustomStyles
        : '';

export const generateEventCustomClass = (state, blockName = 'homepage') =>
    state?.config?.eventCategoryConfig?.eventCustomStyles &&
    state?.config?.eventCategoryConfig?.common &&
    state?.config?.eventCategoryConfig?.common.filter((EVENT) => EventCategoryService.isEventUpToDate(EVENT)).length
        ? ` event-category event-category__${blockName} ` +
          state.config.eventCategoryConfig.common
              .map((event) => '__' + event.eventName.toLowerCase().split(' ').join('-'))
              .join(' ')
        : '';

export const frenchTextFix = (category, isStyle = false) => {
    const isFrench = category.includes('_');

    return isFrench && !isStyle
        ? category.replace('_', "'")
        : isFrench && isStyle
        ? true
        : !isFrench && isStyle
        ? false
        : category;
};
