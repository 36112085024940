import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton } from '../../atoms/Buttons/PrimaryButton';
import { WebGameIcon } from '../../atoms/Icons/WebGameIcon';
import { Link } from '../../atoms/Link/Link';
import { ArkadiumLogoMini } from '../../atoms/Logos/ArkadiumLogo';
import { ImageTypes, ProgressiveImage } from '../../atoms/ProgressiveImage/ProgressiveImage';
import { ICON_SIZES, THEMES } from '../../consts';
import { ActionTypes } from '../../models/Analytics';
import { Game } from '../../models/Game';
import { AnalyticsHomePage } from '../../services/Analytics/AnalyticsHomePage';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './PromoBanner.css';
import { environment } from '../../config/environment';
import { MainBlockPropsExt } from './Redesign/Components/MainBlock';
import { sanitize } from 'isomorphic-dompurify';
import { Helmet } from 'react-helmet-async';

type PromoBannerProps = {
    promoFromConfig: string;
    games: Game[];
    themeName: string;
};

type PromoBannerState = {
    isMobile: boolean;
    bannerImage: HTMLDivElement;
};

const FROM_ADMINSITE = {
    isDescriptionVisible: true,
    isPlayButtonVisible: true,
};

export class PromoBannerBase extends React.PureComponent<
    PromoBannerProps & MainBlockPropsExt & WithTranslation,
    PromoBannerState
> {
    promoGame: Game;

    constructor(props) {
        super(props);
        const { promoFromConfig, games } = this.props;
        const isMobile = DeviceDetector.isMobile();

        this.state = {
            isMobile,
            bannerImage: null,
        };

        const configPromo = promoFromConfig && games.find((game) => game.slug === promoFromConfig);

        this.promoGame = configPromo ? configPromo : games[0];
    }
    componentDidMount() {
        AnalyticsHomePage.promoGame(ActionTypes.IMPRESSION, this.promoGame.slug);
        const contentDiv: Element = document.querySelector('.main-content');

        contentDiv.classList.add(styles.mainContentBg);
    }
    componentWillUnmount() {
        const contentDiv: Element = document.querySelector('.main-content');

        contentDiv.classList.remove(styles.mainContentBg);
    }

    getImageRef = (imageRef) => {
        this.setState({ bannerImage: imageRef });
        const imageMinHeight = 136;
        const imageAspectRatio = 2.7;
        const minWidthToFitHeight = imageMinHeight * imageAspectRatio;
        const imageWidth = parseInt(getComputedStyle(imageRef).width);

        // const newWidth =
        //     imageWidth < minWidthToFitHeight ? minWidthToFitHeight / imageWidth : imageWidth / minWidthToFitHeight;
        if (imageWidth < minWidthToFitHeight) {
            const newWidth = minWidthToFitHeight / imageWidth;
            const newWidthAsProp = `${newWidth * 100}%`;
            // move image to the left to centralize
            const marginLeft = (newWidth * 100 - 100) / 2;
            const margineLeftAsProp = `-${marginLeft}%`;

            console.debug({ newWidth, marginLeft, newWidthAsProp, margineLeftAsProp });
            (imageRef as HTMLDivElement).style.width = newWidthAsProp;
            (imageRef as HTMLDivElement).style.marginLeft = margineLeftAsProp;
            (imageRef as HTMLDivElement).style.minHeight = `${imageMinHeight}px`;
            // (imageRef as HTMLDivElement).style.maxHeight = `${imageMinHeight}px`;
        }
    }
    // remove html tags from string
    getDescriptionText = (text) => (text && typeof text === 'string' ? sanitize(text) : '');

    renderImageBlock = () => {
        const { promoThumbPath, promoThumbPathWebP, assetOriginUrl, customEventGameThumb, customEventGameThumbWebp } =
            this.promoGame;
        const { t } = this.props;
        const thumbOriginUrl = environment.GAMES_THUMBS_BLOB + '/' + this.promoGame.slug + '/';
        const img = thumbOriginUrl + `Game_Tiles/jpeg/760x312.jpg`;
        const webp = thumbOriginUrl + `Game_Tiles/webp/760x312.webp`;
        const section = this.promoGame.isCategory ? t('ROUTES.CATEGORY') : t('ROUTES.GAMES');

        return (
            <>
                <Helmet>
                    <link rel="preload" fetchPriority="high" as="image" href={customEventGameThumbWebp} />
                </Helmet>
                <ImgBlock data-element-description="promo picture">
                    <LinkWrap onClick={(_) => this.onPromoClick()} to={`/${section}/${this.promoGame.alias}`}>
                        <ProgressiveImage
                            imageType={ImageTypes.PROMO}
                            img={img}
                            webp={webp}
                            imgBackFill={assetOriginUrl + promoThumbPath}
                            webpBackFill={assetOriginUrl + promoThumbPathWebP}
                            customEventGameThumb={customEventGameThumb}
                            customEventGameThumbWebp={customEventGameThumbWebp}
                            alt={this.promoGame.name}
                            emitRef={this.getImageRef}
                        />
                    </LinkWrap>
                </ImgBlock>
            </>
        );
    }

    renderDefaultTextBlock = () => (
        <TextBlock>
            {this.renderTitle()}
            {this.renderDescription()}
        </TextBlock>
    )

    renderTextBlockDigitalTurbine = () => {
        return (
            <TextBlock isDigitalTurbine>
                <WebGameIcon />
                {this.renderTitle()}
                {this.renderDescription()}
                <ArkadiumLogoMini size={ICON_SIZES.SMALL} />
            </TextBlock>
        );
    }

    renderTitle = () => <Title data-element-description="promo name">{this.promoGame.name}</Title>;

    renderDescription = () => {
        const { isDescriptionVisible, isPlayButtonVisible } = FROM_ADMINSITE;
        const textContent = this.getDescriptionText(this.promoGame.description);

        return (
            <>
                <Description data-element-description="promo description" visibleOnMobile={isDescriptionVisible}>
                    {textContent}
                </Description>
                {isPlayButtonVisible && this.renderPlayGameButton()}
            </>
        );
    }

    renderPlayGameButton = () => (
        <LinkWrap
            onClick={(_) => this.onPromoClick()}
            to={`/${this.promoGame.isCategory ? this.props.t('ROUTES.CATEGORY') : this.props.t('ROUTES.GAMES')}/${
                this.promoGame.alias
            }`}
        >
            <BtnBlock>
                <PlayBtn data-element-description="promo play button">
                    {this.promoGame.isCategory && this.promoGame.buttonText
                        ? this.promoGame.buttonText
                        : this.props.t('PLAY_NOW')}
                </PlayBtn>
            </BtnBlock>
        </LinkWrap>
    )

    render() {
        if (!this.promoGame) {
            return null;
        }

        const isDigitalTurbine = this.props.themeName === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();

        return (
            <Container>
                <Row>
                    {this.renderImageBlock()}
                    {isDigitalTurbine ? this.renderTextBlockDigitalTurbine() : this.renderDefaultTextBlock()}
                </Row>
            </Container>
        );
    }

    private onPromoClick() {
        AnalyticsHomePage.promoGame(ActionTypes.CLICK, this.promoGame.slug);
    }
}

const Container = (props: any) => <div className={styles.promoContainer} {...props} />;
const LinkWrap = (props: any) => <Link className={styles.link} {...props} />;
const Row = (props: any) => <div className={'row'} {...props} />;
const ImgBlock = (props: any) => <div className={`col-12 col-lg-8 ${styles.imgBlock}`} {...props} />;
const TextBlock = ({ isDigitalTurbine, ...props }: any) => (
    <div
        className={`col-12 col-lg-4 ${classNames(styles.textBlock, {
            [styles.digitalTurbine]: isDigitalTurbine,
        })}`}
        {...props}
    />
);
const Title = (props: any) => (
    <h2 className={styles.promoTitle} {...props}>
        {props.children}
    </h2>
);
const Description = ({ visibleOnMobile, ...props }: any) => <p className={classNames(styles.promoDesc)} {...props} />;
const BtnBlock = (props: any) => <div className={styles.btnBlock} {...props} />;
const PlayBtn = (props: any) => <PrimaryButton className={styles.playBtn} {...props} />;

export const PromoBanner = withTranslation()(PromoBannerBase);
