import React, { Suspense, useState } from 'react';

import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { MiscUtils } from '../../utils/MiscUtils';
import { AppLoader } from '../atoms/AppLoader/AppLoader';
import { environment } from '../config/environment';
import { ErrorBoundary } from '../atoms/ErrorBoundary';
import PurchaseGemsShopTemplate from '../templates/Purchase/PurchaseGemsShop/PurchaseGemsShopTemplate';

export enum PurchasePagesTemplates {
  GEMS_SHOP = 'shop',
}

export const PurchasePage = ({ template }) => {
  let PurchasePageTemplate: () => React.JSX.Element;

  switch (template) {
    case PurchasePagesTemplates.GEMS_SHOP:
      PurchasePageTemplate = PurchaseGemsShopTemplate;
      break;
    default:
      break;
  }

  const [recurlyLoaded, setRecurlyLoaded] = useState<boolean>(false);
  const isLoading = useSelector(({ fullScreenLoading }) => fullScreenLoading);

  // Loading recurly before loading page
  interface ScriptTag {
    src: string;
    addEventListener: (event: string, callback: () => void, options: { once: boolean }) => void;
  }

  interface AddedTags {
    scriptTags: ScriptTag[];
  }

  const recurlyUrl: string = 'https://js.recurly.com/v4/recurly.js';
  const handleChangeClientState = (_: any, addedTags?: AddedTags) => {
    if (!addedTags?.scriptTags) {
      return;
    }

    const foundScript = addedTags.scriptTags.find(({ src }: ScriptTag) => src === recurlyUrl);

    if (foundScript) {
      foundScript.addEventListener('load', () => setRecurlyLoaded(true), { once: true });
    }
  };

  return (
    <>
      <Helmet onChangeClientState={handleChangeClientState}>
        <meta name="robots" content="noindex, nofollow"/>
        <script async src={recurlyUrl}/>
      </Helmet>

      {recurlyLoaded && (
        <ErrorBoundary>
          {isLoading && <AppLoader isFullPage/>}
          {!MiscUtils.isServer && (
            <Suspense fallback={<AppLoader isFullPage/>}>
              <RecurlyProvider
                required={['cvv', 'address1', 'city', 'postal_code', 'state', 'country']}
                publicKey={environment.RECURLY_PUBLIC_KEY}
              >
                <Elements>
                  <PurchasePageTemplate/>
                </Elements>
              </RecurlyProvider>
            </Suspense>
          )}
        </ErrorBoundary>
      )}
    </>
  );
};
