export enum ActionTypes {
    CLICK = 'Click',
    IMPRESSION = 'Impression',
    QUERY = 'Query',
    LOGIN = 'Login',
    AD_BLOCK_DISABLED = 'adBlockDisabled',
    SEE_ALL = 'See_All',
    ANDROID_INSTALL_IMPRESSION = 'Android_Install_Impression',
    CANCEL = 'Cancel',
    INSTALL = 'Install',
    IOS_BOOKMARK_VISIT = 'IOS_Bookmark_Visit',
    ANDROID_BOOKMARK_VISIT = 'Android_Bookmark_Visit',
    VISIT = 'Visit',
    DESKTOP_BOOKMARK_IMPRESSION = 'Desktop_Bookmark_Impression',
    DESKTOP_BOOKMARK_CLICK = 'Desktop_Bookmark_Click',
    AVATAR_AVAILABLE_IMPRESSION = 'avatarAvailableImpression',
    AVATAR_BUY = 'avatarBuy',
    AVATAR_SELECTED = 'avatarSelected',
    DISABLED = 'disabled',
    OTHER = 'Other',
}

export enum AnalyticsGamePlayParams {
    playAgainStart = 'playAgainStart',
    recommendedGameStart = 'recommendedGameStart',
    recPreviousGame = 'recPreviousGame',
}

export enum CustomCategories {
    PROMO_BOX = 'Promo_Box',
    ARENA_PROMO_BOX = 'Arena_Promo_Box',
    PROMO_BOX_SLOT = 'Promo_Box_Slot',
    FTUX_WELCOME = 'FTUX_Welcome',
    DESKTOP_PWA = 'Desktop_PWA',
    DESKTOP_BOOKMARK = 'Desktop_Bookmark',
    IOS_BOOKMARK = 'IOS_Bookmark',
    ANDROID_BOOKMARK = 'Android_Bookmark',
    ANDROID_BOOKMARK_INSTALL = 'Android_Bookmark_Install',
}

export type AnalyticSettingsAdditional = {
    provider: string;
    account: string;
    prefix: string;
};

export class AnalyticSettings {
    account: string;
    accountAlias?: object;
    additionalAccounts?: AnalyticSettingsAdditional[];
    options?: object;
    disableAppInsights?: boolean;
    appInsightsSampleRate?: number | null;

    constructor(data, lang) {
        this.account = data.accountAlias && data.accountAlias[lang] ? data.accountAlias[lang] : data.account;
        this.accountAlias = data.accountAlias;
        this.additionalAccounts = data.additionalAccounts;
        this.options = data.options;
        this.disableAppInsights = !!data.disableAppInsights;
        this.appInsightsSampleRate = data.appInsightsSampleRate || null;
    }
}
