import { CONSTS } from '@arkadium/modules';

export const environment = {
  ENV: 'dev',
  BASE_CDN: 'https://arenacloud.cdn.arkadiumhosted.com',
  BASE_CDN_OVERWRITES: 'https://arenacloud.cdn.arkadiumhosted.com/overwrites-dev',
  ASSETS_CDN: 'https://arenacloud.cdn.arkadiumhosted.com/arena51-assets/canary',
  GAME_RENDERING_IFRAME:
    'https://arenacloud.cdn.arkadiumhosted.com/arkadiummodulesstorage-blob/game-rendering-iframe',
  GAMES_WITH_EAGLE_LIST_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arkadiummodulesstorage-blob/game-rendering-games-list/games-with-eagle-list.json',
  // ads lib
  DISPLAY_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/display/dev2/display-ads.js',
  VIDEO_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/video/dev/video-ads.js',
  // Headers root dir
  ARENA_HEADERS_BLOB: 'https://arenaxstoragedev.blob.core.windows.net/arenax-headers-testing/app/clients',
  // Services
  USER_API: 'https://arenacloud.cdn.arkadiumhosted.com/arenax-51-api-user-dev/api/v1',
  SCORE_API: 'https://arenacloud.cdn.arkadiumhosted.com/arenax-51-api-score-dev/api/v1',
  UUP_SCORE_API: 'https://arenacloud.cdn.arkadiumhosted.com/uup-api-score-dev/api/v1',
  EAGLE_USER_API: 'https://eagle-user-api-dev.arkadiumhosted.com/',
  EAGLE_LEVELUP_API: 'https://arenacloud.cdn.arkadiumhosted.com/eagle-levelup-api-dev/api/v1',
  EAGLE_PAYMENT_API: 'https://eagle-payment-api.uup-aks-dev.arkadiumhosted.com',
  EAGLE_VIRTUAL_ITEMS_API: 'https://eagle-virtual-item-api.uup-aks-dev.arkadiumhosted.com/',
  EAGLE_USER_GAME_DATA_API: CONSTS.EAGLE_USER_GAME_DATA_API_ENDPOINT.dev,

  // Recurly
  RECURLY_BILLING_PAGE: `https://arkadium-test.recurly.com/account/`,
  RECURLY_PUBLIC_KEY: 'ewr1-UsfGfKZUxJUBRuGX7PQnOT',
  RECURLY_PLAN_PREFIX: 'test__',
  RECURLY_GIFT_CARD_PRODUCT_CODE: 'test_gift',

  // ArenaConnect
  FB_BUTTON_PATH: 'https://arenaxstoragedev.blob.core.windows.net/arenax-connect/azure.facebook.next.index.html',
  // workaround bc we don't register dev storage in google auth
  // GOOGLE_BUTTON_PATH: 'https://arenaxstorage.blob.core.windows.net/arenax-connect/azure.google.new.index.html',
  GOOGLE_BUTTON_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-connect/azure.google.new.index.html',
  LOGIN_POPUP:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-connect/51-azure.login-popup.html',
  DEFAULT_GOOGLE_APP_ID: '357011364658-46j1mfak3u1tl958cmhn9b5n9g5jfvpm.apps.googleusercontent.com',
  // Blobs
  GAMES_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-games',
  GAMES_THUMBS_BLOB:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkcom-game-arts',
  X_INDEX_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-index',
  HELP_CONTENT_BLOB: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-help-pages',
  ANALYTICS_SORTING_BLOB:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax51-games-sorting-analytics',
  RELATED_GAMES_BLOB:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax51-related-games-analytics',
  APP_INSIGHTS_SSR_KEY: '4868fc88-9596-43cc-b591-41e8f3d6c2a4', // arena51-appins-dev
  DEFAULT_ADS_TXT_PATH: 'https://arkadsstorage.blob.core.windows.net/arena5/canary/ads.txt',
  GLOBAL_ROBOTS_TXT_PATH: 'https://arenaxstoragedev.blob.core.windows.net/arenax-global-settings/robots/robots.txt',
  REDIRECTS_SETTINGS_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-global-settings/redirects/redirects.json',
  OPENWEB_TOKENS_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-global-settings/openweb/openweb-tokens.json',
  GAME_TEMPLATE_IN_IFRAME_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-index/_arena-shared-content_/game-in-iframe/index.html',
  IFRAME_GAMES_LIST_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-index/_arena-shared-content_/ark-iframe-games/iframe-games-list.json',
  GRM_GAMES_LIST_PATH: CONSTS.GRM_GAMES_LIST_URL.arena51.dev,
  EVENT_CATEGORY_DATA_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-index/_arena-shared-content_/ark-event-category/event-category-config.json',
  LIGHTBOX_PROMOTIONS_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-index/_arena-shared-content_/arena-lightbox-promotions/',

  // Skip ad product SKU name
  GEMS_TEST_SKU: 'pseudo-coins',
  SKU_GEMS_SKIP_AD: 'test__skip_ad',
  SKU_GEMS_SKIP_AD_BUNDLE: 'test__skip_ad_bundle',

  ERROR_DETAILS: true,

  // Recaptcha Key ID
  RECAPTCHAAPPKEY: '6LfIkwAkAAAAACnKy6-B7zMmDkOeH2JK-7u72sHu',
  CDN_CAPTCHA_IFRAME:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-3rd-party-scripts/captcha/iframe-captcha-dev.html',
  CHALLENGE_CAPTCHA_KEY: '6Lff9RknAAAAAGsrf0Os5WfIJlE91oCiEQn1CvLR',
  GAME_API: 'https://arenacloud.cdn.arkadiumhosted.com/game-api-origin-live'
};
