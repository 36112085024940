import React from 'react';

type iconProps = {
    className?: string;
};

export const InputEyeOpen = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29 17C29 18 23.4036 26 16.5 26C9.59644 26 4 18 4 17C4 16 9.59644 8 16.5 8C23.4036 8 29 16 29 17Z"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M16.6787 21.0166C15.5954 21.0166 14.6527 20.6208 13.8506 19.8291C13.0485 19.0375 12.6475 18.0896 12.6475 16.9854C12.6475 15.8812 13.0381 14.9385 13.8193 14.1573C14.6006 13.376 15.5433 12.9854 16.6475 12.9854C17.7516 12.9854 18.6943 13.376 19.4756 14.1573C20.2568 14.9385 20.6475 15.8812 20.6475 16.9854C20.6475 18.0896 20.262 19.0375 19.4912 19.8291C18.7204 20.6208 17.7829 21.0166 16.6787 21.0166Z"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    );
});
