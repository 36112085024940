// OPTIONS
export enum BADGES_STORE_PROPS {
    BADGE_NAME = 'Warm Welcome',
    BADGE_IMAGE_URL = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjciIGhlaWdodD0iNjciIHZpZXdCb3g9IjAgMCA2NyA2NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxjaXJjbGUgY3g9IjMzLjUiIGN5PSIzMy41IiByPSIzMy41IiBmaWxsPSIjMDA0MkJCIi8+CiAgICA8cGF0aCBkPSJNMzIuMDY2NCAyNi40MjU4TDI4LjAyMzQgMTkuNjg3NUMyNy43MzA1IDE5LjE2MDIgMjcuMDg1OSAxOC43NSAyNi40NDE0IDE4Ljc1SDE5LjkzNzVDMTkuMTc1OCAxOC43NSAxOC43MDcgMTkuNjI4OSAxOS4xMTcyIDIwLjI3MzRMMjUuNjc5NyAyOS41ODk4QzI3LjM3ODkgMjcuOTQ5MiAyOS42MDU1IDI2LjgzNTkgMzIuMDY2NCAyNi40MjU4Wk00OC4wMDM5IDE4Ljc1SDQxLjVDNDAuODU1NSAxOC43NSA0MC4yNjk1IDE5LjEwMTYgMzkuOTE4IDE5LjY4NzVMMzUuODc1IDI2LjQyNThDMzguMzM1OSAyNi44MzU5IDQwLjU2MjUgMjcuOTQ5MiA0Mi4yNjE3IDI5LjU4OThMNDguODI0MiAyMC4yNzM0QzQ5LjIzNDQgMTkuNjI4OSA0OC43NjU2IDE4Ljc1IDQ4LjAwMzkgMTguNzVaTTM0IDI4LjEyNUMyOC4yNTc4IDI4LjEyNSAyMy42ODc1IDMyLjc1MzkgMjMuNjg3NSAzOC40Mzc1QzIzLjY4NzUgNDQuMTc5NyAyOC4yNTc4IDQ4Ljc1IDM0IDQ4Ljc1QzM5LjY4MzYgNDguNzUgNDQuMzEyNSA0NC4xNzk3IDQ0LjMxMjUgMzguNDM3NUM0NC4zMTI1IDMyLjc1MzkgMzkuNjgzNiAyOC4xMjUgMzQgMjguMTI1Wk0zOS4zOTA2IDM3LjM4MjhMMzcuMTY0MSAzOS41NTA4TDM3LjY5MTQgNDIuNTk3N0MzNy44MDg2IDQzLjEyNSAzNy4yMjI3IDQzLjUzNTIgMzYuNjk1MyA0My4zMDA4TDM0IDQxLjgzNTlMMzEuMjQ2MSA0My4zMDA4QzMwLjcxODggNDMuNTM1MiAzMC4xMzI4IDQzLjEyNSAzMC4yNSA0Mi41OTc3TDMwLjc3NzMgMzkuNTUwOEwyOC41NTA4IDM3LjM4MjhDMjguMTQwNiAzNi45NzI3IDI4LjM3NSAzNi4zMjgxIDI4LjkwMjMgMzYuMjEwOUwzMi4wMDc4IDM1LjgwMDhMMzMuMzU1NSAzMi45ODgzQzMzLjQ3MjcgMzIuNzUzOSAzMy43MDcgMzIuNjM2NyAzMy45NDE0IDMyLjYzNjdDMzQuMjM0NCAzMi42MzY3IDM0LjQ2ODggMzIuNzUzOSAzNC41ODU5IDMyLjk4ODNMMzUuOTMzNiAzNS44MDA4TDM5LjAzOTEgMzYuMjEwOUMzOS41NjY0IDM2LjMyODEgMzkuODAwOCAzNi45NzI3IDM5LjM5MDYgMzcuMzgyOFoiIGZpbGw9IiNGOUY5RjkiLz4KPC9zdmc+Cg==',
    BADGE_DESCRIPTION = 'We\'re so happy you\'re here — this badge is awarded to players who have created a profile',
    ///
    BADGE_API_PATH = '/profile/recognitions',
    ///
    BADGE_PLAYS = 'ARK_AB_BADGE_PLAYS',
    BADGE_LASTVISIT = 'ARK_AB_BADGE_LASTVISIT',
    BADGE_LOGIN = 'ARK_AB_BADGE_LOGIN',
    BADGE_VISITS = 'ARK_AB_BADGE_VISITS',
    BADGE_SHOWN = 'ARK_AB_BADGE_SHOWN',
    BADGE_DONE = 'ARK_AB_BADGE_DONE',
    ///
    BADGES_EVENT_POPUP = 'BADGES_GAME_POPUP',
    BADGES_EVENT_GAMESTART = 'BADGES_GAME_START',
    BADGES_EVENT_LOGIN = 'BADGES_LOGIN',
    BADGES_EVENT_UPDATED = 'BADGES_UPDATED',
}

// PROPS
export const BADGE_STORAGES_PROPS = {
    localStorage: [
        BADGES_STORE_PROPS.BADGE_PLAYS,
        BADGES_STORE_PROPS.BADGE_LASTVISIT,
        BADGES_STORE_PROPS.BADGE_LOGIN,
        BADGES_STORE_PROPS.BADGE_VISITS,
    ],
    sessionStorage: [
        BADGES_STORE_PROPS.BADGE_SHOWN,
    ],
};

// TYPES
export type TEarnedBadges = {
    badgeName?: string;
    badgeImageUrl?: string;
    badgeDescription?: string;
};
export const THIS_BADGE: TEarnedBadges = {
    badgeName: BADGES_STORE_PROPS.BADGE_NAME,
    badgeImageUrl: BADGES_STORE_PROPS.BADGE_IMAGE_URL,
    badgeDescription: BADGES_STORE_PROPS.BADGE_DESCRIPTION,
};
