import { isServer } from '../../utils';
import { environment } from '../config/environment';
import { AdSettings } from '../models/AdSettings';
import { KeysEnum } from '../models/Enums';
import { adBlockDetector } from './AdBlockerService';
import { AnalyticsGeneral } from './Analytics/AnalyticsGeneral';
import { LocalStorageService } from './LocalStorage';

class AdsService {
    private adRefreshTimeMs: number;
    private lastRefreshDate = Date.now();
    private displayAd = null;
    private adWasRefreshed = false;
    private hasAdRefreshListener: boolean;

    public getVideoAdPath(settings: AdSettings) {
        if (settings.theySell && settings.theySell.video) {
            return this.buildTheySellLibPath(environment.VIDEO_ADS, settings.theySell.video);
        }

        if (!isServer) {
            const customEnv = LocalStorageService.getItem(KeysEnum.arkVideoAdCustomEnv);

            if (customEnv) {
                return this.buildCustomEnvLibPath(environment.VIDEO_ADS, customEnv);
            }
        }

        return environment.VIDEO_ADS;
    }

    buildCustomEnvLibPath(libPath, customFolder) {
        const url = new URL(libPath);
        const parts = url.pathname.split('/');

        parts.splice(parts.length - 2, 1, customFolder);
        url.pathname = parts.join('/');

        return url.href;
    }

    public buildTheySellLibPath(libPath, theySellName): string {
        const url = new URL(libPath);
        const parts = url.pathname.split('/');

        parts.splice(parts.length - 1, 0, theySellName);
        url.pathname = parts.join('/');

        return url.href;
    }

    public initDisplayAds(adRefreshTimeMs: string) {
        this.adRefreshTimeMs = +adRefreshTimeMs || 30000;

        if (isServer) {
            return;
        }

        adBlockDetector.isBlocked.then((isBlocked) => {
            if (!isBlocked) {
                this.displayAd = document.createElement('display-ad-component');
                this.displayAd.coreService.onShowGTMEvent = this.onAdShow;
                this.initAdRefreshEventListener();
            }
        });
    }

    public refresh() {
        if (Date.now() - this.lastRefreshDate > this.adRefreshTimeMs) {
            const ads = Array.from(document.querySelectorAll('display-ad-component'));

            ads.forEach((ad: any) => {
                try {
                    ad.refresh();
                } catch (e) {
                    console.log('Ad can not be refreshed. It can be because of they sell lib. Ad object: ' + ad);
                }
            });
            this.lastRefreshDate = Date.now();
            this.adWasRefreshed = true;
            console.log('-------ad refresh happens--------');
        }
    }

    private onAdShow = (ads, isRefresh?: boolean, eventDetail?) => {
        setTimeout(() => {
            ads.map((e) => {
                const elementDOM = document.getElementById(e);
                const elementParent = elementDOM.parentElement;

                AnalyticsGeneral.displayAd(
                    isRefresh || this.adWasRefreshed,
                    (elementParent as any).isViewable(),
                    [elementDOM.offsetWidth, elementDOM.offsetHeight],
                    (elementParent as any).dataset.id,
                    eventDetail
                );
            });
        }, 3000);
        this.adWasRefreshed = false;
    }

    private initAdRefreshEventListener () {
        if (!this.hasAdRefreshListener && window) {
            window.addEventListener('ad:refresh', () => {
                this.refresh();
            });
            this.hasAdRefreshListener = true;

            // @ts-ignore
            if (window.STORE && window.STORE.getState().game) {

                // @ts-ignore
                const store = window.STORE;
                const gameSlug = store.getState().game.slug;

                if (gameSlug && store.getState().iframeGamesList.includes(gameSlug)) {
                    window.addEventListener('mousemove', () => {
                        this.refresh();
                    });
                }
            }
        }
    }
}

export default new AdsService();
