import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import { FieldError } from 'react-hook-form';

import { InputEyeClose } from '../../FigmaStyleguide/Icons/InputEyeClose';
import { InputEyeOpen } from '../../FigmaStyleguide/Icons/InputEyeOpen';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import styles from './Input.css';
import { debounce } from '../../../utils';

// TODO: We could expand this enum in future if it`ll be needed
export enum INPUT_TYPE {
    TEXT = 'text',
    PASSWORD = 'password',
    SEARCH = 'search',
    EMAIL = 'email',
    BUTTON = 'button',
    FILE = 'file',
}

type InputProps = {
    value: string;
    register: any; // need to check ts for react-hook-form
    name: string;
    ariaInvalid: React.AriaAttributes['aria-invalid'];
    ariaDescribedBy?: string;
    type: string;
    className?: string;
    placeholder: string;
    isValid: boolean;
    showError?: FieldError;
    errorMessage?: string;
    onFocus?: () => void;
    onBlur?: () => void;
    maxLength?: number;
    dataRecurly?: string;
    disabled?: boolean;
    required?: boolean;
};

export const Input = ({
    value,
    register,
    name,
    ariaInvalid,
    ariaDescribedBy,
    type,
    className,
    placeholder,
    isValid,
    onFocus,
    onBlur,
    maxLength,
    dataRecurly,
    showError,
    errorMessage,
    disabled = false,
    required,
}: InputProps) => {
    const [typeInternal, setTypeInternal] = useState(type || INPUT_TYPE.TEXT);
    const toggleType = () => {
        setTypeInternal(typeInternal === INPUT_TYPE.PASSWORD ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD);
    };
    // const onChange = useCallback(register(name).onChange, []);
    // const debouncedOnChange = debounce(onChange, 1000);

    return (
        <div className={styles.wrapper}>
            <input
                aria-describedby={ariaDescribedBy ?? `${name}Error`}
                className={classNames(
                    styles.input,
                    disabled && styles.disabled,
                    isValid ? value?.length > 0 && styles.successful : styles.error,
                    className
                )}
                aria-invalid={ariaInvalid}
                type={typeInternal}
                id={name}
                placeholder={placeholder}
                onFocus={onFocus}
                maxLength={maxLength}
                data-recurly={dataRecurly}
                required={required}
                {...register(name, {
                    // onChange: debouncedOnChange,
                    value: value,
                    onBlur: onBlur,
                })}
                disabled={disabled}
            />
            {/* TODO: For search input type */}
            {/* {value.length > 0 && type === INPUT_TYPE.SEARCH && (
                <button className={styles.handlerIcon}>
                    <CloseIcon />
                </button>
            )} */}
            {type === INPUT_TYPE.PASSWORD && (
                <button onClick={toggleType} className={styles.handlerIcon} type="button">
                    {typeInternal === INPUT_TYPE.PASSWORD ? (
                        <InputEyeClose className={styles.eyeIconClose} />
                    ) : (
                        <InputEyeOpen className={styles.eyeIconOpen} />
                    )}
                </button>
            )}
            {showError && <ErrorMessage className={styles.errorMessage} id={`${name}Error`} message={errorMessage} />}
        </div>
    );
};
