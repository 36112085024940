import { environment } from '../config/environment';
import { AchievementEvent, AchievementEventJSON } from '../models/AchievementEvent';
import { authService } from './AuthService';

type AchievementEventResponse = {
    eventName: string;
    userId: string;
    value: string;
};

const API_PATH = environment.USER_API;

export enum AchievementType {
    RANK_ACHEIEVED = 'rank_acheieved',
    SCORE_ACHEIEVED = 'score_acheieved',
}

export class ProfileService {
    public static async getAchievements(): Promise<AchievementEvent[]> {
        const url = `${API_PATH}/user-event?takeCount=10`;
        const res = await authService.authFetch(url);

        return res.map((event) => {
            const eventValue: AchievementEventJSON = JSON.parse(event.value);

            return {
                eventName: event.eventName,
                score: Number(eventValue.score),
                gameName: eventValue.gameName,
                gameSlug: eventValue.gameSlug,
                userTodayRank: Number(eventValue.userTodayRank) || null,
            };
        });
    }

    public static saveAchievement(event: AchievementEvent) {
        const url = `${API_PATH}/user-event`;
        const body = JSON.stringify({
            eventName: event.eventName,
            value: JSON.stringify({
                score: event.score,
                gameName: event.gameName,
                gameSlug: event.slug,
                userTodayRank: event.userTodayRank,
            }),
        });

        return authService.authFetch(url, { method: 'POST', body });
    }

    public static getRecentlyPlayed(count = 6) {
        const url = `${API_PATH}/recently-played?takeCount=${count}`;

        return authService.authFetch(url);
    }

    public static saveRecentlyPlayed(slug: string) {
        const url = `${API_PATH}/recently-played`;
        const body = JSON.stringify({ slug });

        return authService.authFetch(url, { method: 'POST', body });
    }

    public static buyAvatar(avatar: string) {
        const body = JSON.stringify({ avatarName: avatar });

        return authService.authFetch(`${API_PATH}/profile/buy-avatar`, { method: 'POST', body });
    }
}
