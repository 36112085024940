import classnames from 'classnames';
import React, { ChangeEventHandler } from 'react';
import { TEmailField, TErrorValidation, TGdprUserDataField, TNicknameField, TPasswordField } from '../../../utils';
import styles from './CheckboxInput.css';
export enum ECheckboxInputFields {
    GDPR = 'gdpr',
    CHECKBOX_MARKETING = 'checkbox marketing',
}

type TProps = {
    className?: string;
    field: ECheckboxInputFields;
    text: string;
    checked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    errors: TErrorValidation<TEmailField | TPasswordField | TNicknameField | TGdprUserDataField | ''>[];
    isEagle: boolean;
};
const CheckboxInput = React.memo(({ errors, isEagle, field, text, checked, onChange }: TProps) => {
    const hasError = (field) => errors.map((e) => e.field).includes(field);

    return (
        <label
            className={classnames(styles.checkboxDescription, {
                [styles.eagle]: isEagle,
                [styles.error]: hasError(field),
            })}
        >
            <input type="checkbox" checked={checked} onChange={onChange} />

            <span className={classnames(styles.checkmark, { [styles.eagle]: isEagle })} />
            <span>{text}</span>
        </label>
    );
});

CheckboxInput.displayName = 'CheckboxInput';
export { CheckboxInput };
