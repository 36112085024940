import React from 'react';

import { Conversation } from '@open-web/react-sdk';

import { UrlService } from '../../services/UrlService';

type OpenWebConversationProps = {
    spotId: string;
    arenaUrl: string;
    gameSlug: string;
};

const OpenWebConversation = React.memo((props: OpenWebConversationProps) => {
    const postUrl = UrlService.createURL('', `games/${props.gameSlug}`);

    return (
        <div style={{position: 'relative', zIndex: 1}}>
            <div data-spotim-module="pitc"/>
            <Conversation
                spotId={props.spotId}
                postId={props.gameSlug}
                postUrl={postUrl}
            />
        </div>
    );
});

export default OpenWebConversation;
