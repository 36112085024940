import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import styles from './StarTribuneFooter.css';
import StarTribuneLogo from '../../../../public/svg/StarTribuneLogo.svg';
import StarTribuneFacebook from '../../../../public/svg/StarTribuneFacebook.svg';
import StarTribuneInstagram from '../../../../public/svg/StarTribuneInstagram.svg';
import StarTribuneThread from '../../../../public/svg/StarTribuneThread.svg';
import StarTribuneTiktok from '../../../../public/svg/StarTribuneTiktok.svg';
import StarTribuneX from '../../../../public/svg/StarTribuneX.svg';
import StarTribuneYoutube from '../../../../public/svg/StarTribuneYoutube.svg';

interface IStarTribuneFooter {
  renderDefaultFooter: () => JSX.Element;
}

const SOCIAL_LINKS = [
  { iconElement: <StarTribuneFacebook />, link: 'https://www.facebook.com/startribune/' },
  { iconElement: <StarTribuneInstagram />, link: 'https://www.instagram.com/startribune/' },
  { iconElement: <StarTribuneTiktok />, link: 'https://www.tiktok.com/@startribune' },
  { iconElement: <StarTribuneX />, link: 'https://x.com/StarTribune/' },
  { iconElement: <StarTribuneYoutube />, link: 'https://www.youtube.com/@startribune' },
  { iconElement: <StarTribuneThread />, link: 'https://www.threads.net/@startribune' },
];
const getFooterLinks = (t: TFunction) => (
  [
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.VISIT_STARTRIBUNE'),
      link: 'https://www.startribune.com/'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.ABOUT_STARTRIBUNE'),
      link: 'https://www2.startribune.com/about/'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.CONTACT_US'),
      link: 'https://help.startribune.com/hc/en-us/articles/360061065451-Contact-Star-Tribune?_gl=1*nfc4rr*_ga*MTQ5OTY3NDIwMy4xNzE4NjgyOTcx*_ga_SG3Y2YJFRQ*MTcxOTIwNTA2Ni41Ny4wLjE3MTkyMDUwNzUuMC4wLjA.'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.WORK_FOR_US'),
      link: 'https://recruiting2.ultipro.com/STA1013/JobBoard/94aec289-5757-a8f0-d3bb-77f9cd846172/?q=&o=postedDateDesc'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.MEDIA_KIT'),
      link: 'https://www.startribunecompany.com/ad-specs-pricing/'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.STRIB_STORE'),
      link: 'https://shop.startribune.com/'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.HELP_AND_FEEDBACK'),
      link: 'https://help.startribune.com/'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.TERMS_OF_USE'),
      link: 'https://help.startribune.com/hc/en-us/articles/13174044736141-StarTribune-com-Terms-of-Use'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.PRIVACY_POLICY'),
      link: 'https://help.startribune.com/hc/en-us/articles/12986577916557-StarTribune-com-Privacy-Policy'
    },
    {
      text: t('STAR_TRIBUNE.NAVIGATION_LINKS.POLICIES_AND_STANDARDS'),
      link: 'https://www2.startribune.com/star-tribune-policies-and-standards/482850961/'
    },
  ]
);
const StarTribuneFooter: FC<IStarTribuneFooter> = (props) => {
  const { renderDefaultFooter } = props;
  const { t } = useTranslation('');

  return (
    <>
      {renderDefaultFooter()}
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.logoContainer}>
            <a href="https://www.startribune.com/">
              <StarTribuneLogo />
            </a>
            <p className={styles.logoDescription}>{t('STAR_TRIBUNE.LOGO_DESCRIPTION')}</p>
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.logos}>
              {SOCIAL_LINKS.map(({ iconElement, link }) => (
                <div key={link} className={styles.logo}>
                  <a href={link}>
                    {iconElement}
                  </a>
                </div>
              ))}
            </div>
            <a href="https://www.startribune.com/subscribe/" className={styles.subscribeLink}>
              <button className={styles.subscribeButton}>{t('STAR_TRIBUNE.SUBSCRIBE')}</button>
            </a>
          </div>
        </div>
        <nav className={styles.footerNavigation}>
          {getFooterLinks(t).map(({ text, link }) => (
            <a key={link} href={link} className={styles.footerLink}>
              {text}
            </a>
          ))}
        </nav>
      </div>
    </>
  );
};

export default StarTribuneFooter;
