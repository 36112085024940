import classnames from 'classnames';
import React from 'react';
import styles from './TextButton.css';

type TProps = {
  isEagle: boolean;
  onClick: () => void;
  text: string;
  className?: string;
  defaultCursor?: boolean;
};
const TextButton = React.memo(({
  onClick,
  isEagle,
  text,
  className,
  defaultCursor,
}: TProps) => {
  return (
    <button
      onClick={onClick}
      className={classnames(
        className,
        {
          [styles.old]: !isEagle,
          [styles.eagle]: isEagle,
          [styles.defaultCursor]: defaultCursor && text !== 'support',
        },
      )}
    >
      {text}
    </button>
  );
});

TextButton.displayName = 'TextButton';

export { TextButton };
