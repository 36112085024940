import { Store as StoreTypes } from './IDBStorageService';

// import * as Utils from '../RN_utils/functions';
// import { getInitialSettingsState } from '../store/reducers/settings';
export const getInitialSettingsState = (): StoreTypes.SettingsState => ({
    timer: true,
    sounds: true,
    darkMode: false,
    highlightErrors: false,
    arrowKeysToChangeDirection: false,
    skipOverFilledCells: true,
    jumpBackToBlanksAtTheEndOfWord: false,
    jumpToNextWordIfItIsFilled: true,
    highlightConflictingLetters: true,
    cluesPosition: StoreTypes.CluesPosition.RIGHT,
});
class DBStorage {
    private storedObject: StoredObject = {
        settings: { ...getInitialSettingsState() },
        lastPlayedPuzzleState: null,
        previouslyPlayedScores: {},
        previouslyPlayedPuzzles: {},
    };
    private _rawKey: string = '';
    private _postfix: string = 'default';
    protected readonly DEFAULT_DB_VERSION = 1;
    protected db: any | null = null;
    private dbPromise: IDBOpenDBRequest;
    /**
     * Sets postfix for key in storage
     *
     * @param postfix - skin name, "default" by default
     */
    public set postfix(postfix: string) {
        this._postfix = postfix;
    }

    /** Default key which storage uses */
    public get key(): string {
        return `${this._rawKey}___${this._postfix}___`;
    }

    protected async connectDB(): Promise<void> {
        const key = this.key;

        return new Promise((resolve, reject) => {
            try {
                const request = indexedDB.open(key, this.DEFAULT_DB_VERSION);

                request.onerror = function (event) {
                    // resolve({});
                    console.error("Why didn't you allow my web app to use IndexedDB?!");
                };

                request.onupgradeneeded = function (event: any) {
                    let db = event.target.result;

                    if (!db.objectStoreNames.contains(key)) {
                        db.createObjectStore(key);
                    }
                };

                request.onsuccess = (event: any) => {
                    this.db = event.target.result;
                    resolve();
                };
            } catch (e) {
                console.error("can't open DB", e);
                // resolve({});
            }
        });
    }

    /**
     * Get data from storage
     *
     * @param key - key of data you want to get
     *
     * @returns null if there is nothing for this key or data
     * */
    public async getItem(key: string) {
        if (!this.db) {
            return null;
        }

        return new Promise((resolve, reject) => {
            try {
                this.db.transaction(this.key, 'readwrite').objectStore(this.key).get(key).onsuccess = (event) =>
                    resolve(event.target.result);
            } catch (e) {
                console.error(e);
            }
        });
    }

    /**
     * Save data to storage
     *
     * @param key - key for item
     * @param item - data to be saved
     * */
    public async setItem(key: string, item: any) {
        if (!this.db) {
            return;
        }

        try {
            await this.db.transaction(this.key, 'readwrite').objectStore(this.key).put(item, key);
        } catch (e) {
            console.error(e);
        }
    }
    /**
     * Init store to connect with data from IndexedDB
     *
     * @param rawKey - game name
     * */
    public async init(rawKey: string): Promise<void> {
        if (!rawKey || rawKey.length === 0) throw Error('Key for storage is too short.');
        this._rawKey = rawKey;

        return new Promise((resolve, reject) => {
            this.connectDB().then((_) => resolve());
        });
    }

    /**
     * Save score for the day
     *
     * @param puzzleUrl - url where puzzle was loaded, used as key for score in storage
     * @param totalScore - daily score, including all bonuses etc.
     * @param yearOfPlaying - to be able to clear old scores
     * */
    public setScore(puzzleUrl: string, totalScore: number, yearOfPlaying: number) {
        const scoreKey = `${puzzleUrl}__${yearOfPlaying}__`;

        /** we're saving only first attempt */
        if (scoreKey in this.storedObject.previouslyPlayedScores) return;

        this.storedObject.previouslyPlayedScores[scoreKey] = totalScore;
        this.saveToStorage();
    }

    /**
     * Get saved settings
     *
     * @returns object with all settings for redux store
     */
    public get settings(): StoreTypes.SettingsState {
        return this.storedObject.settings;
    }

    /**
     * Set settings to save in storage
     *
     * @param settings - object with all settings from redux store
     * */
    public set settings(settings: StoreTypes.SettingsState) {
        this.storedObject.settings = settings;
        this.saveToStorage();
    }

    private async saveToStorage() {
        await this.setItem(this.key, this.storedObject);
    }
}

const DBStorageInstance = new DBStorage();

export default DBStorageInstance;

type StoredObject = {
    settings: StoreTypes.SettingsState;
    lastPlayedPuzzleState: StoreTypes.GridState | null;
    previouslyPlayedScores: { [key: string]: number };
    previouslyPlayedPuzzles: { [key: string]: StoreTypes.GridState };
};
