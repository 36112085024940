import { createAction, createReducer } from 'redux-act';

export interface ConfigState {
    feed?: any;
    promoGame?: any;
    sorting?: any;
    gamesRewrite?: any;
    theme?: any;
}

export const setConfig = createAction<ConfigState>('set config');

const reducer = createReducer<ConfigState>({}, {});

reducer.on(setConfig, (_state, payload: any = null) => payload);

export default reducer;
