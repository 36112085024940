import React, { useEffect, useState } from 'react';
import { isLandscape } from '../../../utils';
import { GameState } from '../../models/Enums';
import { DeviceDetector } from '../../services/DeviceDetector';
import { DisplayAd } from '../../molecules/DisplayAd/DisplayAd';
import styles from './Game.css';

export const GRMAds = (props: any) => {
    const { gameState, config } = props;
    const [isOrientationLandscape, setIsOrientationLandscape] = useState(false);
    const handleOrientationChange = () => {
        setIsOrientationLandscape(isLandscape());
    };

    useEffect(() => {
        handleOrientationChange();
        window.addEventListener('resize', handleOrientationChange);
        return window.removeEventListener('resize', handleOrientationChange);
    }, []);
    const isDisplayAdsEnabled = config?.theme?.shouldShowDisplayAd?.() as unknown as boolean;
    const isGameState = gameState === GameState.GAME;
    const isDisplayAdsVisible = isDisplayAdsEnabled && DeviceDetector.isNotPc() && isGameState;
    const isTheySell = config.ad?.theySell?.display;
    const mobTopAdSizes =
        isOrientationLandscape || isTheySell
            ? [[320, 50]]
            : [
                  [320, 50],
                  [320, 100],
              ];

    // focusing on the game after video ad is finished
    // this is fix for IOS old versions, some games lost focus and paused
    useEffect(() => {
        if (isGameState) {
            const iframe = document.querySelector('#game-canvas iframe');
            const canvas = document.querySelector('#game-canvas canvas');
            const gameBlock: any = iframe || canvas;

            if (gameBlock) {
                gameBlock.focus();
            }

            window.dispatchEvent(new Event('game:start')); // do not remove this because external scripts use the event
        }
    }, [isGameState]);

    return (
        isGameState && (
            <>
                {isDisplayAdsVisible && (
                    <GameAd data-element-description="game top ad">
                        {DeviceDetector.isTablet() && (
                            <DisplayAd
                                componentId="ark_display_t1"
                                dataElementDescription="ark-display-m1"
                                dimensions={[[728, 90]]}
                            />
                        )}
                        {DeviceDetector.isMobile() && !isOrientationLandscape && (
                            <DisplayAd
                                componentId="ark_display_m1"
                                dataElementDescription="ark-display-m1"
                                dimensions={mobTopAdSizes}
                            />
                        )}
                        {DeviceDetector.isMobile() && isOrientationLandscape && (
                            <DisplayAd
                                componentId="ark_display_m1"
                                dataElementDescription="ark-display-m1"
                                dimensions={mobTopAdSizes}
                            />
                        )}
                    </GameAd>
                )}
            </>
        )
    );
};

const GameAd = (props: any) => {
    return <div className={styles.gameAd} {...props} />;
};
