import React from 'react';
import { isServer } from '../../../utils';
import { ABTests } from '../../models/ABTests';
import { ABTestManager } from './ABTestManager';
import { GDPRMediatorService } from '../Analytics/GDPR';

export const ABTestContext = React.createContext(new ABTestManager());

export function useABTest<K extends keyof ABTests>(testName: K, defaultValue: ABTests[K]): ABTests[K] {
    const abtests = React.useContext(ABTestContext);
    const result = abtests.getVariation(testName, defaultValue);

    if (!isServer) {
        const { name, value, options } = abtests.getCookie();

        if (options && options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        const cookie = [
            `${name}=${encodeURIComponent(value)}`,
            ...Object.entries(options).map((kv) => kv.join('=')),
        ].join('; ');

        GDPRMediatorService.isBlockedByGdprConsent().then((isBlocked) => {
            if (!isBlocked) {
                document.cookie = cookie;
            }
        });
    }

    return result;
}

export function ABTestProvider({ children, manager }: React.PropsWithChildren<ABTestProviderProps>) {
    return <ABTestContext.Provider value={manager}>{children}</ABTestContext.Provider>;
}

interface ABTestProviderProps {
    manager: ABTestManager;
}
