import React, { useState, useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom';
import { validateEaglePassword, validatePassword, validatePasswords } from '../../../utils';
import { PrimaryButton } from '../../atoms/Buttons/PrimaryButton';
import { CloseIcon } from '../../atoms/Icons/CloseIcon';
import { LockIcon } from '../../atoms/Icons/LockIcon';
import { EagleLoginService } from '../../services/EagleLoginService';
import { userService } from '../../services/UserService';
import { AppState } from '../../store/types';
import styles from './ProfileChangePass.css';
import { ProfileChangePassInput } from './ProfileChangePassInput';

type TProfileChangeProps = {
  isEagle: boolean;
};

const ProfileChangePassComponent: React.FC<TProfileChangeProps & WithTranslation> = ({
  t,
  isEagle,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentPass, setCurrentPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [newConfirmPass, setNewConfirmPass] = useState<string>('');
  const [httpError, setHttpError] = useState<string>('');
  const [isErrorsShown, setIsErrorsShown] = useState<boolean>(false);
  const toggleModal = useCallback(() => {
    setIsModalVisible(!isModalVisible);
    setCurrentPass('');
    setNewPass('');
    setNewConfirmPass('');
    setIsErrorsShown(false);
    setHttpError('');
  }, [isModalVisible]);
  const onChangeInput = useCallback((inputId, e) => {
    const value = e.target.value.trim();

    switch (inputId) {
      case 'currentPass':
        setCurrentPass(value);
        break;
      case 'newPass':
        setNewPass(value);
        break;
      case 'newConfirmPass':
        setNewConfirmPass(value);
        break;
      default:
        break;
    }
  }, []);
  const checkErrors = useCallback(
    (text: string, inputId?: string) => {
      const validateTwoPasswords = () =>
        isEagle ? validateEaglePassword(text, newPass) : validatePasswords(text, newPass);
      const validateOnePassword = () => (isEagle ? validateEaglePassword(text) : validatePassword(text));
      const {
        isValid,
        errors,
      } = inputId === 'newConfirmPass' ? validateTwoPasswords() : validateOnePassword();

      if (isValid) {
        return '';
      }

      return t(`LOGIN.${errors[0].description}`);
    },
    [isEagle, t, newPass],
  );
  const successHandler = useCallback(() => {
    toggleModal();
  }, [toggleModal]);
  const errorHandler = useCallback(
    (err) => {
      setHttpError(err.message);
    },
    [t],
  );
  const eagleErrorHandler = useCallback(
    (code) => {
      setHttpError(t(`LOGIN.${code}`));
    },
    [t],
  );
  const onSubmitForm = useCallback(
    (e) => {
      e.preventDefault();
      setIsErrorsShown(true);

      if (checkErrors(newPass) || checkErrors(newConfirmPass, 'newConfirmPass')) {
        return;
      }

      const changePasswordPayload = {
        oldPassword: currentPass,
        newPassword: newPass,
      };

      if (isEagle) {
        void EagleLoginService.changePassword(changePasswordPayload, successHandler, eagleErrorHandler);
      } else {
        userService.changePassword(currentPass, newPass).then(successHandler).catch(errorHandler);
      }
    },
    [currentPass, newPass, newConfirmPass, checkErrors, isEagle],
  );

  return (
    <Container>
      <ChangePassBtn data-element-description="change password btn" onClick={toggleModal}>
        <ChangePassIcon/>
        {t('CHANGE_PROFILE_PASSWORD')}
      </ChangePassBtn>
      {isModalVisible && createPortal(
        <Modal data-element-description="change password modal">
          <ModalContent>
            <Top data-element-description="change pass title">
              <TopLockIcon/>
              {t('CHANGE_PROFILE_PASSWORD')}

              <CloseBtn data-element-description="change password close btn" onClick={toggleModal}>
                <CloseIcon/>
              </CloseBtn>
            </Top>
            <ModalBody>
              {httpError && <Error>{httpError}</Error>}

              <ProfileChangePassInput
                value={currentPass}
                title={t('CURRENT_PASSWORD')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeInput('currentPass', e);
                }}
              />

              <ProfileChangePassInput
                value={newPass}
                title={t('NEW_PASSWORD')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeInput('newPass', e);
                }}
                errorMsg={isErrorsShown && checkErrors(newPass)}
              />

              <ProfileChangePassInput
                value={newConfirmPass}
                title={t('CONFIRM_NEW_PASSWORD')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeInput('newConfirmPass', e);
                }}
                errorMsg={isErrorsShown && checkErrors(newConfirmPass, 'newConfirmPass')}
              />
            </ModalBody>

            <Bottom>
              <CancelBtn data-element-description="change pass cancel btn" onClick={toggleModal}>
                {t('CANCEL')}
              </CancelBtn>
              <PrimaryButton
                data-element-description="change pass submit btn"
                onClick={onSubmitForm}
                type={'submit'}
              >
                {t('UPDATE')}
              </PrimaryButton>
            </Bottom>
          </ModalContent>
        </Modal>,
        document.body
      )}
    </Container>
  );
};
const Container = (props: any) => <div {...props} />;
const ChangePassBtn = (props: any) => <button className={styles.ChangePassBtn} {...props} />;
const ChangePassIcon = (props: any) => <LockIcon className={styles.LockIcon} {...props} />;
const Modal = (props: any) => <div className={styles.Modal} {...props} />;
const ModalContent = (props: any) => <form className={styles.ModalContent} {...props} />;
const Top = (props: any) => <div className={styles.Top} {...props} />;
const TopLockIcon = (props: any) => <LockIcon className={`${styles.TopLockIcon} ${styles.LockIcon}`} {...props} />;
const CloseBtn = (props: any) => <button className={styles.CloseBtn} {...props} />;
const ModalBody = (props: any) => <div className={styles.ModalBody} {...props} />;
const Bottom = (props: any) => <div className={styles.Bottom} {...props} />;
const CancelBtn = (props: any) => <button className={styles.CancelBtn} {...props} />;
const Error = (props: any) => <div className={styles.Error} {...props} />;
const mapStateToProps = (state: AppState) => ({
  isEagle: state.config.isEagle,
});
const ProfileChangePassState = connect(mapStateToProps)(ProfileChangePassComponent);

export const ProfileChangePass = withTranslation()(ProfileChangePassState);
