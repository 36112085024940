import { createAction, createReducer } from 'redux-act';
import { LoginCaller, LoginState } from '../../models/Login';

export interface LoginDialogState {
    show: boolean;
    state?: LoginState;
    caller?: LoginCaller;
}

export const setLoginDialog = createAction<LoginDialogState>('set login dialog');

const reducer = createReducer<LoginDialogState>({}, { show: false, state: LoginState.Login });

reducer.on(setLoginDialog, (_state, payload: LoginDialogState) => payload);

export default reducer;
