import classNames from 'classnames';
import React from 'react';
import { UrlService } from '../../services/UrlService';
import styles from './EagleAvatar.css';
type TProps = {
    avatar: string;
    wrapperClassName: string;
    onAvatarClick?: () => void;
    noBorderRadius?: boolean;
};
export const EagleAvatar = React.memo(({ avatar, onAvatarClick, wrapperClassName, noBorderRadius }: TProps) => {
    const webpSrc = UrlService.toAvatarLink(avatar, 'webp');
    const pngSrc = UrlService.toAvatarLink(avatar, 'png');

    return (
        <div
            onClick={onAvatarClick}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onAvatarClick();
                }
            }}
            role="presentation"
            className={classNames(wrapperClassName, styles.eagleAvatarWrapper)}
        >
            <picture className={classNames(styles.avatarPicture, { [styles.borderRadius]: !noBorderRadius })}>
                <source srcSet={webpSrc} type="image/webp" />
                <source srcSet={pngSrc} type="image/png" />
                <img src={pngSrc} alt={avatar} />
            </picture>
        </div>
    );
});
