import React from 'react';

import classNames from 'classnames';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import styles from './ErrorMessage.css';
import { WarningIcon } from '../../FigmaStyleguide/Icons/WarningIcon';

type ErrorMessageProps = {
    message: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    id: string;
    className?: string;
};

export const ErrorMessage = ({ message, id, className }: ErrorMessageProps) => (
    <div className={classNames(styles.validationBlock, className)}>
        <WarningIcon />
        <span id={id} role="alert">
            {message}
        </span>
    </div>
);
