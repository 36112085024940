import { Api, ApiGateway, ApiGatewayInitParams } from '@arkadium/eagle-payments-api-client';
import { PurchaseItemDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/purchase-subscription.dto';
import { RecurlyItemDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/recurly-data.dto';
import { isServer } from '../../utils';

import { environment } from '../config/environment';
import { EagleLoginService } from './EagleLoginService';

class PaymentService {
  public paymentApiService: ApiGateway;

  constructor() {
    if (!isServer) {
      this.initAPI();
    }
  }

  public getPurchasableItems = async (purchasableItemsSkuList: string[]): Promise<RecurlyItemDto[]> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    return api.recurlySubscriptions.getPurchasableItems({ ids: purchasableItemsSkuList });
  }
  public purchaseItem = async (data: PurchaseItemDto): Promise<void> => {
    const api = await this.paymentApiService.getApi(Api.v1);

    await api.recurlySubscriptions.purchaseItem(data);

  }

  private initAPI() {
    const params: ApiGatewayInitParams = {
      // Base url of Eagle API
      server: new URL(
        environment.EAGLE_PAYMENT_API || 'https://eagle-payment-api.uup-aks-dev.arkadiumhosted.com',
      ),
      sessionStorage: EagleLoginService.getSessionStorage(),
    };

    this.paymentApiService = new ApiGateway(params);
  }
}

export default new PaymentService();
