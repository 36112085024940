import React, { useEffect, useState } from 'react';
import { fetchApiCall } from '../../../fetch';
import styles from './TodaysSpecialAdWidget.css';

interface ITodaySpecial {
    TopLine: string;
    Image: string;
    Link: string;
}

const todaySpecialApi = `https://www.hsn.com/api/arcade/todays-special`;
const recentlyAiredApi = `https://www.hsn.com/api/arcade/recently-aired`;
const TodaysSpecialAdWidget: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('today');
    const [todaysSpecial, setTodaysSpecial] = useState<ITodaySpecial>(null);
    const [recentlyAired, setRecentlyAired] = useState(null);

    useEffect(() => {
        fetchApiCall(todaySpecialApi).then(({ data }) => setTodaysSpecial(data));
        fetchApiCall(recentlyAiredApi)
            .then(({ data: { Products } }) => {
                const randomIndex = Math.floor(Math.random() * Products.length);

                setRecentlyAired(Products[randomIndex]);
            });

    }, []);

    const handleTabClick = (tab: string) => {
        return setActiveTab(tab);
    };
    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>, tabName: string) => {
        if (event.key === 'Enter' || event.key === 'Space') {
            return handleTabClick(tabName);
        }
    };
    const onBuyClick = () => {
        const redirectLink = activeTab === 'today' ? todaysSpecial.Link : recentlyAired.Link;

        return window.location.assign(redirectLink);
    };

    return (
        <div className={styles.adWidgetContainer}>
            <div className={styles.headerContainer}>
                <button
                    className={`${styles.redButton} ${activeTab === 'today' ? styles.activeButton : ''}`}
                    onClick={() => handleTabClick('today')}
                    onKeyDown={(event) => handleOnKeyDown(event, 'today')}
                >
                    TODAY'S SPECIAL
                </button>
                <button
                    type="button"
                    className={`${styles.blueButton} ${activeTab === 'recently' ? styles.activeButton : ''}`}
                    onClick={() => handleTabClick('recently')}
                    onKeyDown={(event) => handleOnKeyDown(event, 'recently')}
                >
                    RECENTLY <br/> AIRED
                </button>
            </div>
            <div className={styles.productContainer}>
                {activeTab === 'today' && (
                    <img className={styles.productImage} src={todaysSpecial?.Image} alt="Product Today"/>
                )}
                {activeTab === 'recently' && (
                    <img
                        className={styles.productImage}
                        src={recentlyAired?.Image}
                        alt="Product Recently"
                    />
                )}
                <div className={styles.shopLink}>
                    {activeTab === 'today' && (
                        <a href={todaysSpecial?.Link} target="_blank" rel="noopener noreferrer">
                            {todaysSpecial?.TopLine}
                        </a>
                    )}
                    {activeTab === 'recently' && (
                        <a href={recentlyAired?.Link} target="_blank" rel="noopener noreferrer">
                            {recentlyAired?.TopLine}
                        </a>
                    )}
                </div>
            </div>
            <div className={styles.footerContainer}>
                <button
                    type="button"
                    className={styles.blueFooterButton}
                    onKeyDown={(event) => handleOnKeyDown(event, 'today')}
                    onClick={onBuyClick}
                >
                    BUY NOW
                </button>
            </div>
        </div>
    );
};

export default TodaysSpecialAdWidget;
