import * as yup from 'yup';

const commonValidationString = 'is a required field';
const STRING_WITH_SPACES_REGEX = /^[A-Za-z\s]*$/;
const STRING_AND_NUMBERS_WITH_SPACES_REGEX = /^[0-9A-Za-z\s]*$/;

export const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(`First name ${commonValidationString}`)
    .matches(STRING_WITH_SPACES_REGEX, { message: 'Please remove any special characters' }),
  last_name: yup
    .string()
    .required(`Last name ${commonValidationString}`)
    .matches(STRING_WITH_SPACES_REGEX, { message: 'Please remove any special characters' }),
  address1: yup
    .string()
    .required(`Address ${commonValidationString}`)
    .matches(STRING_AND_NUMBERS_WITH_SPACES_REGEX, { message: 'Please remove any special characters' }),
  city: yup
    .string()
    .required(`City ${commonValidationString}`)
    .matches(STRING_WITH_SPACES_REGEX, { message: 'Please remove any special characters' }),
  country: yup.string().required(`Country ${commonValidationString}`),
  postal_code: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  // credit data (recurly iframes)
  cardNumber: yup.object({
    creditBrand: yup.string().required('Please enter a valid credit card number'),
    creditLastFour: yup.string().required('Please enter a valid credit card number'),
  }),
  year: yup.string().required('Please enter a valid date'),
  month: yup.string().required('Please enter a valid date'),
  cvv: yup.boolean().isTrue('Please enter valid code'),
});

export type TFormValues = {
  first_name: string,
  last_name: string,
  address1: string,
  city: string,
  country: string,
  postal_code: string,
  state: string,
  cardNumber: {
    creditBrand: string,
    creditLastFour: string,
  },
  year: string,
  month: string,
  cvv: boolean,
};
export type TFormValue = keyof TFormValues;
export const defaultValues: TFormValues = {
  first_name: '',
  last_name: '',
  address1: '',
  city: '',
  country: '',
  postal_code: '',
  state: '',
  cardNumber: {
    creditBrand: '',
    creditLastFour: '',
  },
  year: '',
  month: '',
  cvv: false,
};
