import React from 'react';

type iconProps = {
    className?: string;
};

export const AvatarIconAdvantage = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-labelledby="avatar-icon-advantage-title"
            className={props.className}
            width="32"
            height="30"
            viewBox="0 0 32 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="avatar-icon-advantage-title">Avatar with premium icon</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0849 5H28.4282C30.8402 5 32.3992 6.94285 31.9103 9.33962L29.1679 23.6604C28.6789 26.057 26.3271 28 23.9151 28H5.57184C3.1597 28 1.60081 26.057 2.08968 23.6604L4.83214 9.33962C5.32101 6.94285 7.67273 5 10.0849 5Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M15.8971 19.585C15.8467 19.5577 15.7947 19.5361 15.742 19.5199C15.7412 19.5196 15.7404 19.5192 15.7396 19.5189C15.4726 19.4138 15.1911 19.3491 14.9098 19.3289C14.8986 19.3281 14.8873 19.3275 14.876 19.3272L14.7318 19.3225C14.7175 19.3221 14.7031 19.322 14.6888 19.3223C14.3086 19.3293 13.9302 19.3754 13.5593 19.4597C13.5241 19.4677 13.4898 19.4778 13.4567 19.4898L13.3731 19.5135C13.0454 19.6001 12.7125 19.6651 12.3765 19.708L12.1205 19.7362L12.0323 19.7415C11.7244 19.7479 11.4118 19.6879 11.0785 19.5401C10.1229 19.0823 9.71144 18.2232 9.89166 17.3232C10.011 16.7274 10.3832 16.1614 10.8491 15.781C10.8493 15.7808 10.8495 15.7806 10.8498 15.7804L17.3235 10.5211L17.3236 10.5211L17.3304 10.5155C18.1479 9.83704 18.3566 9.69633 18.7949 9.5199C19.0734 9.40781 19.3521 9.35044 19.6374 9.35L19.6617 9.35021C20.018 9.35678 20.3617 9.44596 20.6337 9.58635L20.6506 9.59481C21.2905 9.90733 21.633 10.2592 21.8367 10.6699C22.0534 11.1067 22.1585 11.7005 22.1495 12.5757L22.1494 12.5757V12.5845V21.176C22.1494 21.8195 21.9408 22.4551 21.5693 22.9131C21.215 23.35 20.6994 23.6473 19.9824 23.65C19.6588 23.6405 19.3461 23.5678 19.0946 23.4565L19.0774 23.4491C18.4872 23.2032 18.1612 22.8145 17.8005 22.0197C17.7791 21.9726 17.7293 21.8573 17.6766 21.7348L17.6762 21.7339C17.6512 21.6759 17.6263 21.6182 17.6062 21.572L17.606 21.5716C17.5917 21.5386 17.5712 21.4914 17.5559 21.459L17.428 21.1847L17.4193 21.1667C17.0868 20.4954 16.5565 19.9412 15.8971 19.585Z"
                stroke="#F9F9F9"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="28.2481"
                    y1="24.9706"
                    x2="12.1111"
                    y2="4.11544"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C28C2B" />
                    <stop offset="0.498397" stopColor="#E6C14E" />
                    <stop offset="1" stopColor="#C28C2B" />
                </linearGradient>
            </defs>
        </svg>
    );
});
