import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ArrowIcon } from '../../atoms/Icons/ArrowIcon';
import { Link as RouterLink } from '../../atoms/Link/Link';
import { Game } from '../../models/Game';
import styles from './Breadcrumbs.css';

class BreadcrumbsBase extends React.PureComponent<{ game: Game, subfolder: string } & WithTranslation> {
    render() {
        const { t, game, subfolder } = this.props;

        return (
            <Container data-element-description="breadcrumbs container">
                <Link data-element-description="breadcrumbs link" to={`${subfolder}/`}>
                    {t('HOME')}
                </Link>

                <ArrowIcon className={styles.delimiter} />

                <Link
                    data-element-description="breadcrumbs link"
                    to={`/${t('ROUTES.GAMES')}/${game.alias}`}
                    isActive={true}
                >
                    {game.name}
                </Link>
            </Container>
        );
    }
}

const Container = (props: any) => <div className={styles.container} {...props} />;
const Link = ({ isActive, ...props }: any) => (
    <RouterLink className={classNames(styles.link, { [styles.isActive]: isActive })} {...props} />
);

export const Breadcrumbs = withTranslation()(BreadcrumbsBase);
