import { fetchApiCall } from '../../fetch';
import { environment } from '../config/environment';
import { GamesWithEagleList } from '../models/GameRendering';

type GamesList = Record<string, Array<string>>;
export class GamesWithEagleService {
    public static getGamesList = (): Promise<GamesList> => {
        return fetchApiCall(environment.GAMES_WITH_EAGLE_LIST_PATH, {
            timeout: 10 * 1000,
        })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.error(err);
                return {};
            });
    };
    public static doWeNeedToPassEagleInGame = (
        gamesWithEagleList: GamesWithEagleList,
        domain: string,
        slug: string
    ): boolean => {
        const gamesListForDomain: Array<string> | undefined = gamesWithEagleList[domain];

        if (gamesListForDomain) {
            return gamesListForDomain.includes(slug);
        }

        return false;
    };
}
