export interface SlotParams {
    // This value allows to get address of main CDN entrypoint after SSR. Actually it is some Arena so it could be removed in future
    ssrOrigin: string;
    SLOT: 'prod' | 'canary' | 'dev' | 'dev2';
}

export const SlotParamsDeclaration: { [key: string]: SlotParams } = {
    prod: {
        ssrOrigin: localOrOrigin('https://arena51.arkadiumarena.com'),
        SLOT: 'prod'
    },
    canary: {
        ssrOrigin: localOrOrigin('https://arena51-canary.arkadiumarena.com'),
        SLOT: 'canary'
    },
    dev: {
        ssrOrigin: localOrOrigin('https://arena51-dev.arkadiumarena.com'),
        SLOT: 'dev'
    },
    dev2: {
        ssrOrigin: localOrOrigin('https://arena51-dev2.arkadiumarena.com'),
        SLOT: 'dev2'
    }
};

function localOrOrigin(origin: string) {
    return process.env.NODE_ENV === 'production' ? origin : 'http://localhost:4200';
}
