export const FramesData = [
    {
        filename: '',
        analyticsKey: '0',
    },
    {
        filename: 'frame_1.png',
        analyticsKey: '1',
    },
    {
        filename: 'frame_2.png',
        analyticsKey: '2',
    },
    {
        filename: 'frame_3.png',
        analyticsKey: '3',
    },
    {
        filename: 'frame_4.png',
        analyticsKey: '4',
    },
    {
        filename: 'frame_5.png',
        analyticsKey: '5',
    },
    {
        filename: 'frame_6.png',
        analyticsKey: '6',
    },
    {
        filename: 'frame_7.png',
        analyticsKey: '7',
    },
    // {
    //     filename: 'frame_halloween_1.png',
    //     analyticsKey: 'halloween_1',
    // },
    // {
    //     filename: 'frame_halloween_2.png',
    //     analyticsKey: 'halloween_2',
    // },
    // {
    //     filename: 'frame_halloween_3.png',
    //     analyticsKey: 'halloween_3',
    // },
];

export const getFrameIndex = (frameName) => {
    if (frameName) {
        return FramesData.findIndex((item) => item.filename === frameName);
    }

    return 0;
};
