import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/types';
import styles from './NotificationBar.css';
import classNames from 'classnames';
import { SnackbarWarningIcon } from '../../FigmaStyleguide/Icons/SnackbarWarningIcon';
import { TickIcon } from '../../FigmaStyleguide/Icons/TickIcon';
import { CheckmarkIcon } from '../Icons/CheckmarkIcon';

export const NotificationBar = () => {
    const { isOpened, message, type } = useSelector((state: AppState) => state.notificationBar);

    if (!isOpened) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.notificationBar, {
                    [styles.error]: type === 'error',
                    [styles.success]: type === 'success',
                })}
            >
                {type === 'error' ? (
                    <SnackbarWarningIcon className={styles.icon} />
                ) : (
                    <CheckmarkIcon className={styles.icon} />
                )}
                <span>{message}</span>
            </div>
        </div>
    );
};
