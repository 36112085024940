import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PrimaryButton } from '../../atoms/Buttons/PrimaryButton';
import { ArenaConfig } from '../../models/ArenaConfig';
import { LocalStorageService } from '../../services/LocalStorage';
import styles from './CookieBanner.css';

type CookieBannerProps = {
    currentLang: string;
    config: ArenaConfig;
};

export const cookieAcceptKey = 'ark-cookie-accepted-california';

class CookieBannerClass extends React.PureComponent<CookieBannerProps & WithTranslation> {
    //@ts-ignore
    state: {
        isVisible: boolean;
    };

    constructor(props) {
        super(props);
        const isVisible = !LocalStorageService.getItem(cookieAcceptKey);

        this.state = { isVisible };
    }

    render() {
        const { isVisible } = this.state;
        const { t } = this.props;
        const isWaPoClient = this.props.config.sso && this.props.config.sso.name === 'washingtonpost';
        const link = isWaPoClient
            ? 'https://www.washingtonpost.com/privacy-policy/2011/11/18/gIQASIiaiN_story.html'
            : `https://www.arkadium.com/privacy-policy/${this.getLinkParams()}`;
        const link2 = isWaPoClient
            ? 'https://www.washingtonpost.com/privacy-policy/2011/11/18/gIQASIiaiN_story.html?tid=a_inl_manual#CALIFORNIA'
            : `https://www.arkadium.com/privacy-policy-california/${this.getLinkParams()}`;

        return (
            <React.Fragment>
                {isVisible && (
                    <Banner>
                        <Content>
                            <Text dangerouslySetInnerHTML={{ __html: t('COOKIE_TEXT', { link, link2 }) }} />
                            <PrimaryButton className={styles.Button} onClick={this.onAccept}>
                                {t('COOKIE_ACCEPT')}
                            </PrimaryButton>
                        </Content>
                    </Banner>
                )}
            </React.Fragment>
        );
    }

    private onAccept = () => {
        localStorage.setItem(cookieAcceptKey, 'accepted');
        this.setState({ isVisible: false });
    };

    private getLinkParams() {
        const lang = this.props.currentLang;

        return lang === 'en' || lang === 'it'
            ? '?utm_medium=referral&utm_source=arena&utm_content=policy'
            : `?utm_medium=referral&utm_source=arena&utm_content=policy&__arklang=${lang}`;
    }
}

const Banner = (props: any) => <div className={styles.Banner} {...props} />;
const Content = (props: any) => <div className={`container ${styles.Content}`} {...props} />;
const Text = (props: any) => <p className={styles.Text} {...props} />;

export const CookieBanner = withTranslation()(CookieBannerClass);
