import { createAction, createReducer } from 'redux-act';

import { RedirectSettings } from '../../models/Redirects';

export const setRedirects = createAction<RedirectSettings[]>('set redirects');

const reducer = createReducer<RedirectSettings[]>({}, []);

reducer.on(setRedirects, (_state, payload: RedirectSettings[] = []) => payload);

export default reducer;
