import { DisplayAdService } from './DisplayAdService';

class AdBlockDetector {

    get isBlocked(): Promise<boolean> {
        return DisplayAdService.adLoaded()
                .then(_ => !window.__ark_ads__)
                .catch(_ => !window.__ark_ads__);
    }

    get isHidden(): boolean {
        const displayAds = document.getElementsByTagName('display-ad-component');

        if (!displayAds.length) {
            return false;
        }

        for (const ad of displayAds) {
            if (window.getComputedStyle(ad).display !== 'none') {
                return false;
            }
        }

        return true;
    }
}

export const adBlockDetector = new AdBlockDetector();
