// import { IGame } from '../../models/Game/Game';
// import { RegistrationSource } from '../../models/User/RegistrationSource';
import { ProfileAnalyticsAi } from './AI/ProfileAnalyticsAi';
import { AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';

export class ProfileAnalytics {
    aiAnalytics = new ProfileAnalyticsAi();

    // loginImpression(category: string, game: IGame): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.loginImpression(category, game));
    // }

    // loginClick(category: string, game: IGame): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.loginClick(category, game));
    // }

    // loginSuccess(category: string, game: any): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.loginSuccess(category, game));
    // }

    // emailOptIn(): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.emailOptIn());
    // }

    // registration(
    //     category: string,
    //     game: any,
    //     source: RegistrationSource,
    //     avatarType: string,
    //     avatarBackground: string
    // ): AnalyticsEventsPair {
    //     return makeEventsPair(
    //         null,
    //         this.aiAnalytics.registration(category, game, source, avatarType, avatarBackground)
    //     );
    // }

    // publicProfileName(): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.publicProfileName());
    // }

    // profileEmail(): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.profileEmail());
    // }

    // personalInformation(fieldName: string, fieldValue?: string): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.personalInformation(fieldName, fieldValue));
    // }

    // publicProfileCountry(country: string): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.publicProfileCountry(country));
    // }

    // profileAvatar(avatarType: string): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.profileAvatar(avatarType));
    // }

    // profileAvatarCustomize(avatarBackground: string, avatarFrame: string): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.profileAvatarCustomize(avatarBackground, avatarFrame));
    // }

    // onboardingPersonal(): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.onboardingPersonal());
    // }

    // onboardingEmail(): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.onboardingEmail());
    // }

    resendConfirmation(): AnalyticsEventsPair {
        return makeEventsPair(null, this.aiAnalytics.resendConfirmation());
    }

    // resetPassword(type: string): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.resetPassword(type));
    // }

    // socialEmailRequest(): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.socialEmailRequest());
    // }

    // registrationMoreAvatars(avatarType: string): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.registrationMoreAvatars(avatarType));
    // }

    // registrationAvatarSkip(avatarType: string): AnalyticsEventsPair {
    //     return makeEventsPair(null, this.aiAnalytics.registrationAvatarSkip(avatarType));
    // }
}
