import { ActionTypes } from '../models/Analytics';
import { ArenaConfig } from '../models/ArenaConfig';
import { Subject } from '../models/Subject';
import { ThirdPartyScriptsKeys } from '../models/ThirdPartyScripts';
import { LocalStorageService } from '../services/LocalStorage';
import { AnalyticsGeneral } from './Analytics/AnalyticsGeneral';
import { AnalyticsInit } from './Analytics/AnalyticsInit';
import { CookieStorageService } from './CookieStorage';

const OT_POPUP_CLOSED = 'OptanonAlertBoxClosed';

export enum OneTrustCategories {
    PERFORMANCE = 'C0002',
    FUNCTIONAL = 'C0003',
    TARGETING = 'C0004',
}

class OneTrustServiceFactory {
    isEnabled: boolean;
    consents: {
        performance;
        functional;
        targeting;
    } = {
        performance: null,
        functional: null,
        targeting: null,
    };

    targetingConsentSubject = new Subject();

    public init(config: ArenaConfig) {
        this.isEnabled = !!config.scripts.public.find((item) => item.name === ThirdPartyScriptsKeys.ONE_TRUST);

        window.addEventListener('load', this.onLoad);

        if (this.isEnabled) {
            window.addEventListener('message', this.onMessage);
        }
    }

    onLoad = () => {
        this.checkOtPopup();
        this.addTargetingCookiesAnalytics();
    }

    onMessage = (event) => {
        if (event.data === 'arkadium:onetrust') {
            const otPopupClosed = CookieStorageService.get(OT_POPUP_CLOSED);

            if (!otPopupClosed) {
                return;
            }

            const previousConsents = this.consents;

            this.updateConsents();

            AnalyticsInit.setGlobalCustomDimensions(undefined, { targetingConsent: this.consents.targeting });
            LocalStorageService.setItem('targetingConsent', this.consents.targeting);

            if (previousConsents.targeting !== this.consents.targeting) {
                this.targetingConsentSubject.next(this.consents.targeting);
            }
        }
    }

    checkOtPopup() {
        const otPopup = document.getElementById('onetrust-banner-sdk');

        if (!otPopup || !otPopup.offsetWidth || !otPopup.offsetHeight) {
            return;
        }

        const otPopupShowed = LocalStorageService.getItem('OTPopupShowed');

        AnalyticsGeneral.oneTrustPopup(!otPopupShowed);

        if (!otPopupShowed) {
            LocalStorageService.setItem('OTPopupShowed', true);
        }

        window.removeEventListener('load', this.onLoad);
    }

    addTargetingCookiesAnalytics() {
        const inputs = document.querySelectorAll('.category-switch-handler');
        const targetingInput = [].slice
            .call(inputs)
            .find((inp) => (inp as HTMLInputElement).nextElementSibling.innerHTML.includes('Targeting Cookies'));
        const confirmBlock = document.querySelector('.ot-button-group');

        confirmBlock?.addEventListener('click', (e) => {
            const targetIsButton = (e.target as HTMLElement).nodeName === 'BUTTON';

            // send analytics only when targeting cookies are switched off and user clicked on button
            if (!targetingInput?.checked && targetIsButton) {
                AnalyticsGeneral.oneTrust(ActionTypes.DISABLED, false);
            }
        });
    }

    private getActiveGroups(): string[] {
        const str = ((window as any).OnetrustActiveGroups as string) || '';

        return str.split(',').filter((item) => !!item);
    }

    private updateConsents() {
        const groups = this.getActiveGroups();
        const isEnabled = (category) => groups.indexOf(category) !== -1;

        this.consents = {
            performance: isEnabled(OneTrustCategories.PERFORMANCE),
            functional: isEnabled(OneTrustCategories.FUNCTIONAL),
            targeting: isEnabled(OneTrustCategories.TARGETING),
        };
    }
}

export const OneTrustService = new OneTrustServiceFactory();
