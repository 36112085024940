import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatNumbers } from '../../../utils';
import { StarIcon } from '../../atoms/Icons/StarIcon';
import { TrophyIcon } from '../../atoms/Icons/TrophyIcon';
import { AchievementEvent } from '../../models/AchievementEvent';
import { UrlService } from '../../services/UrlService';
import styles from './Achievements.css';

type AchievementRowProps = {
    event: AchievementEvent;
    userName: string;
};

const PLACES = ['NUM_FIRST', 'NUM_SECOND', 'NUM_THIRD'];
const AchievementRowComponent = React.memo(({ event, userName, t }: AchievementRowProps & WithTranslation) => {
    const { userTodayRank, score, gameName, slug } = event;
    const isPrizePlace = userTodayRank > 0 && userTodayRank <= 3;
    const placeText = isPrizePlace ? t(PLACES[userTodayRank - 1]) : '';
    const gameUrl = `${UrlService.getWindowBaseHref()}/${t('ROUTES.GAMES')}/${slug}`;

    return (
        <AchievementEventRow>
            <AchievementIconBlock>
                {isPrizePlace && <TrophyIcon place={userTodayRank} />}
                {!isPrizePlace && <StarIconContent />}
            </AchievementIconBlock>

            <AchievementDescBlock>
                {isPrizePlace && (
                    <AchievementDescText
                        dangerouslySetInnerHTML={{
                            __html: t('ACHIEVED_LEADERBOARD_PLACE', {
                                name: userName,
                                place: placeText,
                                score: formatNumbers(score),
                                gameUrl,
                                gameName,
                            }),
                        }}
                    />
                )}
                {!isPrizePlace && (
                    <AchievementDescText
                        dangerouslySetInnerHTML={{
                            __html: t('ACHIEVED_SCORE', {
                                name: userName,
                                score: formatNumbers(score),
                                gameUrl,
                                gameName,
                            }),
                        }}
                    />
                )}
            </AchievementDescBlock>
        </AchievementEventRow>
    );
});
const AchievementEventRow = (props: any) => <div className={`row ${styles.AchievementEventRow}`} {...props} />;
const AchievementIconBlock = (props: any) => <div className={`col-2 ${styles.IconBlock}`} {...props} />;
const StarIconContent = (props: any) => <StarIcon className={styles.StarIconContent} {...props} />;
const AchievementDescBlock = (props: any) => <div className={'col-10'} {...props} />;
const AchievementDescText = (props: any) => <p className={styles.DescText} {...props} />;

export const AchievementRow = withTranslation()(AchievementRowComponent);
