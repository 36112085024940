import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isIE, isServer } from '../../../utils';
import { CloseIcon } from '../../atoms/Icons/CloseIcon';
import { InfoIcon } from '../../atoms/Icons/InfoIcon';
import { AnnouncementMessage } from '../../models/AnnouncementMessage';
import { ArenaConfig } from '../../models/ArenaConfig';
import {
    AnnounceMessageKeys,
    AnnounceMessageTransKeys,
    AnnounceWaPoQueryParams,
    customArenas,
    GameState,
} from '../../models/Enums';
import { Game } from '../../models/Game';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { DeviceDetector } from '../../services/DeviceDetector';
import { LocalStorageService } from '../../services/LocalStorage';
import { UrlService } from '../../services/UrlService';
import { AppState } from '../../store/types';
import styles from './AnnounceBanner.css';

interface AnnounceBannerProps {
    isGamePage?: boolean;
    game?: Game;
    arenaConfig: ArenaConfig;
    gameState?: GameState;
    inHeader?: boolean;
    userAuthStatus: UserAuthStatus;
}

type TProps =
    AnnounceBannerProps
    & WithTranslation;

class AnnounceBannerBase extends React.PureComponent<TProps> {
    state: {
        selectedKey?: string;
        textToShow?: string;
    } = {};

    constructor(props) {
        super(props);

        if (!isServer) {
            const message = this.getMessage();

            if (message) {
                const msg = message.text;

                this.state = {
                    selectedKey: message.key,
                    textToShow: msg,
                };
            }
        }
    }

    componentDidUpdate() {
        const message = this.getMessage();
        const { selectedKey, textToShow } = this.state;

        if (message && selectedKey && textToShow) {
            const msg = message.text;

            this.setState({
                selectedKey: message.key,
                textToShow: msg,
            });
        }
    }

    getMessage(): { key; text } {
        const redirectMsg = UrlService.getRedirectMsg();

        // Redirect Message has top priority
        if (redirectMsg) {
            return {
                key: null,
                text: redirectMsg,
            };
        }

        const message = this.props.arenaConfig.announcementMessages.find((item) => this.shouldShowMessage(item));

        if (message) {
            return {
                key: message.key,
                text: AnnounceMessageTransKeys[message.key],
            };
        }
    }

    // Process rules
    shouldShowMessage(message: AnnouncementMessage) {
        const {
            games,
            showAllTime,
        } = message.rules;

        if (message.key === AnnounceMessageKeys.internetExplorerShown && !isIE()) {
            return false;
        }

        if (
            message.key === AnnounceMessageKeys.eagleMigrationShown &&
            this.props.userAuthStatus !== UserAuthStatus.USER_AUTHORIZED
        ) {
            return false;
        }

        if (games?.length) {
            if (!this.props.game || games.indexOf(this.props.game.slug) === -1) {
                return false;
            }
        }

        if (!showAllTime) {
            const shown = LocalStorageService.getItem(message.key);

            if (Boolean(shown)) {
                return false;
            }
        }

        return true;
    }

    onClose = () => {
        if (this.state.selectedKey) {
            LocalStorageService.setItem(this.state.selectedKey, true);
        }

        const r = document.querySelector(':root') as HTMLElement;

        r.style.setProperty('--announce-banner-height', '0px');

        this.setState({
            selectedKey: null,
            textToShow: null,
        });
    }

    isWapo = (currentDomain) => {
        const wapoDomains = customArenas.wapo;
        const { hostname } = new URL(currentDomain);

        return wapoDomains.includes(hostname);
    }

    getCustomQueryParamsForWapo = () => {
        if (!this.isWapo(this.props.arenaConfig.theme.domain)) {
            return '';
        }

        return AnnounceWaPoQueryParams[this.props.game?.name] || AnnounceWaPoQueryParams['Arena Homepage'];
    }

    render() {
        const {
            isGamePage,
            gameState,
            arenaConfig,
            t,
        } = this.props;
        const { textToShow } = this.state;

        if (!textToShow) {
            return null;
        }

        const isMobileGame = gameState === GameState.GAME && DeviceDetector.isNotPc();

        return (
            <Wrapper isGamePage={isGamePage} isMobileGame={isMobileGame}>
                <Container isGamePage={isGamePage && !isMobileGame}>
                    <MessageBox isMobileGame={isMobileGame}>
                        <InfoIcon className={styles.infoIcon}/>

                        <Text
                            dangerouslySetInnerHTML={{
                                __html: t(textToShow, {
                                    clientName: arenaConfig.theme.name,
                                    queryParam: this.getCustomQueryParamsForWapo(),
                                }),
                            }}
                        />

                        <CloseButton onClick={this.onClose}>
                            <CloseIcon/>
                        </CloseButton>
                    </MessageBox>
                </Container>
            </Wrapper>
        );
    }
}

const Wrapper = ({
                     isGamePage,
                     isMobileGame,
                     ...props
                 }: any) => (
    <div
        className={classNames(styles.wrapper, {
            [styles.isGamePage]: isGamePage,
            [styles.isMobileOnGame]: isMobileGame && DeviceDetector.isMobile(),
            [styles.isTabletOnGame]: isMobileGame && DeviceDetector.isTablet(),
        })}
        {...props}
    />
);
const Container = ({
                       isGamePage,
                       ...props
                   }: any) => (
    <div className={classNames({ ['container']: isGamePage })} {...props} />
);
const MessageBox = ({
                        isMobileGame,
                        ...props
                    }: any) => (
    <div
        className={classNames(styles.messageBox, {
            [styles.isMobileGame]: isMobileGame,
        })}
        {...props}
    />
);
const Text = (props: any) => <div className={styles.text} {...props} />;
const CloseButton = (props: any) => <button className={styles.closeButton} {...props} />;
const AnnounceBannerTranslated = withTranslation()(AnnounceBannerBase);

export const AnnounceBanner = connect((state: AppState) => ({
    arenaConfig: state.config,
    game: state.game,
    gameState: state.gameState,
    userAuthStatus: state.userAuthStatus,
}))(AnnounceBannerTranslated);
