import classNames from 'classnames';
import React from 'react';
import Trophy from '../../../public/svg/Trophy.svg';
import styles from './Icons.css';

export const TrophyIcon = React.memo(({ place, ...props }: { place: number } & any) => (
    <Trophy
        className={classNames(
            styles.trophyIcon,
            {
                [styles.first]: place === 1,
                [styles.second]: place === 2,
                [styles.third]: place === 3,
            },
            props.className
        )}
        aria-hidden="true"
    />
));
