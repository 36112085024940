import React from 'react';
import DefaultTheme from '../../../theme';
import { debounce, isServer } from '../../../utils';

type ResponsiveProps = {
    minWidth?: number;
    maxWidth?: number;
};

const breakpoints = DefaultTheme.breakPoints;

class Responsive extends React.Component<ResponsiveProps> {
    state = {
        render: Date.now(),
    };

    checkMediaQuery(): boolean {
        if (isServer) {
            return !this.props.maxWidth;
        }

        return !!window.matchMedia(this.getQuery()).matches;
    }

    getQuery(): string {
        const { minWidth, maxWidth } = this.props;
        const conditions = [minWidth && `(min-width: ${minWidth}px)`, maxWidth && `(max-width: ${maxWidth}px)`].filter(
            (value) => !!value
        );

        return conditions.join(' and ');
    }

    onResize = debounce(() => {
        this.setState({
            date: Date.now(),
        });
    }, 300);

    componentDidMount() {
        if (!isServer) {
            window.addEventListener('resize', this.onResize, { passive: true });
        }

        this.onResize();
    }

    componentWillUnmount() {
        if (!isServer) {
            window.removeEventListener('resize', this.onResize);
        }
    }

    render() {
        const chk = this.checkMediaQuery();

        if (chk) {
            return this.props.children;
        }

        return null;
    }
}

export const DesktopQuery = (props: any) => {
    return <Responsive {...props} minWidth={breakpoints.lg} />;
};

export const TabletQuery = (props: any) => {
    return <Responsive {...props} minWidth={breakpoints.md} maxWidth={breakpoints.lg - 1} />;
};

export const MobileQuery = (props: any) => {
    return <Responsive {...props} maxWidth={breakpoints.md - 1} />;
};

export const TabletAndDesktopQuery = (props: any) => {
    return <Responsive {...props} minWidth={breakpoints.md} />;
};

export const MobileAndTabletQuery = (props: any) => {
    return <Responsive {...props} maxWidth={breakpoints.lg - 1} />;
};

export const BelowXLQuery = (props: any) => {
    return <Responsive {...props} maxWidth={breakpoints.xl - 1} />;
};

export const AboveXLQuery = (props: any) => {
    return <Responsive {...props} minWidth={breakpoints.xl} />;
};

export const BelowXXLQuery = (props: any) => {
    return <Responsive {...props} maxWidth={breakpoints.xxl - 1} />;
};

export const AboveXXLQuery = (props: any) => {
    return <Responsive {...props} minWidth={breakpoints.xxl} />;
};

export const BelowSMQuery = (props: any) => {
    return <Responsive {...props} maxWidth={breakpoints.sm - 1} />;
};
