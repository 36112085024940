import { default as classNames, default as classnames } from 'classnames';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { stringHas1Uppercase, stringHas8CharactersLong } from '../../../../utils';
import { RegisterButton } from '../../../atoms/Buttons/RegisterButton';
import { TextButton } from '../../../atoms/Buttons/TextButton';
import { CheckmarkIcon } from '../../../atoms/Icons/CheckmarkIcon';
import { ELoginSteps } from '../../../store/ducks/modal';
import styles from './PopupDumbComponents.css';

type TProps = {
  text: string | React.ReactNode;
  isEagle: boolean;
  onClick?: (e: any) => any;
  step?: ELoginSteps;
  className?: string;
};
const PopupTitle = React.memo(({
  text,
  isEagle,
  className,
}: TProps) => {
  return (
    <div>
      <h3
        className={classnames(styles.popupTitle, className, {
          [styles.eagle]: isEagle,
        })}
      >
        {text}
      </h3>
    </div>
  );
});

PopupTitle.displayName = 'PopupTitle';

const PopupDescription = React.memo(({
  text,
  isEagle,
  step,
  className,
}: TProps) => {
  return (
    <div
      className={classnames(styles.popupDescription, className, {
        [styles.eagle]: isEagle,
        [styles.customMarginBottom]: step === ELoginSteps.REQUEST_EMAIL,
      })}
    >
      {text}
    </div>
  );
});

PopupDescription.displayName = 'PopupDescription';

type TCallToActionTextProps = {
  isEagle: boolean;
  wrapperClassName?: string;
  actionClassName?: string;
  actionText: string;
  buttonText: string;
  onClick: () => void;
  step?: ELoginSteps;
  defaultOrder?: boolean;
  noMargin?: boolean;
  buttonFirst?: boolean;
  defaultCursor?: boolean;
};
const CallToActionText = React.memo(
  ({
    actionText,
    isEagle,
    onClick,
    buttonText,
    wrapperClassName,
    actionClassName,
    step,
    defaultOrder,
    noMargin,
    buttonFirst,
    defaultCursor,
  }: TCallToActionTextProps) => {
    const RegButton = isEagle ? TextButton : RegisterButton;

    return (
      <div
        className={classnames(styles.createAccountRow, wrapperClassName, {
          [styles.eagle]: isEagle,
          [styles.giantMarginBottom]: step === ELoginSteps.REQUEST_EMAIL,
          [styles.defaultOrder]: defaultOrder,
          [styles.noMargin]: noMargin,
        })}
      >
        <div
          className={classnames(styles.createAccount, actionClassName, {
            [styles.eagle]: isEagle,
            [styles.buttonFirst]: buttonFirst,
          })}
        >
          {actionText}&nbsp;
          <RegButton
            onClick={onClick}
            text={buttonText}
            isEagle={isEagle}
            defaultCursor={defaultCursor}
          />
        </div>
      </div>
    );
  },
);

CallToActionText.displayName = 'CallToActionText';
type TPasswordRequirements = { password: string; touched: boolean; tWithPrefix: WithTranslation['t'] };
const PasswordRequirements = React.memo(({
  password,
  touched,
  tWithPrefix,
}: TPasswordRequirements) => {
  const { isValid: isLengthValid } = stringHas8CharactersLong(password);
  const { isValid: isUppercaseValid } = stringHas1Uppercase(password);

  return (
    <div className={classNames(styles.passwordRequirementsRow)}>
      <span className={classNames(styles.passwordRequirementsText)}>{tWithPrefix('PASSWORD_REQUIREMENTS')}</span>
      <div>
        <span
          className={classNames({
            [styles.valid]: touched && isLengthValid,
            [styles.error]: touched && !isLengthValid,
          })}
        >
          <CheckmarkIcon/>
          {tWithPrefix('PASSWORD_LENGTH')}
        </span>
        <span
          className={classNames(styles.uppercase, {
            [styles.valid]: touched && isUppercaseValid,
            [styles.error]: touched && !isUppercaseValid,
          })}
        >
          <div>
            <CheckmarkIcon/>
            {tWithPrefix('PASSWORD_UPPERCASE')} {tWithPrefix('PASSWORD_UPPERCASE_LETTER')}
          </div>
        </span>
      </div>
    </div>
  );
});

PasswordRequirements.displayName = 'PasswordRequirements';
export { PopupTitle, PopupDescription, CallToActionText, PasswordRequirements };
