import { PageTypes } from '../../constants/Pages';
// import { GemsAnalyticsShopLocations } from '../../store/ducks/leanplum/lpAnalytics';
import { GemsAnalyticsAi, GemsAnalyticsShopLocations, GemsDimensionsMocks } from './AI/GemsAnalyticsAi';
import { AnalyticsEvent, AnalyticsEventsPair, makeEventsPair } from './AnalyticsEventBuilder';
import { GoogleAnalytics } from './Google';
import { ActionTypes } from '../../models/Analytics';
import { AnalyticsInit } from './AnalyticsInit';

export class GemsAnalytics {
    aiAnalytics = new GemsAnalyticsAi();

    async gemShopImpression(shopLocation: GemsAnalyticsShopLocations | PageTypes): Promise<AnalyticsEvent> {
        // when user sees gem shop in sidebar
        return await this.aiAnalytics.gemShopImpression(shopLocation);
    }
    async gemPurchaseClick(
        shopLocation: GemsAnalyticsShopLocations | PageTypes,
        internalPrice,
        purchasableItemId,
        purchasableItemAmount
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEvent> {
        // when user clicks on gem card to purchase
        return await this.aiAnalytics.gemPurchaseClick({
                shopLocation,
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            });
    }
    async gemPurchaseClickImpression(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEvent> {
        // when user sees purchase step 1
        return await this.aiAnalytics.gemPurchaseClickImpression({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            });
    }
    async gemPurchaseNextStepClick(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEvent> {
        // when user clicks on submit on step 1
        return await this.aiAnalytics.gemsPurchaseNextStepClick({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            });
    }
    async gemPurchaseNextStepImpression(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEvent> {
        // when user sees purchase step 2
        return await this.aiAnalytics.gemsPurchaseNextStepImpression({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            });
    }
    async gemPurchaseChangeGems(
        shopLocation: GemsAnalyticsShopLocations | PageTypes,
        internalPrice,
        purchasableItemId,
        purchasableItemAmount
        // specialMark = GemsDimensionsMocks.SPECIAL_MARK
    ): Promise<AnalyticsEvent> {
        // when user clicks on "update my gems pack" to change pack
        return await this.aiAnalytics.gemsPurchaseChangeGems({
                shopLocation,
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                specialMark: GemsDimensionsMocks.SPECIAL_MARK,
            });
    }
    async gemPurchaseSuccess(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount,
        shopLocation
    ): Promise<AnalyticsEvent> {
        // when user clicks on "confirm" to finalize purchase
        return await this.aiAnalytics.gemsPurchaseSuccess({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
                shopLocation,
            });
    }
    async gemPurchaseSuccessImpression(
        internalPrice,
        purchasableItemId,
        purchasableItemAmount
    ): Promise<AnalyticsEvent> {
        // when user sees successful purchase screen
        return await this.aiAnalytics.gemsPurchaseSuccessImpression({
                internalPrice,
                purchasableItemId,
                purchasableItemAmount,
            });
    }

    // GAME SPENDING GEMS

    async gemSkipAdButton(internalPrice): Promise<AnalyticsEvent> {
        // when user clicks on btn to pass ad for gem
        const googleEvent = {
            action: ActionTypes.CLICK,
            name: 'Video_Ad_Skip_Click ',
            label: `${AnalyticsInit.domain} | ${AnalyticsInit.gameSlug}`,
            nonInteraction: false,
        };

        GoogleAnalytics.trackEvent(googleEvent);
        return await this.aiAnalytics.gemSkipAdButton({ internalPrice });
    }
    async gemSpendingSkipAdSuccess(internalPrice): Promise<AnalyticsEvent> {
        // when user spent gem and skipped ad
        return await this.aiAnalytics.gemSpendingSkipAdSuccess({ internalPrice });
    }
    async gemSpendingPowerUp(virtualItemId, internalPrice): Promise<AnalyticsEventsPair> {
        // when user spent gem for in-game boost or power-up
        return makeEventsPair(null, await this.aiAnalytics.gemSpendingPowerUp({ virtualItemId, internalPrice }));
    }

    // Advantage subscriptions marketing

    async gemShopSignInUp(registrationLocation, isRegistration = false): Promise<AnalyticsEventsPair> {
        // when user signed in in GEM SHOP TAB... UPD: any register location
        return makeEventsPair(null, await this.aiAnalytics.gemShopSignInUp(registrationLocation, isRegistration));
    }
    async gemShopSubscribeBanner(): Promise<AnalyticsEventsPair> {
        // when user clicked AA promo banner in GEM SHOP TAB
        return makeEventsPair(null, await this.aiAnalytics.gemShopSubscribeBanner());
    }
    async manageGems(shopLocation: GemsAnalyticsShopLocations | PageTypes): Promise<AnalyticsEventsPair> {
        // when user clicked on 'manage gems' in GEM SHOP TAB => check purchases in profile for now
        return makeEventsPair(null, await this.aiAnalytics.manageGems(shopLocation));
    }
}
