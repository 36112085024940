import classnames from 'classnames';
import React from 'react';
import { ArkButtonIcon } from '../Icons/ArkButtonIcon';
import { LoaderIcon } from '../Icons/LoaderIcon';
import styles from './SignInUpButton.css';

export const ESignInUpButtonTheme = {
    OLD: styles.old,
    EAGLE_GREEN: styles.eagleGreen,
    EAGLE_RED: styles.eagleRed,
} as const;

export const ESignInUpButtonSize = {
    MEDIUM: styles.medium,
    SMALL: styles.small,
};

type TProps = {
    text: string;
    isEagle: boolean;
    onClick: () => void;
    theme: string;
    isLoading: boolean;
    size?: string;
    className?: string;
    isRegisterForm?: boolean;
    withoutIcon?: boolean;
    disabled?: boolean;
};
const SignInUpButton = React.memo(
    ({
        text,
        isEagle,
        onClick,
        className,
        theme,
        isLoading,
        isRegisterForm,
        withoutIcon,
        size = ESignInUpButtonSize.MEDIUM,
        disabled,
    }: TProps) => {
        const Loader = <LoaderIcon className={classnames(styles.loaderIcon, { [styles.hidden]: !isLoading })} />;
        const renderButtonContent = () => (
            <>
                {isEagle && !withoutIcon && <ArkButtonIcon className={styles.arkButtonIcon} />}
                <span
                    className={classnames(styles.buttonText, {
                        [styles.nomargin]: isRegisterForm || !isEagle || withoutIcon,
                    })}
                >
                    {text}
                </span>
            </>
        );

        return (
            <button
                type="button"
                onClick={isLoading || disabled ? null : onClick}
                className={classnames(className, styles.signInUpButton, theme, size, { [styles.isLoading]: isLoading })}
                disabled={disabled}
            >
                {isLoading ? Loader : renderButtonContent()}
            </button>
        );
    }
);

SignInUpButton.displayName = 'SignInUpButton';

export { SignInUpButton };
