import React from 'react';

import classNames from 'classnames';

import styles from './Button.css';

type ButtonProps = {
    focusable?: boolean;
    className?: string;
    onClick?: any;
    onMouseOut?: () => void;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
    onMouseMove?: () => void;
    onDragStart?: () => void;
    onMouseEnter?: () => void;
    onMouseDown?: () => void;
    draggable?: boolean;
    children?: any;
    noPadding?: boolean;
    fullWidth?: boolean;
    style?: React.CSSProperties;
    ariaLabel?: string;
    ariaChecked?: boolean;
    ariaDescribedBy?: string;
    role?: string;
    type?: 'submit' | 'reset' | 'button';
    id?: string;
    required?: boolean;
};

export const Button = React.memo((props: ButtonProps) => {
    const {
        className,
        onClick,
        children,
        noPadding,
        fullWidth,
        style,
        ariaLabel,
        role,
        ariaChecked,
        type,
        focusable = true,
        ariaDescribedBy,
        id,
        required = false,
        ...restProps
    } = props;

    return (
        <button
            className={classNames(styles.Base, className, noPadding && styles.noPadding, fullWidth && styles.fullWidth)}
            style={style}
            onClick={onClick}
            {...restProps}
            aria-label={ariaLabel}
            aria-checked={ariaChecked}
            role={role}
            type={type}
            tabIndex={focusable ? 0 : -1}
            aria-describedby={ariaDescribedBy}
            id={id}
            aria-required={required}
        >
            {children}
        </button>
    );
});

Button.displayName = 'Button';
