import { createAction, createReducer } from 'redux-act';
import { AnalyticsSortings } from '../../models/AnalyticsSortings';

export interface SortingsState {
    manual: any[];
    analytics: AnalyticsSortings;
}

export const defaultSortingsState: SortingsState = { manual: [], analytics: { pc: [], tablet: [], mobile: [] } };

export const setSortings = createAction<SortingsState>('set sorting');

const reducer = createReducer<SortingsState>({}, defaultSortingsState);

reducer.on(setSortings, (_state, payload: any = null) => Object.assign({}, _state, payload));

export default reducer;
