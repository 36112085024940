import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { Game } from '../../models/Game';
import { EagleUser, User } from '../../models/User';
import { adBlockDetector } from '../../services/AdBlockerService';
import { DeviceDetector, DeviceType } from '../../services/DeviceDetector';
import { widgetSizeService } from '../../services/WidgetSizeService';
import { AppState } from '../../store/types';
import styles from './FreshDesk.css';

let wasLoaded = false;

interface CustomFields {
    os;
    osVersion;
    browser;
    browserVersion;
    gameName?;
    hostName;
    url?;
    mobile;
    deviceType;
    isAdBlockerActive;
    windowSize?;
    screenSize?;
    gameSize?;
}

type FreshDeskProps = {
    className?: string;
    currentLang: string;
    game: Game;
    t: any;
    freshDeskId: string;
    user: User | EagleUser;
};

type FreshDeskState = {
    wasLoaded: boolean | null;
    isBlocked: boolean | null;
    hasIcon: boolean;
    isHelpPage: boolean;
};

class FreshDeskBase extends React.PureComponent<FreshDeskProps, FreshDeskState> {
    constructor(props) {
        super(props);
        this.state = {
            wasLoaded: null,
            isBlocked: null,
            hasIcon: Array.isArray(this.props.children) && this.props.children.length > 1,
            isHelpPage: false,
        };
    }
    static showWidget() {
        const w = window as any;

        if (w.FreshworksWidget) {
            w.FreshworksWidget('open');
        }
    }
    jscd: CustomFields = null;
    iconRef: JSX.Element = null;

    componentDidMount() {
        if (this.state.hasIcon) {
            this.iconRef = this.props.children[0] as JSX.Element;
        }

        if (window.location.href.includes(this.props.t('ROUTES.HELP'))) {
            this.setState({ isHelpPage: true });
        }

        adBlockDetector.isBlocked.then((isBlocked) => {
            this.setState({ isBlocked });
        });
    }

    init() {
        const w = window as any;

        if (!w.FreshworksWidget) {
            const customFreshDeskId = this.props.freshDeskId;
            const defaultFreshDeskId = '44000000137';
            const freshDeskId = Boolean(customFreshDeskId) ? customFreshDeskId : defaultFreshDeskId;
            // hide default freshdesk button
            const css = `#launcher-frame { display:none; }`;
            const style = document.createElement('style');

            style.type = 'text/css';
            document.head.appendChild(style);
            style.appendChild(document.createTextNode(css));

            // load freshdesk widget scripts (snippet 1)
            const script1 = document.createElement('script');

            script1.text = `
                window.fwSettings = {"widget_id": ${freshDeskId}, "locale": "${this.props.currentLang}"};
                !function () {
                    if ("function" != typeof window.FreshworksWidget) {
                        var n = function () {
                            n.q.push(arguments)
                        };
                        n.q = [], window.FreshworksWidget = n
                    }
                }();
            `;
            document.head.appendChild(script1);

            // load freshdesk widget scripts (snippet 2)
            const script2 = document.createElement('script');

            script2.src = `https://widget.freshworks.com/widgets/${freshDeskId}.js`;
            script2.async = true;
            script2.defer = true;
            script2.onload = () => this.setState({ wasLoaded: true });
            document.head.appendChild(script2);
        } else {
            //already loaded from another place (like help sidebar then click in footer)
            this.setState({ wasLoaded: true });
        }
    }

    prepareUserData(isAdBlockerActive) {
        const { t } = this.props;
        // browser
        const browserInfo = DeviceDetector.detectBrowser();
        let browserVersion = browserInfo.version;

        try {
            const v = browserInfo.version;

            browserVersion = v.substring(0, v.indexOf('.'));
        } catch (e) {}

        // is mobile device (phone or tablet)
        const deviceType = DeviceDetector.detectDevice();
        const mobile = deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET;
        // operating system
        const osInfo = DeviceDetector.detectOS();
        const os = osInfo.name;
        const osVersion = osInfo.version;

        this.jscd = {
            isAdBlockerActive,
            browser: browserInfo.name,
            browserVersion,
            mobile,
            deviceType,
            os,
            osVersion,
            hostName: window.location.host,
        };
    }

    localizeLabels() {
        const { t, currentLang } = this.props;
        const labels = {
            banner: t('FD.BANNER'),
            launcher: t('FD.LAUNCHER'),
            contact_form: {
                title: t('FD.TITLE'),
                submit: t('FD.SUBMIT'),
                confirmation: t('FD.CONFIRMATION'),
            },
        };

        (window as any).FreshworksWidget('setLabels', { [currentLang]: labels });
    }

    fillTheData() {
        const w = window as any;

        w.FreshworksWidget('prefill', 'ticketForm', {
            subject: this.props.game ? this.props.game.name : '',
            custom_fields: {
                cf_game_tag: this.props.game ? this.props.game.slug : '',
                cf_full_url: window.location.href,
                cf_screen_size: widgetSizeService.getScreenSize(),
                cf_window_size: widgetSizeService.getWindowSize(),
                cf_game_size: widgetSizeService.getGameSize(),
                cf_os: this.jscd.os + ' ' + this.jscd.osVersion,
                cf_browser: this.jscd.browser + ' ' + this.jscd.browserVersion,
                cf_arena_url: this.jscd.hostName,
                cf_is_mobile: this.jscd.mobile,
                cf_device_type: this.jscd.deviceType,
                cf_is_adblock_active: this.jscd.isAdBlockerActive,
                cf_user_email: this.props.user ? this.props.user.email : '',
            },
        });
    }

    hideFields() {
        const w = window as any;

        w.FreshworksWidget('hide', 'ticketForm', [
            'custom_fields.cf_game_tag',
            'custom_fields.cf_full_url',
            'custom_fields.cf_screen_size',
            'custom_fields.cf_window_size',
            'custom_fields.cf_game_size',
            'custom_fields.cf_os',
            'custom_fields.cf_browser',
            'custom_fields.cf_arena_url',
            'custom_fields.cf_is_mobile',
            'custom_fields.cf_device_type',
            'custom_fields.cf_is_adblock_active',
            'custom_fields.cf_language',
            'custom_fields.cf_advantage',
            'custom_fields.cf_bonus',
            'custom_fields.cf_expired_subscriptions',
            'custom_fields.cf_user_email',
        ]);
    }

    show = () => {
        if (this.state.wasLoaded === null) {
            this.setState({ wasLoaded: false });
            this.init();
            this.prepareUserData(this.state.isBlocked);
            this.localizeLabels();
            this.fillTheData();
            this.hideFields();
        }

        FreshDeskBase.showWidget();
    };

    renderContentWithIcon = () => {
        const customClassName = this.state.isHelpPage ? styles.loaderForHelpPage : styles.loaderForSidebar;

        return (
            <>
                {this.state.wasLoaded === false ? (
                    <AppLoader customClassName={customClassName} />
                ) : (
                    this.props.children[0]
                )}
                {this.props.children[1]}
            </>
        );
    };

    renderContentWithoutIcon = () => (
        <>
            {this.props.children}
            {this.state.wasLoaded === false && <AppLoader customClassName={styles.loaderWithoutIcon} />}
        </>
    );

    render() {
        return (
            <ContactUsButton
                className={`${this.props.className ?? ''} ${!this.state.hasIcon && styles.flex}`}
                onClick={this.show}
            >
                {this.state.hasIcon ? this.renderContentWithIcon() : this.renderContentWithoutIcon()}
            </ContactUsButton>
        );
    }
}

const ContactUsButton = ({ className, ...props }) => (
    <button className={`${styles.contactUsButton} ${className}`} {...props} />
);
const FreshDeskTranslated = withTranslation()(FreshDeskBase);

export const FreshDesk = connect((state: AppState) => ({
    currentLang: state.currentLang,
    game: state.game,
    freshDeskId: state.config.theme.freshDeskId,
    user: state.user,
}))(FreshDeskTranslated);
