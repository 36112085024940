import { environment } from '../config/environment';
import { Game } from '../models/Game';
import { SlotParamsService } from './SlotAppParamsService';
import { getAssetUrl } from '../../utils';

// SSR origin URL, not current Arena URL origin
const origin = SlotParamsService.params.ssrOrigin;
const SHARED_BLOB = `${environment.X_INDEX_BLOB}/_arena-shared-content_`;
// Cache asset URL to avoid redundant calls
const assetUrl = getAssetUrl();

export class AssetService {

    static getAvatar(avatar: string) {
        return this.buildAssetPath('avatars', avatar);
    }

    static getFlag(country: string) {
        const countryCode = (country ?? 'us').toLowerCase();

        return this.buildAssetPath('flags', `${countryCode}.svg`);
    }

    static getArenaPwaIcon(name: string, size: string) {
        return this.buildAssetPath('pwa/icons', `${name}_${size}.png`);
    }

    static getArenaPwaSplash(name: string) {
        return this.buildAssetPath('pwa/splash', `${name}.png`);
    }

    static getGamePwaIcon(game: Game, size: string) {
        return `${game.assetOriginUrl}${game[`squareIcon${size}`]}`;
    }

    static shared(path = '') {
        return SHARED_BLOB + path;
    }

    static sharedRelativeToOrigin(absoluteOrigin: string, path = '') {
        return path.startsWith('https') ? path : `${absoluteOrigin}/arenaxstorage-blob/arenax-index/_arena-shared-content_${path}`;
    }

    // Helper function to build asset paths
    private static buildAssetPath(folder: string, fileName: string) {
        return `${assetUrl}/${folder}/${fileName}`;
    }
}
