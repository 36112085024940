import { UAParser } from 'ua-parser-js';

export enum DeviceType {
    DESKTOP = 'pc',
    TABLET = 'tablet',
    MOBILE = 'mobile',
}

export interface BrowserInfo {
    name: string;
    version: string;
    major: string;
}

export interface OSInfo {
    name: string;
    version: string;
}

const parser: UAParser = new UAParser();

export class DeviceDetector {
    // This is not 100% correct behavior, but copies Arena 5.0
    static detectDevice(): DeviceType {
        const info = parser.getDevice();

        if (info.type === 'mobile') {
            return DeviceType.MOBILE;
        }

        if (DeviceDetector.isPad() || info.type === 'tablet' || info.vendor === 'Kindle') {
            return DeviceType.TABLET;
        }

        return DeviceType.DESKTOP;
    }

    static isNotPc() {
        return [DeviceType.TABLET, DeviceType.MOBILE].indexOf(DeviceDetector.detectDevice()) !== -1;
    }

    static isDesktop() {
        return DeviceDetector.detectDevice() === DeviceType.DESKTOP;
    }

    static isTablet() {
        return DeviceDetector.detectDevice() === DeviceType.TABLET;
    }

    static isMobile() {
        return DeviceDetector.detectDevice() === DeviceType.MOBILE;
    }

    static detectBrowser(): BrowserInfo {
        const info = parser.getBrowser();

        return {
            name: info.name || '',
            version: info.version,
            major: info.major,
        };
    }

    static isPad(): boolean {
        const os = DeviceDetector.detectOS();

        if (os && os.name) {
            return os.name === 'Mac OS' && navigator.maxTouchPoints > 1;
        }

        return false;
    }

    static detectOS(): OSInfo {
        const osInfo = parser.getOS();

        return {
            name: osInfo.name,
            version: osInfo.version,
        };
    }

    static isIOS(): boolean {
        const osInfo = this.detectOS();

        return osInfo.name === 'iOS';
    }
}
