import classnames from 'classnames';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { WithTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TEmailField, TErrorValidation, validateEmail } from '../../../../utils';
import { ESignInUpButtonTheme, SignInUpButton } from '../../../atoms/Buttons/SignInUpButton';
import { TextButton } from '../../../atoms/Buttons/TextButton';
import { ETextInputFields, ETextInputOrdering, ETextInputTypes, TextInput } from '../../../atoms/Inputs/TextInput';
import { EagleLoginService } from '../../../services/EagleLoginService';
import { setShowRecaptcha } from '../../../store/ducks/recaptcha';
import { RECAPTCHA_ACTIONS, RECAPTCHA_ACTIONS_TYPE, RECAPTCHA_MODES } from '../../ChallengeCaptcha/hooks/useCaptcha';
import { ErrorsList } from '../ErrorsList';
import commonStyles from '../LoginPopup.css';
import { PopupDescription, PopupTitle } from '../PopupDumbComponents/PopupDumbComponents';
import styles from './ForgotPasswordForm.css';

type TProps = {
  openLoginForm: () => void;
  openAfterSignForm: (isThankYouForm?: boolean) => void;
  arenaDomainURL: URL;
  arenaLocale: string;
  tWithPrefix: WithTranslation['t'];
  email: string;
  saveEmail: (email: string) => void;
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  captchaToken: string;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
};

const ForgotPasswordForm = React.memo(
  ({
    tWithPrefix,
    openLoginForm,
    openAfterSignForm,
    arenaDomainURL,
    arenaLocale,
    email,
    saveEmail,
    getCaptchaToken,
    captchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
  }: TProps) => {
    const dispatch = useDispatch();
    const [emailValue, setEmailValue] = useState<string>(email ?? '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<Array<TErrorValidation<TEmailField | ''>>>([]);
    const [eagleError, setEagleError] = useState<string>('');
    const handleEmail = (e: ChangeEvent<HTMLInputElement>) => setEmailValue(e.target.value.trim());
    const handleForgotPasswordButtonClick = () => {
      const {
        isValid,
        errors: emailErrors,
      } = validateEmail(emailValue);

      setErrors([]);
      setEagleError('');

      if (isValid) {
        setIsLoading(true);
        saveEmail(emailValue);
        getCaptchaToken(RECAPTCHA_ACTIONS.PASSWORD_RESET);
      }

      setErrors(emailErrors);
    };

    useEffect(() => {
      if (captchaToken) {
        void fetchForgotPassword();
      }
    }, [captchaToken]);

    const fetchForgotPassword = async () => {
      try {
        await EagleLoginService.requestResetPassword({
          email: emailValue,
          resetPasswordConfirmationFormUrl: arenaDomainURL,
          registrationPlaceUrl: arenaDomainURL,
          languageId: arenaLocale.toUpperCase(),
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
        });

        handleForgotPasswordSuccess();
      } catch (err) {
        handleForgotPasswordError(err);
      }
    };
    const handleForgotPasswordSuccess = () => {
      setIsLoading(false);
      clearCaptchaData();
      openAfterSignForm(true);
    };
    const handleForgotPasswordError = (err: number) => {
      if (err === 1023) {
        dispatch(setShowRecaptcha(true));
      } else {
        setIsLoading(false);
        clearCaptchaData();
        setErrors([
          {
            field: '',
            description: err.toString(),
          },
        ]);
      }
    };

    return (
      <>
        {!showChallengeRecaptcha && (
          <>
            <div className={commonStyles.popupHeader}>
              <PopupTitle text={tWithPrefix('FORGOT_YOUR_PASSWORD')} isEagle/>
              <PopupDescription text={tWithPrefix('FORGOT_YOUR_PASSWORD_DESCRIPTION')} isEagle/>
            </div>
            <div
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleForgotPasswordButtonClick();
                }
              }}
              role="presentation"
              className={classnames(commonStyles.defaultContentWrapper, styles.eagle)}
            >
              <ErrorsList tWithPrefix={tWithPrefix} errors={errors}>
                {eagleError && <div>{eagleError}</div>}
              </ErrorsList>
              <div>
                <TextInput
                  field={ETextInputFields.EMAIL}
                  errors={errors}
                  placeholder={tWithPrefix('YOUR_EMAIL')}
                  type={ETextInputTypes.TEXT}
                  value={emailValue}
                  onChange={handleEmail}
                  ordering={ETextInputOrdering.ONLY_ONE}
                />
                <div className={classnames(styles.resetPasswordRow, styles.eagle)}>
                  <TextButton isEagle onClick={openLoginForm} text={tWithPrefix('CANCEL')}/>

                  <SignInUpButton
                    isEagle
                    theme={ESignInUpButtonTheme.EAGLE_GREEN}
                    isLoading={isLoading}
                    onClick={handleForgotPasswordButtonClick}
                    text={tWithPrefix('RESET')}
                    withoutIcon
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  },
);

ForgotPasswordForm.displayName = 'ForgotPasswordForm';
export { ForgotPasswordForm };
