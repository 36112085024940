import React, { RefObject } from 'react';
import styles from './Game.css';

type CanvasProps = {
    id: string;
    canvasBoxRef: RefObject<HTMLIFrameElement>;
    onIframeLoad: () => void;
    iframeSourceCode: string;
    isIframeGame: boolean;
};

export class GameCanvas extends React.PureComponent<CanvasProps> {
    static displayName = 'GameCanvas';

    render() {
        const { id, canvasBoxRef, onIframeLoad, iframeSourceCode, isIframeGame } = this.props;

        if (isIframeGame) {
            return (
                <CanvasBoxIframe
                    aria-hidden="true"
                    src={iframeSourceCode}
                    id={id}
                    ref={canvasBoxRef}
                    onIframeLoad={onIframeLoad}
                    isIframeGame={isIframeGame}
                />
            );
        } else {
            return <CanvasBoxDiv aria-hidden="true" id={id} />;
        }
    }
}

// Class 'game' is mandatory, since some crosswords disable scroll by this class
const CanvasBoxIframe = React.forwardRef<any, any>(({ onIframeLoad, isIframeGame, ...props }, ref) => (
    <iframe
        src={props.src}
        title="canvas box"
        id="canvas-box"
        className={`game ${styles.canvasBox}`}
        {...props}
        ref={ref}
        onLoad={onIframeLoad}
        allow={`clipboard-read *; clipboard-write *;`}
    />
));
const CanvasBoxDiv = ({ ...props }: any) => <div className={`game ${styles.canvasBox}`} {...props} />;
