import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../atoms/Modal/Modal';
import { ShopBanner } from '../../atoms/ShopBanner/ShopBanner';
import { IframeMessageTypes } from '../../models/Enums';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { AuthType, EagleLoginService, EmailCodes, PaymentQueryParams } from '../../services/EagleLoginService';
import GemsService from '../../services/GemsService';
import { UrlService } from '../../services/UrlService';
import { setGemsAmount } from '../../store/ducks/gems';
import {
  ELoginSteps,
  setLoginModal,
  setLoginOpen,
  setLoginPrevStep,
  setLoginStep,
  setShopOpen,
} from '../../store/ducks/modal';
import { setRecaptchaAction, setRecaptchaToken, setShowRecaptcha } from '../../store/ducks/recaptcha';
import { loginUser, logoutUser } from '../../store/ducks/user';
import { setUserAuthStatus } from '../../store/ducks/userAuthStatus';
import { AppState } from '../../store/types';
import { Login } from './Login';
import styles from './Login.css';
import { setNotificationBar } from '../../store/ducks/notificationBar';
import { setSnackbarData } from '../../store/ducks/layout';
import { GRM } from '../../../modules/GameRenderingModule';

const LoginModalBase = ({ t }: WithTranslation) => {
    const {
      isOpen,
      step,
      fromShopModal,
      fromSkipAdButton,
      userAuthStatus,
      ssoName,
    } = useSelector((state: AppState) => ({
      isOpen: state.modal.login.isOpen,
      step: state.modal.login.step,
      fromShopModal: state.modal.login.fromShopModal,
      fromSkipAdButton: state.modal.login.fromSkipAdButton,
      isEagle: state.config.isEagle,
      userAuthStatus: state.userAuthStatus,
      ssoName: state.config.sso.name,
    }));
    const history = useHistory();
    const dispatch = useDispatch();
    const [recoveryCode, setRecoveryCode] = useState<string>();
    const [authType, setAuthType] = useState<AuthType>(AuthType.EmailPassword);
    const [isResendEmailForm, setIsResendEmailForm] = useState<boolean>(false);
    const [isResendEmailFormInputDisabled, setIsResendEmailFormInputDisabled] = useState<boolean>(false);

    useEffect(() => {
      const isUserAuthorized = userAuthStatus === UserAuthStatus.USER_AUTHORIZED;

      void fetchUserGemsAmount(isUserAuthorized, fromShopModal);
    }, [userAuthStatus]);

    const fetchUserGemsAmount = async (isUserAuthorized: boolean, fromShop: boolean) => {
      try {
        const gemsAmount = await GemsService.getUserGemsAmount();

        handleUserGemsAmountSuccess(gemsAmount, isUserAuthorized, fromShop);
      } catch (err) {
        console.error(err);
      }
    };
    const handleUserGemsAmountSuccess = (gemsAmount: number, isUserAuthorized: boolean, fromShop: boolean) => {
      dispatch(setGemsAmount(gemsAmount));

      if (isUserAuthorized && fromShop) {
        dispatch(setLoginModal({
          isOpen: false,
          step: ELoginSteps.LOGIN,
          fromShopModal: false,
        }));
        history.push(`/${t('ROUTES.SHOP')}`);
      }

      if (isUserAuthorized && fromSkipAdButton) {
        dispatch(
          setLoginModal({
            isOpen: false,
            step: ELoginSteps.LOGIN,
            fromShopModal: false,
            fromSkipAdButton: false,
          }),
        );

        if (!gemsAmount) {
          dispatch(setShopOpen(true));
        }
      }
    };

    useEffect(() => {
      const logoutCode = UrlService.getQSParam(window.location.search, PaymentQueryParams.LOGOUT_CODE);

      if (logoutCode) {
        dispatch(setLoginModal({
          isOpen: true,
          step: ELoginSteps.LOGIN,
          fromShopModal: true,
        }));
        history.replace('');
      }

      const confirmationCode = UrlService.getQSParam(window.location.search, EmailCodes.REGISTER_CONFIRMATION_CODE);
      const recoveryCodeFromQuery = UrlService.getQSParam(window.location.search, EmailCodes.RESET_PASSWORD_CODE);
      const emailChangeConfirmationCode = UrlService.getQSParam(
        window.location.search,
        EmailCodes.EMAIL_CHANGE_CONFIRMATION_CODE,
      );

      if (confirmationCode || emailChangeConfirmationCode) {
        void fetchConfirmUser(confirmationCode, emailChangeConfirmationCode);
      }

      if (recoveryCodeFromQuery) {
        setRecoveryCode(recoveryCodeFromQuery);
        dispatch(setLoginModal({
          isOpen: true,
          step: ELoginSteps.RESET_PASSWORD,
        }));
        history.replace('');
      }

      window.addEventListener('message', onLoginModalMessage);

      return () => window.removeEventListener('message', onLoginModalMessage);
    }, []);

    const fetchConfirmUser = async (confirmationCode?: string, emailChangeConfirmationCode?: string) => {
      const confirmUser = confirmationCode
        ? EagleLoginService.confirmUser
        : EagleLoginService.confirmEmailChangeUser;

      try {
        await confirmUser({
          code: confirmationCode || emailChangeConfirmationCode,
        });
        handleConfirmUserSuccess();
      } catch (err) {
        handleConfirmUserError(err, confirmationCode);
      }
    };
    const handleConfirmUserSuccess = () => {
      if (ssoName !== 'hsn') {
        dispatch(setLoginModal({
          isOpen: true,
          step: ELoginSteps.LOGIN,
        }));
        dispatch(
          setNotificationBar({
            isOpened: true,
            message: t('LOGIN.EAGLE.REGISTER_CONFIRM_MAIL_MESSAGE'),
            type: 'success',
          }),
        );
        history.replace('');
      }
    };
    const handleConfirmUserError = (err: number, confirmationCode: string) => {
      if (ssoName !== 'hsn') {
        dispatch(setLoginModal({
          isOpen: true,
          step: ELoginSteps.LOGIN,
        }));

        if (err === 1019 || err === 1000) {
          openRequestEmailForm(true, false);
          const setSnackbarType = confirmationCode ? setNotificationBar : setSnackbarData;

          dispatch(
            setSnackbarType({
              isOpened: true,
              message: t('LOGIN.EAGLE.REGISTER_CONFIRM_MAIL_MESSAGE_ERROR'),
              type: 'error',
            }),
          );
          history.replace('');
          return;
        }

        openRequestEmailForm();
        history.replace('');
      }
    };
    const openRequestEmailForm = (isResendConfirmationEmail?: boolean, isInputDisabled?: boolean) => {
      if (isResendConfirmationEmail) {
        setIsResendEmailForm(true);
        dispatch(setLoginStep(ELoginSteps.REQUEST_EMAIL));
        setAuthType(AuthType.EmailPassword);

        if (isInputDisabled) {
          setIsResendEmailFormInputDisabled(true);
        }
      } else {
        dispatch(setLoginStep(ELoginSteps.REQUEST_EMAIL));
        dispatch(setLoginPrevStep(ELoginSteps.REQUEST_EMAIL));
      }
    };
    const onClose = () => {
      GRM.makeGameResume();
      dispatch(setRecaptchaToken(null));
      dispatch(setRecaptchaAction(null));
      dispatch(setShowRecaptcha(false));
      dispatch(setLoginOpen(false));
    };
    const onLoginModalMessage = (message: MessageEvent) => {
      const event = message.data;

      if (!event) {
        return;
      }

      // events from auth iframes that store in azure blob
      switch (event.type) {
        case IframeMessageTypes.LOGIN_USER:
          dispatch(loginUser(event.payload));
          break;
        case IframeMessageTypes.SET_AUTHORIZED_STATUS:
          dispatch(setUserAuthStatus(UserAuthStatus.USER_NOT_AUTHORIZED));
          break;
        case IframeMessageTypes.LOGOUT_USER:
          dispatch(logoutUser());
          break;
        default:
          break;

        case IframeMessageTypes.OPEN_LOGIN_POPUP:
          dispatch(setLoginOpen(true));
          break;
        case IframeMessageTypes.CLOSE_LOGIN_POPUP:
          dispatch(setLoginOpen(false));
          break;
      }
    };
    const isGdprForm = step === ELoginSteps.GDPR;
    const isAfterSignForm = step === ELoginSteps.AFTER_SIGN;

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        modalWrapperClassName={classNames({ [styles.popupWrapper]: !fromShopModal })}
        modalClassName={classNames({
          [styles.popup]: !fromShopModal,
          [styles.gdprForm]: isGdprForm,
          [styles.fromShopModal]: fromShopModal,
        })}
        closeIconClassName={classNames({ [styles.closeIcon]: !fromShopModal })}
      >
        {!fromShopModal && <div className={styles.userAvatar}/>}
        {fromShopModal && <ShopBanner addBottomMargin={isAfterSignForm}/>}
        <Login
          recoveryCode={recoveryCode}
          authType={authType}
          setAuthType={setAuthType}
          isResendEmailForm={isResendEmailForm}
          isResendEmailFormInputDisabled={isResendEmailFormInputDisabled}
          openRequestEmailForm={openRequestEmailForm}
        />
      </Modal>
    );
  }
;

export const LoginModal = withTranslation()(LoginModalBase);
