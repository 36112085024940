import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formatLBScores, formatNumbers, isNumTenMillionsOrMore } from '../../../utils';
import { PrimaryButton } from '../../atoms/Buttons/PrimaryButton';
import { TrophyIcon } from '../../atoms/Icons/TrophyIcon';
import { TrophyYouIcon } from '../../atoms/Icons/TrophyYouIcon';
import { THEMES } from '../../consts';
import { KeysEnum } from '../../models/Enums';
import { LeaderboardRecord } from '../../models/LeaderboardRecord';
import { User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { Avatar, EAvatarPlacement } from '../../molecules/Avatar/Avatar';
import { Tooltip } from '../../molecules/Tooltip/Tooltip';
import { AssetService } from '../../services/AssetService';
import { DeviceDetector } from '../../services/DeviceDetector';
import { SSOService } from '../../services/SSOService';
import { AppState } from '../../store/types';
import styles from './LeaderboardRow.css';
import { CookieStorageService } from '../../services/CookieStorage';

interface LeaderboardListProps {
    row?: LeaderboardRecord;
    user?: User;
    userAuthStatus: UserAuthStatus;
    t;
    onLoginClick?: () => void;
    config: any;
}

class LeaderboardRowBase extends React.PureComponent<LeaderboardListProps & WithTranslation> {
    onLoginClick = () => {
        const ssoUrl = SSOService.getLoginPageUrl(this.props.config, window.location.href);

        if (ssoUrl) {
            CookieStorageService.set(KeysEnum.arkStateReturnUrl, window.location.href);
            window.location.href = ssoUrl;
        } else {
            if (this.props.onLoginClick) {
                this.props.onLoginClick();
            }
        }
    }

    render() {
        const { t, user, userAuthStatus, row, config } = this.props;
        const isDigitalTurbine = config.theme.theming.name === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();
        const isAuthorized = userAuthStatus === UserAuthStatus.USER_AUTHORIZED;

        // You Can Be Here
        if (!row) {
            return (
                <React.Fragment>
                    <div /> {/* Render empty div for nth-child selector*/}
                    <LBRow>
                        <PositionColumn>
                            <Position hasTrophy={true} anonymous={!user}>
                                <TrophyYouIcon className={styles.trophyIcon} />
                            </Position>
                        </PositionColumn>

                        <UserInfoColumn isDigitalTurbine={isDigitalTurbine} unlogged>
                            {isAuthorized && (
                                <React.Fragment>
                                    <Avatar avatar={user.avatar} place={EAvatarPlacement.LB_ROW} />
                                    {user.countryCode && <Country country={user.countryCode} />}
                                </React.Fragment>
                            )}
                        </UserInfoColumn>

                        <NameColumn isDigitalTurbine={isDigitalTurbine} unlogged>
                            {t('LEADERBOARD_UNLOGGED_TITLE')}
                        </NameColumn>

                        <ScoreColumn>
                            {!this.props.config.theme.disableLogin && !isAuthorized && (
                                <LoginBtn onClick={this.onLoginClick} isDigitalTurbine={isDigitalTurbine}>
                                    {t('LOG_IN')}
                                </LoginBtn>
                            )}
                        </ScoreColumn>
                    </LBRow>
                </React.Fragment>
            );
        }

        // Regular row
        const showTrophy = row.position <= 3;

        return (
            <LBRow isUser={row.isCurrentUser || !row.position}>
                <PositionColumn>
                    <Position hasTrophy={showTrophy}>
                        {showTrophy && <TrophyIcon place={row.position} className={styles.trophyIcon} />}

                        <PositionNumber hasTrophy={showTrophy}>{row.position || '100+'}</PositionNumber>
                    </Position>
                </PositionColumn>

                <UserInfoColumn>
                    <Avatar avatar={row.avatar} place={EAvatarPlacement.LB_ROW} even={row.position % 2 === 0} />

                    {row.countryCode && <Country country={row.countryCode} />}
                </UserInfoColumn>

                <NameColumn isDigitalTurbine={isDigitalTurbine}>
                    {decodeURIComponent(encodeURIComponent(row.name))}
                </NameColumn>

                <ScoreColumn>
                    {isNumTenMillionsOrMore(row.score) ? (
                        <Tooltip text={formatNumbers(row.score)} tooltipClass={styles.scoresTooltip}>
                            {formatLBScores(row.score)}
                        </Tooltip>
                    ) : (
                        formatLBScores(row.score)
                    )}
                </ScoreColumn>
            </LBRow>
        );
    }
}

const LBRow = ({ isUser, ...props }: any) => (
    <div className={classNames(styles.lbRow, { [styles.isUser]: isUser })} {...props} />
);
const PositionColumn = (props: any) => <div className={styles.positionColumn} {...props} />;
const UserInfoColumn = ({ isDigitalTurbine, unlogged, ...props }: any) => (
    <div
        className={classNames(styles.userInfoColumn, {
            [styles.digitalTurbine]: isDigitalTurbine,
            [styles.unlogged]: unlogged,
        })}
        {...props}
    />
);
const NameColumn = ({ isDigitalTurbine, unlogged, ...props }: any) => (
    <div
        className={classNames(styles.nameColumn, {
            [styles.digitalTurbine]: isDigitalTurbine,
            [styles.unlogged]: unlogged,
        })}
        {...props}
    />
);
const ScoreColumn = ({ isDigitalTurbine, ...props }: any) => (
    <div className={classNames(styles.scoreColumn, { [styles.digitalTurbine]: isDigitalTurbine })} {...props} />
);
const LoginBtn = ({ isDigitalTurbine, ...props }: any) => (
    <PrimaryButton className={classNames(styles.loginBtn, { [styles.digitalTurbine]: isDigitalTurbine })} {...props} />
);
const Country = ({ country, ...props }: any) => (
    <img className={classNames(styles.country)} alt="User Country" src={AssetService.getFlag(country)} />
);
const Position = ({ hasTrophy, anonymous, ...props }: any) => (
    <div
        className={classNames(styles.position, {
            [styles.hasTrophy]: hasTrophy,
            [styles.anonymous]: anonymous,
        })}
        {...props}
    />
);
const PositionNumber = ({ hasTrophy, ...props }: any) => (
    <div className={classNames(styles.positionNumber, { [styles.hasTrophy]: hasTrophy })} {...props} />
);
const LeaderboardTranslated = withTranslation()(LeaderboardRowBase);

export const LeaderboardRow = connect((state: AppState) => ({
    config: state.config,
}))(LeaderboardTranslated);
