import React, { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { Button } from '../../../FigmaStyleguide/Button/Button';
import { Input } from '../../../FigmaStyleguide/Input/Input';
import styles from './PromoCodeBlock.css';

type PromoCodeBlockProps = {
  promoCodes: string[];
  setPromoCodes: (v: string[]) => void;
  setCheckoutPricing: any; // recurly func
  recurlyPricingError: any; // recurly obj
  price: any; // recurly obj
};
type TAdjustments = {
  itemCode: string;
  quantity: number;
};
type TPreviousValues = {
  address: string | null;
  coupon: string;
  adjustments: TAdjustments[];
};

const PromoCodeBlockBase = ({
  t,
  promoCodes,
  setPromoCodes,
  setCheckoutPricing,
  recurlyPricingError,
  price,
}: WithTranslation & PromoCodeBlockProps) => {
  const [promoCodeValue, setPromoCodeValue] = useState<string>(promoCodes.join(''));
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValidCode, setIsValidCode] = useState<boolean>(true);
  const [isCodeApplied, setIsCodeApplied] = useState<boolean>(false);
  const onPromoCodeApply = () => {
    if (isValidCode) {
      setCheckoutPricing((previous: TPreviousValues) => {
        return ({
          ...previous,
          coupon: promoCodeValue,
        });
      });
    }
  };

  useEffect(() => {
    if (price?.now?.discount !== '0.00' && price?.now?.discount !== undefined) {
      setIsCodeApplied(true);
      setPromoCodes([promoCodeValue]);
    }
  }, [price?.now?.discount]);

  useEffect(() => {
    // otherwise it is impossible to distinguish between errors coming from recurly-js :(
    if (recurlyPricingError?.message.toLocaleLowerCase().includes('coupon')) {
      setIsValidCode(false);
      setIsCodeApplied(false);
      setPromoCodes([]);
      setCheckoutPricing((previous: TPreviousValues) => ({
        ...previous,
        coupon: '',
      }));
    }
  }, [recurlyPricingError]);

  const handleFocused = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    if (promoCodeValue === '') {
      setIsFocused(false);
    }
  };
  const handleRemoveCode = () => {
    setIsCodeApplied(false);
    setPromoCodeValue('');
    setPromoCodes([]);
    setCheckoutPricing((previous: TPreviousValues) => ({
      ...previous,
      coupon: '',
    }));

    if (!isValidCode) {
      setIsValidCode(true);
    }
  };
  const handleSetValues = (e: ChangeEvent<HTMLInputElement>) => {
    setPromoCodeValue(e.target.value);

    if (!isValidCode) {
      setIsValidCode(true);
    }
  };
  const renderControlButtons = () => {
    if (isCodeApplied) {
      return (
        <button
          className={styles.removeLink}
          onClick={handleRemoveCode}
        >
          <I18nText keyName="PURCHASE_PAGE.REMOVE_PROMO_CODE"/>
        </button>
      );
    }

    if (isFocused) {
      return (
        <Button
          disabled={!isValidCode}
          secondaryStyle
          onClick={onPromoCodeApply}
        >
          <I18nText keyName="PURCHASE_PAGE.APPLY_BUTTON"/>
        </Button>
      );
    }

    return null;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        {isCodeApplied ?
          t('PURCHASE_PAGE.APPLIED_PROMO_CODE_LABEL')
          :
          t('PURCHASE_PAGE.DEFAULT_PROMO_CODE_LABEL')
        }
      </div>
      <div className={styles.inputRow}>
        <Input
          value={promoCodeValue}
          placeholder="Enter your code here"
          onBlur={handleBlur}
          onFocus={handleFocused}
          onChange={handleSetValues}
          clickOnCloseIcon={handleRemoveCode}
          name="promoCode"
          errorMessage={t('PURCHASE_PAGE.PROMO_CODE_ERROR_MESSAGE')}
          isValid={isValidCode}
          disabled={isCodeApplied}
          className={classNames(!isValidCode && styles.invalidInput)}
          maxLength={30}
        />
      </div>
      <div className={classNames(styles.handlersRow, !isValidCode && styles.invalidRow)}>
        {renderControlButtons()}
      </div>
    </div>
  );
};

export const PromoCodeBlock = withTranslation()(PromoCodeBlockBase);
