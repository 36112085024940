import { createAction, createReducer } from 'redux-act';

import { GameState, GameUnitState } from '../../models/Enums';

export const setGameState = createAction<GameState>('set game status');

const gameStateReducer = createReducer<GameState>({}, null);

gameStateReducer.on(setGameState, (_state, payload: GameState) => payload);

export const setGameUnitState = createAction<GameUnitState>('set game unit status');

const gameUnitReducer = createReducer<GameUnitState>({}, null);

gameUnitReducer.on(setGameUnitState, (_state, payload: GameUnitState) => payload);
export { gameStateReducer, gameUnitReducer };
