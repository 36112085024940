import React from 'react';
import { AbTestsConsole } from '../../atoms/AbTestExperiments/AbTestsConsole';
import { useABTest } from '../../services/ABTests/ABTestReact';
import { LightboxPromotionFeature } from './LightboxPromotionFeature';

export const ABTestedLightboxFeature = () => {
    const variation = useABTest('abtest_Lightbox', 'Lightbox');

    AbTestsConsole.abTestShareInfo('abtest_Lightbox', variation);

    if (variation === 'Control') {
        return null;
    }

    if (variation === 'Lightbox') {
        return <LightboxPromotionFeature />;
    }
};
