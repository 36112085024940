import { DEFAULT_AVATAR } from './Enums';
import { EagleUser, User } from './User';

export class LeaderboardRecord {
    static FromUserAndScore(user: User | EagleUser, score: number): LeaderboardRecord {
        return new LeaderboardRecord({
            name: user.name,
            avatar: user.avatar,
            score,
            countryCode: 'countryId' in user ? user.countryId : user.countryCode,
            isCurrentUser: true,
        });
    }

    static checkAndUpdateCurrentUserData(
        currentUser: User | EagleUser,
        scoreList: LeaderboardRecord[]
    ): LeaderboardRecord[] {
        const currentUserScore = scoreList.find((score) => score.isCurrentUser);

        if (!currentUserScore) {
            return scoreList;
        }

        const currentUserScoreIndex = scoreList.findIndex((score) => score.isCurrentUser);

        return [
            ...scoreList.slice(0, currentUserScoreIndex),
            LeaderboardRecord.FromUserAndScore(currentUser, currentUserScore.score || 0),
            ...scoreList.slice(currentUserScoreIndex + 1),
        ];
    }

    public userId?: string;
    public name: string;
    public avatar: string;
    public score: number;
    public countryCode?: string;
    public isCurrentUser?: boolean;
    // Being settled during render
    public position?: number;

    constructor(
        { userId, name, avatar, score, countryCode, isCurrentUser }: LeaderboardRecord,
        authorizedUserId: string = null
    ) {
        this.userId = userId || '';
        this.name = name;
        this.avatar = avatar || DEFAULT_AVATAR;
        this.score = score;
        this.countryCode = countryCode;
        this.isCurrentUser = isCurrentUser || authorizedUserId === userId;
    }
}
