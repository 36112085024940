import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { StarIcon } from '../../atoms/Icons/StarIcon';
import { AchievementEvent } from '../../models/AchievementEvent';
import { AchievementRow } from './AchievementRow';
import styles from './Achievements.css';
import { sanitizeString } from '../../../utils';

type AchievementsProps = {
    achievements: AchievementEvent[];
    userName: string;
};

const AchievementsComponent = React.memo(({ achievements, userName, t }: AchievementsProps & WithTranslation) => {
    return (
        <Container>
            <Title>
                <TitleIcon /> {t('ACHIEVEMENTS_TITLE')}
            </Title>
            <Body>
                {achievements.map((event: AchievementEvent, i) => (
                    <AchievementRow key={event.eventName + i} event={event} userName={sanitizeString(userName, true)} />
                ))}
            </Body>
        </Container>
    );
});
const Container = (props: any) => <div className={styles.Container} {...props} />;
const Title = (props: any) => (
    <h3 className={styles.Title} {...props}>
        {props.children}
    </h3>
);
const TitleIcon = (props: any) => <StarIcon className={styles.StarIconTitle} {...props} />;
const Body = (props: any) => <div className={styles.Body} {...props} />;

export const Achievements = withTranslation()(AchievementsComponent);
