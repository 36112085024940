import classnames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import AddToDeskIcon from '../../../public/pwa/icons/AddToDesktopIcon.svg';
import BookmarkIcon from '../../../public/pwa/icons/BookmarkIcon.svg';
import { isServer } from '../../../utils';
import { ActionTypes, CustomCategories } from '../../models/Analytics';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { DeviceDetector } from '../../services/DeviceDetector';
import { PwaService } from '../../services/PwaService';
import styles from './PwaButtons.css';

type PwaButtonsProps = {
    isVertical?: boolean;
    isPulseAnimationActive?: boolean;
};

class PwaButtonsBase extends React.PureComponent<PwaButtonsProps & WithTranslation> {
    state = {
        isInstallClicked: false,
        isBookmarkClicked: false,
    };

    isChrome = DeviceDetector.detectBrowser().name === 'Chrome';

    componentDidMount() {
        if (DeviceDetector.isDesktop() && !PwaService.isPwaStandalone()) {
            if (this.isChrome) {
                AnalyticsGeneral.pwa(ActionTypes.IMPRESSION, CustomCategories.DESKTOP_PWA);
            }

            AnalyticsGeneral.pwa(ActionTypes.DESKTOP_BOOKMARK_IMPRESSION, CustomCategories.DESKTOP_BOOKMARK);
        }
    }

    render() {
        const { t, isVertical, isPulseAnimationActive } = this.props;
        const { isInstallClicked, isBookmarkClicked } = this.state;
        const isVisible = !isServer && DeviceDetector.isDesktop() && !PwaService.isPwaStandalone();

        return (
            isVisible && (
                <Container
                    data-element-description="pwa-buttons-block"
                    isVertical={isVertical}
                    isPulseAnimationActive={isPulseAnimationActive}
                >
                    <BokmarkBtn onClick={this.onBookmark} data-element-description="bookmark-button">
                        <BookmarkImg isBookmarkClicked={isBookmarkClicked}>
                            <BookmarkIcon />
                        </BookmarkImg>
                        <BtnText>{t('BOOKMARK_PAGE_BUTTON')}</BtnText>
                    </BokmarkBtn>

                    {this.isChrome && (
                        <InstallBtn onClick={this.onInstall} data-element-description="install-button">
                            <InstallImg isInstallClicked={isInstallClicked}>
                                <AddToDeskIcon />
                            </InstallImg>
                            <BtnText>{t('INSTALL_APP_BUTTON')}</BtnText>
                        </InstallBtn>
                    )}
                </Container>
            )
        );
    }

    private onInstall = () => {
        this.setState({ isInstallClicked: true });
        setTimeout(() => {
            this.setState({ isInstallClicked: false });
        }, 999);

        if ((window as any).deferredPrompt) {
            (window as any).deferredPrompt.prompt();
        }

        AnalyticsGeneral.pwa(ActionTypes.CLICK, CustomCategories.DESKTOP_PWA);
    };

    private onBookmark = () => {
        this.setState({ isBookmarkClicked: true });
        setTimeout(() => {
            this.setState({ isBookmarkClicked: false });
        }, 999);

        const alertTextKey = DeviceDetector.detectOS().name === 'Mac OS' ? 'BOOKMARK_IOS' : 'BOOKMARK';

        window.alert(this.props.t(alertTextKey));
        AnalyticsGeneral.pwa(ActionTypes.DESKTOP_BOOKMARK_CLICK, CustomCategories.DESKTOP_BOOKMARK);
    };
}

const Container = ({ isVertical, isPulseAnimationActive, ...props }) => (
    <div
        className={classnames(styles.Container, {
            [styles.isVertical]: isVertical,
            [styles.isHorizontal]: !isVertical,
            [styles.animationPulse]: isPulseAnimationActive,
        })}
        {...props}
    />
);
const InstallBtn = (props: any) => <button className={styles.Btn} {...props} />;
const BokmarkBtn = (props: any) => <button className={styles.Btn} {...props} />;
const BookmarkImg = ({ isBookmarkClicked, ...props }) => (
    <div
        className={classnames(styles.BtnImg, styles.BookmarkBtn, {
            [styles.animationClick]: isBookmarkClicked,
        })}
        {...props}
    />
);
const InstallImg = ({ isInstallClicked, ...props }) => (
    <div
        className={classnames(styles.BtnImg, {
            [styles.animationClick]: isInstallClicked,
        })}
        {...props}
    />
);
const BtnText = (props: any) => <span className={styles.BtnText} {...props} />;

export const PwaButtons = withTranslation()(PwaButtonsBase);
