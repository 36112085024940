import classNames from 'classnames';
import React from 'react';
import { EagleAvatar } from '../../atoms/EagleAvatar/EagleAvatar';
import { AssetService } from '../../services/AssetService';
import styles from './Avatar.css';

export enum EAvatarPlacement {
    AVATARS_LIST = 'AVATARS_LIST',
    AVATARS_MODAL = 'AVATARS_MODAL',
    PROFILE_PERSON = 'PROFILE_PERSON',
    LB_ROW = 'LB_ROW',
}
type TProps = {
    avatar: string;
    place: EAvatarPlacement;
    onAvatarClick?: () => void;
    even?: boolean;
};
const Avatar = React.memo(({ avatar, place, onAvatarClick, even }: TProps) => {
    const isEagleAvatar = avatar.includes('_');
    const getClassNames = () => {
        switch (place) {
            case EAvatarPlacement.PROFILE_PERSON:
                return classNames(styles.profilePersonAvatar, isEagleAvatar ? styles.profilePersonAvatarEagle : '');
            case EAvatarPlacement.AVATARS_MODAL:
                return styles.avatarsModalAvatar;
            case EAvatarPlacement.AVATARS_LIST:
                return styles.avatarsListAvatar;
            case EAvatarPlacement.LB_ROW:
                return styles.lbRowAvatar;
        }
    };

    return isEagleAvatar ? (
        <EagleAvatar
            avatar={avatar}
            wrapperClassName={classNames(getClassNames(), { [styles.lbRowAvatarEven]: even })}
            noBorderRadius={place === EAvatarPlacement.PROFILE_PERSON}
            onAvatarClick={place === EAvatarPlacement.AVATARS_LIST ? onAvatarClick : null}
        />
    ) : (
        <div
            onClick={place === EAvatarPlacement.AVATARS_LIST ? onAvatarClick : null}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onAvatarClick();
                }
            }}
            role="presentation"
        >
            <img
                className={classNames(getClassNames(), { [styles.lbRowAvatarEven]: even })}
                src={AssetService.getAvatar(avatar)}
                alt={avatar.replace('.png', '')}
            />
        </div>
    );
});

Avatar.displayName = 'Avatar';
export { Avatar };
