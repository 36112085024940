export class Subject {
    fns = [];

    // Being called by game
    next(event) {
        this.fns.forEach(fn => fn(event));
    }

    unsubscribe() {
        this.fns = [];
    }

    subscribe(fn) {
        this.fns.push(fn);
    }
}
