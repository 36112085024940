import classnames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { PrimaryButton } from '../../atoms/Buttons/PrimaryButton';
import { CloseIcon } from '../../atoms/Icons/CloseIcon';
import { PersonIcon } from '../../atoms/Icons/PersonIcon';
import { ActionTypes } from '../../models/Analytics';
import { AvatarMatrix, AvatarsGroup } from '../../models/Avatars';
import { EagleUser, User } from '../../models/User';
import { Avatar, EAvatarPlacement } from '../../molecules/Avatar/Avatar';
import { AvatarsList } from '../../molecules/AvatarsList/AvatarsList';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { EagleLevelupService } from '../../services/EagleLevelupService';
import { ProfileService } from '../../services/ProfileService';
import { userService } from '../../services/UserService';
import { updateUser } from '../../store/ducks/user';
import { AppState } from '../../store/types';
import styles from './AvatarsModal.css';
import { updateEagleUser } from '../../services/EagleLoginService';

type AvatarsModalProps = {
    closeModal: any;
    avatars: AvatarMatrix[];
    user: User | EagleUser;
    dispatch: any;
    isEagle: boolean;
};

const AVATARS_TABS = [AvatarsGroup.HUMANS, AvatarsGroup.ANIMALS, AvatarsGroup.OBJECTS, AvatarsGroup.GAMES];

class AvatarsModalClass extends React.PureComponent<AvatarsModalProps & WithTranslation> {
    state = {
        activeTab: AvatarsGroup.HUMANS,
        activeAvatar: this.props.user.avatar,
        isAvatarLoading: false,
        tabs: AVATARS_TABS,
    };

    constructor(props) {
        super(props);
        this.sortAvatars();
    }

    componentDidMount() {
        const { level, coins } = this.props.user as User;

        AnalyticsGeneral.avatarChange(ActionTypes.AVATAR_AVAILABLE_IMPRESSION, level, coins);

        this.addEagleTabs();
    }

    private saveAvatar = () => {
        const { level, coins } = this.props.user as User;
        const { activeAvatar } = this.state;
        const isEagle = this.props.isEagle;

        AnalyticsGeneral.avatarChange(ActionTypes.AVATAR_SELECTED, level, coins, activeAvatar);
        this.setState({ isAvatarLoading: true });
        const user = { ...this.props.user, avatar: activeAvatar };
        const successHandler = (userUpdated) => {
            this.props.dispatch(updateUser(userUpdated));
            this.setState({ isAvatarLoading: false });
            this.props.closeModal();
        };
        const errorHandler = (err) => {
            console.log(err.message);
            this.setState({ isAvatarLoading: false });
        };

        if (isEagle) {
            updateEagleUser(user).then(successHandler).catch(errorHandler);
        } else {
            userService
                .update(user as User)
                .then(successHandler)
                .catch(errorHandler);
        }
    };

    private chooseAvatar = (activeAvatar: string) => {
        this.setState({ activeAvatar });
    };

    private buyAvatar = (avatar: AvatarMatrix) => {
        this.setState({ isAvatarLoading: true });

        const { isEagle } = this.props;
        const service = isEagle ? EagleLevelupService : ProfileService;

        service
            .buyAvatar(avatar.Name)
            .then((user: User) => {
                AnalyticsGeneral.avatarChange(
                    ActionTypes.AVATAR_BUY,
                    user.level,
                    user.coins,
                    avatar.Name,
                    avatar.Coins
                );

                this.chooseAvatar(avatar.Name);
                this.props.dispatch(updateUser(user));
                this.setState({ isAvatarLoading: false });
            })
            .catch((err) => {
                this.setState({ isAvatarLoading: false });
                console.log(err.message);
            });
    };

    private get selectedAvatars() {
        return this.props.avatars.filter((avatar) => avatar.Group === this.state.activeTab);
    }

    private sortAvatars() {
        this.props.avatars.sort((a, b) => {
            if (a.Level === b.Level) {
                return a.Coins - b.Coins;
            }

            return a.Level - b.Level;
        });
    }

    private onCancel = () => {
        this.props.closeModal();
    };

    private onTabClick = (activeTab) => {
        this.setState({ activeTab });
    };

    private addEagleTabs = () => {
        if (this.props.isEagle) {
            const tabs = [AvatarsGroup.EAGLE, ...AVATARS_TABS];

            this.setState({
                tabs,
                activeTab: AvatarsGroup.EAGLE,
            });
        }
    };

    render() {
        const { t, user } = this.props;
        const { activeTab, activeAvatar, isAvatarLoading } = this.state;

        return (
            <Container>
                <Modal>
                    <Title>
                        <TitleIcon />
                        {t('CHANGE_PROFILE_IMAGE')}

                        <CloseBtn onClick={this.onCancel}>
                            <CloseIcon />
                        </CloseBtn>
                    </Title>

                    <Content>
                        <SelectedAvatarBlock>
                            <SelectedAvatarWrap>
                                {isAvatarLoading && <AppLoader />}

                                {!isAvatarLoading && (
                                    <Avatar avatar={activeAvatar} place={EAvatarPlacement.AVATARS_MODAL} />
                                )}
                            </SelectedAvatarWrap>

                            <SaveBtn onClick={this.saveAvatar}>{t('SAVE')}</SaveBtn>
                            <CancelBtn onClick={this.onCancel}>{t('CANCEL')}</CancelBtn>
                        </SelectedAvatarBlock>

                        <AvatarsBlock>
                            <TabsList>
                                {this.state.tabs.map((tab) => (
                                    <Tab
                                        key={tab}
                                        isActive={tab === activeTab}
                                        onClick={() => {
                                            this.onTabClick(tab);
                                        }}
                                    >
                                        {t(`${tab}_AVATARS`)}
                                    </Tab>
                                ))}
                            </TabsList>
                            <AvatarsList
                                avatars={this.selectedAvatars}
                                user={user}
                                chooseAvatar={this.chooseAvatar}
                                buyAvatar={this.buyAvatar}
                            />
                        </AvatarsBlock>
                    </Content>
                </Modal>
            </Container>
        );
    }
}

const Container = (props: any) => <div className={styles.Container} {...props} />;
const Modal = (props: any) => <div className={styles.Modal} {...props} />;
const Title = (props: any) => <div className={styles.Title} {...props} />;
const TitleIcon = (props: any) => <PersonIcon className={styles.TitleIcon} {...props} />;
const CloseBtn = (props: any) => <button className={styles.CloseBtn} {...props} />;
const Content = (props: any) => <div className={styles.Content} {...props} />;
const SelectedAvatarBlock = (props: any) => <div className={`col-md-4 ${styles.SelectedAvatarBlock}`} {...props} />;
const SelectedAvatarWrap = (props: any) => <div className={styles.SelectedAvatarWrap} {...props} />;
const SaveBtn = (props: any) => <PrimaryButton className={styles.SaveBtn} {...props} />;
const CancelBtn = (props: any) => <button className={styles.CancelBtn} {...props} />;
const AvatarsBlock = (props: any) => <div className={`col-md-8 ${styles.AvatarsBlock}`} {...props} />;
const TabsList = (props: any) => <ul className={styles.TabsList} {...props} />;
const Tab = ({ isActive, ...props }: any) => (
    <li
        className={classnames(styles.Tab, {
            [styles.active]: isActive,
        })}
        {...props}
    />
);
const AvatarsModalLoc = withTranslation()(AvatarsModalClass);

export const AvatarsModal = connect((state: AppState) => ({
    avatars: state.avatars,
    user: state.user,
    isEagle: state.config.isEagle,
}))(AvatarsModalLoc);
