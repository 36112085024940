import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { Game } from '../../models/Game';
import { AppState } from '../types';

export const setGame = createAction<Game>('set game');

const reducer = createReducer<Game>({}, null);

reducer.on(setGame, (_state, payload: Game) => payload);

export default reducer;

/** Selectors */
export const getGameSelector = createSelector(
    (state: AppState) => state.game,
    (game) => {
        return game && new Game(game);
    }
);
