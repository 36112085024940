import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/types';
import { hostnameToArenaDomain } from '../../../utils';
import { IEvent } from '../../services/EventCategoryService';

const CustomEventCat: React.FC<any> = (props) => {
    if (!props?.config?.eventCategoryConfig) {
        return props.children(false, null);
    }

    const eventData: IEvent =
        props?.config?.eventCategoryConfig?.common && props.config.eventCategoryConfig.common.length
            ? props.config.eventCategoryConfig.common[0]
            : null;
    const theme = props?.config?.theme;
    const isArenaExcludedPromo =
        eventData?.eventPromoBannerExcludes && eventData.eventPromoBannerExcludes.length
            ? (theme?.name && eventData.eventPromoBannerExcludes.includes(theme.name)) ||
              (theme?.domain && eventData.eventPromoBannerExcludes.includes(getHostByUrl(theme.domain))) ||
              (props?.arenaOrigin && eventData.eventPromoBannerExcludes.includes(getHostByUrl(props.arenaOrigin)))
            : false;
    const eventPromoData =
        eventData && eventData.eventPromoBanner
            ? {
                  slug: eventData?.eventNameLocales?.[props.lang] || eventData.eventName,
                  name:
                      eventData?.eventPromoBanner?.titleLocales?.[props.lang] ||
                      eventData?.eventNameLocales?.[props.lang] ||
                      eventData.eventName,
                  alias: eventData?.eventNameLocales?.[props.lang].toLowerCase().replace(/\s/gi, '-'),
                  buttonText: eventData?.eventPromoBanner?.buttonLocales?.[props.lang] || '',
                  ...{
                      ...eventData.eventPromoBanner,
                      description:
                          eventData?.eventPromoBanner?.subtitleLocales?.[props.lang] ||
                          eventData.eventDescriptionLocales[props.lang] ||
                          eventData.eventDescription ||
                          '',
                  },
              }
            : null;
    const returned = {
        eventData:
            eventPromoData &&
            Object.values({ ...eventPromoData, buttonText: true }).reduce(
                (previousValue, currentValue) => previousValue && currentValue,
                true
            )
                ? { ...eventPromoData }
                : null,
        isArenaExcludedPromo,
    };

    if (returned.eventData) {
        returned.eventData.isCategory = eventData.isCategory;
    }

    return props.children(returned);
};

function getHostByUrl(url) {
    try {
        return hostnameToArenaDomain(new URL(url).hostname);
    } catch (_) {
        return '';
    }
}

export const EventCat = connect((state: AppState) => ({
    config: state.config,
    arenaOrigin: state.arenaOrigin,
    theme: state.theme,
    lang: state.currentLang,
}))(CustomEventCat);
