import React, { ReactElement, ReactNode, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CloseIcon } from '../../atoms/Icons/CloseIcon';
import styles from './UClickSupportModal.css';

type UClickSupportModalProps = {
  children: ReactElement;
};

const UClickSupportModalBase = ({
  t,
  children,
}: UClickSupportModalProps & WithTranslation) => {
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  return (
    <>
      <ContactUsButton onClick={() => setIsPopupVisible(true)}>{children}</ContactUsButton>
      {isPopupVisible && (
        <Container>
          <Modal>
            <Title>
              {t('UCLICK_MODAL.HAVING_TROUBLE_TEXT')}
              <CloseBtn onClick={() => setIsPopupVisible(false)}>
                <CloseIcon/>
              </CloseBtn>
            </Title>
            <Content>
              <span className={styles.span}>
                {t('UCLICK_MODAL.CLEAR_CACHE_TEXT')}
                <br/>
                <a href="https://www.wikihow.com/Clear-Your-Browser%27s-Cookies" target="_blank">
                  {t('UCLICK_MODAL.CLEAR_CACHE_BUTTON_TEXT')}
                </a>
              </span>
              <span className={styles.span}>
                <b>{t('UCLICK_MODAL.CONTACT_EMAIL_TEXT')}</b>
                <br/>
                <a href="mailto:support@amuniversal.com">support@amuniversal.com</a>
                <br/>
                <b>{t('UCLICK_MODAL.OR_CALL_TEXT')}</b>
                <br/>
                <a href="tel:1(800)255-6734" target="_blank">
                  1 (800) 255-6734
                </a>
              </span>
            </Content>
          </Modal>
        </Container>
      )}
    </>
  );
};
const Container = (props: { children: ReactNode }) => <div className={styles.Container} {...props} />;
const Modal = (props: { children: ReactNode }) => <div className={styles.Modal} {...props} />;
const Title = (props: { children: ReactNode }) => <div className={styles.Title} {...props} />;
const CloseBtn = (props: { children: ReactNode, onClick: () => void }) =>
  <button className={styles.CloseBtn} {...props} />;
const Content = (props: { children: ReactNode }) => <div className={styles.Content} {...props} />;
const ContactUsButton = React.forwardRef<any, any>((
  {
    className,
    ...props
  },
  ref,
) => (
  <button className={`${styles.contactUsButton} ${className}`} ref={ref} {...props} />
));
const UClickSupportModalObj = withTranslation()(UClickSupportModalBase);

export const UClickSupportModal = connect((_) => ({}))(UClickSupportModalObj);
