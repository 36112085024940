import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formatNumbers } from '../../../utils';
import { CoinsIcon } from '../../atoms/Icons/CoinsIcon';
import { InfoIcon } from '../../atoms/Icons/InfoIcon';
import { EagleUser, User } from '../../models/User';
import { Tooltip } from '../../molecules/Tooltip/Tooltip';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './ProfileXp.css';

type ProfileXpType = {
    user: User | EagleUser;
};

class ProfileXpClass extends React.PureComponent<ProfileXpType & WithTranslation> {
    state = {
        progressValue: 0,
    };

    isIE = DeviceDetector.detectBrowser().name === 'IE';

    componentDidMount() {
        setTimeout(() => {
            this.setState({ progressValue: this.props.user.xp });
        }, 1000);
    }

    render() {
        const { t } = this.props;
        const { coins, xp, levelXPThresholdCurrent, level } = this.props.user;
        const needXp = levelXPThresholdCurrent - xp || 100 - xp;

        return (
            <Container>
                <CoinsBlock>
                    <Coins data-element-description="profile coins icon" />
                    <CoinsNum data-element-description="profile coins">{formatNumbers(coins)}</CoinsNum>
                    <CoinsDesc>
                        <CoinsDescText
                            data-element-description="profile coins text"
                            dangerouslySetInnerHTML={{ __html: t('COINS_EARNED') }}
                        />
                        <Tooltip text={t('INFO_TOOLTIP.COINS')}>
                            <InfoIcon />
                        </Tooltip>
                    </CoinsDesc>
                </CoinsBlock>

                <XpBlock>
                    <ProgressBar data-element-description="profile progressbar">
                        <CircularProgressbarWithChildren
                            value={this.state.progressValue}
                            maxValue={levelXPThresholdCurrent}
                            strokeWidth={this.isIE ? 6 : 10} // stroke width more than 7 doesn't work in IE
                            styles={buildStyles({
                                pathTransitionDuration: 0.9,
                                strokeLinecap: 'butt',
                                trailColor: '#eaeaea',
                                pathColor: '#1E36AE',
                            })}
                        >
                            <ProgressTitle>{t('LEVEL')}</ProgressTitle>
                            <ProgressNum>{formatNumbers(level)}</ProgressNum>
                        </CircularProgressbarWithChildren>
                    </ProgressBar>

                    <XpDesc>
                        <XpDescText data-element-description="profile xp threshold">
                            {t('XP')}: {formatNumbers(xp)} / {formatNumbers(levelXPThresholdCurrent)}
                        </XpDescText>
                        <Tooltip
                            text={t('INFO_TOOLTIP.XP', {
                                earnedXp: formatNumbers(xp),
                                needXp: formatNumbers(needXp),
                                nextLevel: formatNumbers(level + 1),
                            })}
                        >
                            <InfoIcon />
                        </Tooltip>
                    </XpDesc>
                </XpBlock>
            </Container>
        );
    }
}

export const ProfileXp = withTranslation()(ProfileXpClass);

const Container = (props: any) => <div className={styles.Container} {...props} />;
const CoinsBlock = (props: any) => <div className={styles.CoinsBlock} {...props} />;
const Coins = (props: any) => <CoinsIcon className={styles.Coins} {...props} />;
const CoinsNum = (props: any) => <div className={styles.CoinsNum} {...props} />;
const CoinsDesc = (props: any) => <div className={styles.CoinsDesc} {...props} />;
const CoinsDescText = (props: any) => <span className={styles.CoinsText} {...props} />;
const XpBlock = (props: any) => <div className={styles.XpBlock} {...props} />;
const ProgressBar = (props: any) => <div className={styles.ProgressBar} {...props} />;
const ProgressTitle = (props: any) => <p className={styles.ProgressTitle} {...props} />;
const ProgressNum = (props: any) => <p className={styles.ProgressNum} {...props} />;
const XpDesc = (props: any) => <div className={styles.XpDesc} {...props} />;
const XpDescText = (props: any) => <span className={styles.XpDescText} {...props} />;
