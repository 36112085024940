import React from 'react';

import classNames from 'classnames';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import { CreditInput } from '../../../atoms/CreditInput/CreditInput';
import { ErrorMessage } from '../../../atoms/ErrorMessage/ErrorMessage';
import { Input } from '../../../atoms/HookFormInput/Input';
import { SearchSelect } from '../../../FigmaStyleguide/Select/SearchSelect';
import { NotifyIconTooltip } from '../../../molecules/NotifyIconTooltip/NotifyIconTooltip';
import styles from './InputWrapper.css';
import { DesktopQuery, MobileQuery } from '../../../atoms/Layout/Responsive';

type InputWrapperProps = {
    register?: any; // need to check ts support for react-hook-form
    errors: any; // need to check ts support for react-hook-form
    errorMessage: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    name?: string;
    ariaInvalid?: React.AriaAttributes['aria-invalid'];
    type?: string;
    label: string;
    description?: string;
    descriptionId?: string;
    placeholder?: string;
    creditInput?: boolean;
    dataRecurly?: string;
    fullWidth?: boolean;
    setValue?: (name: string, value: string) => void;
    setValidation?: (isValid: boolean, name: string, message?: string) => void;
    isValid?: boolean;
    select?: boolean;
    value?: string;
};

export const InputWrapper = ({
    register,
    errors,
    errorMessage,
    name,
    ariaInvalid,
    type,
    label,
    description,
    descriptionId,
    placeholder,
    creditInput,
    dataRecurly,
    fullWidth = true,
    setValue,
    setValidation,
    isValid,
    select,
    value,
}: InputWrapperProps) => {
    return (
        <div className={styles.inputWrapper}>
            {/* TODO: research why text-align is not working in css */}
            <div className={styles.labelWrapper} style={{ textAlign: 'end' }}>
                <label className={styles.label} htmlFor={name}>
                    {label}
                </label>

                {/* mobile tooltipis */}
                {description && (
                    <MobileQuery>
                        <NotifyIconTooltip
                            textTop={description}
                            tooltipNotVisible={false}
                            classNameMessage="profilePage"
                            messageBackgroundColor="rgba(0, 66, 187, 1)"
                        />
                    </MobileQuery>
                )}
            </div>
            <div className={classNames(styles.inputBlock, !fullWidth && styles.minWidth)}>
                {select && (
                    <SearchSelect
                        isValid={isValid}
                        value={value}
                        // tslint:disable-next-line:no-shadowed-variable
                        onChange={(value) => {
                            setValue('country', value);
                            /* here we need to reset state field
                            for example, behavior without reset: the state of Washington can be selected,
                            with the country of the USA,
                            and after changing the country to France the state will not change and
                            will remain Washington
                             */

                            setValue('state', '');
                            setValidation(true, 'country');
                        }}
                        placeholder={'Select your country'}
                        fullWidth
                        className={styles.select}
                        name={'country'}
                        validate={() => {}}
                    />
                )}
                {creditInput && (
                    <CreditInput
                        setValidation={setValidation}
                        setValue={setValue}
                        placeholder={placeholder}
                        dataRecurly={dataRecurly}
                        errors={errors}
                    />
                )}
                {!select && !creditInput && (
                    <Input
                        className={styles.input}
                        isValid={isValid}
                        ariaInvalid={ariaInvalid}
                        register={register}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        dataRecurly={dataRecurly}
                    />
                )}

                {errors[name] && <ErrorMessage id={`${name}Error`} message={errorMessage} />}
                {dataRecurly === 'expirationDate' && (errors.year || errors.month) && (
                    <ErrorMessage id={`${name}Error`} message={errorMessage} />
                )}
            </div>

            {/*  */}
            {description && dataRecurly !== 'cvv' && (
                <DesktopQuery>
                    <span className={styles.description} aria-atomic="true" aria-live="polite" id={descriptionId}>
                        {description}
                    </span>
                </DesktopQuery>
            )}

            {/* cvv tooltip for desktop */}
            {dataRecurly === 'cvv' && (
                <DesktopQuery>
                    <div className={styles.notifyWrapper}>
                        <p className={styles.description} style={{ fontSize: 16, fontWeight: 'bold' }}>
                            Where do i find this?
                        </p>

                        <NotifyIconTooltip
                          // @tslint-disable-no-trailing-whitespace
                            textTop={`Check the back of your card
                            for a 3-digit code.
                            For American Express, look for
                            a 4-digit code on the front
                            of your card.`}
                            tooltipNotVisible={false}
                            classNameMessage="profilePage"
                            messageBackgroundColor="rgba(0, 66, 187, 1)"
                        />
                    </div>
                </DesktopQuery>
            )}
        </div>
    );
};
