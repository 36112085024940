// import { IGame } from '../../../models/Game/Game';
// import { RegistrationSource } from '../../../models/User/RegistrationSource';
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventBuilder } from '../AnalyticsEventBuilder';
// import { GemsAnalyticsRegistrationLocations } from './GemsAnalyticsAi';

export class ProfileAnalyticsAi {
    // loginImpression(category: string, game: IGame): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('loginButton');
    //     event.setEventAction(AnalyticsEventAction.IMPRESSION);
    //     event.setNonInteraction(true);

    //     if (category) {
    //         event.setCustom('category', category);
    //     }

    //     if (game) {
    //         event.setGame(game);
    //     }

    //     return event.build();
    // }

    // loginClick(
    //     category: string,
    //     game: IGame,
    //     registrationLocation?: GemsAnalyticsRegistrationLocations
    // ): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('loginButton');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);

    //     if (category) {
    //         event.setCustom('category', category);
    //     }

    //     if (game) {
    //         event.setGame(game);
    //     }

    //     if (registrationLocation) {
    //         event.setCustom('registrationLocation', registrationLocation);
    //     }

    //     return event.build();
    // }

    // loginSuccess(category: string, game: IGame, loginLocation: GemsAnalyticsRegistrationLocations): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('loginButton');
    //     event.setEventAction(AnalyticsEventAction.LOGIN);
    //     event.setNonInteraction(false);

    //     if (category) {
    //         event.setCustom('category', category);
    //     }

    //     if (game) {
    //         event.setGame(game);
    //     }

    //     if (loginLocation) {
    //         event.setCustom('loginLocation', loginLocation);
    //     }

    //     return event.build();
    // }

    // emailOptIn(): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('loginButton');
    //     event.setEventAction(AnalyticsEventAction.EMAILOPTIN);
    //     event.setNonInteraction(false);
    //     return event.build();
    // }

    // registration(
    //     category: string,
    //     game: IGame,
    //     source: RegistrationSource,
    //     avatarType: string,
    //     avatarBackground: string,
    //     registrationLocation: GemsAnalyticsRegistrationLocations
    // ): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('registration');
    //     event.setEventAction('');
    //     event.setNonInteraction(false);
    //     event.setCustom('registrationOption', source);

    //     if (category) {
    //         event.setCustom('category', category);
    //     }

    //     if (game) {
    //         event.setGame(game);
    //     }

    //     event.setCustom('avatarType', avatarType);
    //     event.setCustom('avatarBackground', avatarBackground);
    //     event.setCustom('registrationLocation', registrationLocation);
    //     return event.build();
    // }

    // publicProfileName(): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('publicProfileName');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     return event.build();
    // }

    // profileEmail(): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('profileEmail');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     return event.build();
    // }

    // personalInformation(fieldName: string, fieldValue?: string): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('personalInformation');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     event.setCustom('personalInfoType', fieldName);
    //     if (fieldName === 'gender') event.setCustom('profileGender', fieldValue);
    //     return event.build();
    // }

    // publicProfileCountry(country: string): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('publicProfileCountry');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setCustom('profileCountry', country);
    //     event.setNonInteraction(false);
    //     return event.build();
    // }

    // profileAvatar(avatarType: string): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('profileAvatar');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     event.setCustom('avatarType', avatarType);
    //     return event.build();
    // }

    // profileAvatarCustomize(avatarBackground: string, avatarFrame: string): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('profileAvatarCustomize');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     event.setCustom('avatarBackground', avatarBackground);
    //     event.setCustom('avatarFrame', avatarFrame);
    //     return event.build();
    // }

    // onboardingPersonal(): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('onboardingPersonal');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     return event.build();
    // }

    // onboardingEmail(): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('onboardingEmail');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     return event.build();
    // }

    resendConfirmation(): AnalyticsEvent {
        const event = this.newEventBuilder();

        event.setName('resendConfirmation');
        event.setEventAction(AnalyticsEventAction.CLICK);
        event.setNonInteraction(false);
        return event.build();
    }

    // resetPassword(type: string): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('resetPassword');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     event.setCustom('type', type);
    //     return event.build();
    // }

    // socialEmailRequest(): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('socialEmailRequest');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     return event.build();
    // }

    // registrationMoreAvatars(avatarType: string): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('registrationMoreAvatars');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     event.setCustom('avatarType', avatarType);
    //     return event.build();
    // }

    // registrationAvatarSkip(avatarType: string): AnalyticsEvent {
    //     const event = this.newEventBuilder();

    //     event.setName('registrationAvatarSkip');
    //     event.setEventAction(AnalyticsEventAction.CLICK);
    //     event.setNonInteraction(false);
    //     event.setCustom('avatarType', avatarType);
    //     return event.build();
    // }

    private newEventBuilder(): AnalyticsEventBuilder {
        return new AnalyticsEventBuilder().baseEvent();
    }
}
