export type PageName = string;

export enum PageTypes {
    Home = 'Home',
    Category = 'Category',
    AllCategories = 'AllCategories',
    Game = 'Game',
    NotFound = 'NotFound',
    Profile = 'Profile',
    PrivacyPolicy = 'PrivacyPolicy',
    PrivacyPolicyCalifornia = 'PrivacyPolicyCalifornia',
    Subscription = 'Subscription',
    AboutUs = 'AboutUs',
    GiftCard = 'GiftCard',
    SiteMap = 'SiteMap',
    CoBranded = 'CoBranded',
    BlogArchive = 'BlogArchive',
    BlogPost = 'BlogPost',
}

export enum ProfileRightSideRoutes {
    NOOP = 'Noop',
    AVATARS_PANEL = 'AvatarsPanel',
    AVATAR_CUSTOMIZE_PANEL = 'AvatarCustomizePanel',
    SCREEN_NAME_PANEL = 'ScreenNamePanel',
    COUNTRY_PICKER_PANEL = 'CountryPickerPanel',
    FIRST_NAME_PANEL = 'FirstNamePanel',
    LAST_NAME_PANEL = 'LastNamePanel',
    MY_LANGUAGE_PANEL = 'MyLanguagePanel',
    DELETE_ACCOUNT_PANEL = 'DeleteAccountPanel',
    BIRTHDAY_PANEL = 'BirthdayPanel',
    PHONE_NUMBER_PANEL = 'PhoneInputPanel',
    GENDER_PANEL = 'GenderPanel',
    EMAIL_PANEL = 'EmailPanel',
    SIGN_IN = 'SignInPanel',
    SIGN_UP = 'SignUpPanel',
    AFTER_SIGN = 'AfterSignPanel',
    AFTER_SIGN_SOCIAL = 'AfterSignSocialPanel',
    THANK_YOU = 'ThankYouPanel',
    RESET_PASSWORD = 'ResetPasswordPanel',
    CONFIRM_RESET_PASSWORD = 'ConfirmResetPasswordPanel',
    REQUEST_EMAIL = 'RequestEmailPanel',
    REQUEST_ACTIVATION = 'RequestActivationPanel',
    SUBSCRIPTION_PANEL = 'SubscriptionPanel',
    AFTER_SIGN_UP = 'AFTER_SIGN_UP',
}

export const excludedAmpPageNames = ['Casino', 'Strategy', 'Memory', 'Sudoku'];
