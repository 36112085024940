import React from 'react';
import { connect } from 'react-redux';
import { GameState } from '../models/Enums';
import { RedirectSettings } from '../models/Redirects';
import { AnalyticsInit } from '../services/Analytics/AnalyticsInit';
import { RedirectsService } from '../services/RedirectsService';
import { UrlService } from '../services/UrlService';
import { setHeaderLocation } from '../store/ducks/header';
import { setShouldTrackPageView } from '../store/ducks/shouldTrackPreview';
import { AppState } from '../store/types';

type RouterWrapperProps = {
    dispatch?: any;
    pathname?: string;
    gameState?: GameState;
    replace?: any;
    shouldTrackPageview?: any;
    redirects?: RedirectSettings[];
    is404?: boolean;
};

class RouterWrapperBase extends React.PureComponent<RouterWrapperProps> {
    static displayName = 'RouterWrapper';

    getSnapshotBeforeUpdate(prevProps) {
        if (this.props.pathname !== prevProps.pathname) {
            const url = new URL(window.location.href);
            const redirect = RedirectsService.getUrlRedirect(url, this.props.redirects);

            if (redirect) {
                // reloading the page to get redirect handled appropriate on SSR
                window.location.reload();
            }

            AnalyticsInit.setGlobalCustomDimensions(undefined, {
                pageType: UrlService.getPageType(),
            });
        }

        return null;
    }

    componentDidUpdate(prevProps: RouterWrapperProps) {
        if (this.props.shouldTrackPageview) {
            this.props.dispatch(setShouldTrackPageView(false));
            AnalyticsInit.trackPageView(true);
        }

        if (this.props.pathname !== prevProps.pathname) {
            try {
                window.dispatchEvent(new Event('urlchanged'));
                this.props.dispatch(setHeaderLocation(this.props.pathname));
            } catch (e) {
                console.error(e);
            }

            // Simulate standard browser navigation
            window.scrollTo(0, 0);
        }
    }

    render() {
        return <div className="router-wrapper">{this.props.children}</div>;
    }
}

export const RouterWrapper = connect<RouterWrapperProps, any, any>((state: AppState) => ({
    pathname: state.router.location.pathname,
    gameState: state.gameState,
    shouldTrackPageView: state.shouldTrackPageView,
    redirects: state.redirects,
    is404: state.is404,
}))(RouterWrapperBase);
