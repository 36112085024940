import { fetchApiCall } from '../../fetch';
import { hostnameToArenaDomain } from '../../utils';
import { environment } from '../config/environment';
import { AnalyticsSortings } from '../models/AnalyticsSortings';
import { Game } from '../models/Game';
import { SortingGame } from '../models/SortingGame';

interface AnalyticsSortingResponse {
    desktop?: any[];
    tablet?: any[];
    mobile?: any[];
}

export class SortingService {
    public static fetchAnalyticsSortings(url: URL, isDefault: boolean): Promise<AnalyticsSortings> {
        const client = isDefault ? 'default' : hostnameToArenaDomain(url.hostname);
        const path = `${environment.ANALYTICS_SORTING_BLOB}/${client}.json`;

        return fetchApiCall(path, { timeout: 10 * 1000 }).then((res) =>
            AnalyticsSortings.fromResponse(res.data)
        );
    }

    public static sortGames(games, manualSortings: any[], analyticsSortings: any[]): Game[] {
        const analyticsSorting = SortingService.filterSorting(games, analyticsSortings);
        const manualSorting = SortingService.filterSorting(games, manualSortings);

        if (analyticsSorting && analyticsSorting.length) {
            games = SortingService.analyticsSortGames(analyticsSorting, games);
        }

        if (manualSorting && manualSorting.length) {
            games = SortingService.manualSortGames(manualSorting, games);
        }

        return games;
    }

    private static filterSorting(games, sorting: SortingGame[]) {
        const slugs = games.map((g) => g.slug);

        return sorting.filter((obj) => slugs.indexOf(obj.slug) !== -1);
    }

    private static analyticsSortGames(sorting, games) {
        sorting.sort((a, b) => a.order - b.order);

        return games.sort((a, b) => {
            const gameA = sorting.find((g) => g.slug === a.slug);
            const gameB = sorting.find((g) => g.slug === b.slug);
            // if order is 0 or undefined, setting it to the max integer to show it after sorted games
            const orderA = gameA && gameA.order > 0 ? gameA.order : Number.MAX_SAFE_INTEGER;
            const orderB = gameB && gameB.order > 0 ? gameB.order : Number.MAX_SAFE_INTEGER;

            return orderA - orderB;
        });
    }

    private static manualSortGames(sorting, games) {
        sorting
            .sort((a, b) => {
                return a.order - b.order;
            })
            .forEach((sortGame) => {
                const changedGame = games.find((game) => {
                    return game.slug === sortGame.slug;
                });
                const oldIndex = games.indexOf(changedGame);

                if (oldIndex !== -1) {
                    games.splice(oldIndex, 1);
                    games.splice(sortGame.order - 1, 0, changedGame);
                }
            });

        return games;
    }
}
