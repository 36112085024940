import nodeFetch from 'node-fetch';
import { checkFetchStatus } from '../../fetch';
import { OpenWebSettings } from '../models/OpenWebToken';
import { environment } from '../config/environment';

function logOpenWebAuthEvent(msg) {
    console.log(`%c Log:OpenWebAuth: ${msg}`, 'background: #0b0c4f; color: #cfd444');
}

export function OpenWebService(user, apiToken: string, initEvents: boolean = true) {
    const ssoApiUrl = (window as any).location.protocol + '//' + (window as any).location.host + `/openweb-sso`;

    function init() {
        if (initEvents) {
            if ((window as any).SPOTIM && (window as any).SPOTIM.startSSO) {
                startSSO();
            } else {
                document.addEventListener('spot-im-api-ready', startSSO, false);
            }

            // In case of token expiration we need to renew sso
            document.addEventListener('spot-im-renew-sso', startSSO, false);
        } else {
            updateUser(user); // in case of user name update
        }
    }

    init();

    function startSSO() {
        const callback = function (codeA, completeSSOCallback) {

            fetch(ssoApiUrl, {
                method: 'POST',
                body: JSON.stringify({
                    codeA,
                    primary_key: user.uid,
                    user_name: user.name,
                    domain: (window as any).location.host
                }),
                headers: {
                    'Authorization': `Bearer ${apiToken}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    } else {
                        return res.json().then((data) => {
                            throw new Error(JSON.stringify(data));
                        });
                    }
                })
                .then((data: { codeB: string }) => {
                    if (data.codeB) {
                        completeSSOCallback(data.codeB);
                    }
                })
                .catch((err) => {
                    completeSSOCallback(null, err);
                });
        };

        (window as any).SPOTIM.startSSO({ callback, userId: user.uid}) // '<unique-user-id>'
            .then(function (data) {
                logOpenWebAuthEvent(`OpenWeb Auth is successful.`);
            })
            .catch(function (reason) {
                logOpenWebAuthEvent(`OpenWeb Auth was failed. Reason is:${reason}`);
            });
    }

    function updateUser(userObj) {
        fetch(ssoApiUrl, {
            method: 'POST',
            body: JSON.stringify({
                action: 'update',
                primary_key: userObj.uid,
                user_name: userObj.name,
                domain: (window as any).location.host
            }),
            headers: {
                'Authorization': `Bearer ${apiToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((data) => {
                        throw new Error(JSON.stringify(data));
                    });
                }
            })
            .then((data: { codeB: string }) => {
                if (data.codeB) {
                    // completeSSOCallback(data.codeB);
                }
            })
            .catch((err) => {
                // completeSSOCallback(null, err);
            });
    }
}

export function OpenWebLogout() {
    (window as any).SPOTIM.logout();
}

export function fetchOpenwebTokens(): Promise<OpenWebSettings> {
        return nodeFetch(environment.OPENWEB_TOKENS_PATH, {timeout: 20000})
            .then((res) => checkFetchStatus(res as any))
            .then((response) => response.json());
}
