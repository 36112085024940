import React from 'react';

type iconProps = {
    className?: string;
    iColor: string;
    backColor: string;
    ariaHidden?: boolean;
};

export const InfoIcon = React.memo(({ className, iColor, backColor, ariaHidden }: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="Info"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            aria-hidden={ariaHidden || undefined}
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" transform="translate(-531 -500) translate(296 500) translate(235)" />
                <path
                    fill={backColor}
                    d="M17 29c2.192 0 4.221-.548 6.087-1.644 1.807-1.039 3.23-2.462 4.269-4.27C28.452 21.222 29 19.193 29 17s-.548-4.221-1.644-6.087c-1.039-1.807-2.462-3.23-4.27-4.269C21.222 5.548 19.193 5 17 5c-2.173 0-4.192.548-6.058 1.644-1.807 1.058-3.24 2.49-4.298 4.298C5.548 12.808 5 14.827 5 17s.548 4.192 1.644 6.058c1.039 1.807 2.462 3.24 4.27 4.298C12.778 28.452 14.807 29 17 29z"
                    transform="translate(-531 -500) translate(296 500) translate(235)"
                />
                <path
                    fill={iColor}
                    d="M18 14v6h1v2h-4v-2h1v-4h-1v-2h3zm0-3v2h-2v-2h2z"
                    transform="translate(-531 -500) translate(296 500) translate(235)"
                />
            </g>
        </svg>
    );
});
