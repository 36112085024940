export class SSOService {

    static getLoginPageUrl(config, currentUrl) {
        if (config.sso && config.sso.name && config.sso.loginUrl) {
            if (config.sso.name === 'usatoday' && currentUrl) {
                return config.sso.loginUrl + decodeURI(currentUrl);
            }

            return config.sso.loginUrl;
        }

        return null;
    }
}
