import { Api, ApiGateway, ApiGatewayInitParams, ApiSet } from '@arkadium/eagle-virtual-items-api-client';
import {
  UserInventoryDto,
  UserInventoryUpdateRequestDto,
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/inventory-item';
import {
  PurchasableItemDto,
  PurchasableItemPurchaseItemRequestDto,
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item';
import {
  UserVirtualCurrencyTransactionListDto,
  VirtualCurrencyDto,
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/virtual-currency';
import { batch } from 'react-redux';
import { isServer } from '../../utils';
import { environment } from '../config/environment';
import { GEMS_TEST_SKU, skipPrerollItem } from '../constants/GemsConstants';
import { setIsPrerollSkipPriceLoading, setPrerollSkipPrice } from '../store/ducks/gems';
import { AppInsightService } from './AppInsight';

import { EagleLoginService } from './EagleLoginService';
import { UrlService } from './UrlService';

interface GemsServiceInterface {
  getUserVirtualCurrenciesList: () => Promise<VirtualCurrencyDto[]>;
  getUserGemsAmount: () => Promise<number>;
  getPurchasableItemsList: (gameArena5Slug: string) => Promise<PurchasableItemDto[]>;
  getPurchasableItemsBySkuList: (purchasableItemsSkuList: string[]) => Promise<PurchasableItemDto[]>;
  getPurchasableItemsBySku: (purchasableItemsSku: string) => Promise<PurchasableItemDto>;
  purchaseItem: (
    items: PurchasableItemPurchaseItemRequestDto[],
    currency: string,
    amount: number,
    gameArena5Slug: string,
  ) => Promise<void>;
  getVirtualCurrencyTransactionHistory: (
    rowsNumberToSkip: number,
    rowsNumberToTake: number,
  ) => Promise<UserVirtualCurrencyTransactionListDto>;
  consumeUserInventory: (updateRequest: UserInventoryUpdateRequestDto) => Promise<UserInventoryDto[]>;
}

class GemsServiceClass implements GemsServiceInterface {
  public gemsApiService: ApiGateway;

  constructor() {
    if (!isServer) {
      const params: ApiGatewayInitParams = {
        server: new URL(environment.EAGLE_VIRTUAL_ITEMS_API),
        sessionStorage: EagleLoginService.getSessionStorage(),
      };

      this.gemsApiService = new ApiGateway(params);
    }
  }

  /**
   * Get list of currencies current user has
   * @return virtualCurrenciesList - list of currencies
   */
  public getUserVirtualCurrenciesList = async () => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.virtualCurrency.getVirtualCurrency();
  }

  /**
   * Get balance of user's current currency
   * @return amount - amount of current virtual currency
   */

  public getUserGemsAmount = async () => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);
    const virtualCurrencyState = await api.virtualCurrency.getVirtualCurrencyBySku(GEMS_TEST_SKU).catch((err) => {
      // correct response from eagle if user has no gems
      if (err?.body?.statusCode === 404) {
        return { amount: 0 };
      }
    });

    if (virtualCurrencyState) {
      return virtualCurrencyState.amount;
    }

    return null;
  }

  /**
   * Get list of all available purchasable items for current game
   * @param gameArena5Slug - ID of game to filter available items
   * @return purchasableItemsList - list of purchasable items
   */
  public getPurchasableItemsList = async (gameArena5Slug: string) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.purchasableItems.getPurchasableItemsByGame({
      gameKeyFilter: gameArena5Slug,
    });
  }

  /**
   * Get information about purchasable items specified as parameters
   * @param purchasableItemsSkuList - array of purchasable items sku's
   * @param purchasableItemsSkuList - array of objects storing information about purchasable items
   */
  public getPurchasableItemsBySkuList = async (purchasableItemsSkuList: string[]) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);
    // as test SKU use this: "eagle_test_for_games1"

    return api.purchasableItems.getPurchasableItemsBySkuList({
      skuList: purchasableItemsSkuList,
    });
  }

  /**
   * Get information about one purchasable item
   * @param purchasableItemsSku - purchasable items sku
   * @param purchasableItemsSku - array of objects storing information about purchasable items
   */
  public getPurchasableItemsBySku = async (purchasableItemsSku: string) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.purchasableItems.getPurchasableItem({ sku: purchasableItemsSku });
  }

  /**
   * Purchase items
   * @param items - list of items to purchase
   * @param currency - ID of virtual currency
   * @param amount - amount of virtual currency
   * @param gameArena5Slug - ID of game to filter available items
   */
  public purchaseItem = async (
    items: PurchasableItemPurchaseItemRequestDto[],
    currency: string,
    amount: number,
    gameArena5Slug: string,
  ) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);
    const timeOffset = new Date().getTimezoneOffset();

    return api.purchasableItems.purchaseItem({
      items,
      currency,
      amount,
      gameKey: gameArena5Slug,
      application: UrlService.getCurrentHostnameForEagle(),
      timeOffset,
    });
  }

  /**
   * Get history of user's virtual currency transactions
   * @param rowsNumberToSkip - number of rows to skip
   * @param rowsNumberToTake - number of rows to take
   * @return history - history object
   */
  public getVirtualCurrencyTransactionHistory = async (rowsNumberToSkip: number, rowsNumberToTake: number) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.virtualCurrency.getVirtualCurrencyTransactions({
      sku: GEMS_TEST_SKU,
      skip: rowsNumberToSkip,
      take: rowsNumberToTake,
    });
  }

  /**
   * Consume user inventory item
   * @param updateRequest - contains items which contains sku and amount
   * @return status of consuming
   */
  public consumeUserInventory = async (updateRequest: UserInventoryUpdateRequestDto) => {
    const api: ApiSet = await this.gemsApiService.getApi(Api.v1);

    return api.userInventory.consumeUserInventory(updateRequest);
  }

  public async updatePriceSkipPreroll(dispatch) {
    dispatch(setIsPrerollSkipPriceLoading(true));

    try {
      const prerollSkipPrice = await this.getPurchasableItemsBySku(skipPrerollItem.sku);

      batch(() => {
        dispatch(setPrerollSkipPrice(prerollSkipPrice?.internalPrices[0].amount));
        dispatch(setIsPrerollSkipPriceLoading(false));
      });
    } catch (err) {
      console.error(err);
      AppInsightService.trackAppError(err, { data: 'UpdatePriceSkipPreroll()' });
      batch(() => {
        dispatch(setPrerollSkipPrice(0));
        dispatch(setIsPrerollSkipPriceLoading(false));
      });
    }
  }
}

const GemsService = new GemsServiceClass();

export default GemsService;

export async function gemsGetCurrentAmount(): Promise<any> {
  if (EagleLoginService.isUserLoggedIn()) {
    return { [GEMS_TEST_SKU]: await GemsService.getUserGemsAmount() };
  }

  return null;
}

export async function gemsIsFirstPurchase(): Promise<boolean | null> {
  if (EagleLoginService.isUserLoggedIn()) {
    const transactions = await GemsService.getVirtualCurrencyTransactionHistory(0, 1);
    const isNotFirst = Boolean(transactions && transactions?.total && transactions.total >= 1);

    return !isNotFirst; // assume first transaction is gems purchase to spend them
  }

  return null;
}

export async function gemsVirtualItemsSpent(): Promise<number | null> {
  if (EagleLoginService.isUserLoggedIn()) {
    const transactions = await GemsService.getVirtualCurrencyTransactionHistory(0, 20); // 20 enough

    return transactions?.items.filter((transaction) => transaction.amount < 0).length; // minus gems transactions
  }

  return null;
}
