import { ABTestsState } from '../store/ducks/abTests';
import { AnalyticsInit } from './Analytics/AnalyticsInit';
import { LocalStorageService } from './LocalStorage';
import { CookieStorageService } from './CookieStorage';

export const PrerollTestKey = 'ark_preroll_test_variation';

export enum PrerollVariations {
    CONTROL = 'prerollAuto_Control',
    FIRST = 'prerollAuto_Var1',
}

class ABTestServiceFactory {
    public updateJewelShuffleABTestDimension() {
        const group = this.getJewelShuffleABTestLocalStorageValue();

        if (group !== '') {
            AnalyticsInit.setGlobalCustomDimensions(
                {
                    ABTestSlot1: group,
                },
                {
                    ABTestSlot1: group,
                }
            );
        }
    }

    public getJewelShuffleABTestLocalStorageValue(): string {
        const val = LocalStorageService.getItem('jewel-shuffle-ab-test-settings');

        if (val) {
            return JSON.parse(val).group;
        }

        return '';
    }

    public init(abTests?: ABTestsState) {
        this.initPrerollTest(abTests.prerollVariation);
    }

    private initPrerollTest(variation: PrerollVariations) {
        // Do not overwrite existing cookie
        if (CookieStorageService.get(PrerollTestKey)) {
            return;
        }

        const isValid = [PrerollVariations.CONTROL, PrerollVariations.FIRST].indexOf(variation) !== -1;

        if (isValid) {
            const cookieLifeTime = { expires: 365, path: '/' };

            CookieStorageService.set(PrerollTestKey, variation, cookieLifeTime);
        }
    }
}

export const ABTestService = new ABTestServiceFactory();
