import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from '../../atoms/Link/Link';
import styles from './Page404Template.css';
import { getAssetUrl } from '../../../utils';

const Page404TemplateBase = React.memo((props: { absoluteBaseHref: string } & WithTranslation) => (
    <Container>
        <Img />

        <Title>{props.t('404_PAGE_TITLE')}</Title>

        <Description>
            {props.t('404_PAGE_TEXT')}
            <RootLink to={'/'}>{new URL(props.absoluteBaseHref).hostname}</RootLink>
        </Description>
    </Container>
));
const Container = (props: any) => <div className={`${styles.container} container`} {...props} />;
const Img = (props: any) => <img className={styles.img} src={`${getAssetUrl()}/images/404-image.png`} alt="Page not found" {...props} />;
const Title = (props: any) => (
    <h1 className={styles.title} {...props}>
        {props.children}
    </h1>
);
const Description = (props: any) => (
    <h2 className={styles.description} {...props}>
        {props.children}
    </h2>
);
const RootLink = (props: any) => <Link className={styles.rootLink} {...props} />;

export const Page404Template = withTranslation()(Page404TemplateBase);
