import { createAction, createReducer } from 'redux-act';
import { LeaderboardRecord } from '../../models/LeaderboardRecord';
import { LeaderboardTabs } from '../../molecules/LeaderboardTab/LeaderboardTab';

export interface LeaderboardState {
    list: LeaderboardRecord[];
    loading: boolean;
    activeTab: LeaderboardTabs;
}

export const setLeaderboardLoading = createAction<boolean>('set leaderboard loading');
export const setLeaderboardList = createAction<LeaderboardRecord[]>('set leaderboard list');
export const setLeaderboardTab = createAction<LeaderboardTabs>('set leaderboard tab');

const reducer = createReducer<LeaderboardState>({}, { list: [], loading: true, activeTab: LeaderboardTabs.TODAY });

reducer.on(setLeaderboardLoading, (_state, payload: boolean) => ({ ..._state, loading: payload }));
reducer.on(setLeaderboardList, (_state, payload: LeaderboardRecord[]) => ({ ..._state, list: payload }));
reducer.on(setLeaderboardTab, (_state, payload: LeaderboardTabs) => ({ ..._state, activeTab: payload }));

export default reducer;
