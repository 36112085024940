import React from 'react';

type iconProps = {
    className?: string;
};

export const BlankCard = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="100"
            height="70"
            viewBox="0 0 100 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 4.66667H90C93.3137 4.66667 96 7.80067 96 11.6667V16.3333H4V11.6667C4 7.80067 6.68629 4.66667 10 4.66667ZM100 28V16.3333V11.6667C100 5.22334 95.5228 0 90 0H10C4.47715 0 0 5.22335 0 11.6667V16.3333V28V58.3333C0 64.7766 4.47716 70 10 70H90C95.5229 70 100 64.7766 100 58.3333V28ZM4 58.3333V28H96V58.3333C96 62.1993 93.3137 65.3333 90 65.3333H10C6.68629 65.3333 4 62.1993 4 58.3333ZM14 37.3333C12.8954 37.3333 12 38.378 12 39.6667C12 40.9553 12.8954 42 14 42H86C87.1046 42 88 40.9553 88 39.6667C88 38.378 87.1046 37.3333 86 37.3333H14ZM14 51.3333C12.8954 51.3333 12 52.378 12 53.6667C12 54.9553 12.8954 56 14 56H48C49.1046 56 50 54.9553 50 53.6667C50 52.378 49.1046 51.3333 48 51.3333H14Z"
                fill="#C4C4C4"
            />
        </svg>
    );
});
