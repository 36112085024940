import React from 'react';
import { AnalyticsInit } from '../services/Analytics/AnalyticsInit';
import { AppInsightsAnalytics } from '../services/Analytics/AppInsights';

type ErrorBoundaryState = {
    swallow?: boolean;
    hasError: boolean;
};

export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.error(error, info);
        AppInsightsAnalytics.trackAppError(`[ErrorBoundary]`, error.message + error.stack);
    }

    render() {
        if (this.state.hasError) {
            if (!this.props.swallow) {
                AnalyticsInit.trackPageView(true, false, true);
            }

            // You can render any custom fallback UI
            return this.props.swallow ? null : <h1>Sorry, something's not working right. Please try again later.</h1>;
        }

        return this.props.children;
    }
}
