import i18n from 'i18next';
import { Game } from '../models/Game';

export class LDJsonTemplateService {
    static buildGameListTemplate(games: Game[], absoluteBaseHref: string, lang: string) {
        const t = i18n.getFixedT(lang);

        return {
            '@context': 'https://schema.org',
            '@type': 'ItemList',
            itemListElement: games.map((game, i) => {
                return {
                    '@type': 'ListItem',
                    position: i + 1,
                    url: `${absoluteBaseHref}/${t('ROUTES.GAMES')}/${game.alias}`,
                };
            }),
        };
    }

    static buildGameTemplate(game: Game, absoluteBaseHref: string, lang: string) {
        const t = i18n.getFixedT(lang);

        return {
            '@context': 'https://schema.org',
            '@type': 'VideoGame',
            name: game.name,
            url: `${absoluteBaseHref}/${t('ROUTES.GAMES')}/${game.alias}`,
            image: game.assetOriginUrl + game.thumbPath,
            description: game.description,
            playMode: 'SinglePlayer',
            inLanguage: ['English'],
            author: {
                '@type': 'Organization',
                name: 'Arkadium',
                url: 'http://www.arkadium.com/',
            },
            publisher: 'Arkadium',
            genre: game.tags,
            gamePlatform: 'Web',
            audience: {
                '@type': 'PeopleAudience',
                requiredMinAge: '4',
            },
            operatingSystem: ['Linux', 'Windows', 'OS X', 'Android', 'Windows Phone', 'iOS'],
            applicationCategory: 'Game',
            offers: {
                '@type': 'Offer',
                price: '0',
                priceCurrency: 'USD',
            },
        };
    }
}
