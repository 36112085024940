import React from 'react';

type iconProps = {
    className?: string;
};

export const PCILogo = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            width="130"
            height="46"
            viewBox="0 0 130 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M79.0127 18.835V6.8667H81.0127V17.0658H85.2232V18.835H79.0127Z" fill="#798697" />
            <path
                d="M86.9072 18.835V6.8667H93.5388V8.63593H88.9072V11.8622H93.4335V13.6314H88.9072V17.0658H93.5388V18.835H86.9072Z"
                fill="#798697"
            />
            <path
                d="M97.9603 18.835L94.3813 6.8667H96.5919L99.2235 16.5454L101.855 6.8667H104.066L100.487 18.835H97.9603Z"
                fill="#798697"
            />
            <path
                d="M105.223 18.835V6.8667H111.855V8.63593H107.223V11.8622H111.749V13.6314H107.223V17.0658H111.855V18.835H105.223Z"
                fill="#798697"
            />
            <path d="M113.855 18.835V6.8667H115.855V17.0658H120.066V18.835H113.855Z" fill="#798697" />
            <path
                d="M127.644 18.835V9.46851L125.75 11.3418L124.592 10.0929L127.855 6.8667H129.644V18.835H127.644Z"
                fill="#798697"
            />
            <path d="M70.6316 29.0362L15.8947 46L0 3.12217L57.3684 0L70.6316 29.0362Z" fill="#02797E" />
            <path
                d="M29.2633 20.5023C29.2633 22.5837 28.2106 24.457 26.4212 25.4978C25.4738 26.1222 24.3159 26.4344 23.2633 26.7466C21.8948 27.0589 20.5264 27.1629 19.158 27.1629H18.1054V32.6788H11.0527V14.1539H19.3685C21.8948 14.0498 24.4212 14.5702 26.7369 15.715C28.3159 16.6516 29.3685 18.5249 29.2633 20.5023ZM22.4212 20.5023C22.4212 18.8371 21.158 18.1086 18.8422 18.1086H18.0001V23H18.8422C19.7896 23.1041 20.7369 22.896 21.5791 22.3756C22.1054 21.9593 22.5264 21.2308 22.4212 20.5023Z"
                fill="#F9F9F9"
            />
            <path
                d="M46.8422 32.5747C45.579 32.7828 44.3158 32.8869 43.0527 32.8869C41.2632 32.8869 39.4737 32.6787 37.7895 32.2624C36.4211 31.8461 35.0527 31.2217 33.7895 30.3891C32.7369 29.6606 31.8948 28.6199 31.3685 27.4751C30.8422 26.2262 30.5264 24.8733 30.5264 23.5204C30.5264 22.1674 30.8422 20.8145 31.3685 19.5656C32.0001 18.4208 32.8422 17.3801 33.8948 16.5475C35.0527 15.7149 36.4211 14.9864 37.7895 14.5701C39.3685 14.0498 41.0527 13.8416 42.7369 13.9457C44.0001 13.9457 45.2632 14.0498 46.5264 14.2579C47.2632 14.362 47.8948 14.5701 48.5264 14.8823V19.7738C47.7895 19.3575 46.9474 19.0452 46.1053 18.8371C45.1579 18.5249 44.2106 18.4208 43.2632 18.4208C41.7895 18.4208 40.4211 18.8371 39.2632 19.6697C38.2106 20.6063 37.6843 22.0633 37.7895 23.4163C37.7895 24.1448 37.8948 24.9774 38.3158 25.6018C38.6316 26.2262 39.0527 26.7466 39.579 27.1629C40.1053 27.5792 40.8422 27.8914 41.4737 28.0995C42.2106 28.3077 43.0527 28.4118 43.7895 28.4118C44.6316 28.4118 45.4737 28.3077 46.3158 28.0995C47.1579 27.8914 48.1053 27.6833 48.9474 27.267V31.9502C48.1053 32.2624 47.8948 32.3665 46.8422 32.5747Z"
                fill="#F9F9F9"
            />
            <path d="M59.2634 14.1538H52.1055V32.5746H59.2634V14.1538Z" fill="#F9F9F9" />
            <path
                d="M59.9997 8.42983C60.105 9.57463 59.5787 10.6153 58.7366 11.3439C57.8945 12.0724 56.8419 12.3846 55.684 12.3846C54.5261 12.3846 53.4734 12.0724 52.6313 11.3439C51.7892 10.6153 51.3682 9.57463 51.3682 8.42983C51.3682 7.28503 51.7892 6.24431 52.6313 5.41173C54.4208 3.95472 56.9471 3.95472 58.7366 5.41173C59.5787 6.24431 59.9997 7.28503 59.9997 8.42983Z"
                fill="#F9F9F9"
            />
            <path
                d="M80.104 31.5465C80.104 29.9854 81.2619 28.6324 82.8408 28.6324C82.9461 28.6324 82.9461 28.6324 83.0514 28.6324C83.9987 28.6324 84.8408 29.0487 85.3672 29.8813L84.5251 30.2976C84.2093 29.7772 83.683 29.465 83.0514 29.465C81.9987 29.465 81.1566 30.2976 81.1566 31.4424V31.5465C81.0514 32.5872 81.8935 33.5238 82.9461 33.5238H83.0514C83.683 33.5238 84.2093 33.2116 84.5251 32.6913L85.3672 33.2116C84.8408 33.9401 83.9987 34.4605 83.0514 34.4605C81.4724 34.4605 80.2093 33.3157 80.104 31.7546C80.104 31.6505 80.104 31.5465 80.104 31.5465Z"
                fill="#798697"
            />
            <path
                d="M87.1567 34.3565V28.7366H91.0515V29.5692H88.2094V31.0262H91.0515V31.8587H88.2094V33.4198H91.0515V34.2524L87.1567 34.3565Z"
                fill="#798697"
            />
            <path
                d="M96.4196 34.3564L95.1565 32.2749H94.2091V34.3564H93.2617V28.7365H95.788C96.7354 28.6324 97.5775 29.3609 97.6828 30.2976C97.6828 30.4016 97.6828 30.4016 97.6828 30.5057C97.6828 31.3383 97.1565 32.0668 96.3144 32.1709L97.6828 34.3564H96.4196ZM96.5249 30.5057C96.5249 29.9853 96.1038 29.5691 95.6828 29.5691H95.5775H94.2091V31.3383H95.5775C95.9986 31.4424 96.4196 31.0261 96.5249 30.5057Z"
                fill="#798697"
            />
            <path
                d="M100.841 34.3565V29.5692H99.1567V28.7366H103.578V29.5692H101.894V34.2524L100.841 34.3565Z"
                fill="#798697"
            />
            <path d="M105.262 34.3565V28.7366H106.209V34.3565H105.262Z" fill="#798697" />
            <path
                d="M108.63 34.3565V28.7366H112.525V29.5692H109.578V31.0262H112.42V31.8587H109.578V34.2524L108.63 34.3565Z"
                fill="#798697"
            />
            <path d="M114.42 34.3565V28.7366H115.367V34.3565H114.42Z" fill="#798697" />
            <path
                d="M117.683 34.3565V28.7366H121.578V29.5692H118.736V31.0262H121.578V31.8587H118.736V33.4198H121.578V34.2524L117.683 34.3565Z"
                fill="#798697"
            />
            <path
                d="M123.683 34.3565V28.7366H125.788C127.262 28.6325 128.63 29.7773 128.736 31.2343C128.736 31.3384 128.736 31.4424 128.736 31.5465C128.736 33.0035 127.578 34.3565 126.104 34.3565C125.999 34.3565 125.894 34.3565 125.788 34.3565H123.683ZM127.683 31.5465C127.788 30.5058 126.946 29.6732 125.999 29.5691C125.894 29.5691 125.788 29.5691 125.788 29.5691H124.63V33.4198H125.788C126.841 33.4198 127.683 32.6913 127.683 31.6506V31.5465Z"
                fill="#798697"
            />
            <path d="M129.919 23.6942H79.0767V24.4227H129.919V23.6942Z" fill="#798697" />
            <path d="M129.919 38.8888H79.0767V39.6173H129.919V38.8888Z" fill="#798697" />
        </svg>
    );
});
