import { UserProfileDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-profile.dto';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ArrowIcon } from '../../atoms/Icons/ArrowIcon';
import { InfoIcon } from '../../atoms/Icons/InfoIcon';
import { LandmarkIcon } from '../../atoms/Icons/LandmarkIcon';
import { EagleUser, User } from '../../models/User';
import { AssetService } from '../../services/AssetService';
import { userService } from '../../services/UserService';
import { updateUser } from '../../store/ducks/user';
import { AppState } from '../../store/types';
import { Tooltip } from '../Tooltip/Tooltip';
import CountriesList from './CountriesList';
import styles from './ProfileLocation.css';
import { updateEagleUser } from '../../services/EagleLoginService';

type ProfileNameProps = {
  user: User | EagleUser;
  dispatch?: any;
  isEagle: boolean;
};

type Country = {
  id: string;
  name: string;
  image: string;
};

const ProfileLocationBase = React.memo(({
  user,
  dispatch,
  t,
  isEagle,
}: ProfileNameProps & WithTranslation) => {
  const allCountries: Country[] = CountriesList.map((item) => ({
    ...item,
    image: AssetService.getFlag(item.id),
  }));
  const getActiveCountry = () => allCountries.find((c) => c.id === user['uid' in user ? 'countryId' : 'countryCode']);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [activeCountry, setActiveCountry] = useState<Country>(getActiveCountry());
  const selectRef = React.useRef<HTMLDivElement>();

  function useDropDownControl(ref: React.RefObject<HTMLDivElement>) {
    useEffect(() => {
      function handleClick(event: Event) {
        if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
          setIsOpened(false);
        }
      }

      document.addEventListener('mousedown', handleClick);

      return () => document.removeEventListener('mousedown', handleClick);
    }, [ref]);
  }

  useDropDownControl(selectRef);

  const toggleDropdown = () => {
    setIsOpened(!isOpened);
  };
  const selectCountry = (country: Country) => {
    setActiveCountry(country);
    toggleDropdown();
    saveLocation(country);
  };
  const saveLocation = (country: Country) => {
    const successHandler = (userData: User | UserProfileDTO) => {
      dispatch(updateUser(userData));
    };
    const errorHandler = (err: Error) => console.log(err);

    if (isEagle) {
      const newUser = {
        ...user,
        countryId: country.id,
      };

      updateEagleUser(newUser as EagleUser).then(successHandler).catch(errorHandler);
    } else {
      const newUser = {
        ...user,
        countryCode: country.id,
      };

      userService
        .update(newUser as User)
        .then(successHandler)
        .catch(errorHandler);
    }
  };

  return (
    <LocationBlock data-element-description="profile location">
      <Label data-element-description="profile label">
        <Icon/>
        {t('LOCATION_TITLE')}
        <Tooltip className={styles.Tooltip} text={t('INFO_TOOLTIP.LOCATION')}>
          <InfoIcon/>
        </Tooltip>
      </Label>

      <Select isOpened={isOpened} ref={selectRef}>
        <SelectBtn data-element-description="profile location selected btn" onClick={toggleDropdown}>
          {activeCountry ? (
            <DropdownItem
              showImg
              src={activeCountry.image}
              text={activeCountry.name}
              alt={activeCountry.name}
            />
          ) : (
            <DropdownItem text="n/a" alt="n/a"/>
          )}

          <SelectBtnIcon/>
        </SelectBtn>
        <Dropdown data-element-description="profile location dropdown">
          {allCountries.map((country) => (
            <DropdownItem
              showImg={isOpened}
              key={country.id}
              src={country.image}
              text={country.name}
              alt={country.name}
              onClick={() => {
                selectCountry(country);
              }}
            />
          ))}
        </Dropdown>
      </Select>
    </LocationBlock>
  );
});
const LocationBlock = (props: any) => <div className={styles.LocationBlock} {...props} />;
const Label = (props: any) => <div className={styles.LocationLabel} {...props} />;
const Icon = (props: any) => <LandmarkIcon className={styles.Icon} {...props} />;
const Select = React.forwardRef<any, any>((
  {
    isOpened,
    ...props
  },
  ref,
) => (
  <div className={classnames(styles.Select, { [styles.isOpened]: isOpened })} ref={ref} {...props} />
));
const SelectBtn = (props: any) => <button className={styles.SelectBtn} {...props} />;
const SelectBtnIcon = (props: any) => <ArrowIcon className={styles.SelectBtnIcon} {...props} />;
const Dropdown = (props: any) => <div className={styles.Dropdown} {...props} />;
const DropdownItem = ({
  showImg,
  src,
  text,
  alt,
  ...props
}: any) => (
  <div className={styles.DropdownItem} {...props}>
    {showImg && <img src={src} className={styles.DropdownImg} alt={alt} {...props} />}
    <span>{text}</span>
  </div>
);
const ProfileState = connect((state: AppState) => ({
  user: state.user,
  isEagle: state.config.isEagle,
}))(ProfileLocationBase);

ProfileLocationBase.displayName = 'ProfileLocation';
export const ProfileLocation = withTranslation()(ProfileState);
