import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { formatNumbers } from '../../../utils';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { CoinsIcon } from '../../atoms/Icons/CoinsIcon';
import { InfoIcon } from '../../atoms/Icons/InfoIcon';
import { ExperienceReward } from '../../models/ExperienceDate';
import { Game } from '../../models/Game';
import { EagleUser, User } from '../../models/User';
import { GameEndLevelUp } from '../../molecules/GameEndLevelUp/GameEndLevelUp';
import { Tooltip } from '../../molecules/Tooltip/Tooltip';
import { AnalyticsGamePage } from '../../services/Analytics/AnalyticsGamePage';
import { EagleLevelupService } from '../../services/EagleLevelupService';
import { userService } from '../../services/UserService';
import { AppState } from '../../store/types';
import { GameEndXpProgress } from '../GameEndXpProgress/GameEndXpProgress';
import styles from './GameEndXp.css';

type GameEndStoreProps = {
    gameScore: number;
    isEagle: boolean;
};

type GameEndXpProps = {
    game: Game;
    experienceReward: ExperienceReward;
    userUpdated: User | EagleUser;
    currentUser: User | EagleUser;
    updateXpReward: any;
};

class GameEndXpBase extends React.PureComponent<GameEndXpProps & GameEndStoreProps & WithTranslation> {
    state: {
        expError: boolean;
    } = {
        expError: false,
    };

    constructor(props) {
        super(props);
        this.getXpReward();
    }

    isUserSame() {
        const { userUpdated, currentUser } = this.props;
        const user = currentUser;
        const userId = user && ('uid' in user ? user.uid : user.id);
        const userUpdatedId = userUpdated && ('uid' in userUpdated ? userUpdated.uid : userUpdated.id);
        const isUserSame = userId === userUpdatedId;

        return isUserSame;
    }

    getXpReward() {
        const { gameScore, game, experienceReward, currentUser, isEagle } = this.props;
        const isUserSame = this.isUserSame();

        // if user has got xp already
        if (experienceReward && isUserSame) {
            return;
        }

        // if user has got xp already and logged in under another account
        if (experienceReward && !isUserSame) {
            this.props.updateXpReward(
                {
                    achievedNewLevel: false,
                    coinsGained: 0,
                    xpPrev: currentUser.xp,
                    xpGained: 0,
                    levelXPThresholdPrev: 0,
                },
                currentUser
            );
            return;
        }

        const service = isEagle ? EagleLevelupService : userService;

        service
            .getExperience(game.slug, gameScore)
            .then(({ progress, user }) => {
                this.props.updateXpReward(progress, user);

                if (progress.achievedNewLevel) {
                    AnalyticsGamePage.levelUp(user.level);
                }
            })
            .catch(() => {
                this.setState({ expError: true });
            });
    }

    render() {
        const { expError } = this.state;
        const { experienceReward, userUpdated, t } = this.props;
        const isCurrentUser = this.isUserSame();

        return (
            <React.Fragment>
                {!experienceReward && !expError && !isCurrentUser && <AppLoader text={t('XP_LOADER_TEXT')} />}

                {expError && <XpError>{t('NO_SCORE_MATRIX_ERROR')}</XpError>}

                {experienceReward && !expError && isCurrentUser && (
                    <Container>
                        <Title>
                            <CoinsIcon data-element-description="coins icon" />
                            <TitleText
                                data-element-description="coins text"
                                dangerouslySetInnerHTML={{
                                    __html: this.props.t('COINS_EARNED', {
                                        num: formatNumbers(experienceReward.coinsGained),
                                    }),
                                }}
                            />

                            <Tooltip text={t('INFO_TOOLTIP.COINS')}>
                                <IconInfo />
                            </Tooltip>
                        </Title>

                        <GameEndXpProgress experienceReward={experienceReward} user={userUpdated} />

                        <DescText
                            data-element-description="points info"
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('XP_EXPLAINED', {
                                    xpGained: formatNumbers(experienceReward.xpGained),
                                    xpLeftToEarn: formatNumbers(userUpdated.levelXPThresholdCurrent - userUpdated.xp),
                                }),
                            }}
                        />

                        {experienceReward.achievedNewLevel && <GameEndLevelUp level={userUpdated.level} />}
                    </Container>
                )}
            </React.Fragment>
        );
    }
}

const Container = (props: any) => <div className={styles.container} {...props} />;
const Title = (props: any) => <div className={styles.title} {...props} />;
const TitleText = (props: any) => <span className={styles.titleText} {...props} />;
const DescText = (props: any) => <p className={styles.descText} {...props} />;
const XpError = (props: any) => <p className={styles.error} {...props} />;
const IconInfo = (props: any) => <InfoIcon className={styles.infoIcon} {...props} />;
const GameEndXpTranslated = withTranslation()(GameEndXpBase);

export const GameEndXp = connect(
    (state: AppState): GameEndStoreProps => ({
        gameScore: state.gameScore,
        isEagle: state.config.isEagle,
    })
)(GameEndXpTranslated);
