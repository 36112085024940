import { ActionTypes } from '../../models/Analytics';
import { AppInsightsAnalytics } from './AppInsights';
export enum ELightboxActionsAnalytics {
    VIEW = 'viewLightbox',
    CLOSE = 'closeLightbox',
    PLAY = 'playLightbox',
}
class AnalyticsLightboxClass {
    event(action: ELightboxActionsAnalytics, actionType: ActionTypes, slug: string, eventDurationInSeconds: number) {
        const eventAppInsight = {
            action: actionType,
            game: slug,
            eventDuration: eventDurationInSeconds,
            nonInteraction: actionType === ActionTypes.IMPRESSION,
        };

        AppInsightsAnalytics.trackEvent(action, eventAppInsight);
    }
}

export const AnalyticsLightbox = new AnalyticsLightboxClass();
