import { createAction, createReducer } from 'redux-act';

import { AvatarMatrix } from '../../models/Avatars';

export const setAvatars = createAction<AvatarMatrix[]>('set avatars');

const reducer = createReducer<AvatarMatrix[]>({}, []);

reducer.on(setAvatars, (state, payload: AvatarMatrix[]) => payload);

export default reducer;
