import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setSnackbarData } from '../../store/ducks/layout';
import { CloseIcon } from '../Icons/CloseIcon';
import { SnackbarWarningIcon } from '../Icons/SnackbarWarningIcon';
import { TickIcon } from '../Icons/TickIcon';
import styles from './Snackbar.css';
import { AppState } from '../../store/types';

export const Snackbar = (): JSX.Element => {
    const defaultRootNode = document.body;
    const snackbarData = useSelector((state: AppState) => state.snackbarData);
    const dispatch = useDispatch();
    const [parentNode, setParentNode] = useState(defaultRootNode);
    const [relative, setRelative] = useState(false);
    const hideSnackbar = () => {
        dispatch(setSnackbarData({ isOpened: false }));
    };
    const closeTimeout =
        snackbarData.isOpened &&
        !snackbarData.static &&
        setTimeout(() => {
            hideSnackbar();
        }, 6000);

    useEffect(() => {
        return () => {
            clearTimeout(closeTimeout);
        };
    }, [snackbarData.isOpened]);

    useEffect(() => {
        if (snackbarData.parentNode) {
            const parentContainer = document.querySelector(snackbarData.parentNode) as HTMLElement;

            setParentNode(parentContainer);
            setRelative(true);
        }
    }, [snackbarData.parentNode]);

    return (
        snackbarData.isOpened &&
        createPortal(
            <div
                className={classNames(
                    styles.snackbarWrapper,
                    relative && styles.relativeState,
                    snackbarData.isOpened && !snackbarData.static && styles.animated,
                    snackbarData.static && styles.animatedIn
                )}
            >
                <div
                    className={classNames(
                        styles.snackbar,
                        snackbarData.type === 'error' && styles.error,
                        snackbarData.type === 'success' && styles.success
                    )}
                >
                    {snackbarData.type === 'error' ? (
                        <SnackbarWarningIcon className={styles.icon} />
                    ) : (
                        <TickIcon className={styles.icon} />
                    )}
                    <span>{snackbarData.message}</span>
                    <CloseIcon onClick={hideSnackbar} className={styles.closeIcon} />
                </div>
            </div>,
            parentNode
        )
    );
};
