import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { sanitizeString } from '../../utils';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary';
import { AchievementEvent } from '../models/AchievementEvent';
import { Game } from '../models/Game';
import { UserAuthStatus } from '../models/UserAuthStatus';
import { EagleLevelupService } from '../services/EagleLevelupService';
import { EagleLoginService } from '../services/EagleLoginService';
import { LocalStorageService } from '../services/LocalStorage';
import { ProfileService } from '../services/ProfileService';
import { SEOService } from '../services/SEOService';
import { UrlService } from '../services/UrlService';
import { absoluteBaseHrefSelector } from '../store/ducks/routerSelectors';
import { AppState } from '../store/types';
import { ProfileTemplate } from '../templates/Profile/Profile';
import { generateSeoHreflangLinksList } from '../utils/generateSeoHreflangLinksList';

export const ProfilePage: React.FC = () => {
  const {
    user,
    userAuthStatus,
    games,
    absoluteBaseHref,
    clientName,
    ssoName,
    themeName,
    arenaLang,
    isEagle,
    sharingImage,
  } =
    useSelector((state: AppState) => ({
      user: state.user,
      userAuthStatus: state.userAuthStatus,
      games: state.games.map((game) => new Game(game)),
      absoluteBaseHref: absoluteBaseHrefSelector(state),
      clientName: state.config.theme.name,
      ssoName: state.config.sso.name,
      themeName: state.config.theme.theming.name,
      arenaLang: state.config.theme.locale,
      isEagle: state.config.isEagle,
      sharingImage: state.config.theme.sharingImage,
    }));
  const { t } = useTranslation();
  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const [achievements, setAchievements] = useState<AchievementEvent[]>(null);
  const [recentlyPlayedGames, setRecentlyPlayedGames] = useState<Game[]>(null);
  const hasAuthToken = LocalStorageService.getItem(ssoName === 'washingtonpost' ? 'arenax_api_token' : 'eagle-access-token');

  useEffect(() => {
    if (userAuthStatus === UserAuthStatus.USER_AUTHORIZED) {
      void getUserProfileData();
    }
  }, [userAuthStatus]);

  useEffect(() => {
    if (user && userAuthStatus === UserAuthStatus.USER_AUTHORIZED) {
      void validateEmailAndSignIn();
    }
  }, [user]);

  const validateEmailAndSignIn = async () => {
    try {
      const eagleUserSpecificData = await EagleLoginService.getUserByEmail(user.email);

      setHasPassword(eagleUserSpecificData.hasPassword);
    } catch (error) {
      console.error(error);
    }
  };
  const getMeta = (canonicalData: string) => {
    const metaDescription = t('PROFILE_META_DESCRIPTION');

    return SEOService.buildMeta(metaDescription, canonicalData, clientName, absoluteBaseHref, sharingImage);
  };
  const userName = user && user.name ? sanitizeString(user.name, true) : '';
  const metaTitle = t('PROFILE_META_TITLE', { userName });
  const subPath = ['ROUTES.PROFILE'];
  const canonical = `${absoluteBaseHref}/${t(subPath[0])}`;
  const seoHreflangLinks = generateSeoHreflangLinksList(absoluteBaseHref, arenaLang, subPath);

  if (!hasAuthToken && userAuthStatus === UserAuthStatus.USER_NOT_AUTHORIZED) {
    return <Redirect to={UrlService.getBaseHref()}/>;
  }

  return (
    <ErrorBoundary>
      <ConnectedHelmet
        title={metaTitle}
        meta={getMeta(canonical)}
        link={[
          {
            rel: 'canonical',
            href: canonical,
          }, ...seoHreflangLinks,
        ]}
      />
      <ProfileTemplate
        user={user}
        userAuthStatus={userAuthStatus}
        achievements={achievements}
        recentlyPlayedGames={recentlyPlayedGames}
        themeName={themeName}
        hasPassword={hasPassword}
      />
    </ErrorBoundary>
  );

  async function getUserProfileData() {
    const achievementsData = await getAchievements();
    const recentlyPlayedGamesData = await getRecentlyPlayed();

    setAchievements(achievementsData);
    setRecentlyPlayedGames(recentlyPlayedGamesData);
  }

  async function getAchievements() {
    if (achievements === null) {
      const service = isEagle ? EagleLevelupService : ProfileService;

      try {
        return service.getAchievements();
      } catch (err) {
        console.log(err.message);
        return [];
      }
    }
  }

  async function getRecentlyPlayed() {
    if (recentlyPlayedGames === null) {
      const service = isEagle ? EagleLevelupService : ProfileService;

      try {
        const gamesSlugs: any[] = await service.getRecentlyPlayed();
        const newGames = games.reduce((acc, game) => {
          const index = gamesSlugs.indexOf(game.slug);

          if (index >= 0) {
            acc.push({
              index,
              game,
            });
          }

          return acc;
        }, []);

        newGames.sort((a, b) => a.index - b.index);
        return newGames.map((item) => item.game);
      } catch (err) {
        console.log(err.message);
        return [];
      }
    }
  }
};
