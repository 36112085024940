import React from 'react';

import classNames from 'classnames';

import { Button } from '../Base/Base';
import styles from './TabButtonS.css';

type TabButtonSProps = {
    onClick?: (e?: any) => void;
    className?: string;
    children?: any;
    active: boolean;
};

export const TabButtonS = React.memo((props: TabButtonSProps) => {
    return (
        <Button
            className={classNames(
                styles.tabItem,
                {
                    [styles.active]: props.active,
                },
                styles[props.className]
            )}
            onClick={props.onClick}
            data-element-description="tab-button"
        >
            {props.children}
        </Button>
    );
});
