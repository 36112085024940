import React, { memo } from 'react';

import { CardMonthElement, CardYearElement } from '@recurly/react-recurly';
import classNames from 'classnames';

import styles from './CreditInput.css';

type InputProps = {
    className?: string;
    handleCreditChange: (name: unknown, value: string) => void;
    errors: unknown;
    onBlur: (change?: unknown, dataRecurly?: string) => void;
    touched: any;
};

export const ExpirationDateInput = memo(({ className, handleCreditChange, errors, onBlur, touched }: InputProps) => {
    const generateStyles = (dataRecurly, additionalStyle?) => {
        return classNames(
            className,
            styles.input,
            styles.cardInput,
            additionalStyle,
            touched[dataRecurly] && !errors[dataRecurly] && styles.successful,
            touched[dataRecurly] && errors[dataRecurly] && styles.error
        );
    };

    return (
        <>
            <CardMonthElement
                className={generateStyles('month', styles.creditExpirationDateInput)}
                onChange={(e) => handleCreditChange(e, 'month')}
                style={{ fontSize: '18px', placeholder: { content: 'MM' } }}
                //@ts-ignore
                onBlur={(e) => onBlur(e, 'month')}
            />
            <span className={styles.slash}>/</span>
            <CardYearElement
                className={generateStyles('year', styles.creditExpirationDateInput)}
                onChange={(e) => handleCreditChange(e, 'year')}
                style={{ fontSize: '18px', placeholder: { content: 'YY' } }}
                //@ts-ignore
                onBlur={(e) => onBlur(e, 'year')}
            />
        </>
    );
});
