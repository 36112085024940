import React from 'react';

type iconProps = {
    className?: string;
};

export const PadLockIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="padlock-icon"
            className={props.className}
            viewBox="0 0 35 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.2574 13.6212V14.6212H24.2574H25.1021C25.9467 14.6212 26.6363 15.3102 26.6363 16.1553V27.9812C26.6363 28.8263 25.9467 29.5153 25.1021 29.5153H9.8974C9.05282 29.5153 8.36328 28.8263 8.36328 27.9812V16.1553C8.36328 15.3102 9.05282 14.6212 9.8974 14.6212H10.7421H11.7421V13.6212V10.2423C11.7421 7.06778 14.3252 4.48468 17.4998 4.48468C20.6743 4.48468 23.2574 7.06778 23.2574 10.2423V13.6212ZM22.0049 14.6212H23.0049V13.6212V10.2423C23.0049 7.20549 20.5366 4.73723 17.4998 4.73723C14.4629 4.73723 11.9947 7.20549 11.9947 10.2423V13.6212V14.6212H12.9947H22.0049Z"
                stroke="#231F20"
                strokeWidth="2"
            />
            <path
                d="M17.9696 22.1987L17.5003 22.4724L17.0311 22.1987C16.7384 22.028 16.5542 21.7247 16.5542 21.3869C16.5542 20.8659 16.9793 20.4407 17.5003 20.4407C18.0214 20.4407 18.4465 20.8659 18.4465 21.3869C18.4465 21.7247 18.2623 22.028 17.9696 22.1987Z"
                fill="#231F20"
                stroke="#231F20"
                strokeWidth="2"
            />
        </svg>
    );
});
