import { loadScript } from '../../utils';
import { environment } from '../config/environment';
import { AdSettings } from '../models/AdSettings';
import { KeysEnum } from '../models/Enums';
import AdsService from './AdsService';
import { Deferred } from './Deferred';
import { LocalStorageService } from './LocalStorage';

const adLoadedDefer = new Deferred();

export class DisplayAdService {
    public static init(settings: AdSettings): Promise<any> {
        let libraryPath = environment.DISPLAY_ADS;

        if (settings.theySell && settings.theySell.display) {
            libraryPath = AdsService.buildTheySellLibPath(libraryPath, settings.theySell.display);
        }

        const customEnv = LocalStorageService.getItem(KeysEnum.arkDisplayAdCustomEnv);

        if (customEnv) {
            libraryPath = AdsService.buildCustomEnvLibPath(environment.DISPLAY_ADS, customEnv);
        }

        loadScript(libraryPath)
            .then((_) => adLoadedDefer.resolve())
            .catch((_) => adLoadedDefer.reject());

        return adLoadedDefer.promise;
    }

    public static adLoaded(): Promise<any> {
        return adLoadedDefer.promise;
    }
}
