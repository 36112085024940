import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../atoms/Modal/Modal';
import { TLightboxPromotion } from '../../models/LightboxPromotions';
import { LightboxPromotionPopup } from '../../molecules/LightboxPromotionPopup/LightboxPromotionPopup';
import { LightboxPromotionService } from '../../services/LightboxPromotionsService';
import { setLightboxPromotionOpen } from '../../store/ducks/modal';
import { AppState } from '../../store/types';
import styles from '../../molecules/LightboxPromotionPopup/LightboxPromotionPopup.css';
import { CookieStorageService } from '../../services/CookieStorage';
import { UrlService } from '../../services/UrlService';
import classNames from 'classnames';
import { DeviceDetector } from '../../services/DeviceDetector';
import { AnalyticsLightbox, ELightboxActionsAnalytics } from '../../services/Analytics/AnalyticsLightbox';
import { ActionTypes } from '../../models/Analytics';

export const LightboxPromotionFeature = () => {
    const [currentPromotion, setCurrentPromotion] = useState<TLightboxPromotion>(null);
    const [isDelayed, setIsDelayed] = useState<boolean>(true);
    const [eventDuration, setEventDuration] = useState<number>(0);
    const games = useSelector((state: AppState) => state.games);
    const allPromotions = useSelector((state: AppState) => state.lightboxPromotions);
    const isOpen = useSelector((state: AppState) => state.modal.promotion.isOpen);
    const currentLang = useSelector((state: AppState) => state.currentLang);
    const dispatch = useDispatch();
    const isMobile = DeviceDetector.isMobile();
    const isArenaExcluded = !allPromotions?.items ? true : LightboxPromotionService.isArenaExcluded(allPromotions);
    const gotPageType = UrlService.getPageType();
    const isHomePage = gotPageType === 'Home page';
    const DEFAULT_WHITE = '#f8f7fd';

    useEffect(() => {
        const currentTime = new Date().getTime();
        const getTime = (date: string): number => new Date(date).getTime();
        const actualPromotions = allPromotions?.items?.filter(
            (promotion) => currentTime > getTime(promotion.startDate) && currentTime < getTime(promotion.endDate)
        );
        const currentPromotion = actualPromotions?.[0];
        const COOKIE_KEY = `lightbox_${currentPromotion?.slug}`;
        const isPromotionShown = CookieStorageService.get(COOKIE_KEY);
        const isGamePresentInFeed = games.find((g) => g.slug === currentPromotion?.slug);

        if (actualPromotions?.length && !isPromotionShown && isHomePage && !isDelayed && isGamePresentInFeed) {
            //TODO: make a random choosing if we have several promotions
            setCurrentPromotion(currentPromotion);
            const expiredDate = new Date();

            expiredDate.setHours(23);
            expiredDate.setMinutes(59);
            expiredDate.setSeconds(59);

            CookieStorageService.set(COOKIE_KEY, true, { expires: expiredDate });
            dispatch(setLightboxPromotionOpen(true));
            const eventDuration = (getTime(currentPromotion.endDate) - getTime(currentPromotion.startDate)) / 1000;

            setEventDuration(eventDuration);
            AnalyticsLightbox.event(
                ELightboxActionsAnalytics.VIEW,
                ActionTypes.IMPRESSION,
                currentPromotion.slug,
                eventDuration
            );
        }
    }, [allPromotions, isDelayed]);

    useEffect(() => {
        setTimeout(() => {
            setIsDelayed(false);
        }, 5000);
    }, []);

    const onClose = (withAnalytics = true) => {
        dispatch(setLightboxPromotionOpen(false));

        withAnalytics &&
            AnalyticsLightbox.event(
                ELightboxActionsAnalytics.CLOSE,
                ActionTypes.CLICK,
                currentPromotion.slug,
                eventDuration
            );
    };

    if (isArenaExcluded || !currentPromotion || !isHomePage || isDelayed) {
        return null;
    }

    const buttonText = allPromotions?.playButtonText[currentLang];
    const DEFAULT_CLOSE_BUTTON_COLOR = DEFAULT_WHITE;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeByOverlayClick
            hasCloseIcon
            closeIconClassName={styles.closeIcon}
            closeIconColor={currentPromotion?.closeButtonColor || DEFAULT_CLOSE_BUTTON_COLOR}
            modalWrapperClassName={styles.modalWrapper}
            modalClassName={classNames(styles.popupWrapper, { [styles.mobile]: isMobile })}
        >
            <LightboxPromotionPopup
                isMobile={isMobile}
                popup={currentPromotion}
                buttonText={buttonText}
                onClose={onClose}
                currentLang={currentLang}
                eventDuration={eventDuration}
                defaultColor={DEFAULT_WHITE}
            />
        </Modal>
    );
};
