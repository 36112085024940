import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { fetchApiCall } from '../../../fetch';
import { VolumeMaxIcon } from '../../atoms/Icons/VolumeMaxIcon';
import { VolumeXIcon } from '../../atoms/Icons/VolumeXIcon';
import { ArrowIcon } from '../../atoms/Icons/ArrowIcon';
import styles from './YoutubeAdWidget.css';

const videoApi = `https://www.hsn.com/api/arcade/video`;

interface IVideo {
    ShopNowUrl: string;
    YoutubeId: string;
}

const YoutubeAdWidget: React.FC = () => {
    const [isMuted, setIsMuted] = useState(true);
    const [showVideo, setShowVideo] = useState(false);
    const [videoInfo, setVideoInfo] = useState<IVideo>(null);

    useEffect(() => {
        fetchApiCall(videoApi).then(({ data }) => {
            setVideoInfo(data);
            setShowVideo(prevState => !prevState);
        });
    }, []);

    const handleToggleMute = () => {
        setIsMuted((prevState) => !prevState);
    };

    return (
        <>
            {videoInfo && showVideo && (
                <div className={styles.videoAdWidget}>
                    <div className={styles.videoContainer}>
                        <ReactPlayer
                            className={styles.videoElement}
                            url={`https://www.youtube.com/watch?v=${videoInfo.YoutubeId}`}
                            playing
                            loop
                            muted={isMuted}
                            width="100%"
                            height="100%"
                            controls
                            onError={() => {
                                setShowVideo(false);
                            }}
                        />
                    </div>
                    <div className={styles.grayBar}>
                        <div
                            className={styles.muteBtn}
                            onClick={handleToggleMute}
                            onKeyDown={handleToggleMute}
                            role="presentation"
                        >
                            {isMuted ? <VolumeXIcon/> : <VolumeMaxIcon/>}
                        </div>
                        <div className={styles.shopNowBtn}>
                            <a
                                className={styles.shopNowLink}
                                href={videoInfo.ShopNowUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Shop This Item
                                <ArrowIcon className={styles.shopNowArrow}/>
                            </a>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default YoutubeAdWidget;
