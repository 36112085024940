import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/types';
import { DEFAULT_AVATAR_BACKGROUND, getAvatarDescription, SPECIAL_AVATARS } from '../../constants/AvatarImagesData';
import { getFrameIndex } from '../../constants/FramesImagesList';
import { AvatarIconAdvantage } from '../../FigmaStyleguide/Icons/AvatarIconAdvantage';
import { AvatarIconCheckmark } from '../../FigmaStyleguide/Icons/AvatarIconCheckmark';
import { AvatarIconPadlock } from '../../FigmaStyleguide/Icons/AvatarIconPadlock';
import { UrlService } from '../../services/UrlService';
import { EagleUser } from '../../models/User';
import styles from './ArkcomAvatar.css';
import { AssetService } from '../../services/AssetService';

export enum AvatarTypes {
  REGULAR = 'regular',
  SMALL = 'small',
}

export enum BorderTypes {
  DEFAULT = 'default',
  GOLD = 'gold',
  DISABLED = 'disabled',
}

export type AvatarsListItemProps = {
  selected?: boolean;
  selectable?: boolean;
  focusable?: boolean;
  regularMousePointer?: boolean;
  onSelect?: () => void;
  size?: AvatarTypes;
  image?: string;
  highlight?: boolean;
  email?: string;
  avatarList?: boolean;
  disabled?: boolean;
  border?: BorderTypes;
  noFrame?: boolean;
  frame?: string;
  background?: string;
  premium?: boolean;
  subscriber?: boolean;
  noPadlock?: boolean;
  isLeaderBoard?: boolean;
  isPublicProfile?: boolean;
  isLeaderBoardHoverOn?: boolean;
  noHover?: boolean;
  isFramelist?: boolean;
};

export const ArkcomAvatar = React.memo(
  ({
    selected,
    selectable,
    focusable = false,
    regularMousePointer,
    onSelect,
    size = AvatarTypes.REGULAR,
    image,
    email,
    highlight,
    avatarList,
    disabled,
    border,
    noFrame,
    frame,
    background,
    premium,
    subscriber,
    noPadlock,
    isLeaderBoard,
    isPublicProfile,
    isLeaderBoardHoverOn,
    noHover,
    isFramelist = false,
  }: AvatarsListItemProps) => {
    const [hover, setHover] = useState<boolean>(false);
    // Hover for padlock only
    const [padlockHover, setPadlockHover] = useState<boolean>(false);
    const [frameName, setFrameName] = useState<string>();
    const userFrame = useSelector((store: AppState) => (store.user as EagleUser)?.subscriberAvatar?.frame);

    useEffect(() => {
      if (isLeaderBoard || isPublicProfile || frame) {
        setFrameName(frame);
        return;
      }
    }, [frame, userFrame, isLeaderBoard, isPublicProfile]);

    const userAvatarBackground = useSelector(
      (state: AppState) => (state?.user as EagleUser)?.avatarBackground || DEFAULT_AVATAR_BACKGROUND,
    );
    const style = classNames(styles.avatar, styles[size], {
      [styles.avatar_regularMousePointer]: regularMousePointer,
      [styles.avatar_selected]: selectable && selected,
      [styles.avatar_disabled]: disabled,
      [styles.noHover]: noHover,
      [styles.hoveredLeaderBoard]: hover,
    });
    let borderStyle: React.CSSProperties;
    const frameIndexForA11 = frameName && getFrameIndex(frameName);
    const avatarDescription = getAvatarDescription(image);
    const onKeyDownHandler = ({ key }) => {
      if (key === 'Enter') {
        onSelect?.();
      }
    };

    switch (border) {
      case BorderTypes.DEFAULT:
        borderStyle = {
          borderStyle: `solid`,
        };
        break;
      case BorderTypes.GOLD:
        borderStyle = {
          borderStyle: `double`,
          borderColor: `transparent`,
          backgroundImage: `linear-gradient(${userAvatarBackground}, ${userAvatarBackground}), linear-gradient(314.74deg, #C28C2B 12.84%, #E6C14E 48.8%, #C28C2B 84.98%)`,
          backgroundOrigin: `border-box`,
          backgroundClip: `content-box, border-box`,
        };
        break;
      case BorderTypes.DISABLED:
        borderStyle = {
          borderStyle: `solid`,
          borderColor: `rgba(108, 108, 108, 1)`,
        };
        break;
      default:
        borderStyle = {
          borderWidth: `2px`,
          borderStyle: `solid`,
        };
        break;
    }

    const webpEagleAvatarSrc = UrlService.toAvatarLink((email && SPECIAL_AVATARS[email]?.image) || image, 'webp');
    const pngEagleAvatarSrc = UrlService.toAvatarLink((email && SPECIAL_AVATARS[email]?.image) || image, 'png');
    const pngArenaAvatarSrc = AssetService.getAvatar(image);
    const isEagleAvatar = image.includes('_');

    return (
      <div
        role="presentation"
        data-testid="avatars-list-item"
        onClick={onSelect}
        className={classNames(style)}
        onKeyDown={onKeyDownHandler}
        aria-current={selected}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={focusable ? 0 : -1}
        onMouseOver={() => setPadlockHover(true)}
        onFocus={() => setPadlockHover(true)}
        onMouseLeave={() => setPadlockHover(false)}
      >
        <div
          className={classNames(styles.avatar__content, {
            [styles.avatarHighLight]: highlight,
            [styles.avatarList]: avatarList,
            [styles.premiumHover]: premium && !subscriber,
          })}
          style={{
            backgroundColor: `${
              background || (email && SPECIAL_AVATARS[email]?.backgroundColor) || userAvatarBackground
            }`,
            ...borderStyle,
          }}
          onMouseOver={() => isLeaderBoardHoverOn && setHover(true)}
          onFocus={() => isLeaderBoardHoverOn && setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {!noFrame && frameName && (
            <div className={classNames(styles.frameWrapper)}>
              <picture>
                <source srcSet={UrlService.toFrameLink(frameName, 'webp')} type="image/webp"/>
                <source srcSet={UrlService.toFrameLink(frameName, 'png')} type="image/png"/>
                <img
                  className={classNames(styles.frame)}
                  src={UrlService.toFrameLink(frameName, 'png')}
                  alt={`Frame ${frameIndexForA11} for Arkadium users`}
                />
              </picture>
            </div>
          )}
          {/* TODO: check subscriber or not */}
          <picture>
            <source srcSet={isEagleAvatar ? webpEagleAvatarSrc : null} type="image/webp"/>
            <source srcSet={isEagleAvatar ? pngEagleAvatarSrc : pngArenaAvatarSrc} type="image/png"/>
            <img
              className={classNames(styles.avatarImg)}
              src={isEagleAvatar ? pngEagleAvatarSrc : pngArenaAvatarSrc}
              alt={avatarDescription || 'No avatar'}
            />
          </picture>
          {premium && !subscriber && (
            <AvatarIconPadlock
              className={classNames(styles.padlockIcon, {
                [styles.srOnly]: !padlockHover,
              })}
            />
          )}
          {frame && !subscriber && !noPadlock && (
            <AvatarIconPadlock
              className={classNames(styles.padlockIcon, { [styles.framePadlock]: isFramelist })}
            />
          )}
          {premium && <AvatarIconAdvantage className={styles.advantageIcon}/>}
          {selected && <AvatarIconCheckmark className={styles.checkmarkIcon} strokeWidth={frame ? 1.5 : 2}/>}
        </div>
      </div>
    );
  },
);

ArkcomAvatar.displayName = 'Avatar';
