import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './PoweredBy.css';

type PoweredByContainerProps = {
    position?: 'left' | 'right';
    children: ReactNode;
};

export const PoweredByContainer = ({ position, children }: PoweredByContainerProps) => {
    return (
        <div className={classNames(position=== 'right' ? styles.poweredByContainer__right : styles.poweredByContainer__left)}>
            {children}
        </div>
    );
};
