import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatNumbers } from '../../../utils';
import { TrophyIcon } from '../../atoms/Icons/TrophyIcon';
import { GameEndHighScore } from './GameEndHighScore';
import styles from './GameEndScore.css';

type GameEndScoreProps = {
    currentScore: number;
    highScore: number;
    userPlace: number;
};

const GameEndScoreBase = React.memo(
    ({ currentScore, highScore, userPlace, t }: GameEndScoreProps & WithTranslation) => {
        let titleTextKey = 'SCORE';

        if (userPlace === 1) {
            titleTextKey = 'GAME_END_USER_FIRST';
        } else if (userPlace === 2) {
            titleTextKey = 'GAME_END_USER_SECOND';
        } else if (userPlace === 3) {
            titleTextKey = 'GAME_END_USER_THIRD';
        }

        return (
            <Container>
                <ScoresTitle userPlace={userPlace} data-element-description="game-end scorebox-header">
                    {t(titleTextKey)}
                </ScoresTitle>

                <ScoresValue data-element-description="game-end scorebox-scores">
                    {userPlace > 0 && userPlace <= 3 && (
                        <Trophy data-element-description="game-end scorebox-icon" place={userPlace} />
                    )}

                    {formatNumbers(currentScore)}
                </ScoresValue>

                <GameEndHighScore highScore={highScore} isNewHighscore={currentScore > highScore} />
            </Container>
        );
    }
);
const Container = (props: any) => <div className={styles.container} {...props} />;
const ScoresTitle = ({ userPlace, ...props }) => (
    <div
        className={classNames(styles.scoresTitle, {
            [styles.isFirst]: userPlace === 1,
            [styles.isSecond]: userPlace === 2,
            [styles.isThird]: userPlace === 3,
        })}
        {...props}
    />
);
const ScoresValue = (props: any) => <div className={styles.scoresValue} {...props} />;
const Trophy = (props: any) => <TrophyIcon className={styles.trophyIcon} {...props} />;

export const GameEndScore = withTranslation()(GameEndScoreBase);
