type ColorsSettings = {
    main: string;
    inverted: string;
    accent: string;
    accentLight: string;
    grayLight: string;
    grayMedium: string;
    grayHeavy: string;
};

type BreakPointsSettings = {
    sm: number;
    md: number;
    lg: number;
    xl: number;
    xxl: number;
};

export type Theme = {
    colors: ColorsSettings;
    breakPoints: BreakPointsSettings;
    cssVariables?: Record<string, any>;
};

const DefaultTheme: Theme = {
    colors: {
        main: '#333',
        inverted: '#fff',
        accent: '#2b779b',
        accentLight: '#62727b',
        grayLight: '#f6f7f8',
        grayMedium: '#e9eaeb',
        grayHeavy: '#d1d2d3',
    },

    breakPoints: {
        sm: 576,
        md: 768,
        lg: 1025,
        xl: 1200,
        xxl: 1370,
    },
    cssVariables: {
        '--default-font': "'Nunito Sans', sans-serif",
        '--second-font': 'var(--default-font)',
        '--score-font': 'var(--default-font)',
        '--full-black': '#000',
        '--home-page-br': '0',
        '--game-end-br': '0',
        '--home-template-container-padding-top': '3em',
        '--global-side-gutters': '8px',
        '--home-template-content-margin-top': '2em',
        '--home-category-content-padding-bottom': '1em',
        '--home-category-header-align-items': 'center',
        // promo banner
        '--promoBanner-bg': '#f2f2f2',
        '--promoBanner-shadow': '0 -15px 25px -10px rgba(0, 0, 0, 0.4)',
        '--promoBanner-border': '1px solid #d9e0e9',
        '--promoBanner-title-fs': '32px',

        '--promoBanner-desc-fs': '16px',
        '--promoBanner-desc-line-clamp': '6',
        '--play-btn-display': 'block',
        '--promoBanner-play-btn-bg-color': '#0032d4',
        '--promoBanner-play-btn-text-color': '#ffffff',
        '--promoBanner-play-btn-hover-color': '#005aff',

        '--profile-location-dropdown-item-fs': '14px',
        // game tile
        '--game-tile-text-block-jc': 'space-between',
        '--game-tile-text-block-border': 'none',
        '--category-title-font-weight': 'bold',

        '--tile-wrapper-width-mobile': '144px',
        '--tile-link-height': 'auto',
        '--tile-link-padding': '8px 0',
        '--game-tile-picture-height-mobile': 'auto',
        '--game-tile-text-height-mobile': 'auto',
        '--tile-text-display': 'none',
        '--tile-text-fs': '20px',
        '--tile-text-color': '#28292a',
        '--tile-text-fw': '600',
        '--tile-text-lh': '30px',
        // '--tile-text-min-height': '0',
        '--tile-text-align': 'left',
        '--tile-text-mt': '.2em',
        '--view-more-fs': '20px',
        '--view-more-color': '#1e36ae',
        '--view-more-text-decoration': 'none',
        '--view-more-mb': '0',

        // gameslist game tile
        '--games-list-games-row-jc': 'inherit',
        '--games-list-tile-wrapper-margin': '0',

        // game end

        '--game-container-br': '0',
        '--game-container-overflow': 'auto',
        '--game-end-container-padding': '0 5px',
        '--game-end-container-bg': 'radial-gradient(#094ca8 20%, transparent 100%)',
        '--game-end-container-bg-color': '#043473',
        '--game-end-play-again-button-bg': '#005aff',
        '--game-end-play-again-button-text': '#ffffff',

        '--game-end-play-again-button-width': '300px',
        '--game-end-play-again-button-font': 'inherit',
        '--game-end-play-again-button-fs': '18px',
        '--game-end-play-button-font-weight': 'inherit',
        '--game-end-login-block-fs': '20px',
        '--game-end-login-block-font-weight': 'inherit',
        '--score-value-fs': '1.9em',
        '--score-value-font-weight': 'inherit',
        '--score-value-color': '#004db3',
        '--game-end-login-block-button-color': '#004db3',
        '--game-end-login-block-icon-width': '64px',
        '--game-end-login-block-icon-padding': '0 15px',

        // leaderboard
        '--lb-login-btn-height': '32px',
        '--lb-tab-br': '2px 2px 0 0',
        '--lb-tab-bg': '#0032d4',
        '--lb-login-btn-bg': '#0032d4',
        '--lb-icon-active-color': '#005aff',

        '--lb-row-avatar-size': '48px',

        '--lb-row-name-column-pb': '0',
        '--lb-row-name-column-align': 'unset',

        '--lb-userInfoColumn-display': 'flex',

        '--lb-nameColumn-unlogged-fs': '14px',
        '--lb-nameColumn-unlogged-fw': '400',
        '--lb-scoreColumn-text-align': 'right',
        '--lb-avatar-size': '48px',
        '--lb-trophyIcon-width': '40px',

        '--lb-positionColumn-width': '64px',
        '--lb-positionColumn-pl': '0',

        '--lb-country-margin': '0 0 0 2px',

        // game page
        '--play-btn-bg-color': '#0032d4',
        '--play-btn-text-color': '#ffffff',
        '--skip-btn-text-color': '#0032d4',

        // svg icons
        '--svg-icon-bg-color': '#0032d4',
        '--svg-icon-text-color': '#ffffff',
        '--svg-icon-bg-color-hover': '#005aff',
    },
};

export const DigitalTurbineTheme: Theme = {
    ...DefaultTheme,
    cssVariables: {
        // fonts
        '--default-font': 'Montserrat, sans-serif',
        '--second-font': 'Oswald, sans-serif',
        '--score-font': 'Nunito Sans, sans-serif',
        '--full-black': '#000',
        '--home-page-br': '8px',
        '--game-end-br': '6px',
        '--home-template-container-padding-top': '0',
        '--global-side-gutters': '16px',
        '--home-template-content-margin-top': '1rem',
        '--home-category-content-padding-bottom': '1.5em',
        '--home-category-header-align-items': 'flex-end',
        '--border': '1px solid #ededed',
        // promo banner
        '--promoBanner-shadow': 'unset',
        '--promoBanner-bg': '#fff',
        '--promoBanner-border': 'var(--border)',
        '--promoBanner-title-fs': '16px',
        '--promoBanner-desc-fs': '11px',
        '--promoBanner-desc-line-clamp': '6',
        '--promoBanner-desc-line-clamp-mobile': '2',
        '--play-btn-display': 'none',
        '--promoBanner-play-btn-bg-color': '#0032d4',
        '--promoBanner-play-btn-text-color': '#ffffff',
        '--promoBanner-play-btn-hover-color': '#005aff',

        '--profile-location-dropdown-item-fs': '12px',

        // game tile
        '--game-tile-text-block-jc': 'center',
        '--game-tile-text-block-border': 'var(--border)',
        '--category-title-font-weight': '400',
        '--tile-wrapper-width-mobile': '113px',
        '--tile-wrapper-margin': '0 8px',
        '--tile-wrapper-padding': '0',
        '--tile-link-height': '100%',
        '--tile-link-padding': '4px 0',
        '--game-tile-picture-height-mobile': '116px',
        '--game-tile-text-height-mobile': '116px',
        '--tile-picture-width': 'calc(116 / 113 * 100%)',
        '--tile-text-fs': '14px',
        '--tile-text-fw': '500',
        '--tile-text-color': 'var(--full-black)',
        '--tile-text-lh': '20px',
        // '--tile-text-min-height': 'calc(var(--tile-text-lh) * 2)',
        '--tile-text-display': 'block',
        '--tile-text-align': 'center',
        '--tile-text-mt': '0',
        '--view-more-fs': '11px',
        '--view-more-color': '#6E32A0',
        '--view-more-text-decoration': 'underline',
        '--view-more-mb': '2px',

        // gameslist game tile
        '--games-list-games-row-jc': 'center',
        '--games-list-tile-wrapper-margin': '8px 10px',

        // game end
        '--game-container-br': 'var(--game-end-br)',
        '--game-container-overflow': 'hidden',
        '--game-end-container-padding': '0 14px',
        '--game-end-container-bg': 'none',
        '--game-end-container-bg-color': '#46196E',
        '--game-end-play-again-button-width': '100%',
        '--game-end-play-again-button-bg': 'linear-gradient(197.09deg, #FA9D28 22.06%, #E37501 88.24%)',
        '--game-end-play-again-button-text': '#ffffff',
        '--game-end-play-again-button-font': 'var(--second-font)',
        '--game-end-play-again-button-fs': '22px',
        '--game-end-play-button-font-weight': '500',
        '--score-value-fs': '24px',
        '--score-value-font-weight': '700',
        '--score-value-color': '#0A1874',
        '--game-end-login-block-fs': '16px',
        '--game-end-login-block-font-weight': '600',
        '--game-end-login-block-button-color': '#6E32A0',

        '--game-end-login-block-icon-width': '54px',
        '--game-end-login-block-icon-padding': '6px 0 0 16px',

        // leaderboard
        '--lb-login-btn-height': '36px',
        '--lb-tab-br': '6px 6px 0 0',
        '--lb-tab-bg': '#46196E',

        '--lb-login-btn-bg': '#E37501',
        '--lb-login-btn-fs': '16px',
        '--lb-login-btn-padding': '7px 12px',
        '--lb-icon-active-color': '#E37501',

        '--lb-row-name-column-width': 'calc(33.33% - calc(var(--lb-row-avatar-size) + 8px))',
        '--lb-row-avatar-size': '32px',
        '--lb-row-name-column-pb': '10px',
        '--lb-row-name-column-align': 'flex-end',

        '--lb-userInfoColumn-display': 'none',
        '--lb-nameColumn-unlogged-fs': '16px',
        '--lb-nameColumn-unlogged-fw': '600',

        '--lb-scoreColumn-text-align': 'center',

        '--lb-avatar-size': '32px',
        '--lb-trophyIcon-width': '53.323px',
        '--lb-positionNumber-pt': '8px',

        '--lb-positionColumn-width': '33.3333%',
        '--lb-positionColumn-pl': '12px',
        '--lb-country-margin': '0 0 0 6px',

        // game page
        '--play-btn-bg-color': '#0032d4',
        '--play-btn-text-color': '#ffffff',
        '--skip-btn-text-color': '#0032d4',

        // svg icons
        '--svg-icon-bg-color': '#0032d4',
        '--svg-icon-text-color': '#ffffff',
        '--svg-icon-bg-color-hover': '#005aff',
    },
};

export const YellowTheme: Theme = {
    ...DefaultTheme,
    cssVariables: {
        // fonts
        '--default-font': "'Nunito Sans', sans-serif",
        '--second-font': 'var(--default-font)',
        '--score-font': 'var(--default-font)',
        '--full-black': '#000',
        '--home-page-br': '0',
        '--game-end-br': '0',
        '--home-template-container-padding-top': '3em',
        '--global-side-gutters': '8px',
        '--home-template-content-margin-top': '2em',
        '--home-category-content-padding-bottom': '1em',
        '--home-category-header-align-items': 'center',
        // promo banner
        '--promoBanner-bg': '#f2f2f2',
        '--promoBanner-shadow': '0 -15px 25px -10px rgba(0, 0, 0, 0.4)',
        '--promoBanner-border': '1px solid #d9e0e9',
        '--promoBanner-title-fs': '32px',

        '--promoBanner-desc-fs': '16px',
        '--promoBanner-desc-line-clamp': '6',
        '--play-btn-display': 'block',
        '--promoBanner-play-btn-bg-color': '#FFCB05',
        '--promoBanner-play-btn-text-color': '#000000',
        '--promoBanner-play-btn-hover-color': '#E2F38E',

        '--profile-location-dropdown-item-fs': '14px',
        // game tile
        '--game-tile-text-block-jc': 'space-between',
        '--game-tile-text-block-border': 'none',
        '--category-title-font-weight': 'bold',

        '--tile-wrapper-width-mobile': '144px',
        '--tile-link-height': 'auto',
        '--tile-link-padding': '8px 0',
        '--game-tile-picture-height-mobile': 'auto',
        '--game-tile-text-height-mobile': 'auto',
        '--tile-text-display': 'none',
        '--tile-text-fs': '20px',
        '--tile-text-color': '#28292a',
        '--tile-text-fw': '600',
        '--tile-text-lh': '30px',
        // '--tile-text-min-height': '0',
        '--tile-text-align': 'left',
        '--tile-text-mt': '.2em',
        '--view-more-fs': '20px',
        '--view-more-color': '#1e36ae',
        '--view-more-text-decoration': 'none',
        '--view-more-mb': '0',

        // gameslist game tile
        '--games-list-games-row-jc': 'inherit',
        '--games-list-tile-wrapper-margin': '0',

        // game end

        '--game-container-br': '0',
        '--game-container-overflow': 'auto',
        '--game-end-container-padding': '0 5px',
        '--game-end-container-bg': 'radial-gradient(#094ca8 20%, transparent 100%)',
        '--game-end-container-bg-color': '#043473',
        '--game-end-play-again-button-bg': '#FFCB05',
        '--game-end-play-again-button-text': '#000000',

        '--game-end-play-again-button-width': '300px',
        '--game-end-play-again-button-font': 'inherit',
        '--game-end-play-again-button-fs': '18px',
        '--game-end-play-button-font-weight': 'inherit',
        '--game-end-login-block-fs': '20px',
        '--game-end-login-block-font-weight': 'inherit',
        '--score-value-fs': '1.9em',
        '--score-value-font-weight': 'inherit',
        '--score-value-color': '#004db3',
        '--game-end-login-block-button-color': '#004db3',
        '--game-end-login-block-icon-width': '64px',
        '--game-end-login-block-icon-padding': '0 15px',

        // leaderboard
        '--lb-login-btn-height': '32px',
        '--lb-tab-br': '2px 2px 0 0',
        '--lb-tab-bg': '#0032d4',
        '--lb-login-btn-bg': '#0032d4',
        '--lb-icon-active-color': '#005aff',

        '--lb-row-avatar-size': '48px',

        '--lb-row-name-column-pb': '0',
        '--lb-row-name-column-align': 'unset',

        '--lb-userInfoColumn-display': 'flex',

        '--lb-nameColumn-unlogged-fs': '14px',
        '--lb-nameColumn-unlogged-fw': '400',
        '--lb-scoreColumn-text-align': 'right',
        '--lb-avatar-size': '48px',
        '--lb-trophyIcon-width': '40px',

        '--lb-positionColumn-width': '64px',
        '--lb-positionColumn-pl': '0',

        '--lb-country-margin': '0 0 0 2px',

        // game page
        '--play-btn-bg-color': '#FFCB05',
        '--play-btn-text-color': '#000000',
        '--skip-btn-text-color': '#000000',

        // svg icons
        '--svg-icon-bg-color': '#FFCB05',
        '--svg-icon-text-color': '#000000',
        '--svg-icon-bg-color-hover': '#E2F38E',
    },
};

export default DefaultTheme;
