import React from 'react';

import { Reactions } from '@open-web/react-sdk';

import { UrlService } from '../../services/UrlService';

type OpenWebReactionsProps = {
    spotId: string;
    arenaUrl: string;
    gameSlug: string;
};

const OpenWebReactions = React.memo((props: OpenWebReactionsProps) => {
    const postUrl = UrlService.createURL('', `games/${props.gameSlug}`);

    return (
        <Reactions
            spotId={props.spotId}
            postId={props.gameSlug}
            postUrl={postUrl}
        />
    );
});

export default OpenWebReactions;
