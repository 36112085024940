import { createAction, createReducer } from 'redux-act';

export const setGemsAmount = createAction<number>('set gems amount');

const gemsReducer = createReducer<number>({}, null);

gemsReducer.on(setGemsAmount, (_state, payload: number) => payload);

export default gemsReducer;

export type BillingDataForTax = null | {
    first_name: string;
    last_name: string;
    postal_code: string;
    country: string;
};
export const setPrerollSkipPrice = createAction<number | null>('set prices for skip preroll');
export const prerollSkipPriceReducer = createReducer<number | null>({}, null);
prerollSkipPriceReducer.on(setPrerollSkipPrice, (_state, payload) => payload);

export const setIsPrerollSkipPriceLoading = createAction<boolean>('preroll price is loading');
export const prerollSkipPriceLoadingReducer = createReducer<boolean>({}, false);
prerollSkipPriceLoadingReducer.on(setIsPrerollSkipPriceLoading, (_state, payload) => payload);

export const setBillingDataForTax = createAction<BillingDataForTax>('set billing data for tax');
export const billingDataForTaxReducer = createReducer<BillingDataForTax>({}, null);
billingDataForTaxReducer.on(setBillingDataForTax, (_state, payload) => payload);

export const setChosenGemsPack = createAction<string>('set active purchasable gems pack');
export const chosenGemsPackItemNameReducer = createReducer<string>(
  {},
  null
);
chosenGemsPackItemNameReducer.on(setChosenGemsPack, (_state, payload: string) => payload);
