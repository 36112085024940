import classNames from 'classnames';
import React from 'react';
import Monthly from '../../../public/svg/Monthly.svg';
import MonhtlyRounded from '../../../public/svg/MonthlyRounded.svg';
import styles from './Icons.css';

export const MonthlyIcon = React.memo((props: any) => {
    const Icon = props.isRounded ? MonhtlyRounded : Monthly;

    return <Icon className={classNames(styles.monthlyIcon, props.className)} aria-hidden="true" />;
});
