import { createAction, createReducer } from 'redux-act';

export const setFullScreenLoading = createAction<boolean>('set fullscreen loader status');
export const fullScreenLoading = createReducer<boolean>({}, true);
fullScreenLoading.on(setFullScreenLoading, (_state, payload) => payload);
export type Snackbar = {
    message?: string;
    type?: string;
    isOpened: boolean;
    static?: boolean;
    parentNode?: string;
};

export const setSnackbarData = createAction<Snackbar>('show snackbar');
export const snackbarReducer = createReducer<Snackbar>(
    {},
    {
        type: 'success',
        message: '',
        isOpened: false,
        static: false,
    }
);
snackbarReducer.on(setSnackbarData, (_state, payload: Snackbar) => ({
    ..._state,
    isOpened: payload.isOpened,
    message: payload.message,
    type: payload.type,
    static: payload.static,
    parentNode: payload.parentNode,
}));

export const setOpensModalElementRef = createAction<HTMLElement>(
    'set reference to the element that opens the modal window'
);

export const opensModalElementRefReducer = createReducer<HTMLElement | null>({}, null);
opensModalElementRefReducer.on(setOpensModalElementRef, (_state, payload: HTMLElement) =>
    payload === undefined ? null : payload
);
