import { FacebookLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/facebook-login.dto';
import { GoogleLoginDTO } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/google-login.dto';
import React, { useEffect, useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setBodyOverflowAuto, setBodyOverflowHidden } from '../../../utils';
import { environment } from '../../config/environment';
import { ActionTypes } from '../../models/Analytics';
import { ArenaAuthResponse } from '../../models/ArenaAuthResponse';
import { ArenaConfig } from '../../models/ArenaConfig';
import { AuthProvider, LoginCaller } from '../../models/Login';
import { OAuthUserResponse } from '../../models/OAuthResponse';
import { EagleUser, User } from '../../models/User';
import CaptchaIframe from '../../molecules/ChallengeCaptcha/CaptchaIframe';
import useCaptcha, { RECAPTCHA_ACTIONS, RECAPTCHA_MODES } from '../../molecules/ChallengeCaptcha/hooks/useCaptcha';
import { AfterSignForm } from '../../molecules/LoginPopup/AfterSignForm/AfterSignForm';
import { ForgotPasswordForm } from '../../molecules/LoginPopup/ForgotPasswordForm/ForgotPasswordForm';
import { GDPRForm } from '../../molecules/LoginPopup/GDPRForm/GDPRForm';
import { LoginForm } from '../../molecules/LoginPopup/LoginForm/LoginForm';
import { RegisterForm } from '../../molecules/LoginPopup/RegisterForm/RegisterForm';
import { RequestEmailForm } from '../../molecules/LoginPopup/RequestEmailForm/RequestEmailForm';
import { ResetPasswordForm } from '../../molecules/LoginPopup/ResetPasswordForm/ResetPasswordForm';
import { AnalyticsGeneral } from '../../services/Analytics/AnalyticsGeneral';
import { GdprAcceptedStorage, LocalStorageSKeys } from '../../services/AuthService';
import { AuthType, EagleLoginService, updateEagleUser } from '../../services/EagleLoginService';
import { LocalStorageService } from '../../services/LocalStorage';
import { UrlService } from '../../services/UrlService';
import { ELoginSteps, setLoginModal, setLoginOpen, setLoginPrevStep, setLoginStep } from '../../store/ducks/modal';
import { setNotificationBar } from '../../store/ducks/notificationBar';
import { setShowRecaptcha } from '../../store/ducks/recaptcha';
import {
  logoutUser,
  updateUserWithGDPRThunk,
} from '../../store/ducks/user';
import { AppState } from '../../store/types';

enum Events {
  FacebookAuth = 'update-user-info',
  GoogleAuth = 'auth-google-user',
}

interface ILoginProps {
  arenaOrigin?: string;
  currentLang?: string;
  isOpen?: boolean;
  step?: ELoginSteps;
  caller?: LoginCaller;
  facebookAppId?: ArenaConfig['facebookAppId'];
  user?: User;
  dispatch?: any;
  authType: AuthType;
  setAuthType: (authType: AuthType) => void;
  recoveryCode: string;
  isResendEmailForm: boolean;
  isResendEmailFormInputDisabled: boolean;
  openRequestEmailForm: (isResendConfirmationEmail?: boolean, isInputDisabled?: boolean) => void;
}

export type TAuthorizedData = {
  user: ArenaAuthResponse | OAuthUserResponse | EagleUser;
  eventType: AuthProvider;
};

type SocialLoginInfoType = {
  data: GoogleLoginDTO | (FacebookLoginDTO & TAuthProviderAppId);
  eventType: AuthProvider;
};

type TAuthProviderAppId = { authProviderAppId: string };

const LoginBase = ({
  t,
  recoveryCode,
  authType,
  setAuthType,
  isResendEmailForm,
  isResendEmailFormInputDisabled,
  openRequestEmailForm,
}: WithTranslation & ILoginProps) => {
  const {
    currentLang,
    isOpen,
    step,
    caller,
    prevStep,
    fromShopModal,
    facebookAppId,
    isEagle,
    user,
  } = useSelector(
    (state: AppState) => ({
      currentLang: state.currentLang,
      isOpen: state.modal.login.isOpen,
      step: state.modal.login.step,
      prevStep: state.modal.login.prevStep,
      caller: state.modal.login.caller,
      fromShopModal: state.modal.login.fromShopModal,
      facebookAppId: state.config.facebookAppId,
      isEagle: state.config.isEagle,
      user: state.user,
    }),
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const arenaDomainURL = useMemo(() => new URL(UrlService.getCurrentOriginForEagle()), []);
  const [email, setEmail] = useState<string>();
  const [isThankYouForm, setIsThankYouForm] = useState<boolean>(false);
  const [gdprAccepted, setGdprAccepted] = useState<GdprAcceptedStorage>({});
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [socialUser, setSocialUser] = useState<OAuthUserResponse>();
  const [eventType, setEventType] = useState<AuthProvider>(AuthProvider.arkadium);
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken,
  } = useCaptcha();

  useEffect(() => {
    window.addEventListener('message', onLoginMessage);

    const isGdprAccepted = LocalStorageService.getItem(LocalStorageSKeys.gdprAccepted);
    const parsedData = JSON.parse(isGdprAccepted);

    if (parsedData) {
      setGdprAccepted(parsedData);
    }

    return () => window.removeEventListener('message', onLoginMessage);
  }, []);

  useEffect(() => {
    if (isOpen) {
      showModal();
    }

    return () => {
      dispatch(setNotificationBar({
        isOpened: false,
        message: '',
      }));
    };
  }, [isOpen, step]);

  useEffect(() => {
    if (user) {
      const userId = 'uid' in user ? user.uid : user.id;
      const needToShowGDPR = isRegistered || gdprAccepted[userId] === false;

      if (needToShowGDPR) {
        const newGdprAccepted = {
          ...gdprAccepted,
          [userId]: false,
        };

        LocalStorageService.setItem(LocalStorageSKeys.gdprAccepted, JSON.stringify(newGdprAccepted));
        setGdprAccepted(newGdprAccepted);
        dispatch(setLoginStep(ELoginSteps.GDPR));
        openModal();
      }
    }
  }, [user, isRegistered]);

  useEffect(() => {
    if (captchaToken && step === ELoginSteps.LOGIN &&
      (eventType === AuthProvider.facebook || eventType === AuthProvider.google)) {
      void onAuthorized({
        eventType,
        user: socialUser,
      });
    }
  }, [captchaToken, eventType]);

  const onLoginMessage = (message: MessageEvent) => {
    const event = message.data;

    if (!event) {
      return;
    }

    switch (event.action) {
      case Events.FacebookAuth:
        setSocialUser(event.data);
        setEventType(AuthProvider.facebook);

        if (isEagle) {
          getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
        } else {
          const facebookData = {
            user: event.data,
            eventType: AuthProvider.facebook,
          };

          void onAuthorized(facebookData);
        }

        break;
      case Events.GoogleAuth:
        setSocialUser(event.user);
        setEventType(AuthProvider.google);

        if (isEagle) {
          getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
        } else {
          const googleData = {
            user: event.user,
            eventType: AuthProvider.google,
          };

          void onAuthorized(googleData);
        }

        break;
      default:
        break;
    }
  };
  const onRegister = (registerEventType: AuthProvider, registerEmail: string) => {
    AnalyticsGeneral.registration(caller, registerEventType);
    setEmail(registerEmail);
  };
  const handleSuccessOAuthLogin = (
    isUserRegistered: boolean,
    authorizedData: TAuthorizedData | SocialLoginInfoType,
  ) => {
    const authEventType = 'eventType' in authorizedData ? authorizedData.eventType : undefined;

    if (isUserRegistered) {
      AnalyticsGeneral.registration(caller, authEventType);
      setIsRegistered(true);
    } else {
      closeModal();
    }

    AnalyticsGeneral.loginAI(ActionTypes.LOGIN, caller, authEventType);
    AnalyticsGeneral.loginGA(ActionTypes.LOGIN, caller, authEventType);
  };
  const performOAuthLogin = async (
    loginData: GoogleLoginDTO | (FacebookLoginDTO & TAuthProviderAppId),
    loginEventType: AuthProvider,
  ) => {
    const oAuthLoginService =
      loginEventType === AuthProvider.google ? EagleLoginService.loginViaGoogle : EagleLoginService.loginViaFacebook;

    return oAuthLoginService(loginData);
  };
  const onAuthorized = async (data: TAuthorizedData) => {
    // eagle login system
    if (isEagle) {
      if (!data.user) {
        return;
      }

      // login via fb/google with eagle
      if ('id' in data.user) {
        const isGoogle = data.eventType === AuthProvider.google;
        const loginData: (GoogleLoginDTO | FacebookLoginDTO) & TAuthProviderAppId = {
          token: data.user.access_token,
          email: data.user.email ?? null,
          emailRetrievalConsent: false,
          registrationPlaceUrl: new URL(arenaDomainURL),
          authProviderAppId: isGoogle ? environment.DEFAULT_GOOGLE_APP_ID : facebookAppId,
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined,
        };

        try {
          const result = await performOAuthLogin(loginData, data.eventType);

          handleSuccessOAuthLogin(result.isRegistered, data);
          clearCaptchaData();
        } catch (err) {
          if (err !== 1023) {
            clearCaptchaData();
          }

          // CaptchaScoreIsTooLow
          dispatch(setShowRecaptcha(err === 1023));

          // EmailDoesNotSpecified
          if (err === 1007) {
            openRequestEmailForm();
            const authenticationType = data.eventType === AuthProvider.google ? AuthType.Google : AuthType.Facebook;

            setAuthType(authenticationType);
          }

          // UserNotConfirmedError
          if (err === 1018) {
            setEmail(email);
            dispatch(setLoginStep(ELoginSteps.AFTER_SIGN));
            dispatch(setLoginPrevStep(ELoginSteps.REQUEST_EMAIL));
          }

        }
      }

      if (eventType !== AuthProvider.google && eventType !== AuthProvider.facebook) {
        closeModal();
      }

      return;
    }
  };
  const onLoginContinue = (data: { gdprUserData: boolean; gdprEmailMarketing: boolean }) => {
    if (isEagle) {
      if (data.gdprUserData) {
        const userId = 'uid' in user ? user.uid : user.id;
        const {
          [userId]: removedProperty,
          ...rest
        } = gdprAccepted;

        setGdprAccepted(rest);
        LocalStorageService.setItem(LocalStorageSKeys.gdprAccepted, JSON.stringify(rest));
      }

      if (data.gdprEmailMarketing) {
        updateEagleUser({ emailRetrievalConsent: data.gdprEmailMarketing }).then(closeModal);
      } else {
        closeModal();
      }
    } else {
      dispatch(updateUserWithGDPRThunk(data, caller));
    }
  };
  const logout = () => {
    dispatch(logoutUser());
    closeModal();
  };
  const openModal = () => {
    dispatch(setLoginOpen(true));
  };
  const closeModal = () => {
    setIsRegistered(false);
    setEmail('');
    setBodyOverflowAuto();
    dispatch(setLoginModal({
      isOpen: false,
      step: ELoginSteps.LOGIN,
      prevStep: null,
    }));
  };
  const showModal = () => {
    setBodyOverflowHidden();

    if (step === ELoginSteps.LOGIN) {
      AnalyticsGeneral.loginAI(ActionTypes.CLICK, caller);
      AnalyticsGeneral.loginGA(ActionTypes.CLICK, caller);
      dispatch(setLoginStep(ELoginSteps.LOGIN));
    } else if (step === ELoginSteps.GDPR) {
      dispatch(setLoginStep(ELoginSteps.GDPR));
    }
  };
  const openLoginForm = () => {
    dispatch(setLoginStep(ELoginSteps.LOGIN));
  };
  const openRegisterForm = () => {
    dispatch(setLoginStep(ELoginSteps.REGISTER));
  };
  const openForgotPasswordForm = () => {
    dispatch(setLoginStep(ELoginSteps.FORGOT_PASSWORD));
    dispatch(setLoginPrevStep(ELoginSteps.FORGOT_PASSWORD));
  };
  const openAfterSignForm = (isThankYouFormDisplayed: boolean) => {
    setIsThankYouForm(isThankYouFormDisplayed);

    dispatch(setLoginStep(ELoginSteps.AFTER_SIGN));
  };
  const setupPreviousStep = (previousStep: ELoginSteps) => {
    dispatch(setLoginPrevStep(previousStep));
  };
  const goToPreviousStep = () => {
    dispatch(setLoginStep(prevStep));
  };
  const saveEmail = (emailToSave: string) => {
    setEmail(emailToSave);
  };
  const tWithPrefix = (key: string, options = {}) => {
    const isKeyNotErrorCode = isNaN(Number(key));

    return t(`LOGIN.${isEagle && isKeyNotErrorCode ? 'EAGLE.' : ''}${key}`, options);
  };
  const handleSupportClick = () => {
    history.push(`/${t('ROUTES.HELP')}`);
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  const sharedProps = {
    getCaptchaToken,
    captchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
    tWithPrefix,
    arenaDomainURL,
    arenaLocale: currentLang,
  };

  return (
    <>
      {step === ELoginSteps.LOGIN && (
        <LoginForm
          onAuthorized={onAuthorized}
          fbAppId={facebookAppId}
          openForgotPasswordForm={openForgotPasswordForm}
          openRegisterForm={openRegisterForm}
          openRequestEmailForm={openRequestEmailForm}
          t={t}
          saveEmail={saveEmail}
          fromShopModal={fromShopModal}
          eventType={eventType}
          {...sharedProps}
        />
      )}
      {step === ELoginSteps.REGISTER && (
        <RegisterForm
          openLoginForm={openLoginForm}
          openAfterSignForm={openAfterSignForm}
          onRegister={onRegister}
          {...sharedProps}
        />
      )}
      {step === ELoginSteps.GDPR && (
        <GDPRForm onLogout={logout} onLoginContinue={onLoginContinue} tWithPrefix={tWithPrefix}/>
      )}
      {step === ELoginSteps.FORGOT_PASSWORD && (
        <ForgotPasswordForm
          email={email}
          saveEmail={saveEmail}
          openLoginForm={openLoginForm}
          openAfterSignForm={openAfterSignForm}
          {...sharedProps}
        />
      )}
      {step === ELoginSteps.REQUEST_EMAIL && (
        <RequestEmailForm
          onSupportClick={handleSupportClick}
          saveEmail={saveEmail}
          emailToConfirm={email}
          openAfterSignForm={openAfterSignForm}
          step={step}
          isResendEmailForm={isResendEmailForm}
          goToPreviousStep={goToPreviousStep}
          setupPreviousStep={setupPreviousStep}
          isResendEmailFormInputDisabled={isResendEmailFormInputDisabled}
          eventType={eventType}
          {...sharedProps}
        />
      )}
      {step === ELoginSteps.AFTER_SIGN && (
        <AfterSignForm
          goToPreviousStep={goToPreviousStep}
          openLoginForm={openLoginForm}
          email={email}
          onSupportClick={handleSupportClick}
          isThankYouForm={isThankYouForm}
          prevStep={prevStep}
          isResendEmailForm={isResendEmailForm}
          authType={authType}
          {...sharedProps}
        />
      )}
      {step === ELoginSteps.RESET_PASSWORD && (
        <ResetPasswordForm
          recoveryCode={recoveryCode}
          tWithPrefix={tWithPrefix}
          onClose={closeModal}
          openLoginForm={openLoginForm}
        />
      )}
      <CaptchaIframe
        iframeRef={iframeRef}
        iframeURL={iframeURL}
        setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}
      />
    </>
  );
};

export const Login = withTranslation()(LoginBase);
