import React from 'react';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { PaymentMethod } from '../../../models/Payment/PaymentForm';
import PurchasePageTemplate from '../PurchasePageTemplate';

const paymentMethodsToUse: PaymentMethod[] = [PaymentMethod.CARD, PaymentMethod.PAYPAL];

export default () => (
    <PurchasePageTemplate paymentType={PaymentType.gems} paymentMethodsApplied={paymentMethodsToUse} />
);
