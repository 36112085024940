import ReactGA from '@arkadium/react-ga4';
import { isServer, trimUndefinedProps } from '../../../utils';
import { AnalyticSettingsAdditional } from '../../models/Analytics';
import { OneTrustService } from '../OneTrustService';
import { QuantcastService } from '../QuantcastService';

class GAService {
    private gaTrackers: string[] = ['arkadiumTracker'];
    private gaInitData: any[] = [];
    private gaCustomDimensions = {};
    private gaDisabled = true;

    init(analyticsSettings: any) {
        if (!analyticsSettings.account) {
            this.gaDisabled = true;
            return;
        }

        if (isServer) {
            return;
        }

        this.gaInitData.push({
            trackingId: analyticsSettings.account,
            gaOptions: {
                name: 'arkadiumTracker',
            },
        });

        this.gaInitData.forEach((data) => {
            if (analyticsSettings.options) {
                data.gaOptions = { ...data.gaOptions, ...analyticsSettings.options };
            }
        });

        ReactGA.initialize(this.gaInitData);
        this.gaDisabled = false;
        ReactGA.ga('set', 'anonymizeIp', true);
        // https://developers.google.com/tag-platform/gtagjs/routing#default_group
        // If a send_to parameter is not set, events are routed to the default target group.
        ReactGA.gtag('config', analyticsSettings.account, { groups: 'default' });

        if (OneTrustService.isEnabled) {
            OneTrustService.targetingConsentSubject.subscribe((consent) => {
                if (consent) {
                    ReactGA.ga('set', 'allowAdPersonalizationSignals', true);
                } else {
                    ReactGA.ga('set', 'allowAdPersonalizationSignals', false);
                }
            });
        }

        if (QuantcastService.isEnabled) {
            QuantcastService.targetingConsentSubject.subscribe((consent) => {
                if (consent) {
                    ReactGA.ga('set', 'allowAdPersonalizationSignals', true);
                } else {
                    ReactGA.ga('set', 'allowAdPersonalizationSignals', false);
                }
            });
        }
    }

    public addAdditionalAccount(settings: AnalyticSettingsAdditional) {
        if (this.gaDisabled) {
            return;
        }

        if (settings.account && settings.account.startsWith('G')) {
            ReactGA.gtag('config', settings.account, {
                groups: settings.prefix,
                page_path: window.location.pathname
            });
        } else {
            this.gaTrackers.push(settings.prefix);
            this.gaInitData.push({
                trackingId: settings.account,
                gaOptions: {name: settings.prefix},
            });
        }
    }

    public trackEvent(data) {
        if (this.gaDisabled) {
            return;
        }

        trimUndefinedProps(data);

        // for GA4 name needs to be the first arg
        if (data.name) {
            ReactGA.event(data.name, {
                    ...this.gaCustomDimensions,
                    ...data,
                });
        } else {
            ReactGA.event(
                {
                    ...this.gaCustomDimensions,
                    ...data,
                },
                this.gaTrackers
            );
        }
    }

    public setCustomDimension(data: object) {
        this.gaCustomDimensions = {
            ...this.gaCustomDimensions,
            ...data,
        };
    }
}

export const GoogleAnalytics = new GAService();
