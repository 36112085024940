import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatNumbers } from '../../../utils';
import { ExperienceReward } from '../../models/ExperienceDate';
import { EagleUser, User } from '../../models/User';
import { GameEndProgressBar } from '../../molecules/GameEndProgressBar/GameEndProgressBar';
import styles from './GameEndXpProgress.css';

type GameEndXpProgressProps = {
    experienceReward: ExperienceReward;
    user: User | EagleUser;
};

class GameEndXpProgressBase extends React.PureComponent<GameEndXpProgressProps & WithTranslation> {
    render() {
        const { t, user } = this.props;
        const { xpGained, achievedNewLevel, xpPrev } = this.props.experienceReward;

        return (
            <Container data-element-description="points wall">
                <Level data-element-description="user level">
                    {t('LEVEL')} {formatNumbers(user.level)}
                </Level>
                <TopRow>
                    <CurrentXp>
                        <XpVal data-element-description="current xp">{formatNumbers(user.xp)}</XpVal>
                        <XpText
                            data-element-description="current xp text"
                            dangerouslySetInnerHTML={{ __html: this.props.t('CURRENT_XP_TEXT') }}
                        />
                    </CurrentXp>
                    <NextLevel>
                        <XpText
                            data-element-description="xp threshold text"
                            dangerouslySetInnerHTML={{
                                __html: this.props.t('NEXT_XP_TEXT', {
                                    levelNum: formatNumbers(user.level + 1),
                                }),
                            }}
                        />
                        <XpVal data-element-description="xp threshold">
                            {formatNumbers(user.levelXPThresholdCurrent - user.xp)}
                        </XpVal>
                    </NextLevel>
                </TopRow>

                <GameEndProgressBar
                    achievedNewLevel={achievedNewLevel}
                    levelXPThresholdCurrent={user.levelXPThresholdCurrent}
                    xpCurrent={user.xp}
                    xpGained={xpGained}
                    xpPrev={xpPrev}
                />
            </Container>
        );
    }
}

const Container = (props: any) => <div className={styles.container} {...props} />;
const Level = (props: any) => <div className={styles.level} {...props} />;
const TopRow = (props: any) => <div className={styles.topRow} {...props} />;
const CurrentXp = (props: any) => <div className={styles.currentXp} {...props} />;
const XpVal = (props: any) => <div className={styles.xpVal} {...props} />;
const XpText = (props: any) => <div className={styles.xpText} {...props} />;
const NextLevel = (props: any) => <div className={styles.nextLevel} {...props} />;

export const GameEndXpProgress = withTranslation()(GameEndXpProgressBase);
