import { createAction, createReducer } from 'redux-act';

import { UserTopScores } from '../../models/UserTopScores';

export const setUserTopScores = createAction<UserTopScores>('set score box');

const reducer = createReducer<UserTopScores>({}, null);

reducer.on(setUserTopScores, (_state, payload: UserTopScores) => payload);

export default reducer;
