import { MiscUtils } from '../../../utils/MiscUtils';
import { LS_COOKIE_CONSTS } from '../../models/Enums';
import { AppInsightService } from '../AppInsight';
import { LocalStorageService } from '../LocalStorage';
import { AnalyticsEventsPair } from './AnalyticsEventBuilder';
import { AnalyticsExtension, extendersList } from './Extensions';
import { ProfileAnalytics } from './ProfileAnalytics';
import { GemsAnalytics } from './GemsAnalytics';
import { GoogleAnalytics } from './Google';

class AnalyticsService {

    profile = new ProfileAnalytics();

    gems = new GemsAnalytics();

    globalAIData: any = {
        targetingConsent: LocalStorageService.getItem(LS_COOKIE_CONSTS.TARGETING_CONSENT),
    };

    private extensionManager: AnalyticsExtension = new AnalyticsExtension(extendersList);

    trackEvent(event: AnalyticsEventsPair) {
        if (!MiscUtils.isServer) {
            this.extensionManager.extend(event);

            if (event.ai) {
                AppInsightService.trackEvent(event.ai.name, {
                    ...event.ai.data,
                    ...this.globalAIData,
                });
            }

            if (event.ga) {
                GoogleAnalytics.trackEvent(event.ga.data);
            }
        }
    }
}
export const Analytics = new AnalyticsService();
