import { apiFetch } from '../../fetch';
import { environment } from '../config/environment';
import { ArenaAuthResponse } from '../models/ArenaAuthResponse';
import { ExperienceResponse } from '../models/ExperienceDate';
import { OAuthResponse } from '../models/OAuthResponse';
import { User } from '../models/User';
import { authService } from './AuthService';
import { GeoService } from './GeoService';
import { UrlService } from './UrlService';

export const API_PATH = environment.USER_API;

class UserService {
    createFromOAuthResponse(res: OAuthResponse, { gdprUserData, gdprEmailMarketing }): Promise<User> {
        return GeoService.getUserCountry().then((country) => {
            const body = JSON.stringify({
                firstName: res.user.first_name,
                lastName: res.user.last_name,
                email: res.user.email,
                name: res.user.name,
                authToken: res.user.access_token,
                countryCode: country,
                gdprUserData,
                gdprEmailMarketing,
            });
            const url = `${API_PATH}/user/create?authProvider=${res.authProvider}&arenaDomain=${UrlService.domain}`;

            return apiFetch(url, { method: 'POST', body }).then((userData: ArenaAuthResponse) => {
                void authService.setToken(userData.access_token);
                return userData.user;
            });
        });
    }

    update(user: User): Promise<User> {
        return authService.authFetch(`${API_PATH}/user`, { method: 'POST', body: JSON.stringify(user) });
    }

    getExperience(slug: string, value: number): Promise<ExperienceResponse> {
        const body = JSON.stringify({ slug, value });

        return authService.authFetch(`${API_PATH}/profile/reward`, { method: 'POST', body });
    }

    changePassword(oldPassword: string, newPassword: string) {
        const body = JSON.stringify({ oldPassword, newPassword });

        return authService.authFetch(`${API_PATH}/user/password/change`, { method: 'POST', body });
    }
}

export const userService = new UserService();
