type KeyValue = {
  [key: string]: any;
};

export type AnalyticsEvent = {
  name: string;
  data: KeyValue;
};

export type AnalyticsEventsPair = {
  ga: AnalyticsEvent;
  ai: AnalyticsEvent;
};

export function makeEventsPair(gaEvent: AnalyticsEvent | null, aiEvent: AnalyticsEvent | null): AnalyticsEventsPair {
  return {
    ga: gaEvent,
    ai: aiEvent,
  } as AnalyticsEventsPair;
}

export enum AnalyticsEventAction {
  CLICK = 'Click',
  IMPRESSION = 'Impression',
  CANCEL = 'Cancel',
  NO = 'No',
  LOGIN = 'Login',
  SKIP_AD = 'SkipAd',
  POWER_UP = 'PowerUp',
}

export class AnalyticsEventBuilder {
  public options = {};

  private name: string | number = '';

  baseEvent(): AnalyticsEventBuilder {
    return this;
  }

  pageViewEvent(): AnalyticsEventBuilder {
    return this;
  }

  setNonInteraction(noninteraction: boolean): AnalyticsEventBuilder {
    this.add({ noninteraction });
    return this;
  }

  setEventAction(action: AnalyticsEventAction | string | number): AnalyticsEventBuilder {
    this.add({ action });
    return this;
  }

  setName(name: string) {
    this.name = name;
  }

  setCustom(key: string, value: any): AnalyticsEventBuilder {
    this.add({ [key]: value });
    return this;
  }

  build() {
    return {
      name: this.name,
      data: { ...this.options },
    } as AnalyticsEvent;
  }

  private add(data: KeyValue = {}) {
    this.options = {
      ...this.options,
      ...data,
    };
  }
}
