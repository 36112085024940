import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PrimaryBigButton } from '../../atoms/Buttons/PrimaryBigButton';
import { RefreshIcon } from '../../atoms/Icons/RefreshIcon';
import { BelowXXLQuery, DesktopQuery, TabletQuery } from '../../atoms/Layout/Responsive';
import { THEMES } from '../../consts';
import { ActionTypes } from '../../models/Analytics';
import { ArenaConfig } from '../../models/ArenaConfig';
import { KeysEnum } from '../../models/Enums';
import { ExperienceReward } from '../../models/ExperienceDate';
import { Game } from '../../models/Game';
import { EagleUser, User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { UserTopScores } from '../../models/UserTopScores';
import { DisplayAd } from '../../molecules/DisplayAd/DisplayAd';
import { GameEndLogin } from '../../molecules/GameEndLogin/GameEndLogin';
import { LeaderboardTabs } from '../../molecules/LeaderboardTab/LeaderboardTab';
import { AnalyticsGamePage } from '../../services/Analytics/AnalyticsGamePage';
import { AnalyticsInit } from '../../services/Analytics/AnalyticsInit';
import { DeviceDetector } from '../../services/DeviceDetector';
import { EagleLevelupService } from '../../services/EagleLevelupService';
import { LocalStorageService } from '../../services/LocalStorage';
import { AchievementType, ProfileService } from '../../services/ProfileService';
import RemoteScoreService from '../../services/RemoteScoreService';
import UUPScoreService from '../../services/UUPScoreService';
import { saveScore } from '../../store/ducks/gameScores';
import { setLeaderboardList, setLeaderboardLoading } from '../../store/ducks/leaderboard';
import { AppState } from '../../store/types';
import { GameEndScore } from '../GameEndScores/GameEndScore';
import { GameEndXp } from '../GameEndXp/GameEndXp';
import styles from './GameEnd.css';
import { AchievementEvent } from '../../models/AchievementEvent';

type GameEndProps = {
    user?: User | EagleUser;
    userAuthStatus: string;
    gameScore?: number;
    isScoreSaving?: boolean;
    topScores?: UserTopScores;
    game: Game;
    gameStartTime: Date;
    dispatch: any;
    config: ArenaConfig;
    isAdsEnabled: boolean;
    aspectRatio: number;
    isEagle: boolean;
    saveRecentlyPlayed: (slug: string) => Promise<void>;
};

type GameEndState = {
    userPlace: number;
    areUserScoresLoaded: boolean;
    highscores: object[];
    experienceReward: ExperienceReward;
    userUpdated: User;
};

class GameEndBase extends React.PureComponent<GameEndProps & WithTranslation> {
    containerRef = React.createRef<HTMLDivElement>();
    userWhoGotPlace: User | EagleUser;

    state: GameEndState = {
        userPlace: null,
        areUserScoresLoaded: false,
        highscores: [],
        experienceReward: null,
        userUpdated: null,
    };

    constructor(props) {
        super(props);

        if (!props.gameScore) {
            this.state.areUserScoresLoaded = true;
        } else {
            this.getUserPlace();
        }
    }

    componentDidMount() {
        const gameEndTime = Math.round((Date.now() - this.props.gameStartTime.getTime()) / 1000);

        AnalyticsGamePage.gameEnd(gameEndTime, this.props.gameScore);
        AnalyticsGamePage.playAgain(ActionTypes.IMPRESSION);
        this.scrollWindowToTop(); // scrolling to top if user has 0 points

        window.dispatchEvent(new Event('game:end')); // do not remove this because external scripts use the event

        if (this.props.userAuthStatus !== UserAuthStatus.USER_AUTHORIZED) {
            this.getUserPlace();
        }
    }

    componentDidUpdate(prevProps: GameEndProps, prevState: GameEndState) {
        if (prevState.areUserScoresLoaded !== this.state.areUserScoresLoaded && this.state.areUserScoresLoaded) {
            this.scrollWindowToTop(); // scrolling to top after user scores are loaded
        }

        // checks if user have logged in
        if (
            prevProps.userAuthStatus !== UserAuthStatus.USER_AUTHORIZED &&
            this.props.userAuthStatus === UserAuthStatus.USER_AUTHORIZED
        ) {
            if (this.userWhoGotPlace) {
                // if user have logged in with another account, setting user place and score to 0
                const isAnotherAuthedUser =
                    'uid' in this.props.user
                        ? this.props.user.uid !== (this.userWhoGotPlace as EagleUser).uid
                        : this.props.user.id !== (this.userWhoGotPlace as User).id;

                if (isAnotherAuthedUser) {
                    const timeOffset = new Date().getTimezoneOffset();
                    const startTime = this.props.gameStartTime || new Date();
                    const dateTime = startTime.toISOString();

                    this.setState({ userPlace: 0 });

                    this.props.dispatch(saveScore(this.props.game.slug, 0, dateTime, timeOffset));
                }

                return;
            }

            this.getUserPlace();
            this.props.saveRecentlyPlayed(this.props.game.slug);
        }
    }

    updateXpReward = (experienceReward: ExperienceReward, userUpdated: User) => {
        this.setState({ experienceReward, userUpdated });
    };

    render() {
        const title = this.getCaption();
        const { user, gameScore, game, config, isAdsEnabled, isScoreSaving } = this.props;
        const { userPlace, experienceReward, userUpdated } = this.state;
        // TODO: move this flag to game config and get it through the game data
        const donNotSupportSaveScore =
            config.theme.disableLogin || game.slug.includes('uclick') || game.slug.includes('amu-');
        const isDigitalTurbine =
            this.props.config.theme.theming.name === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();

        return (
            <React.Fragment>
                <Container ref={this.containerRef} data-element-description="game-end container">
                    <Title data-element-description="game-end header" dangerouslySetInnerHTML={{ __html: title }} />

                    <Row>
                        <GameEndScore currentScore={gameScore} highScore={this.getHighScore()} userPlace={userPlace} />
                    </Row>
                    <RowButtons>
                        {
                            <PrimaryBigButton
                                data-element-description="game-end play-again-button"
                                onClick={isScoreSaving ? null : this.onPlayAgain}
                                disabled={isScoreSaving}
                            >
                                <PlayAgainIcon isMirrored={isDigitalTurbine} />
                                {this.props.t('PLAY_AGAIN')}
                            </PrimaryBigButton>
                        }
                    </RowButtons>

                    {!user && !donNotSupportSaveScore && (
                        <Row>
                            <GameEndLogin />
                        </Row>
                    )}

                    {user && (
                        <RowXp>
                            <GameEndXp
                                experienceReward={experienceReward}
                                userUpdated={userUpdated}
                                currentUser={user}
                                game={game}
                                updateXpReward={this.updateXpReward}
                            />
                        </RowXp>
                    )}

                    {isAdsEnabled && (
                        <Row>
                            <DesktopQuery>
                                <BelowXXLQuery>
                                    <DisplayAd
                                        componentId="ark_display_ge1"
                                        dataElementDescription="ark-display-ge1"
                                        dimensions={[[468, 60]]}
                                    />
                                </BelowXXLQuery>
                            </DesktopQuery>
                            <TabletQuery>
                                <DisplayAd
                                    componentId="ark_display_ge1"
                                    dataElementDescription="ark-display-ge1"
                                    dimensions={[[728, 90]]}
                                />
                            </TabletQuery>
                        </Row>
                    )}
                </Container>
            </React.Fragment>
        );
    }

    private onPlayAgain = () => {
        AnalyticsInit.setPlayAgainOrFromRecGames(null, 'yes');
        AnalyticsGamePage.playAgain(ActionTypes.CLICK);
        window.location.reload();
    };

    private getUserPlace() {
        const { gameScore, user, game, userAuthStatus, isEagle, dispatch } = this.props;
        const isUserLoggedIn = userAuthStatus === UserAuthStatus.USER_AUTHORIZED;
        const ScoreService = isEagle ? UUPScoreService : RemoteScoreService;
        const authorizedUserId = user && ('uid' in user ? user.uid : user.id);

        ScoreService.getLeaderboard(game.slug, LeaderboardTabs.TODAY, true, authorizedUserId).then((results: any) => {
            if (isUserLoggedIn) {
                ScoreService.patchCachedLeaderboardWithUserScore(game.slug, user as EagleUser, gameScore, false).then(
                    () => {
                        ScoreService.getLeaderboard(game.slug, LeaderboardTabs.TODAY, false, authorizedUserId).then(
                            (results) => {
                                dispatch(setLeaderboardList(results));
                                dispatch(setLeaderboardLoading(false));
                            }
                        );
                    }
                );
            }

            let userPlace;
            const userAlreadyHasPlace = results.find((res) => res.isCurrentUser);
            const willUserGetPlace = userAlreadyHasPlace ? gameScore >= userAlreadyHasPlace.score : gameScore > 0;

            if (willUserGetPlace) {
                const scores = results
                    .filter((scoreRow: any) => !scoreRow.isCurrentUser)
                    .concat([{ type: 'newRecord', score: gameScore }])
                    .sort((a, b) => b.score - a.score)
                    .slice(0, 100);

                userPlace = scores.findIndex((s) => s.type === 'newRecord') + 1;
            }

            // TODO levelup microservice for Eagle
            if (isUserLoggedIn && gameScore > 0) {
                const service = isEagle ? EagleLevelupService : ProfileService;
                const timeOffset = new Date().getTimezoneOffset();
                const startTime = this.props.gameStartTime || new Date();
                const dateTime = startTime.toISOString();

                // It happens on change score event
                // dispatch(saveScore(game.slug, gameScore, dateTime, timeOffset));

                service.saveAchievement({
                    eventName: userPlace ? AchievementType.RANK_ACHEIEVED : AchievementType.SCORE_ACHEIEVED,
                    score: gameScore,
                    gameName: game.name,
                    slug: game.slug,
                    userTodayRank: userPlace,
                } as AchievementEvent);
            }

            this.setState({
                userPlace: userPlace || 0,
                areUserScoresLoaded: true,
                highScore: results,
            });

            if (isUserLoggedIn) {
                this.userWhoGotPlace = user;
            }

            if (!isUserLoggedIn && gameScore > 0) {
                LocalStorageService.setItem(
                    KeysEnum.arkStatePlayedGame,
                    JSON.stringify({
                        slug: game.slug,
                        score: gameScore,
                        eventName: userPlace ? AchievementType.RANK_ACHEIEVED : AchievementType.SCORE_ACHEIEVED,
                        gameName: game.name,
                        userTodayRank: userPlace,
                    } as AchievementEvent)
                );
            }
        });
    }

    private getHighScore() {
        const { topScores, gameScore } = this.props;

        return (topScores && topScores.all) || gameScore;
    }

    private getCaption() {
        const { t, gameScore } = this.props;
        const { highscores, userPlace } = this.state;

        if (gameScore > this.getHighScore()) {
            return t('GAME_END_NEW_HIGHSCORE');
        }

        if (userPlace > 0 && userPlace <= 3) {
            return t('GAME_END_TOP_LEADERBOARD');
        } else if (userPlace > 3 && userPlace < highscores.length - 1) {
            const count = highscores.length - userPlace;

            return t('GAME_END_GREATE_JOB', { count });
        } else {
            return t('GAME_END_ZERO_HEADER');
        }
    }

    private scrollWindowToTop() {
        if (DeviceDetector.isNotPc()) {
            const gameEndDom: any = this.containerRef.current;

            if (gameEndDom) {
                const adElementName = DeviceDetector.isMobile()
                    ? '[data-id=ark_display_m1]'
                    : '[data-id=ark_display_t1]';
                const topAd = document.querySelector(adElementName);
                const topPadding = topAd ? topAd.clientHeight + 15 : 10;
                const topPos = gameEndDom.getBoundingClientRect().top - topPadding;

                Promise.resolve().then(() => {
                    window.scrollTo(0, topPos);

                    // fix of scroll to top on game end on mobiles issue,
                    // each time when user scrolls a page, it jumps to a page's top
                    window.scrollTo = function () {
                        return;
                    };
                });
            }
        }
    }
}

const Container = React.forwardRef<any, any>((props, ref) => <div ref={ref} className={styles.container} {...props} />);
const Title = (props: any) => (
    <p className={classNames(styles.title, styles.__override)} {...props}>
        {props.children}
    </p>
);
const Row = (props: any) => <div className={styles.row} {...props} />;
const RowButtons = (props: any) => <div className={`${styles.row} ${styles.rowButtons}`} {...props} />;
const RowXp = (props: any) => <div className={`${styles.row} ${styles.rowXp}`} {...props} />;
const PlayAgainIcon = ({ isMirrored, ...props }: any) => (
    <RefreshIcon className={styles.playAgainIcon} isMirrored={isMirrored} {...props} />
);

export const GameEndTrans = withTranslation()(GameEndBase);

export const GameEnd = connect((state: AppState) => ({
    user: state.user,
    userAuthStatus: state.userAuthStatus,
    gameScore: state.gameScore,
    topScores: state.userTopScores,
    isAdsEnabled: state.config.theme.shouldShowDisplayAd(),
    isEagle: state.config.isEagle,
    isScoreSaving: state.isScoreSaving,
}))(GameEndTrans);
