import classNames from 'classnames';
import React from 'react';
import { THEMES } from '../../consts';
import { Game } from '../../models/Game';
import { GameTile } from '../../molecules/Tiles/GameTile';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './GamesList.css';

type GamesListProps = {
    games: Game[];
    columnClass: string;
    hScroll?: string;
    onTileClick?: (slug: string) => void;
    isTextDisabled?: boolean;
    themeName: string;
    useExternalImages?: boolean;
};

export class GamesList extends React.PureComponent<GamesListProps> {
    render() {
        const isDigitalTurbine = this.props.themeName === THEMES.DIGITAL_TURBINE && DeviceDetector.isMobile();

        return (
            <Container hScroll={this.props.hScroll}>
                <Row>
                    {this.props.games.map((game) => (
                        <Cell
                            key={game.slug}
                            className={classNames(styles.tileCell, this.props.columnClass, {
                                [styles.digitalTurbine]: isDigitalTurbine,
                            })}
                        >
                            <GameTile
                                onTileClick={this.props.onTileClick}
                                game={game}
                                isTextDisabled={this.props.isTextDisabled}
                                themeName={this.props.themeName}
                                useExternalImages={this.props.useExternalImages}
                            />
                        </Cell>
                    ))}
                </Row>
            </Container>
        );
    }
}

const Row = (props: any) => <div className={`row ${styles.gamesRow}`} {...props} />;
const Container = ({ hScroll, ...props }) => (
    <div
        className={classNames(styles.Container, {
            [styles.hScrollMobile]: hScroll === 'mobile',
            [styles.hScrollTablet]: hScroll === 'tablet',
        })}
        {...props}
    />
);
const Cell = (props: any) => <div {...props} />;
