import {
  UserInventoryUpdateRequestDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/inventory-item';
import {
  PurchasableItemPurchaseItemRequestDto
} from '@arkadium/eagle-virtual-items-api-client/dist/types/api/v1/dto/purchasable-item';

import { environment } from '../config/environment';

export const skipPrerollItem: PurchasableItemPurchaseItemRequestDto = {
    sku: environment.SKU_GEMS_SKIP_AD_BUNDLE,
    amount: 1,
};

export const skipPrerollVirtualItem: UserInventoryUpdateRequestDto = {
    items: [
        {
            sku: environment.SKU_GEMS_SKIP_AD,
            amount: 1,
        },
    ],
};

// TODO: replace later with actual gems SKU
export const GEMS_TEST_SKU = environment.GEMS_TEST_SKU;
export const GEMS_COST_PREROLL = 5; // todo after mvp replace with real request to eagle
