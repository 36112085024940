import { AnalyticsEventsPair } from '../AnalyticsEventBuilder';
import { AnalyticsExtender } from './types';

export const extendersList: AnalyticsExtender[] = [];

export class AnalyticsExtension {
    list: AnalyticsExtender[] = [];

    constructor(list: AnalyticsExtender[]) {
        this.list = list;
    }

    extend(event: AnalyticsEventsPair) {
        this.list.forEach((extend) => {
            extend(event);
        });
    }
}
