import { createAction, createReducer } from 'redux-act';

import { UserAuthStatus } from '../../models/UserAuthStatus';

export const setUserAuthStatus = createAction<UserAuthStatus>('set user auth status');

const reducer = createReducer<UserAuthStatus>({}, UserAuthStatus.USER_NOT_AUTHORIZED);

reducer.on(setUserAuthStatus, (_state, payload: UserAuthStatus) => payload);

export default reducer;
