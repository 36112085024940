import classNames from 'classnames';
import { TFunction } from 'i18next';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isServer, isUclickGame } from '../../../utils';
import { LogoIcon } from '../../atoms/Icons/LogoIcon';
import { Link as RouterLink } from '../../atoms/Link/Link';
import { FreshDesk } from '../../molecules/Freshdesk/FreshDesk';
import { Tooltip } from '../../molecules/Tooltip/Tooltip';
import { UClickSupportModal } from '../../molecules/UclickSupportModal/UClickSupportModal';
import { DeviceDetector, DeviceType } from '../../services/DeviceDetector';
import { UrlService } from '../../services/UrlService';
import { AppState } from '../../store/types';
import { ElPaisFooter } from '../CustomFooters/ElPais/ElPaisFooter';
import styles from './Footer.css';

import YPCImage from '../../../public/svg/YPC.svg';
import StarTribuneFooter from '../CustomFooters/StarTribune/StarTribuneFooter';

interface FooterBaseProps {
    client: string;
    isUclickGameOn: boolean;
    arenaName: string;
    gameName: string;
    routerPath: string;
    allCategoriesKeys: string[];
}

const EL_PAIS = 'elpais';
const AS = 'as';
const USAT = 'usatoday';
const ABC = 'abc-es';
const STAR_TRIBUNE = 'startribune';

export class FooterBase extends React.PureComponent<FooterBaseProps & WithTranslation> {
    state = {
        supportTooltipText: this.props.t('CONTACT_US_TOOLTIP'),
    };

    componentDidMount() {
        this.updateTooltipText();
    }

    componentDidUpdate() {
        this.updateTooltipText();
    }

    updateTooltipText() {
        const { isUclickGameOn } = this.props;

        if (isUclickGameOn) {
            this.setState({
                supportTooltipText: 'Contact UClick, the provider of these games',
            });
        }
    }

    showCookieSettingButton(): boolean {
        if (isServer) {
            return false;
        }

        const w = window as any;

        return (w && w.__ARK__ThirdPartyScripts && w.__ARK__ThirdPartyScripts.showCookieSettingButton) || false;
    }

    render() {
        const {
            client,
            routerPath,
        } = this.props;
        const isSubscription = routerPath === `/${this.props.t('ROUTES.SHOP')}`;

        if (isSubscription) {
            return null;
        }

        const showCookieSettingButton = this.showCookieSettingButton();

        switch (client) {
            case EL_PAIS:
            case AS:
                return <ElPaisFooter client={client} />;
            case STAR_TRIBUNE:
                return (
                  <StarTribuneFooter
                      renderDefaultFooter={() => this.renderDefaultFooter(showCookieSettingButton)}
                  />
                );
            default:
                return this.renderDefaultFooter(showCookieSettingButton);
        }
    }

    renderContactUsButton = (suffix?: string) => {
        const {
            t,
            isUclickGameOn,
        } = this.props;
        const SupportElement = isUclickGameOn ? UClickSupportModal : FreshDesk;
        const isDesktop = DeviceDetector.detectDevice() === DeviceType.DESKTOP;

        return isDesktop ? (
            <Tooltip text={this.state.supportTooltipText}>
                <SupportElement><>{t('CONTACT_US_BTN')}{suffix}</></SupportElement>
            </Tooltip>
        ) : (
            <SupportElement><>{t('CONTACT_US_BTN')}{suffix}</></SupportElement>
        );
    }

    renderDefaultFooter(showCookieSettingButton: boolean) {
        const {
            t,
            client,
            arenaName,
            gameName,
            routerPath,
        } = this.props;
        const currLang = this.props.i18n.language;
        const privacyPolicyUrl = UrlService.getPrivacyPolicyUrl(currLang, client);
        let pageType = '';

        if (!isServer) {
            const gotPageType = UrlService.getPageType();
            const isHomePage = gotPageType === 'Home page';

            switch (true) {
                /* fix localized urls not always detect category page vs home page */
                case isHomePage && window.location.pathname.length <= 3 /* locale code redirects, e.g. /de/ to /de */
                :
                    pageType = 'Home page';
                    break;
                default:
                    pageType = gotPageType;
                    break;
            }
        }

        const seoText = generateSeoText(pageType, t, arenaName, gameName, routerPath);

        return (
            <FooterInner>
                <Container>
                    <Row>
                        {client !== USAT && (
                            <LogoColumn>
                                <LogoLink
                                    href="https://www.arkadium.com/"
                                    aria-label="Arkadium Logo"
                                    target="_blank"
                                >
                                    <LogoIcon/>
                                </LogoLink>
                            </LogoColumn>
                        )}

                        <LinksColumn client={client}>
                            <Row>
                                <ItemColumn>
                                    <GlobalLink rel="noreferrer nofollow" href={privacyPolicyUrl} target="_blank">
                                        {client === USAT ? t('ARKADIUMS_PRIVACY_BTN') : t('PRIVACY_BTN')}
                                    </GlobalLink>
                                </ItemColumn>

                                {client === ABC && (
                                    <>
                                        <ItemColumn>
                                            <Link href={`https://www.abc.es/contacto/aviso-legal.html`} target="_blank">{t('LEGAL_WARNING_LINK')}</Link>
                                        </ItemColumn>
                                        <ItemColumn>
                                            <Link href={`https://www.abc.es/contacto/contacte.html`} target="_blank">{t('CONTACT_US_BTN')}</Link>
                                        </ItemColumn>
                                    </>
                                )}

                                <ItemColumn>{this.renderContactUsButton(client === ABC ? ' - Arkadium' : undefined)}</ItemColumn>

                                <ItemColumn>
                                    <GlobalLink
                                        rel="noreferrer nofollow"
                                        href="https://corporate.arkadium.com/arena"
                                        target="_blank"
                                    >
                                        {t('PUBLISHERS_BTN')}
                                    </GlobalLink>
                                </ItemColumn>

                                <ItemColumn>
                                    <Link to={`/${t('ROUTES.HELP')}`}>{t('HELP_PAGE_LINK')}</Link>
                                </ItemColumn>

                                {client === USAT ? (
                                    <>
                                        <ItemColumn>
                                            <Link href={`https://cm.usatoday.com/privacy`} target="_blank">{t('PRIVACY_BTN')}</Link>
                                        </ItemColumn>
                                        <ItemColumn>
                                            <Link href="https://cm.usatoday.com/your-privacy-choices" target="_blank">
                                                <YPCImage />
                                                {t('USAT_PRIVACY_CHOICES')}
                                            </Link>
                                        </ItemColumn>
                                    </>
                                ): showCookieSettingButton && (
                                    <ItemColumn>
                                        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                                            {' '}
                                            {t('COOKIE_SETTINGS_BTN')}
                                        </button>
                                    </ItemColumn>
                                )}
                            </Row>
                        </LinksColumn>
                    </Row>
                    {Boolean(seoText) && (
                        <Row>
                            <p className={classNames(styles['footer_seo-text'], styles.__override)}>{seoText}</p>
                        </Row>
                    )}
                </Container>
            </FooterInner>
        );
    }
}

const Container = (props) => <div className={styles.customContainer} {...props} />;
const FooterInner = (props) => <footer className={styles.footerInner} {...props} />;
const Row = (props) => <div className="row" {...props} />;
const LogoColumn = (props) => <div className="col-xs-12 col-md-4 col-lg-4 order-2 order-md-0" {...props} />;
const LinksColumn = (props) => <div className={`col-xs-12 col-md-${props.client === USAT ? '8' : '7'} col-lg-8`} {...props} />;
const ItemColumn = (props) => (
    <div className={`col-xs-12 col-md-6 col-lg-6 ${styles.itemColumn}`} {...props} />
);
const LogoLink = (props) => (
    <a className={styles.logoLink} {...props}>
        {props.children}
    </a>
);
const GlobalLink = (props) => (
    <a className={styles.link} {...props}>
        {props.children}
    </a>
);
const Link = (props) => <RouterLink className={styles.link} {...props} />;
const FooterTranslated = withTranslation()(FooterBase);

export const Footer = connect((state: AppState) => ({
    client: state.config.theme.client,
    isUclickGameOn: isUclickGame(state.game),
    arenaName: state.config.theme.name,
    gameName: state?.game?.name || '',
    routerPath: state?.router?.location?.pathname || '',
    allCategoriesKeys: state.config.categories.allCategories,
}))(FooterTranslated);

function generateSeoText(
    pageType: string,
    t: TFunction,
    clientName: string,
    gameName: string,
    routerPath: string,
): string {
    clientName = clientName === 'Ask Games' ? 'Ask Games powered by Arkadium' : clientName; // no translation because US
    let seoText: string = t('FOOTER_SEO_TEXT', { clientName });

    try {
        switch (pageType) {
            case 'Game page':
                seoText =
                    t(
                        'FOOTER_SEO_TEXT__GAME',
                        {
                            gameName,
                            clientName,
                        },
                    );
                break;
            case 'Category page':
                const routerPathArr = routerPath.split('/');
                const categoryFromUrl = routerPathArr[routerPathArr.length - 1];
                const categoryDeserialized = UrlService.deserializeCategoryFromUrl(categoryFromUrl);

                seoText =
                    t(
                        'FOOTER_SEO_TEXT__CATEGORY',
                        {
                            categoryName: categoryDeserialized,
                            clientName,
                        },
                    );
                break;
            default:
                // use default footer seo text case of HOME_PAGE and others, - default seo text
                break;
        }
    } catch (e) {
        console.error(`Footer seo text generation error: ${e}`);
        // use default footer seo text fallback
    }

    return seoText;
}
