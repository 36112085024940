import { isServer } from '../../utils';
import { SlotParams, SlotParamsDeclaration } from '../models/SlotParams';

class IsomorphicSlotParams {
    public params: SlotParams | any = {};

    constructor() {
        if (!isServer) {
            this.params = (window as any).__SLOT_SERVER_PARAMS__ || {};
        } else {
            const validSlots = Object.keys(SlotParamsDeclaration);
            const slot = process.env.APP_SLOT || 'prod';

            if (validSlots.indexOf(slot) === -1) {
                throw new Error(
                    `Invalid APP_SLOT parameter was passed - ${slot}. Valid values are: ${validSlots.join(', ')}`
                );
            }

            console.debug('BUILD: App slot used -> ' + slot);

            // Then it will be printed to html as __SLOT_SERVER_PARAMS__
            this.params = SlotParamsDeclaration[slot];
        }
    }

    isProd() {
        return this.params.SLOT === 'prod';
    }

    isDev() {
        return this.params.SLOT === 'dev';
    }
}

export const SlotParamsService = new IsomorphicSlotParams();
