import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { GameState } from '../../models/Enums';
import { baseHrefSelector } from '../../store/ducks/routerSelectors';
import { AppState } from '../../store/types';
import styles from './Link.css';
import classNames from 'classnames';

interface LinkProps {
    baseHref: string;
    to?: string;
    href?: string;
}

const LinkBase = React.memo(({ baseHref, to, href, gameState, ...props }: LinkProps & any) => {
    const isGame = gameState && gameState !== GameState.AD_BLOCKER;

    // If href is provided, it would render regular link
    if (href) {
        return (
            <a href={baseHref + href} {...props}>
                {props.children}
            </a>
        );
    }

    if (isGame) {
        return (
            <a href={baseHref + to} {...props}>
                {props.children}
            </a>
        );
    }

    // If to is provided, it would render router link
    if (to) {
        return <RouterLink to={baseHref + to} {...props} />;
    }

    return null;
});

export const Link = connect(
    (state: AppState) => ({
        gameState: state.gameState,
        baseHref: baseHrefSelector(state),
    }),
    {}
)(LinkBase);

type NavLinkProps = {
    children: ReactNode;
    to?: string;
    target?: string;
    className?: string;
    rel?: string;
    role?: React.AriaRole;
    draggable?: boolean;
    tabIndex?: number;
    suppressHydrationWarning?: boolean;
    onClick?: (...args: unknown[]) => void;
    onMouseDown?: (...args: unknown[]) => void;
    onTouchStart?: (...args: unknown[]) => void;
    onDragStart?: (...args: unknown[]) => void;
    onKeyDown?: (...args: unknown[]) => void;
    itemProp?: string;
    // the title is added so that only links in the header don't jump
    title?: string;
};

export const NavLink = ({
    to,
    className,
    target,
    rel,
    tabIndex,
    role,
    children,
    onClick,
    title,
    itemProp,
    onDragStart,
    onKeyDown,
    draggable,
    suppressHydrationWarning,
}: NavLinkProps) => {
    return (
        <Link
            role={role}
            onClick={onClick}
            className={classNames(styles.baseStyle, className)}
            data-name="22"
            to={to}
            title={title}
            itemProp={itemProp}
            target={target}
            rel={rel}
            tabIndex={tabIndex}
            onDragStart={onDragStart}
            onKeyDown={onKeyDown}
            draggable={draggable}
            suppressHydrationWarning={suppressHydrationWarning}
        >
            {children}
        </Link>
    );
};
