import React, { useEffect } from 'react';

import { OpenWebProvider } from '@open-web/react-sdk';
import { useDispatch } from 'react-redux';

import { OpenWebService } from '../../services/OpenWebService';
import { EagleUser, User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { EagleLoginService } from '../../services/EagleLoginService';
import { LoginCaller } from '../../models/Login';
import { logoutUser } from '../../store/ducks/user';
import { ELoginSteps, setLoginModal } from '../../store/ducks/modal';

type OpenWebProviderComponentProps = {
    user: User | EagleUser;
    userAuthStatus: UserAuthStatus;
    spotId: string;
    disableLogin: boolean;
};

const OpenWebProviderComponent = ({user , spotId, disableLogin}: OpenWebProviderComponentProps) => {
    const dispatch = useDispatch();

    async function loginIntoOpenWeb() {
        if (disableLogin) {
            return;
        }

        const token = await EagleLoginService.getToken();

        if (token) {
            OpenWebService(user, token, true);
        }
    }

    useEffect(() => {
        if (user) {
            loginIntoOpenWeb();
        }
    }, [user]);

    return (
        <OpenWebProvider
            spotId={spotId}
            tracking={{
                ['spot-im-login-start']: (event) => {
                    if (!disableLogin) {
                        dispatch(setLoginModal({isOpen: true, step: ELoginSteps.LOGIN, caller: LoginCaller.OpenWeb}));
                    }
                },
                ['spot-im-current-user-sent-message-clicked']: (event) => {
                    event.preventDefault();
                    // TODO: Analytics.trackEvent(Analytics.games.commentSubmit(game));
                },
                ['spot-im-user-logout']: async (event) => {
                    event.preventDefault();

                    // true for not calling own logout in loop
                    if (!disableLogin && await EagleLoginService.getToken()) {
                        dispatch(logoutUser());
                    }
                },
            }}
        />
    );
};

export default OpenWebProviderComponent;
