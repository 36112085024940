import { createAction, createReducer } from 'redux-act';
import { createSelector } from 'reselect';
import { Game } from '../../models/Game';

export const setGames = createAction<Game[]>('add games');

const reducer = createReducer<Game[]>({}, []);

reducer.on(setGames, (_state, payload: Game[] = []) => payload.slice(0));

export default reducer;

/** Selectors */
const getConfig = (state) => state.config;
const getGames = (state) => state.games;
const getLang = (state) => state.currentLang;

export const gamesByLangSelector = createSelector(getGames, getLang, (games, lang) => {
    if (!games) {
        return [];
    }

    return games.filter((game) => game.locales && game.locales.indexOf(lang) !== -1).map((game) => new Game(game));
});

export const gameByAliasSelector = createSelector(
    getConfig,
    getGames,
    getLang,
    (state, props) => props.match.params.game,
    (config, games, lang, gameParam) => {
        // TODO Remove rewrite logic if obsolete
        const rewrites = config.feed && config.feed.gamesRewrite;
        let alias = gameParam;

        if (rewrites && rewrites[gameParam]) {
            alias = rewrites[gameParam];
        }

        const game = games.find((g) => g.alias === alias) as Game;

        if (game && game.locales.indexOf(lang) !== -1) {
            return new Game(game);
        }
    }
);
