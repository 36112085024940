import { AnalyticsInit } from './Analytics/AnalyticsInit';
import { LocalStorageService } from './LocalStorage';
import { ThirdPartyScriptsKeys } from '../models/ThirdPartyScripts';
import { ArenaConfig } from '../models/ArenaConfig';
import { Subject } from '../models/Subject';

class QuantcastServiceFactory {
    isEnabled: boolean = false;
    isGdprSupport: boolean = false;
    isTargetingConsent: boolean = true;
    targetingConsentSubject = new Subject();
    readonly gdprCookieConsent = 'gdprCookieConsent';
    readonly targetingConsent = 'targetingConsent';

    public init(config: ArenaConfig) {
        this.isEnabled = !!config.scripts.public.find((item) => item.name === ThirdPartyScriptsKeys.QUANTCAST);
        this.isGdprSupport = config.isGdprSupport || false;

        if (!this.isEnabled) {
            return;
        }

        // @ts-ignore
        if (window.__tcfapi) {
            // @ts-ignore
            window.__tcfapi('addEventListener', 2, this.onChanges);
        } else {
            this.setConsent(true);
            this.setGdprCookieConsent(false);
        }
    }

    onChanges = () => {
        try {
            // @ts-ignore
            __tcfapi('getTCData', 2, (tcData) => {
                try {
                    this.setConsent(Boolean(tcData.purpose.consents[1]));
                    console.log('targetingConsent =' + Boolean(tcData.purpose.consents[1]));
                } catch (ex) {
                    this.setConsent(false);
                    console.log('Exception: targetingConsent = false');
                }

                try {
                    if (tcData.gdprApplies) {
                        const consent = Boolean(tcData.purpose.consents[1]) && Boolean(tcData.vendor.consents[11]);

                        this.setGdprCookieConsent(consent);
                        console.log('gdprConsent =' + consent);
                    } else {
                        this.setGdprCookieConsent(true);
                    }
                } catch (ex) {
                    this.setGdprCookieConsent(false);
                    console.log('Exception: gdprConsent  =' + this.getGdprCookieConsent());
                }
            });
        } catch (e) {
            this.setConsent(true);
            this.setGdprCookieConsent(true);
        }
    };

    blockedByGdprConsent = () => {
        if (!this.isEnabled || !this.isGdprSupport) {
            return false;
        }

        return !this.getGdprCookieConsent();
    };

    private setConsent(consent: boolean) {
        const prevTargetingConsentEnabled = this.isTargetingConsent;

        this.isTargetingConsent = consent;

        AnalyticsInit.setGlobalCustomDimensions(undefined, { targetingConsent: this.isTargetingConsent });
        LocalStorageService.setItem(this.targetingConsent, this.isTargetingConsent, true);

        if (prevTargetingConsentEnabled !== this.isTargetingConsent) {
            this.targetingConsentSubject.next(this.isTargetingConsent);
        }
    }

    private setGdprCookieConsent(consent: boolean) {
        LocalStorageService.setItem(this.gdprCookieConsent, consent, true);
    }

    getGdprCookieConsent = () => {
        const consent = LocalStorageService.getItem(this.gdprCookieConsent) === 'true';

        return consent;
    };
}

export const QuantcastService = new QuantcastServiceFactory();
