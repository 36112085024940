import { DeviceDetector } from '../services/DeviceDetector';
import { LocalStorageService } from '../services/LocalStorage';
import { SortingService } from '../services/SortingServices';
import { setConfig } from '../store/ducks/config';
import { setGames } from '../store/ducks/games';
import { AppState } from '../store/types';

export class StoreModifiers {
    /** Local test config for QA */
    static useConfigFromLocalStorage(store): void {
        const configCopy = Object.assign({}, store.getState().config);

        Object.keys(configCopy).forEach((prop) => {
            const localConfigData = LocalStorageService.getItem(`TEST__CONFIG__${prop}`);

            if (localConfigData) {
                configCopy[prop] = JSON.parse(localConfigData);
            }
        });
        store.dispatch(setConfig(configCopy));
    }

    /** Ensure if sorting from server is valid */
    static applyGlobalGamesSortings(store): void {
        const { games, sortings } = store.getState() as AppState;
        const deviceType = DeviceDetector.detectDevice();
        const sortedGames = SortingService.sortGames(games.slice(), sortings.manual, sortings.analytics[deviceType]);

        store.dispatch(setGames(sortedGames));
    }
}
