import React from 'react';
import { AppLoader } from '../../atoms/AppLoader/AppLoader';
import { AchievementEvent } from '../../models/AchievementEvent';
import { Game } from '../../models/Game';
import { EagleUser, User } from '../../models/User';
import { UserAuthStatus } from '../../models/UserAuthStatus';
import { Achievements } from '../../organisms/Achievements/Acheivements';
import { ProfilePerson } from '../../organisms/ProfilePerson/ProfilePerson';
import { ProfileXp } from '../../organisms/ProfileXp/ProfileXp';
import { RecentlyPlayed } from '../../organisms/RecentlyPlayed/RecentlyPlayed';
import styles from './Profile.css';
import { AnnounceBanner } from '../../organisms/AnnounceBanner/AnnounceBanner';
import { ProfileBadgesBlock } from '../../atoms/Badges/ProfileBadgesBlock/ProfileBadgesBlock';

type ProfileProps = {
  user: User | EagleUser;
  userAuthStatus: UserAuthStatus;
  achievements: AchievementEvent[];
  recentlyPlayedGames: Game[];
  themeName: string;
  hasPassword: boolean;
};

export class ProfileTemplate extends React.PureComponent<ProfileProps> {
  render() {
    const {
      user,
      userAuthStatus,
      achievements,
      recentlyPlayedGames,
      themeName,
      hasPassword,
    } = this.props;
    const isContentVisible =
      userAuthStatus === UserAuthStatus.USER_AUTHORIZED && achievements !== null && recentlyPlayedGames !== null;

    return (
      <Container>
        <AnnounceBanner/>
        {!isContentVisible && <AppLoader/>}
        {isContentVisible && userAuthStatus === UserAuthStatus.USER_AUTHORIZED && (
          <TopBlock>
            <PersonBlock>
              <ProfilePerson user={user} hasPassword={hasPassword}/>
            </PersonBlock>
            <XpBlock>
              <ProfileXp user={user}/>
            </XpBlock>
          </TopBlock>
        )}
        {isContentVisible && (
          <BottomBlock>
            <ProfileBadgesBlock userName={user.name}/>
            <RecentlyPlayedBlock>
              <RecentlyPlayed themeName={themeName} games={recentlyPlayedGames || []}/>
            </RecentlyPlayedBlock>
            <AchievementsBlock>
              <Achievements achievements={achievements || []} userName={user.name}/>
            </AchievementsBlock>
          </BottomBlock>
        )}
      </Container>
    );
  }
}

const Container = (props: any) => <div className={`container ${styles.Content}`} {...props} />;
const TopBlock = (props: any) => <div className={`row ${styles.TopBlock}`} {...props} />;
const PersonBlock = (props: any) => <div className={`col-md-6 ${styles.PersonBlock}`} {...props} />;
const XpBlock = (props: any) => <div className={`col-md-6 ${styles.XpBlock}`} {...props} />;
const BottomBlock = (props: any) => <div className={`row ${styles.BottomBlock}`} {...props} />;
const AchievementsBlock = (props: any) => <div className={'col-md-6'} {...props} />;
const RecentlyPlayedBlock = (props: any) => <div className={'col-md-6'} {...props} />;
