import React from 'react';
import styles from './LightboxPromotionPopup.css';
import { useTranslation } from 'react-i18next';
import { TLightboxPromotion } from '../../models/LightboxPromotions';
import { LightboxPromotionService } from '../../services/LightboxPromotionsService';
import { Link } from 'react-router-dom';
import { AnalyticsLightbox, ELightboxActionsAnalytics } from '../../services/Analytics/AnalyticsLightbox';
import { ActionTypes } from '../../models/Analytics';
type TProps = {
    popup: TLightboxPromotion;
    buttonText: string;
    onClose: (withAnalytics: boolean) => void;
    currentLang: string;
    isMobile: boolean;
    eventDuration: number;
    defaultColor: string;
};

export const LightboxPromotionPopup = ({
    popup,
    buttonText,
    onClose,
    currentLang,
    isMobile,
    eventDuration,
    defaultColor,
}: TProps) => {
    const DEFAULT_BG_COLOR_PLAY_BUTTON = '#b80ee5';
    const DEFAULT_PLAY_BUTTON_TEXT_COLOR = defaultColor;
    const DEFAULT_DESCRIPTION_TEXT_COLOR = defaultColor;
    const imageUrl = LightboxPromotionService.getImageUrl(popup.image, isMobile);
    const { t } = useTranslation();
    const submitButtonUrl = `/${t('ROUTES.GAMES')}/${popup.slug}${popup.arkpromo ? '?arkpromo=' : ''}${
        popup.arkpromo ?? ''
    }`;
    const onPlayClick = () => {
        AnalyticsLightbox.event(ELightboxActionsAnalytics.PLAY, ActionTypes.CLICK, popup.slug, eventDuration);

        onClose(false);
    };

    return (
        <section className={styles.sectionWrapper}>
            <img src={imageUrl} className={styles.image} alt={popup.title[currentLang]} />
            <h3
                className={styles.title}
                style={{ color: popup.descriptionTextColor || DEFAULT_DESCRIPTION_TEXT_COLOR }}
            >
                {popup.title[currentLang]}
            </h3>
            <article
                className={styles.description}
                style={{ color: popup.descriptionTextColor || DEFAULT_DESCRIPTION_TEXT_COLOR }}
            >
                {popup.description[currentLang]}
            </article>
            <Link
                style={{
                    backgroundColor: popup.buttonColor || DEFAULT_BG_COLOR_PLAY_BUTTON,
                    color: popup.buttonTextColor || DEFAULT_PLAY_BUTTON_TEXT_COLOR,
                }}
                className={styles.submitButton}
                to={submitButtonUrl}
                onClick={onPlayClick}
            >
                {buttonText}
            </Link>
        </section>
    );
};
