import classNames from 'classnames';
import React from 'react';
import styles from './Button.css';
type TBuyButton = {
    price?: string;
    onClick: () => void;
    className?: string;
};

export const BuyButton = ({ price, onClick, className }: TBuyButton) => {
    return (
        <button className={classNames(styles.buyButton, className)} onClick={onClick}>
            {price}
        </button>
    );
};
