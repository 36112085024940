import { RecurlyGemPackItems, RecurlyGemsItemNames } from './RecurlyGemPackItems';

export enum PaymentType {
  gems = 'Gems',
}

export type RecurlyPlanObject = {
  key: string;
  plan: string;
  title: string;
  description: string;
  price?: number;
  gemsAmount?: number;
};

const getRecurlyGoodsNames = () => {
  const gemPackItems = RecurlyGemPackItems();

  return {
    [PaymentType.gems]: { ...gemPackItems },
  };
};

export const RecurlyGoodsNames = {
  [PaymentType.gems]: RecurlyGemsItemNames,
};

export const RecurlyPlans = getRecurlyGoodsNames();

export function chooseGemsPacksPlan() {
  return RecurlyPlans[PaymentType.gems].forRegistered;
}
