export class MiscUtils {
    public static get isServer(): boolean {
        return typeof window === 'undefined';
    }

    public static isAdBlockEnabled(): boolean {
        return (window as any).__ark_ads__ === undefined || typeof (window as any).__ark_ads__.AdVideo !== 'function';
    }

    public static getNowTimestampUTC(): number {
        return new Date().getTime();
    }
    public static numberWithCommas(num: string | number): string {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}
