import React, { createRef, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArrowIcon } from '../../atoms/Icons/Styleguide/ArrowIcon';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import CountriesList from '../../constants/CountriesList';
import { UrlService } from '../../services/UrlService';
import { Input } from '../Input/Input';
import styles from './Select.css';
import { AssetService } from '../../services/AssetService';

type SearchSelectProps = {
    value: string;
    onChange: (v) => void;
    name: string;
    items?: unknown[];
    placeholder?: string;
    fullWidth?: boolean;
    className?: string;
    validate?: (name, value) => void;
    isValid: boolean;
};

export const SearchSelect = ({
    value,
    onChange,
    name,
    items,
    placeholder,
    fullWidth,
    className,
    validate,
    isValid,
}: SearchSelectProps) => {
    const searchList = createRef<HTMLUListElement>();
    const selectRef = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<{ name: string; id: string } | null>(
        CountriesList.filter((i) => i.id === value)[0] || null
    );
    const getCountryImgUrl = (id) => {
        return AssetService.getFlag(id.toLowerCase());
    };
    const handleDocumentClick = (e) => {
        if (!(e.target === selectRef.current || selectRef.current.contains(e.target))) {
            setIsOpened(false);
        }
    };

    useEffect(() => {
        window.document.addEventListener('click', handleDocumentClick);

        return () => {
            window.document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    // TODO: try to use searchservice/improve search
    const filteredItems = (initialArray) => {
        const result = [];

        if (name === 'country') {
            initialArray.forEach((i) => {
                i.name.toLowerCase().includes(searchValue.toLowerCase()) && result.push(i);
            });
        } else {
            initialArray.forEach((i) => {
                i.toLowerCase().includes(searchValue.toLowerCase()) && result.push(i);
            });
        }

        return result;
    };

    useEffect(() => {
        !isOpened && setSearchValue('');
    }, [isOpened]);

    // added for autocomplete -
    // if the user has a country in the data for autofill in the correct format(and we have it from our side),
    // then select this country
    useEffect(() => {
        if (name === 'country') {
            filteredItems(CountriesList).filter((i) => {
                if (i.name.toLowerCase() === searchValue.toLowerCase()) {
                    onChange(i.id);
                    setSelectedCountry(i);
                    setIsOpened(false);
                    validate && validate(name, i.id);
                    setSearchValue('');
                }
            });
        }
    }, [searchValue]);

    // added for autocomplete -
    // if the user has a value in the autofill data and we have it in the filtered items,
    // then select this value
    useEffect(() => {
        if (name !== 'country') {
            items
                ? filteredItems(items).length &&
                  filteredItems(items).filter((item) => {
                      if (item.toLowerCase() === searchValue.toLowerCase()) {
                          onChange(item);
                          setIsOpened(false);
                          validate && validate(name, item);
                          setSearchValue('');
                      }
                  })
                : searchValue.length > 0 && validate && validate(name, '');
        }
    }, [searchValue, items]);

    // TODO countryselect: avoid downloading every flag img on reopening/search

    const renderItems = (type) => {
        switch (type) {
            case 'country':
                return filteredItems(CountriesList).length ? (
                    filteredItems(CountriesList).map((i, ind) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                        <li
                            tabIndex={ind}
                            onClick={() => {
                                onChange(i.id);
                                setSelectedCountry(i);
                                setIsOpened(false);
                                validate && validate(name, i.id);
                                setSearchValue('');
                            }}
                            key={i.id}
                        >
                            <img alt={i.name} src={getCountryImgUrl(i.id)} className={styles.countryImg} />
                            {i.name}
                        </li>
                    ))
                ) : (
                    <li className={styles.noResultsText}>
                        <I18nText keyName="NOT_FOUND_COUNTRY" />
                    </li>
                );
            default:
                return filteredItems(items).length ? (
                    filteredItems(items).map((item, index) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                        <li
                            onClick={() => {
                                onChange(item);
                                setIsOpened(false);
                                validate && validate(name, item);
                                setSearchValue('');
                            }}
                            // Usage index as a key doesn't cause any issues in this case.
                            // Because won't be sorted or edited by user.
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                        >
                            {item}
                        </li>
                    ))
                ) : (
                    <li className={styles.noResultsText}>
                        <I18nText keyName="NOT_FOUND_COUNTRY" />
                    </li>
                );
        }
    };

    return (
        <div
            ref={selectRef}
            className={classNames(
                className,
                styles.selectWrapper,
                fullWidth && styles.fullWidth,
                isOpened && styles.opened
            )}
        >
            {/* TODO: add validate of input if needed */}
            <Input
                className={classNames(
                    value && styles.selected,
                    fullWidth && styles.fullWidth,
                    className && className,
                    styles.searchInput
                )}
                closeIconClassName={styles.searchCloseIcon}
                value={searchValue}
                onChange={(i) => {
                    setSearchValue(i.target.value);
                }}
                onFocus={() => setIsOpened(true)}
                placeholder={!value ? placeholder : ''}
                isValid={isValid}
                clickOnCloseIcon={() => setSearchValue('')}
                maxLength={20}
            />
            <div className={classNames(styles.selectedItem, value && styles.selected)}>
                {name !== 'country' && !isOpened && <span>{value}</span>}
                {name === 'country' && selectedCountry !== null && !isOpened && (
                    <>
                        <img
                            alt={selectedCountry.name}
                            src={getCountryImgUrl(selectedCountry.id)}
                            className={styles.countryImg}
                        />
                        <span>{selectedCountry.name}</span>
                    </>
                )}
            </div>
            {(value === '' || searchValue.length > 0) && <ArrowIcon className={styles.selectArrow} />}
            {isOpened && (
                <ul ref={searchList} className={styles.itemsWrapper}>
                    {renderItems(name)}
                </ul>
            )}
            {value && !isOpened && (
                <CloseIcon
                    onMouseDown={() => {
                        onChange('');
                        setSelectedCountry(null);
                    }}
                    className={styles.closeIcon}
                />
            )}
        </div>
    );
};
