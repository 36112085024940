import React from 'react';
import { DesktopQuery, MobileQuery, TabletAndDesktopQuery, TabletQuery } from '../../atoms/Layout/Responsive';
import { ActionTypes, CustomCategories } from '../../models/Analytics';
import { Game } from '../../models/Game';
import { DisplayAd } from '../../molecules/DisplayAd/DisplayAd';
import { PageBottomDescription } from '../../molecules/PageBottomDescription/PageBottomDescription';
import { PwaButtons } from '../../molecules/PwaButtons/PwaButtons';
import { AnnounceBanner } from '../../organisms/AnnounceBanner/AnnounceBanner';
import { GamesList } from '../../organisms/GamesList/GamesList';
import { AnalyticsHomePage } from '../../services/Analytics/AnalyticsHomePage';
import { PoweredBy } from '../../organisms/PoweredBy/PoweredBy';
import { PoweredByContainer } from '../../organisms/PoweredBy/PoweredByContainer';
import { frenchTextFix } from '../../services/EventCategoryService';

import classnames from 'classnames';
import styles from './Category.css';

type SearchProps = {
    categoryKey: string;
    category?: string;
    games: Game[];
    caption: string;
    isAdsEnabled: boolean;
    themeName?: string;
    disablePoweredBy?: boolean;
    client?: string;
    eventCustomStyles?: string;
    eventsCustomClass?: string;
};

export const CategoryTemplate = React.memo(
    ({ games = [], caption, categoryKey, category, isAdsEnabled, themeName, eventCustomStyles, eventsCustomClass, client, disablePoweredBy }: SearchProps) => {
        const onTileClick = (slug) => {
            AnalyticsHomePage.tilesSections(
                ActionTypes.CLICK,
                CustomCategories.PROMO_BOX_SLOT,
                `${categoryKey}|${slug}|yes`
            );
        };

        return (
            <React.Fragment>
                {<style>{eventCustomStyles || ''}</style>}
                <Container data-element-description="category page" eventsCustomClass={eventsCustomClass}>
                    <AnnounceBanner />

                    {isAdsEnabled && (
                        <MobileQuery>
                            <AdRowTop>
                                <DisplayAd
                                    componentId="ark_display_top"
                                    dataElementDescription="ark-display-top"
                                    dimensions={[[320, 50]]}
                                />
                            </AdRowTop>
                        </MobileQuery>
                    )}

                    <TopRow>
                        <PwaButtons />
                        <TitleCell data-element-description="category page title">{caption}</TitleCell>

                        <div className="d-none d-md-block col">
                            {!disablePoweredBy && client === 'usatoday' && (
                                <PoweredByContainer position='right'>
                                    <PoweredBy/>
                                </PoweredByContainer>
                            )}
                        </div>
                    </TopRow>

                    <Content>
                        <GamesTiles>
                            <GamesList
                                onTileClick={(slug) => onTileClick(slug)}
                                games={games}
                                columnClass="col-lg-3 col-md-4 col-6"
                                themeName={themeName}
                            />

                            {isAdsEnabled && (
                                <AdRowBottom>
                                    <DesktopQuery>
                                        <DisplayAd
                                            componentId="ark_display_bottom"
                                            dataElementDescription="ark-display-bottom"
                                            dimensions={[[728, 90]]}
                                        />
                                    </DesktopQuery>
                                    <TabletQuery>
                                        <DisplayAd
                                            componentId="ark_display_bottom"
                                            dataElementDescription="ark-display-bottom"
                                            dimensions={[[468, 60]]}
                                        />
                                    </TabletQuery>
                                    <MobileQuery>
                                        <DisplayAd
                                            componentId="ark_display_bottom"
                                            dataElementDescription="ark-display-bottom"
                                            dimensions={[[300, 250]]}
                                        />
                                    </MobileQuery>
                                </AdRowBottom>
                            )}

                            {category && <PageBottomDescription category={category} />}
                        </GamesTiles>

                        {isAdsEnabled && (
                            <TabletAndDesktopQuery>
                                <AdSidebar>
                                    <DesktopQuery>
                                        <DisplayAd
                                            componentId="ark_display_r1"
                                            dataElementDescription="ark-display-r1"
                                            className={styles.displayAd}
                                            dimensions={[
                                                [300, 600],
                                                [300, 250],
                                            ]}
                                        />
                                    </DesktopQuery>
                                    <TabletQuery>
                                        <DisplayAd
                                            componentId="ark_display_r1"
                                            dataElementDescription="ark-display-r1"
                                            className={styles.displayAd}
                                            dimensions={[[160, 600]]}
                                        />
                                    </TabletQuery>
                                </AdSidebar>
                            </TabletAndDesktopQuery>
                        )}
                    </Content>
                </Container>
            </React.Fragment>
        );
    }
);

const Container = ({eventsCustomClass, ...props}) => (
    <div className={classnames('container', styles.container, eventsCustomClass)} {...props} />
);
const Content = (props: any) => <div className={styles.content} {...props} />;
const GamesTiles = (props: any) => <div className={styles.gamesTiles} {...props} />;
const AdSidebar = (props: any) => <div className={styles.adSidebar} {...props} />;
const AdRowTop = (props: any) => <div className={styles.adRowTop} {...props} />;
const AdRowBottom = (props: any) => <div className={styles.adRowBottom} {...props} />;
const TopRow = (props: any) => <div className={styles.topRow} {...props} />;
const TitleCell = (props: any) => (
    <h1 className={styles.title} {...props}>
        {frenchTextFix(props.children)}
    </h1>
);
