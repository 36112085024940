import { isServer } from '../../utils';

let webpSupported: Promise<boolean>;

export class ImageService {

    static isWebpSupported(): Promise<boolean> {
        if (isServer || !self.createImageBitmap) {
            return Promise.resolve(false);
        }

        if (webpSupported) {
            return webpSupported;
        }

        webpSupported = new Promise((resolve) => {
            const image  = new Image();

            image.onerror = function () { return resolve(false); };

            image.onload = function () { return resolve(image.width === 1); };

            image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
        });

        return webpSupported;
    }
}
