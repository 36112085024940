import React from 'react';
import { Game } from '../../models/Game';
import styles from './GameRules.css';

export const GameRules = React.memo((props: { game: Game, t: any }) => {
    const { game, t } = props;

    return (
        <Container>
            <Header>{game.name} {t('RULES_OVERVIEW_WORD')}</Header>
            <Description>
                <div dangerouslySetInnerHTML={{ __html: game.description }} />
            </Description>

            {game.rules && (
                <Description>
                    <div dangerouslySetInnerHTML={{ __html: game.rules }} />
                </Description>
            )}
        </Container>
    );
});

const Container = (props) => <div className={styles.container} {...props} />;
const Header = (props) => (<h2 className={styles.header} {...props}>{props.children}</h2>);
const Description = (props) => <div className={styles.description} {...props} />;
