import { sanitizeString } from '../../utils';

const LOCATION_CHANGE_EVENT = '@@router/LOCATION_CHANGE';

export const urlSanitizerMiddleware = ({ dispatch, getState }) => (next) => (action: {type: string, payload: any}) => {

    if (action.type === LOCATION_CHANGE_EVENT) {
        action.payload.location.pathname = sanitizeString(action.payload.location.pathname);
    }

    return next(action);
};
