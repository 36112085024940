import { createAction, createReducer } from 'redux-act';

import { GameRelatedGames } from '../../models/RelatedGames';

export const setRelatedGames = createAction<GameRelatedGames[]>('set game related games');

const reducer = createReducer<GameRelatedGames[]>({}, []);

reducer.on(setRelatedGames, (_state, payload: GameRelatedGames[]) => payload);

export default reducer;
