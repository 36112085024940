import classNames from 'classnames';
import React, { createRef, useEffect, useState } from 'react';
import { useCheckoutPricing, UseCheckoutPricingInput } from '@recurly/react-recurly';
import { useDispatch, useSelector } from 'react-redux';

import { PageTypes } from '../../constants/Pages';
import {
  chooseGemsPacksPlan,
  PaymentType,
  RecurlyGoodsNames
} from '../../constants/RecurlyPurchase';
import { ProgressBar } from '../../FigmaStyleguide/ProgressBar/ProgressBar';
import { PaymentMethod } from '../../models/Payment/PaymentForm';
import CaptchaIframe from '../../molecules/ChallengeCaptcha/CaptchaIframe';
import { ChallengeCaptchaModal } from '../../molecules/ChallengeCaptcha/modal/ChallengeCaptchaModal';
import useCaptcha from '../../molecules/ChallengeCaptcha/hooks/useCaptcha';
import { SummaryBlock } from '../../molecules/Subscription/SummaryBlock/SummaryBlock';
import { GemsAnalyticsCustomDimensions } from '../../services/Analytics/AI/GemsAnalyticsAi';
import { LocalStorageSKeys } from '../../services/AuthService';
import { LocalStorageService } from '../../services/LocalStorage';
import { setChosenGemsPack } from '../../store/ducks/gems';
import { setFullScreenLoading } from '../../store/ducks/layout';
import { setPaymentMethod, setStepIndex } from '../../store/ducks/subscription/common';
import { PaymentInfoStep } from './PurchaseSteps/PaymentInfoStep';
import { ReviewPaymentInfoStep } from './PurchaseSteps/ReviewPaymentInfoStep';
import { ConfirmationStep } from './PurchaseSteps/ConfirmationStep';
import SummaryInfo from './SummaryInfo/SummaryInfo';
import styles from './PurchasePageTemplate.css';
import { BelowSMQuery } from '../../atoms/Layout/Responsive';

type PurchasePageTemplateProps = {
  paymentType?: PaymentType;
  paymentMethodsApplied?: PaymentMethod[];
};

const paymentMethodsAppliedDefault: PaymentMethod[] = [
  PaymentMethod.CARD,
  PaymentMethod.PAYPAL
];
const PurchasePageTemplate = ({
                                paymentType = PaymentType.gems,
                                paymentMethodsApplied = paymentMethodsAppliedDefault
                              }: PurchasePageTemplateProps) => {
  const dispatch = useDispatch();
  const formRef = createRef<HTMLFormElement>();
  const [promoCodes, setPromoCodes] = useState<string[]>([]);
  const [recurlyPricingError, setRecurlyPricingError] = useState(null);
  const paymentMethod = useSelector((state) => state.paymentMethod);
  const chosenGemsPackItemName =
    LocalStorageService.getItem(LocalStorageSKeys.gemPackId) || RecurlyGoodsNames[PaymentType.gems][0];
  const stepIndex = useSelector((state) => state.stepIndex);
  const pageTypeIsGiftCard = useSelector((state) => state.pageType) === PageTypes.GiftCard;
  const taxData = useSelector((state) => state.billingDataForTax);
  const [smoothScrolling, setSmoothScrolling] = useState({
    isScrolling: false,
    id: null
  });
  const gemPackInStore = useSelector((state) => state.chosenGemsPackItemName);
  const paymentGoodPlan = chooseGemsPacksPlan()[chosenGemsPackItemName];
  const planCode = paymentGoodPlan?.plan;
  const initialPricingInput: UseCheckoutPricingInput = {
    ['adjustments']: [
      {
        ['itemCode']: paymentGoodPlan?.plan,
        ['quantity']: 1
      }
    ],
    address:
      paymentMethod === PaymentMethod.PAYPAL || !taxData
        ? null
        : taxData
  };
  const [{ price }, setCheckoutPricing] = useCheckoutPricing(initialPricingInput, setRecurlyPricingError);
  // Prices
  const taxSum = price?.now?.taxes === '0.00' ? null : `${price?.currency?.symbol}${price?.now?.taxes}`;
  const totalSum = price?.now?.total; // check subtotal for test
  // For Analytics
  const packObj = chooseGemsPacksPlan()[chosenGemsPackItemName];
  const gemsAnalyticsProps: GemsAnalyticsCustomDimensions = {
    gemsPackId: chosenGemsPackItemName,
    priceInGem: Number(price?.now?.subtotal),
    gemsInPack: packObj?.gemsAmount,
    isFirstPurchase: true
  };
  const {
    iframeRef,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    iframeURL
  } = useCaptcha();

  useEffect(() => {
    const chosenGemPack = LocalStorageService.getItem(LocalStorageSKeys.gemPackId);

    dispatch(setChosenGemsPack(chosenGemPack));
  }, [gemPackInStore]);

  useEffect(() => {
    setCheckoutPricing((prev) => ({ ...prev, ...initialPricingInput }));
  }, [paymentType, paymentMethod, taxData, setCheckoutPricing, chosenGemsPackItemName]);

  useEffect(() => {
    if (stepIndex === 0) {
      dispatch(setFullScreenLoading(false));
    }

    return () => {
      dispatch(setStepIndex(0));
      dispatch(setPaymentMethod(PaymentMethod.CARD));
    };
  }, []);

  useEffect(() => {
    if (smoothScrolling.isScrolling) {
      formRef?.current?.children.namedItem(smoothScrolling.id).scrollIntoView({
        behavior: 'smooth'
      });
      setSmoothScrolling({
        isScrolling: false,
        id: null
      });
    }
  }, [smoothScrolling]);

  const getStepView = (step: number) => {
    switch (step) {
      case 1:
        return (
          <ReviewPaymentInfoStep
            paymentGoodPlan={paymentGoodPlan}
            paymentType={paymentType}
            price={price}
            setSmoothScrolling={setSmoothScrolling}
            ref={formRef}
            gemsAnalyticsProps={gemsAnalyticsProps}
          />
        );
      case 2:
        return (
          <ConfirmationStep
            paymentType={paymentType}
            gemsAnalyticsProps={gemsAnalyticsProps}
            getCaptchaToken={getCaptchaToken}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ChallengeCaptchaModal
        modalClassName={classNames({ [styles.captchaModalWrapper]: showChallengeRecaptcha })}
        onClose={clearCaptchaData}
        isVisible={showChallengeRecaptcha}
      >
        <CaptchaIframe
          iframeRef={iframeRef}
          iframeURL={iframeURL}
          setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}
        />
      </ChallengeCaptchaModal>
      <main className={styles.subscriptionTemplate}>
        <div className={styles.paymentInfo}>
          {!pageTypeIsGiftCard && (
            <div className={styles.progressBarBlock}>
              <ProgressBar
                steps={[{ label: 'Payment Info' }, { label: 'Review' }, { label: 'Confirmation' }]}
                activeStepIndex={stepIndex}
              />
            </div>
          )}
          <BelowSMQuery>
            <div className={styles.summaryTopMobileBlock}>
              <SummaryBlock
                heading={stepIndex !== 2 ? 'Your order' : 'Order Summary'}
                totalSum={totalSum}
                taxSum={taxSum}
                promoCodes={promoCodes}
                price={price}
                paymentGoodPlan={paymentGoodPlan}
                paymentType={paymentType}
                gemsAnalyticsProps={gemsAnalyticsProps}
              />
            </div>
          </BelowSMQuery>
          {/* this is not in the getStepView() function, because we want to save the user's credit card data
                 if user returns from step 2
                 (the data is in the iframe, so the component won't unmount to save the data) */}
          <PaymentInfoStep
            isStepVisible={stepIndex === 0}
            ref={formRef}
            paymentMethodsApplied={paymentMethodsApplied}
            gemsAnalyticsProps={gemsAnalyticsProps}
          />
          {getStepView(stepIndex)}
        </div>
        <SummaryInfo
          formRef={formRef}
          setCheckoutPricing={setCheckoutPricing}
          totalSum={totalSum}
          taxSum={taxSum}
          setPromoCodes={setPromoCodes}
          recurlyPricingError={recurlyPricingError}
          promoCodes={promoCodes}
          price={price}
          paymentGoodPlan={paymentGoodPlan}
          paymentType={paymentType}
          gemsAnalyticsProps={gemsAnalyticsProps}
          planId={planCode}
          getCaptchaToken={getCaptchaToken}
          showChallengeRecaptcha={showChallengeRecaptcha}
          clearCaptchaData={clearCaptchaData}
        />
      </main>
    </>
  );
};

export default PurchasePageTemplate;
