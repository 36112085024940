import classNames from 'classnames';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DeviceDetector } from '../../services/DeviceDetector';
import styles from './Loader.css';

const GameLoaderBase = React.memo((props: any & WithTranslation) => {
    const letters = Array.from(props.t('GAME_LOADER_TITLE').toUpperCase());

    return (
        <LoaderContainer isForMobileCrawling={props.isForMobileCrawling}>
            <LoaderText>
                {letters.map((letter, i) => {
                    const letterShown = letter !== ' ' ? letter : '&nbsp;';

                    return <LoadingLetter key={i} dangerouslySetInnerHTML={{ __html: letterShown }} />;
                })}
            </LoaderText>
        </LoaderContainer>
    );
});
const LoaderContainer = (props: any) => (
    <div
        className={classNames(styles.loaderContainer, {
            [styles.fullScreen]: DeviceDetector.isNotPc() && !props.isForMobileCrawling,
        })}
        {...props}
    />
);
const LoaderText = (props: any) => <div className={styles.loaderText} {...props} />;
const LoadingLetter = (props: any) => <span className={styles.loadingLetter} {...props} />;

export const GameLoader = withTranslation()(GameLoaderBase);
