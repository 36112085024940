// SIMPLE ENCRYPTION MECHANISM (DECIMAL NUMBER TO ANOTHER-BASE-NUM_STRING)
enum BADGE_CRYPT_BASE {
   BASE_NUMBER = 4
}
export const BadgesEncodeValue = (value: number): string => value && value.toString(BADGE_CRYPT_BASE.BASE_NUMBER);
export const BadgesDecodeValue = (value: string): number => value && parseInt(value, BADGE_CRYPT_BASE.BASE_NUMBER);

// SAVING TO LOCAL STORAGE (GET AND SET)
export const BadgesStorageInterface = (propName: string, propValue?: number): any => {

    return (
        propValue === undefined
            ? BadgesDecodeValue(window.localStorage.getItem(propName))
            : window.localStorage.setItem(propName, BadgesEncodeValue(propValue))
    );
};
