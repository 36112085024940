import React, { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { I18nText } from '../../../atoms/i18nText/i18nText';
import { Link, NavLink } from '../../../atoms/Link/Link';
import { RecurlyPlanObject } from '../../../constants/RecurlyPurchase';
import { PaymentType } from '../../../constants/RecurlyPurchase';
import { PCILogo } from '../../../FigmaStyleguide/Icons/PCILogo';
import { SOCLogo } from '../../../FigmaStyleguide/Icons/SOCLogo';
import { RECAPTCHA_ACTIONS_TYPE } from '../../../molecules/ChallengeCaptcha/hooks/useCaptcha';
import { PromoCodeBlock } from '../../../molecules/Subscription/PromoCodeBlock/PromoCodeBlock';
import { SummaryBlock } from '../../../molecules/Subscription/SummaryBlock/SummaryBlock';
import { GemsAnalyticsCustomDimensions } from '../../../services/Analytics/AI/GemsAnalyticsAi';
import { UrlService } from '../../../services/UrlService';
import styles from '../PurchasePageTemplate.css';
import { SubmitButton } from './SubmitButton';

type SummaryInfoProps = {
  totalSum: string;
  taxSum: string | null;
  promoCodes: string[];
  setPromoCodes: (v: any) => void;
  setCheckoutPricing: any; // recurly func
  recurlyPricingError: any; // recurly obj
  price: any; // recurly obj
  formRef: RefObject<HTMLFormElement>;
  paymentGoodPlan: RecurlyPlanObject;
  paymentType: PaymentType;
  gemsAnalyticsProps: GemsAnalyticsCustomDimensions;
  planId: string;
  getCaptchaToken: (action: RECAPTCHA_ACTIONS_TYPE) => void;
  showChallengeRecaptcha: boolean;
  clearCaptchaData: () => void;
};

const SummaryInfo = React.memo(
  ({
    totalSum,
    taxSum,
    promoCodes,
    setPromoCodes,
    setCheckoutPricing,
    recurlyPricingError,
    price,
    formRef,
    paymentGoodPlan,
    paymentType,
    gemsAnalyticsProps,
    getCaptchaToken,
    showChallengeRecaptcha,
    clearCaptchaData,
  }: SummaryInfoProps) => {
    const stepIndex = useSelector((state) => state.stepIndex);
    const currLang = useSelector((state) => state.currentLang);
    const privacyPolicyUrl = UrlService.getPrivacyPolicyUrl(currLang);

    return (
      <div className={styles.summaryInfo}>
        <div className={styles.sticky}>
          <SummaryBlock
            heading={stepIndex !== 2 ? 'Summary' : 'Order Summary'}
            totalSum={totalSum}
            taxSum={taxSum}
            hidden={stepIndex === 2}
            price={price}
            promoCodes={promoCodes}
            paymentGoodPlan={paymentGoodPlan}
            paymentType={paymentType}
            gemsAnalyticsProps={gemsAnalyticsProps}
          />
          <div className={styles.summaryInfo__nextStep}>
            {stepIndex !== 2 && (
              <div className={styles.mainBtnWrapper}>
                <SubmitButton
                  formRef={formRef}
                  paymentType={paymentType}
                  gemsAnalyticsProps={gemsAnalyticsProps}
                  getCaptchaToken={getCaptchaToken}
                  showChallengeRecaptcha={showChallengeRecaptcha}
                  clearCaptchaData={clearCaptchaData}
                />
              </div>
            )}
            {stepIndex !== 2 && (
              <div className={styles.nextstepText}>
                {stepIndex === 0 ? (
                  <p>
                    <I18nText keyName="PURCHASE_PAGE.CONFIRMING_PURCHASE_INFO_TEXT"/>
                  </p>
                ) : (
                  <p>
                    <I18nText keyName="PURCHASE_PAGE.CONFIRMING_PURCHASE_DESCRIPTION"/>
                    <br/>
                    Arkadium's&nbsp;
                    <NavLink
                      to="/visitor-agreement.pdf"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <I18nText keyName="PURCHASE_PAGE.SUBSCRIPTION_AGREEMENT"/>&nbsp;
                    </NavLink>
                    and&nbsp;
                    <Link
                      className={styles.baseLink}
                      href={privacyPolicyUrl}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <I18nText keyName="PRIVACY_BTN"/>
                    </Link>
                  </p>
                )}
              </div>
            )}
          </div>
          {stepIndex === 0 && (
            <PromoCodeBlock
              recurlyPricingError={recurlyPricingError}
              setCheckoutPricing={setCheckoutPricing}
              promoCodes={promoCodes}
              setPromoCodes={setPromoCodes}
              price={price}
            />
          )}
          {stepIndex !== 2 && (
            <div className={styles.secureTypesBlock}>
              <div className={styles.pciLogo}>
                <PCILogo/>
              </div>
              <div className={styles.socLogo}>
                <SOCLogo/>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default SummaryInfo;
